import { BehaviorSubject } from "rxjs";
import http from "../common/common";
import { handleResponse } from "../helpers/handle-response";
import { history } from "../helpers/history";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(data) {
  return http.post("/auth/signin", data)
    .then(handleResponse)
    .then((user) => {
      if (user.data.payload != null) {
        var currentUser = JSON.stringify(user.data.payload);
        localStorage.setItem("currentUser", currentUser);
        currentUserSubject.next(currentUser);
      }
      return user;
    })
    .catch((error) => {
      
    });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  history.push("/");
  let pathUrl = window.location.href;
  window.location.href = pathUrl;
}

export default authService;