import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { adminRole, applicantRole, brokerRole, rrRole, supervisorRole24, supervisorRole4 } from '../../helpers/constants';

const LeftNav = () => {

    const [role, setRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        if (user !== undefined && user !== null)
            setRole(user.userRole);
        else {
            navigate("/");
        }
    });

    return (
        <>
            <section className="leftNav" data-gr-ext-installed="" light-mode="light">
                <NavLink to={role && role === applicantRole ? '/dashboard' : role === brokerRole ? '/broker/dashboard'
                    : role === rrRole ? '/registerrep/dashboard' : role === supervisorRole24 || role === supervisorRole4 ? '/supervisor/dashboard' : '/admin/dashboard'}>
                    <div className='logo'>
                        <img src='../../assets/images/willson-davis.jpg' />
                    </div></NavLink>

                <ul className="leftMenu">

                    {role && role === applicantRole && <li title='Dashboard'><NavLink to='/dashboard' className="active"><i className="icoMenu icon-application"></i></NavLink></li>}

                    {role && role === brokerRole && <li title='Dashboard'><NavLink to='/broker/dashboard'><i className="icoMenu icon-application"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Approval Queue'><NavLink to='/broker/application-queue'><i className="icoMenu admin-Approval"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Closed Queue'><NavLink to='/broker/pendingcancel-queue'><i className="icoMenu admin-Closed"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Broker Alert Types'><NavLink to='/broker/brokeralerttypes'><i className="icoMenu admin-Alert"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Billing History'><NavLink to='/broker/brokerbillinghistory'><i className="icoMenu admin-Billing"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Broker Clients'><NavLink to='/broker/brokerclients'><i className="icoMenu admin-Representatives"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Supervisors'><NavLink to='/broker/supervisors'><i className="icoMenu admin-Supervisors"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Register Representatives'><NavLink to='/broker/registerreps'><i className="icoMenu admin-Representatives"></i></NavLink></li>}
                    {role && role === brokerRole && <li title='Broker Documents'><NavLink to='/broker/brokerdocuments'><i className="icoMenu admin-Documents"></i></NavLink></li>}

                    {role && role === rrRole && <li title='Dashboard'><NavLink to='/registerrep/dashboard'><i className="icoMenu icon-application"></i></NavLink></li>}
                    {role && role === rrRole && <li title='Approval Queue'><NavLink to='/registerrep/applications'><i className="icoMenu admin-Approval"></i></NavLink></li>}

                    {role && role === supervisorRole24 && <li title='Dashboard'><NavLink to='/supervisor/dashboard'><i className="icoMenu icon-application"></i></NavLink></li>}
                    {role && role === supervisorRole4 && <li title='Dashboard'><NavLink to='/supervisor/dashboard'><i className="icoMenu icon-application"></i></NavLink></li>}
                    {role && role === supervisorRole24 && <li title='Approval Queue'><NavLink to='/supervisor/applications'><i className="icoMenu admin-Approval"></i></NavLink></li>}
                    {role && role === supervisorRole4 && <li title='Approval Queue'><NavLink to='/supervisor/applications'><i className="icoMenu admin-Approval"></i></NavLink></li>}

                    {role && role === adminRole && <li title='Dashboard'><NavLink to='/admin/dashboard'><i className="icoMenu icon-application"></i></NavLink></li>}
                    {role && role === adminRole && <li title='Brokers'><NavLink to='/admin/brokers'><i className="icoMenu admin-Supervisors"></i></NavLink></li>}
                    {role && role === adminRole && <li title='Pricing Plans'><NavLink to='/admin/pricingplans'><i className="icoMenu admin-Billing"></i></NavLink></li>}
                    {role && role === adminRole && <li title='Alert Types'><NavLink to='/admin/alerttypes'><i className="icoMenu  admin-Alert"></i></NavLink></li>}
                    {role && role === adminRole && <li title='Applications'><NavLink to='/admin/applications'><i className="icoMenu admin-Approval"></i></NavLink></li>}
                </ul>
            </section>
        </>
    );
};

export default LeftNav;