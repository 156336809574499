import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import brokerService from '../../services/brokerservice';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import { checkAuthorization, convertUTCToLocalTime } from '../../common/common';
import { Link } from "react-router-dom";

const BrokerBillingHistory = () => {

    const [brokerBillingHistory, setBrokerBillingHistory] = useState(null);
    const [brokerBillingHistoryShow, setBrokerBillingHistoryShow] = useState(false);
    const [currentBrokerBillingHistory, setCurrentBrokerBillingHistory] = useState(null);
    const handleBrokerBillingHistoryClose = () => setBrokerBillingHistoryShow(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getBrokerBillingHistory();
    }, []);

    const handleBrokerBillingHistoryShow = (item) => {
        setCurrentBrokerBillingHistory(item);
        setBrokerBillingHistoryShow(true);
    }

    const getBrokerBillingHistory = () => {
        loader.show();
        brokerService.getBrokerBillingHistory()
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setBrokerBillingHistory(result);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const columns = [
        {
            name: 'Pricing Plan',
            selector: row => <div className='paddingLeft-15'>{row.pricingPlan.planName}</div>,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => moment(convertUTCToLocalTime(row.startDate)).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => moment(convertUTCToLocalTime(row.endDate)).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Paid Date',
            selector: row => moment(convertUTCToLocalTime(row.paidDate)).format('DD MMM YYYY'),
            sortable: true,
        },
        {
            name: 'Price($)',
            selector: row => '$' + row.price.toFixed(2),
            sortable: true,
        },
        {
            name: 'Is Paid',
            selector: row => row.isPaid === true ? "Yes" : "No",
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Billing History" onClick={() => handleBrokerBillingHistoryShow(row)}>View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Broker Billing History</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Broker Billing History</h2>
                        </div>
                    </div>

                    {brokerBillingHistory && <DataTable theme="default" columns={columns} data={brokerBillingHistory} pagination />}

                    <div className="row pt-3">
                    </div>

                </section>

                <Modal
                    show={brokerBillingHistoryShow}
                    onHide={handleBrokerBillingHistoryClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Broker Billing History</h3>
                                        <table className="table">
                                            <tbody data-gr-ext-installed="" light-mode="light">
                                                <tr>
                                                    <th className='cellBoldText'>Pricing Plan</th><td className='cellText'>{currentBrokerBillingHistory?.pricingPlan.planName}</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>Start Date</th><td className='cellText'>{moment(convertUTCToLocalTime(currentBrokerBillingHistory?.startDate)).format('DD MMM YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>End Date</th><td className='cellText'>{moment(convertUTCToLocalTime(currentBrokerBillingHistory?.endDate)).format('DD MMM YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>Paid Date</th><td className='cellText'>{moment(convertUTCToLocalTime(currentBrokerBillingHistory?.paidDate)).format('DD MMM YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>Start Date</th><td className='cellText'>${currentBrokerBillingHistory?.price.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>Start Date</th><td className='cellText'>{currentBrokerBillingHistory?.isPaid ? "Yes" : "No"}$</td>
                                                </tr>
                                                <tr>
                                                    <th className='cellBoldText'>Created Date</th><td className='cellText'>{moment(currentBrokerBillingHistory?.createdDate).format('DD MMM YYYY')}</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        <div className='row'>
                                            <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleBrokerBillingHistoryClose()}>Close</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default BrokerBillingHistory;