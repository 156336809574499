import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { convertUTCToLocalTime } from '../../common/common';

const AlertTypes = () => {

    const [alertTypes, setAlertTypes] = useState(null);
    const [alertTypeAddShow, setAlertTypeAddShow] = useState(false);
    const [alertTypeEditShow, setAlertTypeEditShow] = useState(false);
    const [currentAlertType, setCurrentAlertType] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState(null);

     //Pagination
     const [totalRows, setTotalRows] = useState(0);
     const [perPage, setPerPage] = useState(10);

    const styleCursor = {
        cursor: "pointer"
    }

    const handleAlertTypeAddClose = () => {
        setAlertTypeAddShow(false);
        addformik.resetForm();
    }
    const handleAlertTypeEditClose = () => setAlertTypeEditShow(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getAlertTypes(1);
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const getAlertTypes = (page) => {
        loader.show();
        if(page === 1) {
            setSearchKeyword(null);
        }
        let data = {
            "searchKeyword": searchKeyword !== null ? searchKeyword : null,
            "pageNumber": page,
            "pageSize": perPage
        }
        adminService.searchAlertTypes(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setAlertTypes(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else{
                    setAlertTypes(null);
                    loader.hide()
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Add alert type starts here

    const handleAlertTypeAddShow = () => {
        addformik.resetForm();
        setAlertTypeAddShow(true);
        setCurrentAlertType(null);
    }

    let addformikValues = {
        addAlertType: null,
        addCaption: null,
        addMessageTemplate: null
    };

    let ADD_INITIAL_FORM_STATE = addformikValues;

    const ADD_FORM_VALIDATION = Yup.object().shape({
        addAlertType: Yup.string()
            .required("Alert type is required").nullable(),
        addCaption: Yup.string()
            .required("Caption is required").nullable(),
        addMessageTemplate: Yup.string()
            .required("Message template is required").nullable()
    });

    const addformik = useFormik({
        enableReinitialize: true,
        initialValues: ADD_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                type: values.addAlertType,
                caption: values.addCaption,
                messageTemplate: values.addMessageTemplate
            };
            adminService.createAlertType(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setAlertTypeAddShow(false);
                        getAlertTypes(1);
                        toast.success("Alert type has been added successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    else {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    //Add alert type ends here

    //Edit alert type starts here

    let formikValues = {
        id: currentAlertType?.id,
        type: currentAlertType?.typeName,
        caption: currentAlertType?.caption,
        messageTemplate: currentAlertType?.messageTemplate
    };

    let INITIAL_FORM_STATE = formikValues;
    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                id: currentAlertType?.id,
                type: values.type,
                caption: values.caption,
                messageTemplate: values.messageTemplate
            };
            adminService.updateAlertType(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setAlertTypeEditShow(false);
                        getAlertTypes(1);
                        toast.success("Alert type has been updated successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleAlertTypeEditShow = (item) => {
        setCurrentAlertType(item);
        setAlertTypeEditShow(true);
    }

    /* Searching criteria starts here */
    let searchFormikValues = {
        searchKeyword: null
    };

    let SEARCH_INITIAL_FORM_STATE = searchFormikValues;

    SEARCH_INITIAL_FORM_STATE = searchFormikValues;
    const searchformik = useFormik({
        enableReinitialize: true,
        initialValues: SEARCH_INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                "searchKeyword": values.searchKeyword !== null ? values.searchKeyword : null,
                "pageNumber": 1,
                "pageSize": perPage
            };
            adminService.searchAlertTypes(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload
                        setAlertTypes(result);
                        setTotalRows(result[0].totalRecords);
                        loader.hide();
                    }
                    else {
                        setAlertTypes(null);
                        loader.hide();
                    }
                    setSearchKeyword(values.searchKeyword);
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleResetFields = () => {
        searchformik.resetForm({ values: { searchKeyword: '' } });
        setSearchKeyword(null);
        getAlertTypes(1);
    }

    /* Searching criteria ends here */

    const handlePageChange = page => {
        getAlertTypes(page);
    };

    const columns = [
        {
            name: 'Type',
            selector: row => <div className='paddingLeft-15'>{row.typeName}</div>,
            sortable: true,
        },
        {
            name: 'Caption',
            selector: row => row.caption,
            sortable: true,
        },
        {
            name: 'Message Template',
            selector: row => row.messageTemplate,
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => moment(convertUTCToLocalTime(row.createdDate)).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Alert Type" onClick={(e) => handleAlertTypeEditShow(row)}>Edit</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Alert Types</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Alert Types</h2>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={searchformik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                                    onBlur={searchformik.handleBlur} onChange={searchformik.handleChange} value={searchformik.values.searchKeyword}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields(searchformik)} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-2'>
                            <ul className="table_btns float-right">
                                <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handleAlertTypeAddShow()}>Add Alert Type</button></li>
                            </ul>
                        </div>
                    </div>

                    {alertTypes && <DataTable theme="default" columns={columns} data={alertTypes}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange} />}
                    {alertTypes === null && <DataTable pagination noDataComponent="No record found" />}
                    <div className="row pt-3"></div>

                </section>

                {/* Add Alert Type */}

                <Modal
                    show={alertTypeAddShow}
                    onHide={handleAlertTypeAddClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Add a New Alert Type</h3>

                                        <form onSubmit={addformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Type<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addAlertType"
                                                                        maxLength="250" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addAlertType} />
                                                                    {addformik.touched.addAlertType && addformik.errors.addAlertType ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addAlertType}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Caption<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addCaption"
                                                                        onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addCaption} />
                                                                    {addformik.touched.addCaption && addformik.errors.addCaption ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addCaption}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Message Template<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addMessageTemplate"
                                                                        onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addMessageTemplate} />
                                                                    {addformik.touched.addMessageTemplate && addformik.errors.addMessageTemplate ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addMessageTemplate}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Add</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleAlertTypeAddClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Edit Alert Type */}
                <Modal
                    show={alertTypeEditShow}
                    onHide={handleAlertTypeEditClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Alert Type</h3>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Type</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="alertType"
                                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.type} />
                                                                    {formik.touched.type && formik.errors.type ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.type}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Caption<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="caption"
                                                                        onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.caption} />
                                                                    {formik.touched.caption && formik.errors.caption ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.caption}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Message Template<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="messageTemplate"
                                                                        onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.messageTemplate} />
                                                                    {formik.touched.messageTemplate && formik.errors.messageTemplate ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.messageTemplate}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleAlertTypeEditClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default AlertTypes;