import React, { useEffect } from 'react';
import { getLoggedInUserInfo } from '../../common/common';
import ReviewComponent from '../../components/shared/ReviewComponent';

const SupervisorReviewApplication = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
           <ReviewComponent UserId={getLoggedInUserInfo().createdBy} />
        </>
    );
};

export default SupervisorReviewApplication;