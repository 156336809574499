import React, { useEffect } from 'react';
import { getLoggedInUserInfo } from '../../common/common';
import ReviewComponent from '../../components/shared/ReviewComponent';
import { Link } from "react-router-dom";

const AdminReviewApplication = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <ReviewComponent UserId={getLoggedInUserInfo().id} />
        </>
    );
};

export default AdminReviewApplication;