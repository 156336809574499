import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import { useFormik } from "formik";
import * as Yup from "yup";
import OLAEnums from '../helpers/olaenums';
import lovService from '../services/lovservice';
import loader from "super-react-loader";
import { defaultCountry } from '../helpers/constants';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';

const DueDiligenceEntityInfo = () => {

    const navigate = useNavigate();
    const [countryList, setCountryList] = useState(null);
    const [entityActivityList, setEntityAcivityList] = useState(null);
    const [withdrawalFrequencyList, setWithdrawalFrequencyList] = useState(null);
    const [commercialRetailBusinessList, setCommercialRetailBusinessList] = useState(null);
    const [onGoingFundingSourceList, setOnGoingFundingSourceList] = useState(null);
    const [entityRiskToleranceList, setEntityRiskToleranceList] = useState(null);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [entityDueDiligence, setEntityDueDiligence] = useState(null);
    const [showNegativeInformationEntity, setShowNegativeInformationEntity] = useState(false);
    const [showTransactForeignBonds, setShowTransactForeignBonds] = useState(false);
    const [showLowPricedSecurities, setShowLowPricedSecurities] = useState(false);
    const [showEntityDoesNotIssueBearerShares, setShowEntityDoesNotIssueBearerShares] = useState(false);
    const [showEntityNegativeNewsInfo, setShowEntityNegativeNewsInfo] = useState(false);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getCountries();
        getEntityAccountActivityList();
        getWithdrawalFrequencyList();
        getCommercialRetialBusinessList();
        getOngoingFundSourceLoist();
        getEntityRiskToleranceList();
        getEntityDueDiligence();
    }, []);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };

    const styleUnderline = {
        textDecorationLine: 'underline'
    }

    const handlePrevious = () => {
        navigate('/account-entity-features');
    }

    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getEntityAccountActivityList = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.EntityAccountActivity)
            .then((json) => {
                if (json.data.payload !== null) {
                    setEntityAcivityList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getWithdrawalFrequencyList = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.ExpectedWithdrawals)
            .then((json) => {
                if (json.data.payload !== null) {
                    setWithdrawalFrequencyList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getCommercialRetialBusinessList = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.CommercialRetailBusiness)
            .then((json) => {
                if (json.data.payload !== null) {
                    setCommercialRetailBusinessList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getOngoingFundSourceLoist = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.PrimaryOngoingFundingSource)
            .then((json) => {
                if (json.data.payload !== null) {
                    setOnGoingFundingSourceList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getEntityRiskToleranceList = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.RiskTolerance)
            .then((json) => {
                if (json.data.payload !== null) {
                    setEntityRiskToleranceList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getEntityDueDiligence = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 9
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    setApplicationInfo(result.applicationInfo);
                    // if (result.applicationInfo.applicationStatus === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval
                    //     || result.applicationInfo.applicationStatus === OLAEnums.ApplicationStatusEnum.Declined_RR
                    //     || result.applicationInfo.applicationStatus === OLAEnums.ApplicationStatusEnum.Declined_Sup) {
                    //     setCtrlDisable(true);
                    // }
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }

                    if (result.entityDueDeligenceInfo !== null) {
                        setEntityDueDiligence(result?.entityDueDeligenceInfo);

                        result.entityDueDeligenceInfo?.transactForeignBonds === null ? setShowTransactForeignBonds(null) :
                            result.entityDueDeligenceInfo?.transactForeignBonds === true ? setShowTransactForeignBonds(true) : setShowTransactForeignBonds(false);

                        result.entityDueDeligenceInfo?.lowPricedSecurities === null ? setShowLowPricedSecurities(null) :
                            result.entityDueDeligenceInfo?.lowPricedSecurities === true ? setShowLowPricedSecurities(true) : setShowLowPricedSecurities(false);

                        result.entityDueDeligenceInfo?.confirmEntityDoesNotIssueBearerShares === null ? setShowEntityDoesNotIssueBearerShares(null) :
                            result.entityDueDeligenceInfo?.confirmEntityDoesNotIssueBearerShares === true ? setShowEntityDoesNotIssueBearerShares(true) : setShowEntityDoesNotIssueBearerShares(false);

                        result.entityDueDeligenceInfo?.entityOrAssociatesHaveNegativeNews === null ? setShowNegativeInformationEntity(null) :
                            result.entityDueDeligenceInfo?.entityOrAssociatesHaveNegativeNews === true ? setShowNegativeInformationEntity(true) : setShowNegativeInformationEntity(false);

                        if (result.entityDueDeligenceInfo?.entityOrAssociatesHaveNegativeNews === true) {
                            setShowEntityNegativeNewsInfo(true);
                        }
                        else {
                            setShowEntityNegativeNewsInfo(false);
                        }
                        loader.hide();
                    }
                    else {
                        setShowTransactForeignBonds(null);
                        setShowLowPricedSecurities(null);
                        setShowEntityDoesNotIssueBearerShares(null);
                        setShowNegativeInformationEntity(null);
                        setShowEntityNegativeNewsInfo(false);
                        loader.hide();
                    }
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const settranactionForeignBonds = (strVal) => {
        if (strVal === "true") {
            setShowTransactForeignBonds(true);
        }
        else {
            setShowTransactForeignBonds(false);
        }
    }

    const setlowPriceSecurities = (strVal) => {
        if (strVal === "true") {
            setShowLowPricedSecurities(true);
        }
        else {
            setShowLowPricedSecurities(false);
        }
    }

    const setentityBearerShare = (strVal) => {
        if (strVal === "true") {
            setShowEntityDoesNotIssueBearerShares(true);
        }
        else {
            setShowEntityDoesNotIssueBearerShares(false);
        }
    }

    const setnegativeInformationEntity = (strVal) => {
        if (strVal === "true") {
            setShowNegativeInformationEntity(true);
            setShowEntityNegativeNewsInfo(true);
        }
        else {
            setShowNegativeInformationEntity(false);
            setShowEntityNegativeNewsInfo(false);
        }
    }

    //Form values set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: null,
        accountName: null,
        countryOfIncorporation: null,
        businessLocation: null,
        entityAccountActivity: null,
        withdrawalFrequency: null,
        initialDepositAmount: null,
        initialDepositSource: null,
        institutionName: null,
        scopeOfBusiness: null,
        ongoingFundingSource: null,
        entityRisk: null,
        tranactionForeignBonds: null,
        lowPriceSecurities: null,
        entityBearerShare: null,
        negativeInformationEntity: null,
        negativeInformationEntityNewsInfo: null
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        accountName: Yup.string().required("Enter account name").nullable(),
        countryOfIncorporation: defaultCountry === null ? Yup.string().required("Please select an option").nullable() : Yup.string().nullable(),
        businessLocation: Yup.string().required("Enter business location").nullable(),
        entityAccountActivity: Yup.string().required("Please select an option").nullable(),
        withdrawalFrequency: Yup.string().required("Please select an option").nullable(),
        initialDepositAmount: Yup.number().required("Enter initial deposit").typeError('You must specify a number').min(10, 'Minimum value 10$').nullable(),
        initialDepositSource: Yup.string().required("Enter initial deposit source").nullable(),
        institutionName: Yup.string().required("Enter institution name").nullable(),
        scopeOfBusiness: Yup.string().required("Please select an option").nullable(),
        ongoingFundingSource: Yup.string().required("Please select an option").nullable(),
        entityRisk: Yup.string().required("Please select an option").nullable(),
        tranactionForeignBonds: Yup.string().required("Please select an option").nullable(),
        lowPriceSecurities: Yup.string().required("Please select an option").nullable(),
        entityBearerShare: Yup.string().required("Please select an option").nullable(),
        negativeInformationEntity: Yup.string().required("Please select an option").nullable(),
        negativeInformationEntityNewsInfo: showEntityNegativeNewsInfo ? Yup.string().required('Enter association negative news info').nullable() : Yup.string().nullable().notRequired(),
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: applicationInfo?.applicationId,
        accountName: entityDueDiligence?.accountName,
        countryOfIncorporation: entityDueDiligence?.countryOfIncorporation,
        businessLocation: entityDueDiligence?.businessLocation,
        entityAccountActivity: entityDueDiligence?.primaryEntityAccActivity,
        withdrawalFrequency: entityDueDiligence?.expectedWithdrawalFreq,
        initialDepositAmount: entityDueDiligence?.depositAmount,
        initialDepositSource: entityDueDiligence?.depositSource,
        institutionName: entityDueDiligence?.institutionName,
        scopeOfBusiness: entityDueDiligence?.scopeOfBusiness,
        ongoingFundingSource: entityDueDiligence?.fundingSource,
        entityRisk: entityDueDiligence?.assessedRisk,
        tranactionForeignBonds: entityDueDiligence?.transactForeignBonds,
        lowPriceSecurities: entityDueDiligence?.lowPricedSecurities,
        entityBearerShare: entityDueDiligence?.confirmEntityDoesNotIssueBearerShares,
        negativeInformationEntity: entityDueDiligence?.entityOrAssociatesHaveNegativeNews,
        negativeInformationEntityNewsInfo: entityDueDiligence?.entityOrAssociatesNegativeNewsInfo
    };

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            let applicationId = applicationInfo?.applicationId;
            let data = {
                "loggedInUserId": getLoggedInUserInfo().id,
                "applicationId": applicationId,
                "accountName": values.accountName,
                "countryOfIncorporation": values.countryOfIncorporation === null || values.countryOfIncorporation === undefined ? defaultCountry : values.countryOfIncorporation,
                "businessLocation": values.businessLocation,
                "primaryEntityAccActivity": values.entityAccountActivity,
                "expectedWithdrawalFreq": values.withdrawalFrequency,
                "transactForeignBonds": showTransactForeignBonds,
                "lowPricedSecurities": showLowPricedSecurities,
                "depositAmount": values.initialDepositAmount,
                "depositSource": values.initialDepositSource,
                "institutionName": values.institutionName,
                "scopeOfBusiness": values.scopeOfBusiness,
                "fundingSource": values.ongoingFundingSource,
                "confirmEntityDoesNotIssueBearerShares": showEntityDoesNotIssueBearerShares,
                "entityOrAssociatesHaveNegativeNews": showEntityNegativeNewsInfo,
                "entityOrAssociatesNegativeNewsInfo": values.negativeInformationEntityNewsInfo,
                "assessedRisk": values.entityRisk
            };
            applicationService.saveEntityDueDeligenceInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getEntityDueDiligence();
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/owners-officials");
                        }
                        else {
                            toast.success("Entity due diligence info has been saved successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });
    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <div className="tab-content" id="duediligenceform">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">Entity Due Diligence Form</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Account Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="accountName" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.accountName}
                                                            maxLength="50" placeholder="Enter Account Name" />
                                                        {formik.touched.accountName && formik.errors.accountName ? (
                                                            <p className="error">{formik.errors.accountName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Country of Incorporation<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="countryOfIncorporation" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.countryOfIncorporation}>
                                                            {/* <option value="">Select Country</option> */}
                                                            {countryList && countryList.map((option) => (
                                                                <option value={option.id} key={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.countryOfIncorporation && formik.errors.countryOfIncorporation ? (
                                                            <p className="error">{formik.errors.countryOfIncorporation}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Business Location<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="businessLocation" maxLength="50" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.businessLocation}
                                                            placeholder="Enter Business Location" />
                                                        {formik.touched.businessLocation && formik.errors.businessLocation ? (
                                                            <p className="error">{formik.errors.businessLocation}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Primary Entity Account Activity<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="entityAccountActivity" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.entityAccountActivity}>
                                                            <option value="">Select Account Activity</option>
                                                            {entityActivityList && entityActivityList.map((option) => (
                                                                <option value={option.id} key={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.entityAccountActivity && formik.errors.entityAccountActivity ? (
                                                            <p className="error">{formik.errors.entityAccountActivity}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Expected Withdrawal Frequency<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="withdrawalFrequency" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.withdrawalFrequency}>
                                                            <option value="">Select Withdrawal Frequency</option>
                                                            {withdrawalFrequencyList && withdrawalFrequencyList.map((option) => (
                                                                <option value={option.id} key={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.withdrawalFrequency && formik.errors.withdrawalFrequency ? (
                                                            <p className="error">{formik.errors.withdrawalFrequency}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Initial Deposit Amount<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="initialDepositAmount" maxLength="50" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.initialDepositAmount}
                                                            placeholder="Enter Initial Deposit Amount" />
                                                        {formik.touched.initialDepositAmount && formik.errors.initialDepositAmount ? (
                                                            <p className="error">{formik.errors.initialDepositAmount}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Initial Deposit Source<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="initialDepositSource" maxLength="50" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.initialDepositSource}
                                                            placeholder="Enter Initial Deposit Source" />
                                                        {formik.touched.initialDepositSource && formik.errors.initialDepositSource ? (
                                                            <p className="error">{formik.errors.initialDepositSource}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Institution Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="institutionName" maxLength="50" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.institutionName}
                                                            placeholder="Enter Institution Name" />
                                                        {formik.touched.institutionName && formik.errors.institutionName ? (
                                                            <p className="error">{formik.errors.institutionName}</p>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Scope of Business<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="scopeOfBusiness" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.scopeOfBusiness}>
                                                            <option value="">Select Scope of Business</option>
                                                            {commercialRetailBusinessList && commercialRetailBusinessList.map((option) => (
                                                                <option value={option.id} key={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.scopeOfBusiness && formik.errors.scopeOfBusiness ? (
                                                            <p className="error">{formik.errors.scopeOfBusiness}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Primary Ongoing Funding Source<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="ongoingFundingSource" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.ongoingFundingSource}>
                                                            <option value="">Select Primary Ongoing Funding Source</option>
                                                            {onGoingFundingSourceList && onGoingFundingSourceList.map((option) => (
                                                                <option value={option.id} key={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.ongoingFundingSource && formik.errors.ongoingFundingSource ? (
                                                            <p className="error">{formik.errors.ongoingFundingSource}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Based on your due diligence the entity is assessed the risk<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="entityRisk" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.entityRisk}>
                                                            <option value="">Select Entity Risk Tolerance</option>
                                                            {entityRiskToleranceList && entityRiskToleranceList.map((option) => (
                                                                <option value={option.id} key={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.entityRisk && formik.errors.entityRisk ? (
                                                            <p className="error">{formik.errors.entityRisk}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <label className="form-label">Will transact foreign bonds?<span className="error-info">*</span></label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="tranactionForeignBonds" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                        onClick={(e) => settranactionForeignBonds(e.target.value)}
                                                        checked={showTransactForeignBonds === null ? false : showTransactForeignBonds === true ? false : true}
                                                    />No
                                                    <label className="form-check-label" for="radio1"></label>
                                                </div>
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="tranactionForeignBonds" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                        onClick={(e) => settranactionForeignBonds(e.target.value)}
                                                        checked={showTransactForeignBonds === null ? false : showTransactForeignBonds === true ? true : false}
                                                    />Yes
                                                    <label className="form-check-label" for="radio1">&nbsp;</label>
                                                </div>
                                            </div>
                                            {formik.touched.tranactionForeignBonds && formik.errors.tranactionForeignBonds ? (
                                                <p className="error" style={styleError}>{formik.errors.tranactionForeignBonds}</p>
                                            ) : null}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <label className="form-label">Will transact low priced securities?<span className="error-info">*</span></label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="lowPriceSecurities" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                        onClick={(e) => setlowPriceSecurities(e.target.value)}
                                                        checked={showLowPricedSecurities === null ? false : showLowPricedSecurities === true ? false : true}
                                                    />No
                                                    <label className="form-check-label" for="radio1"></label>
                                                </div>
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="lowPriceSecurities" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                        onClick={(e) => setlowPriceSecurities(e.target.value)}
                                                        checked={showLowPricedSecurities === null ? false : showLowPricedSecurities === true ? true : false}
                                                    />Yes
                                                    <label className="form-check-label" for="radio1">&nbsp;</label>
                                                </div>
                                            </div>
                                            {formik.touched.lowPriceSecurities && formik.errors.lowPriceSecurities ? (
                                                <p className="error" style={styleError}>{formik.errors.lowPriceSecurities}</p>
                                            ) : null}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10">
                                                <label className="form-label">You certify that this entity <span style={styleUnderline}>does not</span> issue bearer share?<span className="error-info">*</span></label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="entityBearerShare" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                        onClick={(e) => setentityBearerShare(e.target.value)}
                                                        checked={showEntityDoesNotIssueBearerShares === null ? false : showEntityDoesNotIssueBearerShares === true ? false : true}
                                                    />No
                                                    <label className="form-check-label" for="radio1"></label>
                                                </div>
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="entityBearerShare" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                        onClick={(e) => setentityBearerShare(e.target.value)}
                                                        checked={showEntityDoesNotIssueBearerShares === null ? false : showEntityDoesNotIssueBearerShares === true ? true : false}
                                                    />Yes
                                                    <label className="form-check-label" for="radio1">&nbsp;</label>
                                                </div>
                                            </div>
                                            {formik.touched.entityBearerShare && formik.errors.entityBearerShare ? (
                                                <p className="error" style={styleError}>{formik.errors.entityBearerShare}</p>
                                            ) : null}
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-10">
                                                <label className="form-label">Are you aware of any negative information concerning the
                                                    entity, associated individuals (i.e Senior Management, Beneficial Owner, etc) or related entities?<span className="error-info">*</span></label>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="negativeInformationEntity" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                        onClick={(e) => setnegativeInformationEntity(e.target.value)}
                                                        checked={showNegativeInformationEntity === null ? false : showNegativeInformationEntity === true ? false : true}
                                                    />No
                                                    <label className="form-check-label" for="radio1"></label>
                                                </div>
                                                <div className="form-check float-end">
                                                    <input type="radio" className="form-check-input" name="negativeInformationEntity" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                        onClick={(e) => setnegativeInformationEntity(e.target.value)}
                                                        checked={showNegativeInformationEntity === null ? false : showNegativeInformationEntity === true ? true : false}
                                                    />Yes
                                                    <label className="form-check-label" for="radio1">&nbsp;</label>
                                                </div>
                                            </div>
                                            {formik.touched.negativeInformationEntity && formik.errors.negativeInformationEntity ? (
                                                <p className="error" style={styleError}>{formik.errors.negativeInformationEntity}</p>
                                            ) : null}
                                        </div>

                                        {showEntityNegativeNewsInfo && <div className='rows'>
                                            <div className="col-lg-4 mt-2">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Entity or Association Negative News Info<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="negativeInformationEntityNewsInfo" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.negativeInformationEntityNewsInfo}
                                                            maxLength="100" />
                                                        {formik.touched.negativeInformationEntityNewsInfo && formik.errors.negativeInformationEntityNewsInfo ? (
                                                            <p className="error">{formik.errors.negativeInformationEntityNewsInfo}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(false)}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(true)}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );
};

export default DueDiligenceEntityInfo;