import React, { useEffect } from 'react';
import LogFromFISComponent from '../../components/shared/LogFromFISComponent';

const SupervisorLogFromFIS = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
           <LogFromFISComponent />
        </>
    );
};

export default SupervisorLogFromFIS;