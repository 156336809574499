import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { checkAuthorization, convertUTCToLocalTime, getLoggedInUserInfo, toTitleCase } from '../../common/common';
import Modal from 'react-bootstrap/Modal';
import lovService from '../../services/lovservice';
import { useNavigate } from 'react-router';
import OLAEnums from '../../helpers/olaenums';
import loader from "super-react-loader";
import moment from 'moment/moment';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';

const Dashboard = () => {
    const navigate = useNavigate();
    const [clientApps, setClientApps] = useState(null);
    const [accountTypeList, setAccountTypeList] = useState(null);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [selectedAccountSubType, setSelectedAccountSubType] = useState(null);
    const [selectedJointAccountSubType, setSelectedJointAccountSubType] = useState(null);
    const [currentAppId, setCurrentAppId] = useState(null);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [jointAccountTypeList, setJointAccountTypeList] = useState(null);

    //for modal popup
    const [cancelPopupShow, setCancelPopupShow] = useState(false);
    const handleCancelPopupClose = () => setCancelPopupShow(false);
    const [show, setShow] = useState(false);
    const handleClose = () =>  {
        setShow(false);
        setAccountType(OLAEnums.AccountTypeEnum.Personal);
    }
    const [showJointDropdown, setShowJointDropdown] = useState(false);
    const [showPersonalLabel, setShowPersonalLabel] = useState(true);
    const [showEntityLabel, setShowEntityLabel] = useState(false);
    const [showRetirementLabel, setShowRetirementLabel] = useState(false);
    const [countNotCompleted, setCountNotCompleted] = useState(0);
    const [focusedClass, setFocusedClass] = useState("acTypes active");
    const [selectedApp, setSelectedApp] = useState(null);
    const [cancelDuplicatePopupShow, setCancelDuplicatePopupShow] = useState(false);
    const handleDuplicatePopupClose = () => setCancelDuplicatePopupShow(false);

    const styleJointAccount = {
        marginTop: 15, marginBottom: 5
    };
    const stylePointer = {
        cursor: 'pointer', color: '#003366'
    };

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const handleShow = () => {
        setSelectedAccountType(OLAEnums.AccountTypeEnum.Personal);
        setSelectedAccountSubType(null);
        if (countNotCompleted > 0) {
            toast.error("Can't create a new application since you currently have one with a 'Not Completed' status.", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            localStorage.removeItem("currentAppId");
            setShow(true);
            getPersonalAccountTypes();
            getJointAccountSubTypes();
            setShowJointDropdown(false);
        }
    }

    const handleChange = (event) => { //ccount dropdown change event
        setSelectedAccountSubType(event.target.value);
        if (parseInt(event.target.value) === OLAEnums.PersonalAccountTypeEnum.Joint) {
            setShowJointDropdown(true);
        }
        else {
            setShowJointDropdown(false);
            setSelectedJointAccountSubType(null);
        }

        if (parseInt(event.target.value) === OLAEnums.EntityAccountTypeEnum.CCorporation ||
            parseInt(event.target.value) === OLAEnums.EntityAccountTypeEnum.LimitedLiabilityCompany) {
            setShowJointDropdown(false);
            setSelectedJointAccountSubType(null);
            setSelectedAccountSubType(event.target.value);
        }

        if (parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.TraditionalIRA ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.RothIRA ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.SimpleIRAParticipant ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.BeneficiaryRothIRA ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.BeneficiaryTraditionalIRA ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.RolloverIRA ||
            parseInt(event.target.value) === OLAEnums.RetirementTypeEnum.SEPIRA
        ) {
            setSelectedAccountSubType(event.target.value);
        }
    };

    const handleJoinAccountChange = (event) => {
        setSelectedJointAccountSubType(event.target.value);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicantApplications(1);
        setSelectedAccountType(OLAEnums.ParentLovEnum.Personal); //e.g. personal, entity, retirement
    }, []);

    const setAccountType = (acctType) => { //set the main account type here e.g Personal, Entity and Retirement
        setSelectedAccountType(acctType);
        if (acctType === OLAEnums.AccountTypeEnum.Personal) {
            setShowPersonalLabel(true);
            setShowEntityLabel(false);
            setShowRetirementLabel(false);
            setShowJointDropdown(false);
            getPersonalAccountTypes();
            setSelectedAccountSubType(OLAEnums.PersonalAccountTypeEnum.Individual);
        }
        else if (acctType === OLAEnums.AccountTypeEnum.EntityAccount) {
            setShowPersonalLabel(false);
            setShowEntityLabel(true);
            setShowRetirementLabel(false);
            getEntityAccountTypes();
            setSelectedAccountSubType(OLAEnums.EntityAccountTypeEnum.CCorporation);
        }
        else if (acctType === OLAEnums.AccountTypeEnum.Retirement) {
            setShowRetirementLabel(true);
            setShowPersonalLabel(false);
            setShowEntityLabel(false);
            getRetirementAccountTypes();
            setSelectedAccountSubType(OLAEnums.RetirementTypeEnum.TraditionalIRA);
        }
    }

    const getPersonalAccountTypes = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.Personal)
            .then((json) => {
                if (json.data.payload !== null) {
                    setAccountTypeList(json.data.payload);
                    setSelectedAccountSubType(json.data.payload[0].id);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getJointAccountSubTypes = () => { //load joint account sub types for binding dropdown list
        lovService.getListbyParentId(OLAEnums.AccountTypeEnum.JointAccount)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setJointAccountTypeList(result);
                    setSelectedJointAccountSubType(json.data.payload[0].id);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getEntityAccountTypes = () => {
        lovService.getListbyParentId(OLAEnums.AccountTypeEnum.EntityAccount)
            .then((json) => {
                if (json.data.payload !== null) {
                    var filterEntities = json.data.payload.filter(x => x.id !== 2433);
                    setAccountTypeList(filterEntities);
                    //setSelectedAccountSubType(json.data.payload[0].id);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getRetirementAccountTypes = () => {
        lovService.getListbyParentId(OLAEnums.AccountTypeEnum.Retirement)
            .then((json) => {
                if (json.data.payload !== null) {
                    setAccountTypeList(json.data.payload);
                    //setSelectedAccountSubType(json.data.payload[0].id);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getApplicantApplications = (page) => {
        loader.show();
        let data = {
            loggedInUserId: getLoggedInUserInfo().id,
            pageNumber: page,
            pageSize: perPage
        }
        applicationService.getApplicantApplications(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setClientApps(result);
                    setTotalRows(result[0].totalRecords);
                    //call another api for not completed count
                    applicationService.getNotCompletedCount()
                        .then((json) => {
                            setCountNotCompleted(json.data.payload);
                            loader.hide();
                        })
                        .catch((error) => {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            loader.hide();
                        });

                    //Now check if individual, joint, entity, ira has two 'Not Completed' status then you can't create new account

                    loader.hide();
                }
                else {
                    //setCountNotCompleted(0);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName,
            sortable: true,
        },
        {
            name: 'Account Sub Type',
            selector: row => row.accountSubTypeName,
            sortable: true,
        },
        {
            name: 'Created Date',
            // selector: row => moment.utc(row.createdDate).format("DD MMM, yyyy hh:mm a"),
            selector: row => moment(convertUTCToLocalTime(row.createdDate)).format("DD MMM, yyyy hh:mm a"),
            sortable: true,
        },
        {
            center: true,
            name: 'Application Status',
            selector: row => row.applicationStatusName,
            cell: row => row.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted && row.applicationStatus !== OLAEnums.ApplicationStatusEnum.Canceled
                ? <div className="acc-status statusapproved smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>
                : <div className="acc-status statusprocessing smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: "250px"
        },
        {
            button: true,
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => handleViewApplication(row)}>View Application</Dropdown.Item>
                            {
                                row.applicationStatus === OLAEnums.ApplicationStatusEnum.NotCompleted ?
                                    <Dropdown.Item onClick={(e) => handleCancelApplication(row)}>Cancel Application</Dropdown.Item> : ""
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            )
        }
    ];

    const handlePageChange = page => {
        getApplicantApplications(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let user = getLoggedInUserInfo();
        let data = {
            loggedInUserId: user.id,
            pageNumber: page,
            pageSize: newPerPage
        }
        applicationService.getApplicantApplications(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setClientApps(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setCountNotCompleted(0);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };

    const getCurrentAppId = () => {
        setCurrentAppId(getApplicantApplications());
    }

    const handleViewApplication = (item) => {
        localStorage.setItem("currentAppId", item.applicationId);

        if (item.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
            navigate('/review')
        }
        else if (item.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
            navigate('/entity-info');
        }
        else if (item.accountType === OLAEnums.AccountTypeEnum.Retirement || item.accountType === OLAEnums.AccountTypeEnum.Personal) {
            navigate('/personal-info');
        }
        else {
            navigate('/personal-info');
        }
    }

    const saveApplicantAccountTypeInfo = () => {
        let data = {
            "applicationId": localStorage.getItem("currentAppId"),
            "accountType": selectedAccountType,
            "jaSubType": selectedAccountSubType === parseFloat(OLAEnums.PersonalAccountTypeEnum.Individual) ? null : selectedJointAccountSubType,
            "accountSubType": selectedAccountSubType,
            "accountTypeOption": selectedAccountType === parseFloat(OLAEnums.AccountTypeEnum.Retirement) ? OLAEnums.AccountTypeOptionsEnum.Cash : OLAEnums.AccountTypeOptionsEnum.Margin, //not necessary we will provide account type option here
            "isMarginEnable": selectedAccountType !== parseFloat(OLAEnums.AccountTypeEnum.Retirement) ? true : false //not necessary we will provide isMarginenable here
        };
        applicationService.saveApplicantAccountTypeInfo(data)
            .then((json) => {
                if (json.data.payload !== null && json.data.payload[0].applicationId !== null) {
                    localStorage.setItem("currentAppId", json.data.payload[0].applicationId);
                    if (selectedAccountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                        navigate("/entity-info");
                    }
                    else {
                        navigate("/personal-info");
                    }
                }
                else {
                    toast.error(json.data.payload[0].responseMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    handleClose(); //close the popup if not create application
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleCancelApplication = (app) => {
        if (app.applicationStatus === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm) {
            toast.error("You cannot cancel the application because it is already submitted to clearing firm", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            setCancelPopupShow(true);
            setSelectedApp(app);
        }
    }

    const cancelApplication = () => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let data = {
            "UserId": userId,
            "ApplicationId": selectedApp.applicationId,
            "ApplicationStatus": OLAEnums.ApplicationStatusEnum.Canceled,
            "AlertTypeId": OLAEnums.AlertTypeEnum.AccountCanceled,
            "CreatedBy": getLoggedInUserInfo().userRole
        };
        applicationService.updateApplicationStatus(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    getApplicantApplications(1);
                    setCancelPopupShow(false);
                    loader.hide();
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const openDuplicatePopupModal = () => {
        let data = {
            "userId": getLoggedInUserInfo().id,
            "accountType": selectedAccountType,
            "accountSubType": selectedAccountSubType
        };
        applicationService.checkDuplicateApplicationStatus(data)
            .then((json) => {
                if (json.data.payload) {
                    handleClose();
                    setCancelDuplicatePopupShow(true);
                }
                else {
                    setCancelDuplicatePopupShow(false);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const createDuplidateApplication = () => {
        setCancelDuplicatePopupShow(false);
        let data = {
            "loggedInUserId": getLoggedInUserInfo().id,
            "applicationId": null,
            "accountType": selectedAccountType,
            "accountSubType": selectedAccountSubType
        };
        applicationService.createDuplicateApplication(data)
            .then((json) => {
                getApplicantApplications(1);
                toast.success("New application has been created successfully", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Client Applications</h2>
                        </div>
                        <div className="col-lg-6"><button type="button" className="create_Btn" onClick={handleShow}>Create New Account</button></div>
                    </div>

                    {clientApps && <DataTable theme="default" columns={columns} data={clientApps} responsive
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {clientApps === null && <DataTable theme="default" columns={columns} noDataComponent="No record found" />}

                </section>

                {/* Application start popup */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <Modal.Header closeButton>
                                    <h4 className="modal-title">Create new Account</h4>
                                </Modal.Header>
                                <div className="modal-body">
                                    <div className="modal-heading">What type of account would you like to open today?</div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            {applicationInfo === null && <div className={selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.Personal ? "acTypes active" : "acTypes"}
                                                onClick={(e) => setAccountType(OLAEnums.AccountTypeEnum.Personal)}>
                                                <span className="acTypesIco"><i className="icoMenu icon-personail-ico"></i></span>
                                                <span className="acTypesLabels">Personal</span>
                                            </div>}

                                            {applicationInfo !== null && <div className={selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.Personal ? "acTypes active" : "acTypes"}
                                                onClick={(e) => setAccountType(OLAEnums.AccountTypeEnum.Personal)}>
                                                <span className="acTypesIco"><i className="icoMenu icon-personail-ico"></i></span>
                                                <span className="acTypesLabels">Personal</span>
                                            </div>}

                                        </div>
                                        <div className="col-lg-4">
                                            {/* <div className="acTypes"> */}
                                            <div className={selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.EntityAccount ? "acTypes active" : "acTypes"}
                                                onClick={(e) => setAccountType(OLAEnums.AccountTypeEnum.EntityAccount)}>
                                                <span className="acTypesIco"><i className="icoMenu icon-entity-ico"></i></span>
                                                <span className="acTypesLabels">Entity</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className={selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.Retirement ? "acTypes active" : "acTypes"}
                                                onClick={(e) => setAccountType(OLAEnums.AccountTypeEnum.Retirement)}>
                                                <span className="acTypesIco"><i className="icoMenu icon-retirement-ico"></i></span>
                                                <span className="acTypesLabels">Retirement</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {showPersonalLabel && <div className="col-lg-12">
                                            <div className="modal-heading">What type of personal account?</div>
                                            <select className="form-select" value={selectedAccountSubType} onChange={handleChange}>
                                                {accountTypeList && accountTypeList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                            {showJointDropdown && <div style={styleJointAccount}>
                                                <div className="modal-heading">Choose Type of Joint Account You Like to open Today?</div>
                                                <select className="form-select" onChange={handleJoinAccountChange} value={selectedJointAccountSubType}>
                                                    {jointAccountTypeList && jointAccountTypeList.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>}
                                        </div>}

                                        {showEntityLabel && <div className="col-lg-12">
                                            <div className="modal-heading">What type of entity account?</div>
                                            <select className="form-select" value={selectedAccountSubType} onChange={handleChange}>
                                                {accountTypeList && accountTypeList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>}

                                        {showRetirementLabel && <div className="col-lg-12">
                                            <div className="modal-heading">What type of retirement account?</div>
                                            <select className="form-select" value={selectedAccountSubType} onChange={handleChange}>
                                                {accountTypeList && accountTypeList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>}
                                    </div>
                                    <button type='button' className='next_btn_area' onClick={saveApplicantAccountTypeInfo}>Next</button>
                                    {/* <button type='button' className='next_btn_area' onClick={openDuplicatePopupModal}>Next</button> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Cancel modal popup */}
                <Modal
                    show={cancelPopupShow}
                    onHide={handleCancelPopupClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='imgArea'>
                                        <img src='../assets/images/del_img.png' />
                                    </div>
                                    <div className='msgs_Area'>
                                        <h3>Cancel Application?</h3>
                                        <p>Are you sure you want to cancel this application?</p>
                                        <div className='row'>
                                            {/* <div className='col-lg-1'></div> */}
                                            <div className='col-lg-6'><button type='button' className='yes_noBtn' onClick={(e) => cancelApplication()}
                                            >Yes</button></div>
                                            <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCancelPopupClose()}>Close</button></div>
                                            {/* <div className='col-lg-1'></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Duplicate application creation check modal popup */}
                <Modal
                    show={cancelDuplicatePopupShow}
                    onHide={handleDuplicatePopupClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <Modal.Header closeButton>
                                    <h4 className="modal-title">Create Duplicate Application</h4>
                                </Modal.Header>
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        {/* <h3>Create Duplicate Application</h3> */}
                                        <p>Are you sure you want to create application with previous application detail?</p>
                                        <div className='row'>
                                            <div className='col-lg-1'></div>
                                            <div className='col-lg-5'><button type='button' className='yes_noBtn' onClick={(e) => createDuplidateApplication()}
                                            >Yes</button></div>
                                            <div className='col-lg-5'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => saveApplicantAccountTypeInfo()}>No</button></div>
                                            <div className='col-lg-1'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default Dashboard;