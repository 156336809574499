import React from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import ProfileComponent from "../../components/shared/ProfileComponent";
import { Link } from "react-router-dom";

const SupervisorProfile = () => {

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/supervisor/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Profile</li>
                        </ol>
                    </nav>
                    <ProfileComponent />
                </section>
            </section>

        </>
    );
};

export default SupervisorProfile;