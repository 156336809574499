import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import brokerService from '../../services/brokerservice';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import { checkAuthorization, getLoggedInUserInfo } from '../../common/common';
import { Link } from "react-router-dom";

const BrokerAlertTypes = () => {

    const [brokerAlertTypes, setBrokerAlertTypes] = useState(null);
    const [brokerAlertTypeShow, setBrokerAlertTypeShow] = useState(false);
    const [brokerAlertTypeEditShow, setBrokerAlertTypeEditShow] = useState(false);
    const [currentBrokerAlertType, setCurrentBrokerAlertType] = useState(null);
    const handleBrokerAlertTypeClose = () => setBrokerAlertTypeShow(false);
    const handleBrokerAlertTypeEditClose = () => setBrokerAlertTypeEditShow(false);

    const styleCursor = {
        cursor: "pointer"
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getBrokerAlertTypes();
    }, []);

    const handleBrokerAlertTypeShow = (item) => {
        setCurrentBrokerAlertType(item);
        setBrokerAlertTypeShow(true);
    }

    const handleBrokerAlertTypeEditShow = (item) => {
        setCurrentBrokerAlertType(item);
        setBrokerAlertTypeEditShow(true);
    }

    const getBrokerAlertTypes = () => {
        loader.show();
        brokerService.getBrokerAlertTypes()
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setBrokerAlertTypes(result);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    let formikValues = {
        alertType: currentBrokerAlertType?.alertType.caption,
        messageSubject: currentBrokerAlertType?.messageSubject,
        messageTemplate: currentBrokerAlertType?.messageTemplate
    };

    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                Id: currentBrokerAlertType?.id,
                MessageSubject: values.messageSubject,
                MessageTemplate: values.messageTemplate,
                AlertTypeId: currentBrokerAlertType?.alertTypeId
            };
            brokerService.updateBrokerAlertType(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload
                        setBrokerAlertTypeEditShow(false);
                        getBrokerAlertTypes();
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    /* Searching criteria starts here */

    let searchFormikValues = {
        searchKeyword: null
    };

    let SEARCH_INITIAL_FORM_STATE = searchFormikValues;

    SEARCH_INITIAL_FORM_STATE = searchFormikValues;
    const searchformik = useFormik({
        enableReinitialize: true,
        initialValues: SEARCH_INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                brokreUserId: getLoggedInUserInfo().id,
                searchKeyword: values.searchKeyword
            };
            brokerService.searchBrokerAlertTypes(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setBrokerAlertTypes(json.data.payload);
                        loader.hide();
                    }
                    else {
                        setBrokerAlertTypes(null);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleResetFields = (form) => {
        searchformik.resetForm({ values: { searchKeyword: '' } });
        getBrokerAlertTypes();
    }

    /* Searching criteria ends here */

    const columns = [
        {
            name: 'Alert Type',
            selector: row => <div className='paddingLeft-15'>{row.alertType.type}</div>,
            sortable: true,
        },
        {
            name: 'Subject',
            selector: row => row.messageSubject,
            sortable: true,
        },
        {
            name: 'Message',
            selector: row => row.messageTemplate,
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Broker Alert Type" onClick={() => handleBrokerAlertTypeShow(row)}>View</Dropdown.Item>
                            <Dropdown.Item title="Edit Broker Alert Type" onClick={() => handleBrokerAlertTypeEditShow(row)}>Edit</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: "200px"
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Broker Alert Types</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Broker Alert Types</h2>
                        </div>
                        <div className="col-lg-8">
                            <form onSubmit={searchformik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                                    onBlur={searchformik.handleBlur} onChange={searchformik.handleChange} value={searchformik.values.searchKeyword}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields(searchformik)} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {brokerAlertTypes && <DataTable theme="default" columns={columns} data={brokerAlertTypes} pagination />}
                    {brokerAlertTypes === null && <DataTable pagination noDataComponent="No record found" />}
                    <div className="row pt-3">
                    </div>

                </section>

                <Modal
                    show={brokerAlertTypeShow}
                    onHide={handleBrokerAlertTypeClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Broker Alert Type</h3>
                                        <table className="table">
                                            <tbody data-gr-ext-installed="" light-mode="light">
                                                <tr>
                                                    <th className='cellBoldText'>Alert Type</th><td className='cellText'>{currentBrokerAlertType?.alertType.caption}</td>
                                                </tr>
                                                <tr>
                                                    <td className='cellBoldText'>Subject</td><td className='cellText'>{currentBrokerAlertType?.messageSubject}</td>
                                                </tr>
                                                <tr>
                                                    <td className='cellBoldText'>Message</td><td className='cellText'>{currentBrokerAlertType?.messageTemplate}</td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        <div className='row'>
                                            <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleBrokerAlertTypeClose()}>Cancel</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Modal.Body>
                </Modal>

                <Modal
                    show={brokerAlertTypeEditShow}
                    onHide={handleBrokerAlertTypeClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Broker Alert Type</h3>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Alert Type</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="alertType" disabled={true}
                                                                        value={formik.values.alertType} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Subject<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="messageSubject"
                                                                        maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.messageSubject} />
                                                                    {formik.touched.messageSubject && formik.errors.messageSubject ? (
                                                                        <p className="error">{formik.errors.messageSubject}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Message<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="messageTemplate"
                                                                        maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.messageTemplate} placeholder="Enter an email address" />
                                                                    {formik.touched.messageTemplate && formik.errors.messageTemplate ? (
                                                                        <p className="error">{formik.errors.messageTemplate}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleBrokerAlertTypeEditClose()}>Close</button></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default BrokerAlertTypes;