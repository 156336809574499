import React from 'react'
import { Routes, Route, } from "react-router-dom";
import ForgotPassword from './pages/accounts/forgotpassword';
import Login from './pages/accounts/login';
import Register from './pages/accounts/register';
import ResetPassword from './pages/accounts/resetpassword';
import AccountFeatures from './pages/application/account-features';
import Dashboard from './pages/application/dashboard';
import EmploymentInfo from './pages/application/employment-info';
import InvestorProfile from './pages/application/investor-profile';
import PersonalInfo from './pages/application/personal-info';
import RegulatoryItems from './pages/application/regulatory-items';
import UploadDocuments from './pages/application/upload-documents';
import DisclosureSignatureInfo from './pages/application/disclosures-signatures';
import ReviewInfo from './pages/application/review';
import BrokerDashboard from './pages/broker/dashboard';
import BrokerClients from './pages/broker/brokerclients';
import ClientApplications from './pages/broker/clientapplications';
import ApplicationQueue from './pages/broker/applicationqueue';
import PendingCancelQueue from './pages/broker/pendingcancelqueue';
import BrokerReviewApplication from './pages/broker/review-application';
import EntityAccounInfo from './pages/application/entity-info';
import InvestorEntityProfile from './pages/application/investor-entity-profile';
import RegulatoryEntityItems from './pages/application/regulatory-entity-items';
import AccountEntityFeatures from './pages/application/account-entity-features';
import OwnersOfficialsInfo from './pages/application/owners-officials';
import DueDiligenceInfo from './pages/application/due-diligence-info';
import IRABeneficiariesInfo from './pages/application/ira-beneficiaries';
import AdminDashboard from './pages/admin/dashboard';
import BrokerAlertTypes from './pages/broker/brokeralerttypes';
import BrokerBillingHistory from './pages/broker/brokerbillinghistory';
import Supervisors from './pages/broker/supervisors';
import RegisterReps from './pages/broker/registerreps';
import BrokerDocuments from './pages/broker/brokerdocuments';
import RRDashboard from './pages/registerrep/dashboard';
import RRReviewApplication from './pages/registerrep/review-application';
import SupervisorDashboard from './pages/supervisor/dashboard';
import SupervisorReviewApplication from './pages/supervisor/review-application';
import RRApplications from './pages/registerrep/applications';
import SupervisorApplications from './pages/supervisor/applications';
import BrokerApplicationSummary from './pages/broker/applicationsummary';
import ClientProfile from './pages/application/profile';
import BrokerProfile from './pages/broker/profile';
import RegisterRepProfile from './pages/registerrep/profile';
import SupervisorProfile from './pages/supervisor/profile';
import AdminProfile from './pages/admin/profile';
import Brokers from './pages/admin/brokers';
import PricingPlans from './pages/admin/pricingplans';
import AlertTypes from './pages/admin/alerttypes';
import AdminUsers from './pages/admin/users';
import AdminApplications from './pages/admin/applications';
import AdminReviewApplication from './pages/admin/review-application';
import AdminApplicationSummary from './pages/admin/applicationsummary';
import BillingHistory from './pages/admin/billinghistory';
import BrokerApplications from './pages/admin/brokerapplications';
import BrokerSupervisors from './pages/admin/supervisors';
import BrokerRegisterReps from './pages/admin/registerreps';
import RRApplicationSummary from './pages/registerrep/applicationsummary';
import SupervisorApplicationSummary from './pages/supervisor/applicationsummary';
import ChangePassword from './pages/accounts/changepassword';
import SupervisorLogFromFIS from './pages/supervisor/logfromfis';
import BrokerLogFromFIS from './pages/broker/logfromfis'; 
import RRLogFromFIS from './pages/registerrep/logfromfis'; 
import AdminLogFromFIS from './pages/admin/logfromfis'; 
import DeleteUser from './pages/accounts/deleteuser';
import UploadEntityDocuments from './pages/application/upload-entity-documents';

function routes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Register />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/resetpassword' element={<ResetPassword />} />
            <Route path='/changepassword' element={<ChangePassword /> } />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path="/personal-info" element={<PersonalInfo />} />
            <Route path='/employment-info' element={<EmploymentInfo />} />
            <Route path='/investor-profile' element={<InvestorProfile />} />
            <Route path='/regulatory-items' element={<RegulatoryItems />} />
            <Route path='/account-features' element={<AccountFeatures />} />
            <Route path='/upload-documents' element={<UploadDocuments />} />
            <Route path='/upload-entity-documents' element={<UploadEntityDocuments />} />
            <Route path='/disclosures-signatures' element={<DisclosureSignatureInfo />} />
            <Route path='/review' element={<ReviewInfo />} />
            <Route path='/entity-info' element={<EntityAccounInfo />} />
            <Route path='/investor-entity-profile' element={<InvestorEntityProfile />} />
            <Route path='/regulatory-entity-items' element={<RegulatoryEntityItems />} />
            <Route path='/account-entity-features' element={<AccountEntityFeatures />} />
            <Route path='/due-diligence-info' element={<DueDiligenceInfo />} />
            <Route path='/owners-officials' element={<OwnersOfficialsInfo />} />
            <Route path='/ira-beneficiaries' element={<IRABeneficiariesInfo />} />
            <Route path='/profile' element={<ClientProfile />} />
            <Route path='/deleteuser' element={<DeleteUser />} />

            {/**Broker**/}
            <Route path='/broker/dashboard' element={<BrokerDashboard /> } />
            <Route path='/broker/application-queue' element={<ApplicationQueue /> } />
            <Route path='/broker/pendingcancel-queue' element={<PendingCancelQueue /> } />
            <Route path='/broker/review-application' element={<BrokerReviewApplication /> } />
            <Route path='/broker/brokerclients' element={<BrokerClients /> } />
            <Route path='/broker/clientapplications/:id' element={<ClientApplications /> } />
            <Route path='/broker/brokeralerttypes' element={<BrokerAlertTypes />} />
            <Route path='/broker/brokerbillinghistory' element={<BrokerBillingHistory />} />
            <Route path='/broker/supervisors' element={<Supervisors />} />
            <Route path='/broker/registerreps' element={<RegisterReps />} />
            <Route path='/broker/brokerdocuments' element={<BrokerDocuments />} />
            <Route path='/broker/applicationsummary' element={<BrokerApplicationSummary />} />
            <Route path='/broker/profile' element={<BrokerProfile />} />
            <Route path='/broker/logfromfis/:id' element={<BrokerLogFromFIS />} />

            {/**RegisterRep**/}
            <Route path='/registerrep/dashboard' element={<RRDashboard />} />
            <Route path='/registerrep/applications' element={<RRApplications />} />
            <Route path='/registerrep/review-application' element={<RRReviewApplication /> } />
            <Route path='/registerrep/profile' element={<RegisterRepProfile />} />
            <Route path='/registerrep/applicationsummary' element={<RRApplicationSummary />} />
            <Route path='/registerrep/logfromfis/:id' element={<RRLogFromFIS />} />

            {/**Supervisor**/}
            <Route path='/supervisor/dashboard' element={<SupervisorDashboard />} />
            <Route path='/supervisor/applications' element={<SupervisorApplications />} />
            <Route path='/supervisor/review-application' element={<SupervisorReviewApplication /> } />
            <Route path='/supervisor/profile' element={<SupervisorProfile />} />
            <Route path='/supervisor/applicationsummary' element={<SupervisorApplicationSummary />} />
            <Route path='/supervisor/logfromfis/:id' element={<SupervisorLogFromFIS />} />

            {/**Admin**/}
            <Route path='/admin/profile' element={<AdminProfile />} />
            <Route path='/admin/dashboard' element={<AdminDashboard />} />
            <Route path='/admin/brokers' element={<Brokers />} />
            <Route path='/admin/pricingplans' element={<PricingPlans />} />
            <Route path='/admin/alerttypes' element={<AlertTypes />} />
            <Route path='/admin/users' element={<AdminUsers />} />
            <Route path='/admin/applications' element={<AdminApplications />} />
            <Route path='/admin/review-application' element={<AdminReviewApplication />} />
            <Route path='/admin/applicationsummary' element={<AdminApplicationSummary />} />
            <Route path='/admin/billinghistory/:id' element={<BillingHistory />} />
            <Route path='/admin/brokerapplications/:id' element={<BrokerApplications />} />
            <Route path='/admin/supervisors/:id' element={<BrokerSupervisors />} />
            <Route path='/admin/registerreps/:id' element={<BrokerRegisterReps />} />
            <Route path='/admin/logfromfis/:id' element={<AdminLogFromFIS />} />

        </Routes >
    )
}

export default routes