import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import { toast } from 'react-toastify';
import { checkAuthorization, convertUTCToLocalTime, getLoggedInUserInfo } from '../../common/common';
import { useNavigate } from 'react-router';
import { useFormik } from "formik";
import * as Yup from "yup";
import accountService from '../../services/accountservice';
import { applicantRole } from '../../helpers/constants';
import moment from 'moment/moment';
import Modal from 'react-bootstrap/Modal';
import brokerService from '../../services/brokerservice';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";

const BrokerClients = () => {

    const navigate = useNavigate();
    const styles = {
        marginLeft: 0
    };

    const [brokerClients, setBrokerClients] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getBrokerClients(1);
    }, []);

    let formikValues = {
        username: "",
        email: ""
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().min(3, "Username must be minimum 3 characters").required("Username is equired"),
        email: Yup.string().email("Enter a valid email address").required("Email address is required")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            accountService.signupApplicant({
                username: values.username,
                emailAddress: values.email,
                password: "Demo@Project123",
                roleName: applicantRole,
                brokerUserId: getLoggedInUserInfo().id
            })
                .then((json) => {
                    if (json.data.payload !== null) {
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        handleClose();
                        getBrokerClients();
                    } else {
                        toast.warn(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                    formik.resetForm(formik.initialValues);
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                });
        }
    });

    const getBrokerClients = (page) => {
        let data = {
            "searchKeyword": null,
            "pageNumber": page,
            "pageSize": perPage
        };
        brokerService.searchBrokerClients(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setBrokerClients(result);
                    setTotalRows(result[0].totalRecords);
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handlePageChange = page => {
        getBrokerClients(page);
    };

    const handleShow = () => {
        setShow(true);
    }

    const columns = [
        {
            name: 'UserId',
            selector: row => <div className='paddingLeft-15'>{row.id}</div>,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => <a href={"mailto:" + row.email}>{row.email}</a>,
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => moment(convertUTCToLocalTime(row.createdDate)).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Applications" onClick={() => redirectToClientApplications(row.id)}>View Applications</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: "200px"
        }
    ];

    const redirectToClientApplications = (id) => {
        navigate('/broker/clientapplications/' + id);
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Broker Clients</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Broker Clients</h2>
                        </div>
                        <div className="col-lg-6">
                            {/* <button type="button" className="create_Btn" onClick={handleShow}>Create New Client</button> */}
                        </div>
                    </div>

                    {/* <table className="table table-area ">
                        <thead>
                            <tr>
                                <th scope="col">User Id</th>
                                <th scope="col">Username</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Broker Name</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody data-gr-ext-installed="" light-mode="light">
                            {brokerClients && brokerClients.map((item, i) => (
                                <tr key={i}>

                                    <td>{item.id}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.brokerName}</td>
                                    <td>{moment(item.createdDate).format("DD MMM, yyyy")}</td>
                                    <td>
                                        <NavLink to={'/broker/clientapplications/'+item.id}><span className="ico_table icon-open" title='View Applications'></span></NavLink>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table> */}

                    {brokerClients && <DataTable theme="default" columns={columns} data={brokerClients}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        //onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />}
                    {brokerClients === null && <DataTable pagination noDataComponent="No record found" />}

                    <div className="row pt-3"></div>

                </section>

                {/* <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <Modal.Header closeButton>
                                    <h4 className="modal-title">Create New Client</h4>
                                </Modal.Header>
                                <div className="modal-body">

                                    <form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Username<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="username"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.username}
                                                            maxLength="50" placeholder="Enter a username" />
                                                        {formik.touched.username && formik.errors.username ? (
                                                            <p className="error">{formik.errors.username}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Email Address<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="email" maxLength="50"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email}
                                                            placeholder="Enter an email address" />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <p className="error">{formik.errors.email}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className="btnWrapper">
                                            <li><button type='submit' className='btn-primary'>Create New Client</button></li>
                                            <li><button type='button' className='btn-primary' onClick={handleClose}>Cancel</button></li>
                                        </ul>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}

            </section>
        </>
    );
};

export default BrokerClients;