import React, { useState, useEffect } from "react";
import { hideSSN, toTitleCase } from "../../common/common";
import Header from "../../layouts/header/header";
import LeftNav from "../../layouts/leftnav/leftnav";
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import OLAEnums from '../../helpers/olaenums';
import { useNavigate } from 'react-router';
import lovService from "../../services/lovservice";
import { useForm } from 'react-hook-form';
import applicationService from "../../services/applicationservice";
import { Link } from "react-router-dom";

const AdminApplications = () => {
    const [allApps, setAllApps] = useState(null);
    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [applicationStatusList, setApplicationStatusList] = useState(null);
    const [searchKey, setSearchKey] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    const [searchDropdown, setSearchDropdown] = useState(null);
    const styleLeft = {
        textAlign: 'left'
    }

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicationStatuses();
        getAllApplications(1);
    }, []);

    const handlePageChange = page => {
        getAllApplications(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => { 
        loader.show();
        let appStatus = searchStatus;
        if (appStatus === 'Select Status' || appStatus === null) {
            appStatus = null;
        }
        var filterValue = searchDropdown !== null ? parseInt(searchDropdown) : null;
        let data = {
            "applicationStatus": appStatus,
            "firstName": filterValue === 1 ? searchKey : null,
            "lastName": filterValue === 2 ? searchKey : null,
            "accountNumber": filterValue === 3 ? searchKey : null,
            "ssn": filterValue === 4 ? searchKey : null,
            "pageNumber": page,
            "pageSize": newPerPage
        };
        applicationService.searchApplicationsByAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload
                    setAllApps(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });

    };

    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        navigate('/admin/review-application');
    }

    const redirectToApplicationSummary = (id) => {
        localStorage.setItem("currentAppId", id);
        navigate('/admin/applicationsummary');
    }

    const resetSearchForm = () => {
        resetField('searchAppStatus');
        resetField('searchDropdownFilter');
        resetField('searchKeyword');
        setSearchStatus(null);
        setSearchKey(null);
        setSearchDropdown(null);
        getAllApplications(1);
    }

    const getAllApplications = (page) => {
        loader.show();
        let data = {
            "applicationStatus": null,
            "firstName": "",
            "lastName": "",
            "accountNumber": "",
            "ssn": "",
            "pageNumber": page,
            "pageSize": perPage
        };
        applicationService.searchApplicationsByAdmin(data)
            .then((json) => {
                if (json.data !== null) {
                    var result = json.data.payload;
                    setAllApps(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setAllApps(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });

    }

    const getApplicationStatuses = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.ApplicationStatus)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    setApplicationStatusList(result);
                }
                else {
                    return null;
                }
            })
            .catch((error) => {
            });
    }

    const { register, handleSubmit, resetField } = useForm({});

    const searchSubmit = (values) => {
        loader.show();
        let appStatus = values.searchAppStatus;
        if (appStatus === 'Select Status' || appStatus === null) {
            appStatus = null;
        }
        var filterValue = parseInt(values.searchDropdownFilter);
        setSearchKey(values.searchKeyword);
        setSearchStatus(values.searchAppStatus);
        setSearchDropdown(values.searchDropdownFilter);

        let data = {
            "applicationStatus": appStatus,
            "firstName": filterValue === 1 ? values.searchKeyword : null,
            "lastName": filterValue === 2 ? values.searchKeyword : null,
            "accountNumber": filterValue === 3 ? values.searchKeyword : null,
            "ssn": filterValue === 4 ? values.searchKeyword : null,
            "pageNumber": 1,
            "pageSize": perPage
        };

        applicationService.searchApplicationsByAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setAllApps(result);
                    setTotalRows(result[0].totalRecords);
                }
                else {
                    setAllApps(null);
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }
    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName,
            sortable: true,
        },
        {
            name: 'Account Sub Type',
            selector: row => row.accountSubTypeName,
            sortable: true,
        },
        {
            name: 'Name',
            //selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount ? "-" : row.firstName !== null ? row.firstName + ' ' + row.lastName : row.userName,
            selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount && row.entityName !== null ? toTitleCase(row.entityName) : row.firstName !== null ? toTitleCase(row.firstName) + ' ' + toTitleCase(row.lastName) : row.userName,
            sortable: true,
        },
        {
            name: 'Social Security Number',
            selector: row => !row.isForeignAccount && row.socialSecurityNumber !== null ? <div title={row.socialSecurityNumber}>{row.socialSecurityNumber}</div> : <div title={"000-000-000"}>000-00-0000</div>,
            //selector: row => !row.isForeignAccount && row.isForeignAccount !== null && row.socialSecurityNumber !== null ? <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div> : !row.isForeignAccount && row.socialSecurityNumber !== null ? <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div> : row.isForeignAccount ? <div title={"000-000-000"}>XXX-XXX-000</div> : "-",
            sortable: true,
        },
        {
            name: 'Citizenship',
            selector: row => row.citizenshipCountryName,
            sortable: true,
        },
        {
            name: 'Application Status',
            selector: row => row.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted && row.applicationStatus !== OLAEnums.ApplicationStatusEnum.Canceled
                ? <div className="acc-status statusapproved smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>
                : <div className="acc-status statusprocessing smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: '270px'
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                            <Dropdown.Item onClick={(e) => redirectToApplicationSummary(row.applicationId)}>Application Summary</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Applications</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-2">
                            <h2 className="heading">Applications</h2>
                        </div>
                        <div className="col-lg-10">
                            <form onSubmit={handleSubmit(searchSubmit)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <select name="searchAppStatus" className="form-select" {...register("searchAppStatus")}>
                                                    <option value="" selected>Select Status</option>
                                                    {applicationStatusList && applicationStatusList.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <select className="form-select" aria-label="Default select example" name="searchDropdownFilter" {...register("searchDropdownFilter")}>
                                                    <option value="">Select Option</option>
                                                    <option value="1">First Name</option>
                                                    <option value="2">Last Name</option>
                                                    <option value="3">Account Number</option>
                                                    <option value="4">Social Security Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword" {...register("searchKeyword")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <ul className="search_btns">
                                            <li><span className='searchBtn' style={{ cursor: 'pointer' }} onClick={() => resetSearchForm()}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={{ width: "110px", border: "1px solid #215ddf" }}>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {allApps && <DataTable theme="default" columns={columns} data={allApps} pagination paginationServer paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange} />}
                    {allApps === null && <DataTable theme="default" columns={columns} noDataComponent="No record found" />}
                    <div className="row pt-3"></div>
                </section>
            </section>
        </>
    );
};

export default AdminApplications;