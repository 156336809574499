import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import lovService from '../services/lovservice';
import { toast } from 'react-toastify';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import { ErrorMessage, FieldArray, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo, validatePostalCode } from '../common/common';
import InputMask from 'react-input-mask';
import moment from 'moment';
import loader from 'super-react-loader';
import { defaultCountry } from '../helpers/constants';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';
import { useMemo } from 'react';
import ErrorFields from './ErrorFields';

const EntityInfo = () => {

    const navigate = useNavigate();
    const [stateList, setStateList] = useState(null);
    const [entityStateList, setEntityStateList] = useState(null);
    const [mailingStates, setMailingStates] = useState(null);
    const [idTypeList, setIdTypeList] = useState(null);
    const [issuanceStateList, setIssuanceStateList] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [industrialClassifications, setIndustrialClassfications] = useState(null);
    const [indusClassification, setIndusClassification] = useState(null);
    const [entityInfo, setEntityInfo] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);
    const [effectDate, setEffectDate] = useState(null);

    const [showCountryCitizenship, setShowCountryCitizenship] = useState(false);
    const [showSSN, setShowSSN] = useState(false);
    const [showUSPermanentResident, setShowUSPermanentResident] = useState(false);
    const [showForeignAccount, setShowForeignAccount] = useState(false);
    const [ctrlIsUSCitizen, setCtrlIsUSCitizen] = useState(null);
    const [ctrlIsUSPermanentRes, setCtrlIsUSPermanentRes] = useState(null);
    const [ctrlIsForeignAccount, setCtrlIsForeignAccount] = useState(null);
    const [ctrlIdType, setCtrlIdType] = useState(null);
    const [showIssuanceState, setShowIssuanceState] = useState(false);
    const [showIsSamePrimaryApp, setShowIsSamePrimaryApp] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getCountries();
        loadIdTypeList(false);
        loadEntityInfoOriginStates(defaultCountry);
        loadEntityInfoStates(defaultCountry);
        loadMailingStates(defaultCountry);
        loadIssuanceStateList(defaultCountry);
        getIndustrialClassificationList();
        getEntityInformation();
    }, []);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };

    const radioTopMargin = {
        marginTop: '12px',
    };

    const loadEntityInfoOriginStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setStateList(json.data.payload);
                }
                else {
                    setStateList(null);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleEntityInfoOriginStates = (e) => {
        loadEntityInfoOriginStates(e.target.value);
    }

    const loadEntityInfoStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setEntityStateList(json.data.payload);
                }
                else {
                    setEntityStateList(null);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleEntityInfoStates = (e) => {
        loadEntityInfoStates(e.target.value);
    }

    const loadMailingStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setMailingStates(json.data.payload);
                }
                else {
                    setMailingStates(null);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const loadIdTypeList = (flag) => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.IdType)
            .then((json) => {
                if (json.data.payload !== null) {
                    if (!flag) {
                        setIdTypeList(json.data.payload);
                    }
                    else {
                        var list = json.data.payload.filter(x => x.id !== parseInt(OLAEnums.DocumentTypeEnum.DriverLicense));
                        setIdTypeList(list);
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const toggleIsSameAsPrimaryAddress = (cbVal, props) => {
        if (cbVal) {
            setShowIsSamePrimaryApp(true);
            loadMailingStates(props.values.businessCountryId);
            props.setFieldValue("mailingAddress", props.values.businessAddress);
            props.setFieldValue("mailingAddress2", props.values.businessAddress2);
            props.setFieldValue("mailingCountryId", props.values.businessCountryId);
            props.setFieldValue("mailingStateId", props.values.businessStateId);
            props.setFieldValue("mailingCity", props.values.businessCity);
            props.setFieldValue("mailingZipCode", props.values.businessPostalCode);
        }
        else {
            setShowIsSamePrimaryApp(false);
            loadMailingStates(defaultCountry);
            props.setFieldValue("mailingAddress", "");
            props.setFieldValue("mailingAddress2", "");
            props.setFieldValue("mailingCountryId", "");
            props.setFieldValue("mailingStateId", "");
            props.setFieldValue("mailingCity", "");
            props.setFieldValue("mailingZipCode", "");
        }
    }

    const loadIssuanceStateList = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setIssuanceStateList(json.data.payload);
                }
                else {
                    setIssuanceStateList(null);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleMailingStates = (e) => {
        loadMailingStates(e.target.value);
    }
    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handlePrevious = () => {
        navigate('/dashboard');
    }
    const getIndustrialClassificationList = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.IndustrialClassification)
            .then((json) => {
                if (json.data.payload !== null) {
                    setIndustrialClassfications(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const setRadioButtonsForEntityInfo = (entityList) => {
        entityList.forEach(entity => {
            if (entity.isUSCitizen) {
                entity.showSSN = true
                entity.showCountryCitizenship = false
            }
            else if (entity.isUSPermanentResident) {
                entity.showUSPermanentResident = true;
                entity.showSSN = true
                entity.showCountryCitizenship = true
            }
            else if (entity.isForeignAccount) {
                entity.showUSPermanentResident = true;
                entity.showForeignAccount = true
                entity.showSSN = false
                entity.showCountryCitizenship = true
            }
            else {
                entity.showSSN = false
                entity.showCountryCitizenship = false
                entity.showUSPermanentResident = false;
                entity.showForeignAccount = false
            }
        })
        return entityList

    }
    let authSignerOBJ = {
        signerFirstName: null,
        signerMiddleName: null,
        signerLastName: null,
        signerDateofBirth: null,
        signerEmail: null,
        signerCitizenship: null,
        signerSSN: '',
        signerForeignTaxId: null,
        isUSCitizen: null,
        isUSPermanentResident: null,
        isForeignAccount: null,
        idTypeId: null,
        idNumber: null,
        idIssueDate: null,
        idExpireDate: null,
        idIssuanceState: null,
        showUSPermanentResident: false,
        showSSN: false,
        showCountryCitizenship: false,
        showForeignAccount: false,
        showIssuanceState: false

    }
    const getEntityInformation = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }
                    if (result?.entityInformationInfo !== null) {
                        result.entityInformationInfo = {
                            ...result?.entityInformationInfo, authSign: result.entitySignerInfoList ? result.entitySignerInfoList.map(ele => ({

                                signerFirstName: ele.firstName,
                                signerMiddleName: ele.middleName,
                                signerLastName: ele.lastName,
                                signerDateofBirth: moment.utc(ele?.dateOfBirth).format('YYYY-MM-DD'),
                                signerEmail: ele.emailAddress,
                                signerCitizenship: ele.citizenshipId,
                                signerSSN: ele.ssn,
                                signerForeignTaxId: ele.foreignTaxId,
                                isUSCitizen: ele.isUSCitizen,
                                isUSPermanentResident: ele.isUSPermanentResident,
                                isForeignAccount: ele.isForeignAccount,
                                idTypeId: ele.idTypeId,
                                idNumber: ele.idNumber,
                                idIssueDate: moment.utc(ele.issueDate).format('YYYY-MM-DD'),
                                idExpireDate: moment.utc(ele.expirationDate).format('YYYY-MM-DD'),
                                idIssuanceState: ele.issuanceState,
                                showUSPermanentResident: false,
                                showSSN: false,
                                showCountryCitizenship: false,
                                showForeignAccount: false,
                                showIssuanceState: false

                            })) : [{ ...authSignerOBJ }]
                        }

                        result?.entityInformationInfo.authSign.forEach(ele => {
                            if (!ele?.isForeignAccount && ele?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                ele.showIssuanceState = true;
                            }
                            else {
                                ele.showIssuanceState = false;
                            }
                        })

                        setEntityInfo(result?.entityInformationInfo);
                        loadEntityInfoOriginStates(result?.entityInformationInfo?.originCountryId);
                        loadEntityInfoStates(result?.entityInformationInfo?.countryId);
                        loadMailingStates(result?.entityInformationInfo?.mailingCountryId);
                        setEffectDate(result?.entityInformationInfo?.effectiveDate ? moment.utc(result?.entityInformationInfo?.effectiveDate).format('YYYY-MM-DD') : null);

                        //set foreign account detail fields
                        result?.entityInformationInfo?.isUSCitizen === null ? setCtrlIsUSCitizen(null) : result?.entityInformationInfo?.isUSCitizen === true ? setCtrlIsUSCitizen(true) : setCtrlIsUSCitizen(false);
                        result?.entityInformationInfo?.isUSPermanentResident === null ? setCtrlIsUSPermanentRes(null) : result?.entityInformationInfo?.isUSPermanentResident === true ? setCtrlIsUSPermanentRes(true) : setCtrlIsUSPermanentRes(false);
                        result?.entityInformationInfo?.isForeignAccount === null ? setCtrlIsForeignAccount(null) : result?.entityInformationInfo?.isForeignAccount === true ? setCtrlIsForeignAccount(true) : setCtrlIsForeignAccount(false);
                        setRadioButtonsForEntityInfo(result?.entityInformationInfo.authSign);
                        // if (result?.entityInformationInfo?.isForeignAccount) {
                        //     loadIdTypeList(true);
                        // }
                        // else {
                        //     loadIdTypeList(false);
                        // }
                        loadIdTypeList(false);


                        // setCtrlIdType(result.entityInformationInfo?.idTypeId);
                        if (result.entityInformationInfo?.isMailingSameAsPrimary) {
                            setShowIsSamePrimaryApp(true);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }
    const handleIdTypeChange = (e, setvalue, item, props) => {
        // setCtrlIdType(e.target.value);
        if (parseInt(e.target.value) === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
            setvalue(item + 'showIssuanceState', true)
        }
        else {
            setvalue(item + 'showIssuanceState', false)
        }
        props.handleChange(e);
    }

    const handleEffectDateChange = (event) => {
        const value = event.target.value;
        setEffectDate(value);
    };

    //Form values set here

    let formikValues = {
        applicationId: localStorage.getItem("currentAppId"),
        entityName: null,
        ssn: null,
        taxedAs: null,
        businessPhone: null,
        originCountryId: null,
        originStateId: null,
        resolutionAdoptionDate: null,
        businessAddress: null,
        businessAddress2: null,
        businessCity: null,
        businessStateId: null,
        businessPostalCode: null,
        businessCountryId: null,
        mailingAddress: null,
        mailingAddress2: null,
        mailingCity: null,
        mailingStateId: null,
        mailingZipCode: null,
        mailingCountryId: null,
        largeTraderId: null,
        effectiveDate: null,
        leiNumber: null,
        authSign: [{ ...authSignerOBJ }],
        rbIndustrialClassfication: null,
        isMailingSameAsPrimary: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        entityName: Yup.string().required("Entity name is required").min(2, "Mininum 2 characters required").nullable(),
        ssnein: Yup.string().test("len", "Invalid social security number.", (val) => {
            const val_length_without_dashes = val !== undefined ? val.replace(/-|_/g, "").length : 0;
            return val_length_without_dashes === 9;
        }).required("Social security number is required"),
        taxedAs: Yup.string().required("Select type of entity").nullable(),
        businessPhone: Yup.string().test("len", "Invalid phone number", (val) => {
            const val_length_without_dashes = val !== undefined ? val.replace(/-|_/g, "").length : 0;
            return val_length_without_dashes === 10 || val_length_without_dashes === 14;
        }).required("Home phone number is required"),
        originCountryId: defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable(),
        //originStateId: Yup.string().test('checkOriginState', 'Select state', () => !stateList.length).nullable(),
        originStateId: Yup.string().when('checkOriginState', {
            is: () => stateList.length > 0,
            then: Yup.string().required('Select state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        resolutionAdoptionDate: Yup.date().required("Entity Resolution Date is Required").nullable(),
        businessAddress: Yup.string().required("Enter address").nullable(),
        businessCity: Yup.string().required("Enter city").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        //businessStateId: Yup.string().test('checkBusinessState', 'Select state', () => !entityStateList.length).nullable(),
        businessStateId: Yup.string().when('checkBusinessState', {
            is: () => entityStateList.length > 0,
            then: Yup.string().required('Select state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        businessPostalCode: Yup.string().required("Enter postal code").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)),
        businessCountryId: defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable(),
        mailingAddress: Yup.string().required("Enter mailing address").nullable(),
        mailingCity: Yup.string().required("Enter mailing city").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        //mailingStateId: Yup.string().test('checkMailingState', 'Select state', () => !mailingStates.length).nullable(),
        mailingStateId: Yup.string().when('checkMailingState', {
            is: () => mailingStates.length > 0,
            then: Yup.string().required('Select state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        mailingZipCode: Yup.string().required("Enter mailing postal code").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)),
        mailingCountryId: defaultCountry === null ? Yup.string().required("Select mailing country").nullable() : Yup.string().nullable(),
        largeTraderId: Yup.string().nullable().matches(
            /^[0-9]{8}-[0-9]{4}$/,
            'Must be in the format 00000000-0000'
        ),
        leiNumber: Yup.string().nullable().matches(/^[a-zA-Z0-9]*$/, {  //original matcher
            message: 'Invalid format.',
        }),
        rbIndustrialClassfication: indusClassification === null ? Yup.string().required('Industrial classification required').nullable() : Yup.string().nullable().notRequired(),
        authSign: Yup.array().of(Yup.object().shape(
            {
                signerFirstName: Yup.string().required("Enter signer first name").min(2, "Mininum 2 characters required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
                //signerMiddleName: Yup.string().required("Enter signer middle name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
                signerLastName: Yup.string().required("Enter signer last name").min(2, "Mininum 2 characters required").nullable(),
                signerDateofBirth: Yup.string().required("Enter date of birth").nullable()
                    .test("DOB", "Please choose a valid date of birth", (value) => {
                        return moment().diff(moment(value), "years") >= 16;
                    }).test("DOB", "Please enter a valid date of birth", (value) => {
                        return (moment().diff(moment(value), "years") <= 99)
                    }),
                signerEmail: Yup.string().email('Invalid email format').required('Signer email address is required').nullable(),
                signerCitizenship: defaultCountry === null ? Yup.string().required("Select signer citizenship").nullable() : Yup.string().nullable(),
                signerSSN: Yup.string().when(['isUSCitizen', 'isUSPermanentResident'], {
                    is: (isUSCitizen, isUSPermanentResident) => {
                        return isUSCitizen == 'true' || isUSPermanentResident == 'true'
                    },
                    then: Yup.string().required("Enter social security number").test("len", "Invalid social security number", (val) => {
                        const val_length_without_dashes = val !== undefined ? val.replace(/-|_/g, "").length : null;
                        return val_length_without_dashes === 9;
                    }),
                    otherwise: Yup.string().nullable(),
                }),

                // signerForeignTaxId: Yup.string().test("len", "Invalid foreign tax Id", (val) => {
                //     const val_length_without_dashes = val !== undefined ? val.replace(/-|_/g, "").length : 0;
                //     return val_length_without_dashes === 9;
                // }).required("Foreign Tax Id is required"),
                isUSCitizen: Yup.string().required("Please select an option").nullable(),
                isUSPermanentResident: Yup.string().when('isUSCitizen', {
                    is: false || 'false',
                    then: Yup.string().required("Please select an option").nullable()
                }).nullable(),
                isForeignAccount: Yup.string().when('isUSPermanentResident', {
                    is: (isUSPermanentResident) => { return isUSPermanentResident == 'false' || false },
                    then: Yup.string().required("Please select an option").nullable()
                }).nullable(),
                idTypeId: Yup.string().required("Select Id type").nullable(),
                idNumber: Yup.string().required("Enter Id number").nullable(),
                idIssueDate: Yup.date().nullable().required('Enter Id issue date').transform((value, originalValue) => {
                    const date = new Date(originalValue);
                    return isNaN(date) ? undefined : date;
                }),
                idExpireDate: Yup.date().nullable().required('Enter Id issue date').transform((value, originalValue) => {
                    const date = new Date(originalValue);
                    return isNaN(date) ? undefined : date;
                }),
                idIssuanceState: Yup.string().when(['idTypeId', 'isForeignAccount'], {
                    is: (idTypeId) => idTypeId == OLAEnums.DocumentTypeEnum.DriverLicense,
                    then: Yup.string().required("Select Id issuance state").nullable()
                }).nullable(),
            }
        )),

    });

    formikValues = useMemo(() => ({
        entityName: entityInfo?.entityName,
        ssnein: entityInfo?.ssnein,
        taxedAs: entityInfo?.taxClassification,
        businessPhone: entityInfo?.businessPhone,
        originCountryId: entityInfo?.originCountryId,
        originStateId: entityInfo?.originStateId,
        resolutionAdoptionDate: moment.utc(entityInfo?.resolutionAdoptionDate).format('YYYY-MM-DD'),
        businessAddress: entityInfo?.businessAddress,
        businessAddress2: entityInfo?.businessAddress2,
        businessCity: entityInfo?.city,
        businessStateId: entityInfo?.stateId,
        businessPostalCode: entityInfo?.postalCode,
        businessCountryId: entityInfo?.countryId,
        mailingAddress: entityInfo?.mailingAddress,
        mailingAddress2: entityInfo?.mailingAddress2,
        mailingCity: entityInfo?.mailingCity,
        mailingStateId: entityInfo?.mailingStateId,
        mailingZipCode: entityInfo?.mailingPostalCode,
        mailingCountryId: entityInfo?.mailingCountryId,
        largeTraderId: entityInfo?.largeTradeId,
        effectiveDate: effectDate ? effectDate : null,
        leiNumber: entityInfo?.leiNumber,
        authSign: entityInfo?.authSign || [{ ...authSignerOBJ }],

        rbIndustrialClassfication: entityInfo?.industrialClassificationId,
        isMailingSameAsPrimary: entityInfo?.isMailingSameAsPrimary,
    }), [entityInfo])
    let INITIAL_FORM_STATE = formikValues;
    // const props = useFormik({
    //     enableReinitialize: true,
    //     initialValues: INITIAL_FORM_STATE,
    //     validationSchema: FORM_VALIDATION,
    //     onSubmit: 
    // });

    const handleIndustrialClassification = (value) => {
        setIndusClassification(value);
    }
    // useEffect(() => {
    //     if (entityInfo && entityInfo.isUSCitizen) {
    //         setIsCitizenshipValue(true)
    //     }
    //     else {
    //         setIsCitizenshipValue(false)

    //     }
    // }, [entityInfo])

    const setIsCitizenshipValue = (radioVal, setvalue, item) => {
        if (radioVal === "true") {
            setCtrlIsUSCitizen(true);
            // setShowCountryCitizenship(false);
            setvalue(item + 'showCountryCitizenship', false)

            setvalue(item + 'showSSN', true)
            // setShowUSPermanentResident(false);
            setvalue(item + 'showUSPermanentResident', false)
            setvalue(item + 'showForeignAccount', false)

        }
        else {
            setCtrlIsUSCitizen(false);
            // setShowCountryCitizenship(true);
            setvalue(item + 'showCountryCitizenship', true)
            setvalue(item + 'showSSN', false)
            setvalue(item + 'showUSPermanentResident', true)
            setvalue(item + 'showForeignAccount', true)
        }
        setCtrlIsUSPermanentRes(null);
        setCtrlIsForeignAccount(null);
        loadIdTypeList(false);
    }

    const setUSPermanentResidentValue = (radioVal, setvalue, item) => {
        if (radioVal === "true") {
            setCtrlIsUSPermanentRes(true);
            setvalue(item + 'showCountryCitizenship', true)
            setvalue(item + 'showSSN', true)
            setvalue(item + 'showForeignAccount', false)
        }
        else {
            setCtrlIsUSPermanentRes(false);
            setvalue(item + 'showCountryCitizenship', false)
            setvalue(item + 'showSSN', false)
            setvalue(item + 'showForeignAccount', true)
        }
        setCtrlIsForeignAccount(null);
        loadIdTypeList(false);
    }

    const setForeignAccountValue = (radioVal, setvalue, item) => {
        if (radioVal === "true") {
            setCtrlIsForeignAccount(true);
            setvalue(item + 'showCountryCitizenship', true)
            setvalue(item + 'showSSN', false)
            loadIdTypeList(true);
        }
        else {
            setCtrlIsForeignAccount(false);
            setvalue(item + 'showCountryCitizenship', false)
            setvalue(item + 'showSSN', false)
            loadIdTypeList(false);
            toast.error("You must be a US citizen or permanent resident to open an account", { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const deleteAuthSigner = (id) => {
        applicationService.deleteAuthSigner(id)
            .then((json) => {
                if (json.data.payload) {
                    
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const removeAuthSignerHandler = (arrayHelper, index) =>{
       arrayHelper.remove(index)
    }

    // useFieldErrorStyle(props.errors, props.touched)
    return (
        <>
            <div className="tab-content" id="personal">
                <Formik initialValues={INITIAL_FORM_STATE} validationSchema={FORM_VALIDATION} enableReinitialize
                    onSubmit={(values) => {
                        loader.show();
                        let applicationId = localStorage.getItem("currentAppId");
                        for (let index = 0; index < values.authSign.length; index++) {
                            if (values.authSign[index].isForeignAccount === "false" && values.authSign[index].isUSPermanentResident != 'true') {
                                toast.error("All signers must be a US citizen or permanent resident to open an account", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                loader.hide();
                                return;
                            }

                        }

                        // let entUSPerminentResident = null;
                        // let entIsForeignAccount = null;
                        // let ssn = null;
                        values.authSign = values.authSign.map(ele => ({ ...ele, entUSPerminentResident: null, entIsForeignAccount: null, ssn: null }))
                        values.authSign.forEach(ele => {
                            if (ele.isUSCitizen == 'true' ? true : false) {
                                ele.entUSPerminentResident = null;
                                ele.entIsForeignAccount = null;
                                ele.ssn = ele.signerSSN;
                            }
                            else {
                                ele.entUSPerminentResident = ele.isUSPermanentResident;
                                if (ele.isUSPermanentResident == 'true') {
                                    ele.entIsForeignAccount = null;
                                    ele.ssn = ele.signerSSN;
                                }
                                else if (ele.isForeignAccount) {

                                    ele.entIsForeignAccount = ele.isForeignAccount;
                                    ele.ssn = null;
                                    ele.citizenshipId = ele.signerCitizenship;
                                }
                            }
                        })

                        let data = {
                            "applicationId": applicationId,
                            "entityName": values.entityName,
                            "ssnein": values.ssnein,
                            "taxClassificationId": values.taxedAs,
                            "originCountryId": values.originCountryId === null || values.originCountryId === undefined ? defaultCountry : values.originCountryId,
                            "originStateId": values.originStateId,
                            "mailingPreference": 7672,
                            "businessPhone": values.businessPhone,
                            "businessAddress": values.businessAddress,
                            "businessAddress2": values.businessAddress2,
                            "city": values.businessCity,
                            "stateId": values.businessStateId,
                            "postalCode": values.businessPostalCode,
                            "countryId": values.businessCountryId === null || values.businessCountryId === undefined ? defaultCountry : values.businessCountryId,
                            "mailingAddress": values.mailingAddress,
                            "mailingAddress2": values.mailingAddress2,
                            "mailingCity": values.mailingCity,
                            "mailingStateId": values.mailingStateId,
                            "mailingPostalCode": values.mailingZipCode,
                            "mailingCountry": values.mailingCountryId === null || values.mailingCountryId === undefined ? defaultCountry : values.mailingCountryId,
                            "industrialClassificationId": indusClassification === null ? entityInfo?.industrialClassificationId : indusClassification,
                            "largeTradeId": values.largeTraderId,
                            "leiNumber": values.leiNumber,
                            "effectiveDate": effectDate,
                            "resolutionAdoptionDate": values.resolutionAdoptionDate,
                            "catsAccountTypeId": null,
                            "passportNumber": null,
                            "passportExpDate": null,
                            "isMailingSameAsPrimary": showIsSamePrimaryApp,

                            //auth signer list
                            authSign: values.authSign.map(value => ({
                                "applicationId": applicationId,
                                "firstName": value.signerFirstName,
                                "middleName": value.signerMiddleName,
                                "lastName": value.signerLastName,
                                "dateOfBirth": value.signerDateofBirth,
                                "emailAddress": value.signerEmail,
                                //"citizenshipId": (value.isUSCitizen == 'true' ? true : false) ? defaultCountry : (value.signerCitizenship || null),
                                "citizenshipId": value.isUSCitizen == 'true' || value.isUSPermanentResident == 'true' ? defaultCountry : value.signerCitizenship,
                                "ssn": value.signerSSN,
                                "foreignTaxId": value.signerForeignTaxId,
                                "isUSCitizen": value.isUSCitizen,
                                "isUSPermanentResident": value.entUSPerminentResident,
                                "isForeignAccount": value.entIsForeignAccount,
                                "idTypeId": parseInt(value.idTypeId),
                                "idTypeOther": null,
                                "idNumber": value.idNumber,
                                "issueDate": value.idIssueDate,
                                "expirationDate": value.idExpireDate,
                                "issuanceState": value.idIssuanceState || null,
                            }))
                        };
                        applicationService.saveEntityInformation(data)
                            .then((json) => {
                                if (json.data.payload !== null) {
                                    getEntityInformation();
                                    loader.hide();
                                    if (!saveOnly) {
                                        navigate("/investor-entity-profile");
                                    }
                                    else {
                                        toast.success("Entity information has been saved successfully", {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    }
                                }
                                loader.hide();
                            })
                            .catch((error) => {
                                toast.error(error, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                loader.hide();
                            });
                    }}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="formContainer">
                                <div className="container-fluid">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <FormErrorToottip errorList={props.errors} touchlist={props.touched}></FormErrorToottip>
                                                <ErrorFields errors={props.errors} touchlist={props.touched}></ErrorFields>
                                                <h2 className="heading">Entity Information</h2>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Entity Name<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <input type="text" className="inputForm" name="entityName" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.entityName}
                                                                    maxLength="50" placeholder="Enter Entity Name" />
                                                                {props.touched.entityName && props.errors.entityName ? (
                                                                    <p className="error">{props.errors.entityName}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">EIN/SSN<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <InputMask className="inputForm" name="ssnein" mask="999-99-9999" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.ssnein}
                                                                    placeholder="Enter EIN/SSN" />
                                                                {props.touched.ssnein && props.errors.ssnein ? (
                                                                    <p className="error">{props.errors.ssnein}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Type of Entity<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select className="form-select" name="taxedAs" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.taxedAs}>
                                                                    <option value="">Select</option>
                                                                    <option value={2432}>Partnership</option>
                                                                    <option value={4512}>LLC</option>
                                                                    <option value={2430}>Corporation</option>
                                                                    <option value={2431}>S Corporation</option>
                                                                    <option value={2433}>Trust</option>
                                                                </select>
                                                                {props.touched.taxedAs && props.errors.taxedAs ? (
                                                                    <p className="error">{props.errors.taxedAs}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Business Phone<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <InputMask className="inputForm" name="businessPhone" mask="999-999-9999-9999" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessPhone}
                                                                    placeholder="Enter Phone" />
                                                                {props.touched.businessPhone && props.errors.businessPhone ? (
                                                                    <p className="error">{props.errors.businessPhone}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Origin Country<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select className="form-select" name="originCountryId" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} //onChange={props.handleChange} 
                                                                    onChange={(e) => {
                                                                        props.setFieldValue("originCountryId", e.target.value);
                                                                        handleEntityInfoOriginStates(e);
                                                                    }}
                                                                    value={props.values.originCountryId}>
                                                                    {countryList && countryList.map((option) => (
                                                                        <option value={option.id} key={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                                {props.touched.originCountryId && props.errors.originCountryId ? (
                                                                    <p className="error">{props.errors.originCountryId}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Origin State<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select className="form-select" name="originStateId" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.originStateId}>
                                                                    <option value="">Select State</option>
                                                                    {stateList && stateList.map((option) => (
                                                                        <option value={option.id} key={option.id}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                                {props.touched.originStateId && props.errors.originStateId ? (
                                                                    <p className="error">{props.errors.originStateId}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Entity Resolution Date<span className="error-info">*</span></label>
                                                            <div className="input_area">

                                                                <input type="date" format="YYYY-MM-DD" className="inputForm" name="resolutionAdoptionDate"
                                                                    //defaultValue={moment.utc(applicantList && applicantList[0]?.dateofBirth).format('YYYY-MM-DD')}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                    value={props.values.resolutionAdoptionDate}
                                                                />

                                                                {props.touched.resolutionAdoptionDate && props.errors.resolutionAdoptionDate ? (
                                                                    <p className="error">{props.errors.resolutionAdoptionDate}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Business Address 1<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <input type="text" className="inputForm" name="businessAddress"
                                                                    placeholder="Enter Business Address 1" maxLength={250} disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessAddress}
                                                                />
                                                                {props.touched.businessAddress && props.errors.businessAddress ? (
                                                                    <p className="error">{props.errors.businessAddress}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Business Address 2</label>
                                                            <div className="input_area">
                                                                <input type="text" className="inputForm" name="businessAddress2"
                                                                    placeholder="Enter Business Address 2" maxLength={250} disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessAddress2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Country<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select className="form-select" name="businessCountryId" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={(e) => {
                                                                        props.setFieldValue("businessCountryId", e.target.value);
                                                                        handleEntityInfoStates(e);
                                                                    }} value={props.values.businessCountryId}>
                                                                    {countryList && countryList.map((option) => (
                                                                        <option value={option.id} key={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                                {props.touched.businessCountryId && props.errors.businessCountryId ? (
                                                                    <p className="error">{props.errors.businessCountryId}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">State<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select className="form-select" name="businessStateId" disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessStateId}>
                                                                    <option value="">Select State</option>
                                                                    {entityStateList && entityStateList.map((option) => (
                                                                        <option value={option.id} key={option.id}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                                {props.touched.businessStateId && props.errors.businessStateId ? (
                                                                    <p className="error">{props.errors.businessStateId}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">City<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <input type="text" className="inputForm" name="businessCity"
                                                                    placeholder="Enter City" maxLength={50} disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessCity}
                                                                />
                                                                {props.touched.businessCity && props.errors.businessCity ? (
                                                                    <p className="error">{props.errors.businessCity}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="input_wrapper">
                                                            <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <input type="text" className="inputForm" name="businessPostalCode"
                                                                    placeholder="Enter Postal Code" maxLength={10} disabled={ctrlDisable}
                                                                    onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.businessPostalCode}
                                                                />
                                                                {props.touched.businessPostalCode && props.errors.businessPostalCode ? (
                                                                    <p className="error">{props.errors.businessPostalCode}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Mailing Preference</h2>
                                            </div>
                                            <div className="col-lg-12">
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-lg-8'>
                                                            <div className="form-check float-left pb-4">
                                                                <input className="form-check-input"
                                                                    onClick={(e) => toggleIsSameAsPrimaryAddress(e.target.checked, props)} checked={showIsSamePrimaryApp ? true : false}
                                                                    type="checkbox" disabled={ctrlDisable} onBlur={props.handleBlur}
                                                                    value={props.values.isMailingSameAsPrimary} name="cbIsSameAsPrimaryAddress" />
                                                                <label className="form-check-label">Is same as primary applicant business address?</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Mailing Address 1<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" name="mailingAddress" className="inputForm" disabled={ctrlDisable}
                                                                        placeholder="Enter Mailing Address 1" maxLength="250"
                                                                        onChange={props.handleChange} value={props.values.mailingAddress}
                                                                    />
                                                                    {props.touched.mailingAddress && props.errors.mailingAddress ? (
                                                                        <p className="error">{props.errors.mailingAddress}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Mailing Address 2</label>
                                                                <div className="input_area">
                                                                    <input type="text" name="mailingAddress2" className="inputForm" disabled={ctrlDisable}
                                                                        placeholder="Enter Mailing Address 2" maxLength="250"
                                                                        onChange={props.handleChange} value={props.values.mailingAddress2}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Country<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <select name="mailingCountryId" className="form-select" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} value={props.values.mailingCountryId}
                                                                        onChange={(e) => {
                                                                            props.setFieldValue("mailingCountryId", e.target.value);
                                                                            handleMailingStates(e);
                                                                        }}
                                                                    >
                                                                        {countryList && countryList.map((option) => (
                                                                            <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {props.touched.mailingCountryId && props.errors.mailingCountryId ? (
                                                                        <p className="error">{props.errors.mailingCountryId}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">State<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <select name="mailingStateId" className="form-select" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.mailingStateId}>
                                                                        <option value="">Select State</option>
                                                                        {mailingStates && mailingStates.map((option) => (
                                                                            <option value={option.id}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {props.touched.mailingStateId && props.errors.mailingStateId ? (
                                                                        <p className="error">{props.errors.mailingStateId}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">City<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="mailingCity" maxLength="50" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.mailingCity}
                                                                        placeholder="Enter City" />
                                                                    {props.touched.mailingCity && props.errors.mailingCity ? (
                                                                        <p className="error">{props.errors.mailingCity}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="mailingZipCode"
                                                                        maxLength="10" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.mailingZipCode}
                                                                        placeholder="Enter Postal Code" />
                                                                    {props.touched.mailingZipCode && props.errors.mailingZipCode ? (
                                                                        <p className="error">{props.errors.mailingZipCode}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Large Trader ID<span className="error-info"></span></label>
                                                                <div className="input_area">
                                                                    <InputMask className="inputForm" name="largeTraderId" mask="99999999-9999" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.largeTraderId}
                                                                        placeholder="Enter Large Trader ID" />
                                                                    {props.touched.largeTraderId && props.errors.largeTraderId ? (
                                                                        <p className="error">{props.errors.largeTraderId}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">Effective Date<span className="error-info"></span></label>
                                                                <div className="input_area">
                                                                    <input type="date" format="YYYY-MM-DD" className="inputForm" name="effectiveDate"
                                                                        onBlur={props.handleBlur} onChange={handleEffectDateChange} disabled={ctrlDisable}
                                                                        value={effectDate || ""} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label">LEI Number<span className="error-info"></span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="leiNumber" maxLength="20" disabled={ctrlDisable}
                                                                        onBlur={props.handleBlur} onChange={props.handleChange} value={props.values.leiNumber}
                                                                        placeholder="Enter LEI Number" />
                                                                    {props.touched.leiNumber && props.errors.leiNumber ? (
                                                                        <p className="error">{props.errors.leiNumber}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Authorized Signer</h2>
                                            </div>

                                            <FieldArray name="authSign" render={arrayHelpers => (
                                                <div className="col-lg-12">
                                                    {props.values.authSign?.map((ele, index) => <div className='border-1 border-bottom'>
                                                        <div className='d-flex justify-content-between'>
                                                            <h2 className='heading'>Signer {index + 1}</h2>
                                                            <div>
                                                                {index != 0 && <button type='button' onClick={() => arrayHelpers.remove(index)} className='me-3 btn-primary'>Remove</button>}
                                                                {/* {index != 0 && <button type='button' onClick={() => removeAuthSignerHandler(arrayHelpers, index)} className='me-3 btn-primary'>Remove</button>} */}
                                                                {index == 0 && <button type='button' onClick={() => props.values.authSign.length <= 3 && arrayHelpers.push({ ...authSignerOBJ })} className=' btn-primary'>Add</button>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">First Name<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="text" name={`authSign.${index}.signerFirstName`} className="inputForm" placeholder="Enter First Name" maxLength="50"
                                                                            disabled={ctrlDisable} onBlur={props.handleBlur} onChange={props.handleChange} value={ele.signerFirstName}
                                                                        />
                                                                        {/* {props.touched?.authSign[index]?.signerFirstName && props.errors?.authSign[index]?.signerFirstName ? (
                                                                            <p className="error">{props.errors[index]?.signerFirstName}</p>
                                                                        ) : null} */}
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerFirstName`}></ErrorMessage>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Middle Name<span className="error-info"></span></label>
                                                                    <div className="input_area">
                                                                        <input type="text" name={`authSign.${index}.signerMiddleName`} className="inputForm" placeholder="Enter Middle Name" maxLength="50"
                                                                            disabled={ctrlDisable} onBlur={props.handleBlur} onChange={props.handleChange} value={ele.signerMiddleName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Last Name<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="text" name={`authSign.${index}.signerLastName`} className="inputForm" placeholder="Enter Last Name" maxLength="50"
                                                                            disabled={ctrlDisable} onBlur={props.handleBlur} onChange={props.handleChange} value={ele.signerLastName}
                                                                        />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerLastName`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Date of Birth<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name={`authSign.${index}.signerDateofBirth`}
                                                                            //defaultValue={moment.utc(applicantList && applicantList[0]?.dateofBirth).format('YYYY-MM-DD')}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            value={ele.signerDateofBirth} />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerDateofBirth`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Email Address<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="email" className="inputForm" name={`authSign.${index}.signerEmail`} maxLength="50" disabled={ctrlDisable}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.signerEmail}
                                                                            placeholder="Enter email address" />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerEmail`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">ITIN/Foreign Tax ID<span className="error-info"></span></label>
                                                                    <div className="input_area">
                                                                        <InputMask className="inputForm" name={`authSign.${index}.signerForeignTaxId`} mask="999-99-9999" disabled={ctrlDisable}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.signerForeignTaxId}
                                                                            placeholder="Enter ITIN/Foreign Tax ID" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Are you a US citizen?</label>
                                                                    <div className='input_area' style={radioTopMargin}>

                                                                        <input type="radio" className="form-check-input" name={`authSign.${index}.isUSCitizen`} onClick={(e) => { setIsCitizenshipValue(e.target.value, props.setFieldValue, `authSign.${index}.`) }}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isUSCitizen) === 'true') == true}
                                                                            value={true} />
                                                                        <label className="form-label">&nbsp;Yes&nbsp;</label>

                                                                        <input type="radio" className="form-check-input" name={`authSign.${index}.isUSCitizen`} onClick={(e) => {
                                                                            setIsCitizenshipValue(e.target.value, props.setFieldValue, `authSign.${index}.`)
                                                                        }}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isUSCitizen) === 'false') == true}
                                                                            value={false} />
                                                                        <label className="form-label">&nbsp;No&nbsp;</label>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.isUSCitizen`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {ele.showUSPermanentResident && <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Are you a US permanent resident?</label>
                                                                    <div className='input_area' style={radioTopMargin}>
                                                                        <input className="form-check-input" name={`authSign.${index}.isUSPermanentResident`} type="radio" onClick={(e) => setUSPermanentResidentValue(e.target.value, props.setFieldValue, `authSign.${index}.`)}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isUSPermanentResident) === 'true') == true}
                                                                            value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                                        <input className="form-check-input" type="radio" name={`authSign.${index}.isUSPermanentResident`} onClick={(e) => setUSPermanentResidentValue(e.target.value, props.setFieldValue, `authSign.${index}.`)}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isUSPermanentResident) === 'false') == true}
                                                                            value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.isUSPermanentResident`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                        <div className='row'>

                                                            {ele.showForeignAccount && <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Is this a foreign account?</label>
                                                                    <div className="input_area" style={radioTopMargin}>
                                                                        <input className="form-check-input" name={`authSign.${index}.isForeignAccount`} type="radio" onClick={(e) => setForeignAccountValue(e.target.value, props.setFieldValue, `authSign.${index}.`)}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isForeignAccount) === 'true') == true}
                                                                            value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                                        <input className="form-check-input" type="radio" name={`authSign.${index}.isForeignAccount`} onClick={(e) => setForeignAccountValue(e.target.value, props.setFieldValue, `authSign.${index}.`)}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} disabled={ctrlDisable}
                                                                            checked={(String(ele.isForeignAccount) === 'false') == true}
                                                                            value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.isForeignAccount`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {ele.showCountryCitizenship && <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Citizenship<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <select name={`authSign.${index}.signerCitizenship`} className="form-select" disabled={ctrlDisable}
                                                                            //onBlur={props.handleBlur} 
                                                                            onChange={props.handleChange} value={ele.signerCitizenship}>
                                                                            {countryList && countryList.map((option) => (
                                                                                <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerCitizenship`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {ele.showSSN && <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Social Security Number<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <InputMask className="inputForm" name={`authSign.${index}.signerSSN`} mask="999-99-9999" disabled={ctrlDisable}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.signerSSN}
                                                                            placeholder="Enter Social Security Number" />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.signerSSN`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                        <div className="row pt-2">
                                                            {/* Id type selection starts here */}
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Select Id Type<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <select className="form-select" name={`authSign.${index}.idTypeId`} disabled={ctrlDisable}
                                                                            onBlur={props.handleBlur} //onChange={props.handleChange} value={props.values.idTypeId}
                                                                            value={ele.idTypeId} onChange={(e) => handleIdTypeChange(e, props.setFieldValue, `authSign.${index}.`, props)}>
                                                                            <option value="">Select Id Type</option>
                                                                            {idTypeList && idTypeList.map((option) => (
                                                                                <option value={option.id}>{option.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.idTypeId`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Id Number<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="text" className="inputForm" name={`authSign.${index}.idNumber`} disabled={ctrlDisable}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.idNumber}
                                                                            maxLength="17" placeholder="Enter Id Number" />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.idNumber`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Id Issue Date<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name={`authSign.${index}.idIssueDate`} disabled={ctrlDisable}
                                                                            defaultValue={moment.utc(entityInfo && entityInfo?.issueDate).format('YYYY-MM-DD')}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange} min={moment().subtract(12, 'years').format('YYYY-MM-DD')}
                                                                            value={ele.idIssueDate} max={moment().format("YYYY-MM-DD")} />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.idIssueDate`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Id Expiration Date<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name={`authSign.${index}.idExpireDate`} disabled={ctrlDisable}
                                                                            defaultValue={moment.utc(entityInfo && entityInfo?.expirationDate).format('YYYY-MM-DD')}
                                                                            onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.idExpireDate} min={moment().format("YYYY-MM-DD")} />
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.idExpireDate`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {ele.showIssuanceState && <div className="col-lg-3">
                                                                <div className="input_wrapper">
                                                                    <label className="form-label">Id Issuance State<span className="error-info">*</span></label>
                                                                    <div className="input_area">
                                                                        <select className="form-select" name={`authSign.${index}.idIssuanceState`} onBlur={props.handleBlur} onChange={props.handleChange}
                                                                            value={ele.idIssuanceState} disabled={ctrlDisable}>
                                                                            <option value="">Select State</option>
                                                                            {issuanceStateList && issuanceStateList.map((option) => (
                                                                                <option value={option.id}>{option.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        <p className="error">
                                                                            <ErrorMessage name={`authSign.${index}.idIssuanceState`}></ErrorMessage>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>)}
                                                </div>
                                            )}>

                                            </FieldArray>

                                        </div>
                                    </div>

                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Industrial Classification<span className="error-info">*</span></h2>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {industrialClassifications && industrialClassifications.map((option) => (
                                                            <div className="form-check">
                                                                <input type="radio" disabled={ctrlDisable} id="rbIndustrialClassfication" name="rbIndustrialClassfication" value={option.id}
                                                                    onClick={(e) => handleIndustrialClassification(option.id)} className="form-check-input"
                                                                    checked={option.id === indusClassification ? true : option.id === entityInfo?.industrialClassificationId ? true : false}
                                                                />
                                                                <label className="form-check-label" for="radio1">{option.name}</label>
                                                            </div>

                                                        ))}
                                                        {props.touched.rbIndustrialClassfication && props.errors.rbIndustrialClassfication ? (
                                                            <p className="error" style={styleError}>{props.errors.rbIndustrialClassfication}</p>
                                                        ) : null}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul className="btnWrapper">
                                <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(false)} disabled={ctrlDisable}>Save and Continue</button></li>
                                {props.isValid && <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                                <li><button type='button' className='btn-primary' onClick={(e) => handlePrevious()}>Previous</button></li>
                            </ul>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );

};

export default EntityInfo;