import http from "../common/common";
import { authHeader } from "../helpers/auth-headers";
import { handleResponse } from "../helpers/handle-response";

const getStates = () => {
    return http.post("/ola/getstates", null, null).then(handleResponse);
}

const getStatesByCountryId = (id) => {
    return http.get("/ola/getstatesbycountryid/" + id, null).then(handleResponse);
}

const getCountries = () => {
    return http.post("/ola/getcountries", null, null).then(handleResponse);
}

//Application end points

const saveApplicantAccountTypeInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveapplicantaccounttypeinfo", data, requestOptions)
        .then(handleResponse);
}

const saveApplicantPrimaryCoAppInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveapplicantprimarycoappinfo", data, requestOptions)
        .then(handleResponse);
}

const saveEmploymentInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveemploymentinfo", data, requestOptions)
        .then(handleResponse);
}

const saveInvestorProfileInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveinvestorprofileinfo", data, requestOptions)
        .then(handleResponse);
}

const saveRegulatoryItemsInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveregulatoryitemsinfo", data, requestOptions)
        .then(handleResponse);
}

const saveAccountFeaturesInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveaccountfeaturesinfo", data, requestOptions)
        .then(handleResponse);
}

const uploadApplicationDocuments = (data, config) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/uploadapplicationdocuments", data, requestOptions, config)
        .then(handleResponse);
}

const saveDisclosureandSignatureInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/savedisclosureandsignatureinfo", data, requestOptions)
        .then(handleResponse);
}

const updateApplicationStatus = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/updateapplicationstatus", data, requestOptions)
        .then(handleResponse);
}

const getApplicationReview = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationreview", data, requestOptions)
        .then(handleResponse);
}

const getApplicationBrokerLevel = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationbrokerlevel", data, requestOptions)
        .then(handleResponse);
}

const getAGTBrokerApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getagtbrokerapplications", data, requestOptions)
        .then(handleResponse);
}

const getSubordinateDashboardData = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getsubordinatedashboarddata", data, requestOptions)
        .then(handleResponse);
}

const getApplicationQueue = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationqueue", data, requestOptions)
        .then(handleResponse);
}

const getBrokerApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getbrokerapplications", data, requestOptions)
        .then(handleResponse);
}

const getSubordinateApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getsubordinateapplications", data, requestOptions)
        .then(handleResponse);
}
//Applications for Supervisor
const getSupervisorApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getsupervisorapplications", data, requestOptions)
        .then(handleResponse);
}

//Applications for RR
const getRRApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getrrapplications", data, requestOptions)
        .then(handleResponse);
}

const updateBrokerApplicationStatus = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/updatebrokerapplicationstatus", data, requestOptions)
        .then(handleResponse);
}

const saveForeignDueDeligence = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveforeignduedeligence", data, requestOptions)
        .then(handleResponse);
}

const applicationApexAppeal = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/applicationapexappeal", data, requestOptions)
        .then(handleResponse);
}

const createExternalBankAccount = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/createexternalbankaccount", data, requestOptions)
        .then(handleResponse);
}

const getExternalBankList = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getexternalbanklist", data, requestOptions)
        .then(handleResponse);
}

const closeApexAccount = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/closeapexaccount", data, requestOptions)
        .then(handleResponse);
}

const saveEntityInformation = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveentityinformation", data, requestOptions)
        .then(handleResponse);
}

const saveInvestorProfileEntityInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveinvestorprofileentityinfo", data, requestOptions)
        .then(handleResponse);
}

const saveRegulatoryItemsEntityInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveregulatoryitemsentityinfo", data, requestOptions)
        .then(handleResponse);
}

const saveAccountFeaturesEntityInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveaccountfeaturesentityinfo", data, requestOptions)
        .then(handleResponse);
}

const saveEntityOfficerandOwnerInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveentityofficerandownerinfo", data, requestOptions)
        .then(handleResponse);
}

const deleteEntityOfficerandOwner = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteentityofficerandowner", data, requestOptions)
        .then(handleResponse);
}

const deleteEntityOfficerandOwnerById = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteentityofficerandownerbyid", data, requestOptions)
        .then(handleResponse);
}

const saveEntityDueDeligenceInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveentityduedeligenceinfo", data, requestOptions)
        .then(handleResponse);
}

const saveIRABeneficiaryInfo = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/saveirabeneficiaryinfo", data, requestOptions)
        .then(handleResponse);
}

const deleteIRABeneficiary = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteirabeneficiary", data, requestOptions)
        .then(handleResponse);
}

const deleteIRABeneficiaryById = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteirabeneficiarybyid", data, requestOptions)
        .then(handleResponse);
}

const deleteApplication = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteapplication", data, requestOptions)
        .then(handleResponse);
}

const closeApplication = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/closeapplication", data, requestOptions)
        .then(handleResponse);
}

const getApplicantApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicantapplications", data, requestOptions)
        .then(handleResponse);
}

const getApplicationsByClientId = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationsbyclientid", data, requestOptions)
        .then(handleResponse);
}

const deleteApplicationDocument = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteapplicationdocument", data, requestOptions)
        .then(handleResponse);
}

const getNotCompletedCount = () => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getnotcompletedcount", null, requestOptions)
        .then(handleResponse);
}

const generateApplicationPdf = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/generateapplicationpdf", data, requestOptions)
        .then(handleResponse);
}

const getApplicationSummary = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationsummary", data, requestOptions)
        .then(handleResponse);
}

const submitApplicationByClient = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/submitapplicationbyclient", data, requestOptions)
        .then(handleResponse);
}

const generateAccountNumber = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/generateaccountnumber", data, requestOptions)
        .then(handleResponse);
}

const searchApplicationsByAdmin = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/searchapplicationsbyadmin", data, requestOptions)
        .then(handleResponse);
}

const getApplicationStats = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/getapplicationstats", data, requestOptions)
        .then(handleResponse);
}

const sendRequestToGiact = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/sendrequesttogiact", data, requestOptions)
        .then(handleResponse);
}

const downloadFileFromS3 = (data) => {
    const requestOptions = { method: "GET", responseType: "blob", headers: authHeader() };
    return http
        .get("/ola/downloadfilefroms3?fileName=" + data, requestOptions)
        .then(handleResponse);
}

const getPrintedPdfFile = (data) => {
    const requestOptions = { method: "GET", responseType: "blob", headers: authHeader() };
    return http
        .get("/ola/getPrintedPdfFile?fileName=" + data, requestOptions)
        .then(handleResponse);
}

const checkDuplicateApplicationStatus = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/checkduplicateapplicationstatus", data, requestOptions)
        .then(handleResponse);
}

const createDuplicateApplication = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/createduplicateapplication", data, requestOptions)
        .then(handleResponse);
}

const sendRequestToFIS = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/sendrequesttofis", data, requestOptions)
        .then(handleResponse);
}

const getFISInfoByApplicationId = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/ola/getFISInfoByApplicationId/" + id, requestOptions)
        .then(handleResponse);
}

const getLogfromFIS = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/ola/getLogfromFIS/" + id, requestOptions)
        .then(handleResponse);
}

const deleteAuthSigner = (id) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/ola/deleteauthsigner/" + id, requestOptions)
        .then(handleResponse);
}

const applicationService = {
    getStates,
    getStatesByCountryId,
    getCountries,
    saveApplicantAccountTypeInfo,
    saveApplicantPrimaryCoAppInfo,
    saveEmploymentInfo,
    saveInvestorProfileInfo,
    saveRegulatoryItemsInfo,
    saveAccountFeaturesInfo,
    uploadApplicationDocuments,
    saveDisclosureandSignatureInfo,
    updateApplicationStatus,
    getApplicationReview,
    getApplicationBrokerLevel,
    getAGTBrokerApplications,
    getBrokerApplications,
    updateBrokerApplicationStatus,
    saveForeignDueDeligence,
    applicationApexAppeal,
    createExternalBankAccount,
    getExternalBankList,
    closeApexAccount,
    saveEntityInformation,
    saveInvestorProfileEntityInfo,
    saveRegulatoryItemsEntityInfo,
    saveAccountFeaturesEntityInfo,
    saveEntityOfficerandOwnerInfo,
    deleteEntityOfficerandOwner,
    deleteEntityOfficerandOwnerById,
    saveEntityDueDeligenceInfo,
    saveIRABeneficiaryInfo,
    deleteIRABeneficiary,
    deleteIRABeneficiaryById,
    deleteApplication,
    closeApplication,
    getApplicantApplications,
    deleteApplicationDocument,
    generateApplicationPdf,
    getApplicationsByClientId,
    getNotCompletedCount,
    getSubordinateApplications,
    getSupervisorApplications,
    getApplicationSummary,
    getRRApplications,
    submitApplicationByClient,
    generateAccountNumber,
    getApplicationQueue,
    searchApplicationsByAdmin,
    getApplicationStats,
    sendRequestToGiact,
    downloadFileFromS3,
    getPrintedPdfFile,
    checkDuplicateApplicationStatus,
    createDuplicateApplication,
    sendRequestToFIS,
    getFISInfoByApplicationId,
    getLogfromFIS,
    getSubordinateDashboardData,
    deleteAuthSigner
};

export default applicationService;