import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import brokerService from '../../services/brokerservice';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import adminService from '../../services/adminservice';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';

const BrokerRegisterReps = () => {

    const [repsList, setRepsList] = useState(null);
    const [repAddShow, setRepAddShow] = useState(false);
    const [repEditShow, setRepEditShow] = useState(false);
    const [currentRep, setCurrentRep] = useState(null);
    const [showStatus, setShowStatus] = useState(false);
    const [showEditStatus, setShowEditStatus] = useState(false);
    const [keyword, setKeyword] = useState('');

    const params = useParams();

    const handleRepAddClose = () => {
        setRepAddShow(false);
        reset();
    }
    const handleRepEditClose = () => {
        setRepEditShow(false);
        resetEdit();
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getRegisterReps(1);
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const styleCursor = {
        cursor: "pointer"
    }

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const handleRegisterRepAddShow = () => {
        setRepAddShow(true);
        setCurrentRep(null);
    }

    const handlePromotetoSupervisor = (item) => {
        loader.show();
        let data = {
            userId: item.id,
            roleName: item.roleName
        };
        brokerService.updateSubordinateRole(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    getRegisterReps(1);
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const columns = [
        {
            name: 'Username',
            selector: row => <div className='paddingLeft-15'>{row.userName}</div>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: row => <a href={"mailto:" + row.emailAddress}>{row.emailAddress}</a>,
            sortable: true,
        },
        {
            name: 'Rep Code',
            selector: row => row.repCode !== null ? row.repCode : "-",
            sortable: true,
        },
        {
            name: 'Creation Date',
            selector: row => moment.utc(row.createdDate).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.isLocked === true ? "Locked" : "UnLocked",
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Register Rep" onClick={(e) => handleResisterRepEditShow(row)}>Edit</Dropdown.Item>
                            {/* <Dropdown.Item title="Promote to Supervisor" onClick={(e) => handlePromotetoSupervisor(row)}>Promote to Supervisor</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    const getRegisterReps = (page) => {
        loader.show();
        let data = {
            "brokerUserId": params.id,
            "searchKeyword": keyword,
            "userRole": "RR",
            "pageNumber": page,
            "pageSize": perPage
        };
        //adminService.searchSubOrdinates(data)
        adminService.getBrokerRRSupervisorForAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setRepsList(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setRepsList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Add registered rep

    const validationSchema = Yup.object().shape({
        adduserName: Yup.string().required("Username is required").nullable(),
        addfirstName: Yup.string().required("First name is required").nullable(),
        addlastName: Yup.string().required("Last name is required").nullable(),
        addrepCode: Yup.string().required("Rep code is required").nullable(),
        addemailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable(),
        addPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
        addConfirmPassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref("addPassword"), null],
            "Password and confirm password doesn’t match").nullable()
    });

    const { register: registerAdd, handleSubmit: handleSubmitAdd, reset, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) });

    const addRegisterRepSubmit = (values) => {
        loader.show();
        let data = {
            loggedInUserId: null,
            userName: values.adduserName,
            password: values.addPassword,
            firstName: values.addfirstName,
            lastName: values.addlastName,
            middleInitial: values.addmiddleInitial,
            emailAddress: values.addemailAddress,
            repCode: values.addrepCode,
            isLocked: showStatus,
            roleName: 'RR',
            brokerUserId: params.id
        };
        adminService.addSubordinate(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    setRepAddShow(false);
                    getRegisterReps(1);
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
                else {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Edit register representative 

    const validationSchemaEdit = Yup.object().shape({
        firstName: Yup.string().required("First name is required").nullable(),
        lastName: Yup.string().required("Last name is required").nullable(),
        repCode: Yup.string().required("Rep code is required").nullable(),
        emailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable()
    });

    const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit }, reset: resetEdit, setValue: setValueEdit } = useForm({ resolver: yupResolver(validationSchemaEdit) });

    const handleResisterRepEditShow = (item) => {
        item.isLocked ? setShowEditStatus(true) : setShowEditStatus(false);
        setRepEditShow(true);
        setCurrentRep(item);
        setValueEdit("firstName", item.firstName);
        setValueEdit("lastName", item.lastName);
        setValueEdit("middleInitial", item.middleInitial);
        setValueEdit("emailAddress", item.emailAddress);
        setValueEdit("userName", item.userName);
        setValueEdit("repCode", item.repCode);
        setValueEdit("cbEditLock", item.isLocked);
    }

    const editRegrepSubmit = (values) => {
        loader.show();
        let data = {
            loggedInUserId: null,
            UserId: currentRep?.id,
            firstName: values.firstName,
            lastName: values.lastName,
            middleInitial: values.middleInitial,
            emailAddress: values.emailAddress,
            repCode: values.repCode,
            isLocked: values.isLocked
        };
        brokerService.updateBrokerSubOrdinate(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    setRepEditShow(false);
                    getRegisterReps(1);
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePageChange = page => {
        getRegisterReps(page);
    };

    const toggleStatusCheckbox = (cbVal) => {
        if (cbVal) {
            setShowStatus(true);
        }
        else {
            setShowStatus(false);
        }
    }

    const toggleEditLockChange = (cbVal) => {
        cbVal ? setShowEditStatus(true) : setShowEditStatus(false);
    }

    /* Searching criteria starts here */

    const { register, handleSubmit, resetField } = useForm({});

    const searchSubmit = (values) => {
        loader.show();
        let data = {
            "brokerUserId": params.id,
            "userRole": "RR",
            "searchKeyword": values.searchKeyword,
            "pageNumber": 1,
            "pageSize": perPage
        };
        //adminService.searchSubOrdinatesByAdmin(data)
        adminService.getBrokerRRSupervisorForAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setRepsList(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setRepsList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleResetFields = () => {
        resetField('searchKeyword');
        setKeyword('');
        reset({ searchKeyword: '' });
        getRegisterReps(1);
    }

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    };

    /* Searching criteria ends here */

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        loader.show();
        let data = {
            "brokerUserId": params.id,
            "userRole": "RR",
            "searchKeyword": keyword,
            "pageNumber": page,
            "pageSize": perPage
        };
        //adminService.searchSubOrdinatesByAdmin(data)
        adminService.getBrokerRRSupervisorForAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setRepsList(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                else {
                    setRepsList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };


    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/brokers">Brokers</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Register Representatives</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Register Representatives</h2>
                        </div>
                        <div className="col-lg-8">
                            <form onSubmit={handleSubmit(searchSubmit)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword" {...register('searchKeyword')} 
                                                value={keyword} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields()} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-3'>
                                        <ul className="table_btns float-right">
                                            <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handleRegisterRepAddShow()}>Add Register Representative</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {repsList && <DataTable theme="default" columns={columns} data={repsList}
                        pagination paginationServer
                        paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {repsList === null && <DataTable pagination noDataComponent="No record found" />}

                    <div className="row pt-3"></div>

                </section>

                {/* Add Register Rep */}
                <Modal
                    show={repAddShow}
                    onHide={handleRepAddClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Add a New Register Representative</h3>
                                        <form onSubmit={handleSubmitAdd(addRegisterRepSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" name="adduserName" {...registerAdd('adduserName')} maxLength={50} className="inputForm" />
                                                                    <p className="error" style={styleLeft}>{errors?.adduserName && errors.adduserName.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Password<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="password" className="inputForm" name="addPassword" maxLength={50} {...registerAdd('addPassword')} />
                                                                    <p className='error' style={styleLeft}>{errors?.addPassword && errors.addPassword.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Confirm Password<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="password" className="inputForm" name="addConfirmPassword" maxLength={50} {...registerAdd('addConfirmPassword')} />
                                                                    <p className='error' style={styleLeft}>{errors?.addConfirmPassword && errors.addConfirmPassword.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="addfirstName" maxLength={50} {...registerAdd('addfirstName')} />
                                                                    <p className='error' style={styleLeft}>{errors?.addfirstName && errors.addfirstName.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="addlastName" {...registerAdd('addlastName')} maxLength={50} />
                                                                    <p className='error' style={styleLeft}>{errors?.addlastName && errors.addlastName.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="addmiddleInitial" {...registerAdd('addmiddleInitial')} maxLength={50} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="addemailAddress" {...registerAdd('addemailAddress')} maxLength={50} />
                                                                    <p className='error' style={styleLeft}>{errors?.addemailAddress && errors.addemailAddress.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Rep Code<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="addrepCode" {...registerAdd('addrepCode')} maxLength={4} />
                                                                    <p className="error" style={styleLeft}>{errors?.addrepCode && errors.addrepCode.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-2">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Locked</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                <input className="form-check-input" onClick={(e) => toggleStatusCheckbox(e.target.checked)} {...registerAdd('addcbStatus')}
                                                                        type="checkbox" value={showStatus} checked={showStatus ? true : false} name="addcbStatus" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Add</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleRepAddClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Edit Register Rep */}
                <Modal
                    show={repEditShow}
                    onHide={handleRepEditClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Register Representative</h3>

                                        <form onSubmit={handleSubmitEdit(editRegrepSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="userName" {...registerEdit('userName')} maxLength={50} disabled={true} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="firstName" {...registerEdit('firstName')} maxLength={50} />
                                                                    <p className="error" style={styleLeft}>{errorsEdit?.firstName && errorsEdit.firstName.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="lastName" {...registerEdit('lastName')} maxLength={50} />
                                                                    <p className="error" style={styleLeft}>{errorsEdit?.lastName && errorsEdit.lastName.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="middleInitial" {...registerEdit('middleInitial')} maxLength={50} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="emailAddress" {...registerEdit('emailAddress')} maxLength={50} />
                                                                    <p className="error" style={styleLeft}>{errorsEdit?.emailAddress && errorsEdit.emailAddress.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Rep Code<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                <input type="text" className="inputForm" name="repCode" {...registerEdit('repCode')} maxLength={4} />
                                                                    <p className="error" style={styleLeft}>{errorsEdit?.repCode && errorsEdit.repCode.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-2">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Locked</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                <input className="form-check-input" type="checkbox" onClick={(e) => toggleEditLockChange(e.target.checked)}
                                                                        value={showEditStatus} checked={showEditStatus} name="cbEditLock" {...registerEdit('cbEditLock')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleRepEditClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default BrokerRegisterReps;