import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import accountService from '../../services/accountservice';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { Dropdown } from 'react-bootstrap';
import { getLoggedInUserInfo } from '../../common/common';
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pageSize } from '../../helpers/constants';

const Brokers = () => {

    const [brokerList, setBrokerList] = useState(null);
    const [pricingPlans, setPricingPlans] = useState(null);
    const [brokerAddShow, setBrokerAddShow] = useState(false);
    const [brokerEditShow, setBrokerEditShow] = useState(false);
    const [currentBroker, setCurrentBroker] = useState(null);
    const [showLockedStatus, setShowLockedStatus] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [eDocSettingsShow, setEDocSettingsShow] = useState(false);

    const navigate = useNavigate();

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(pageSize);

    const styleCursor = {
        cursor: "pointer"
    }

    const styleLeft = {
        textAlign: 'left'
    }

    const handleBrokerAddClose = () => {
        setBrokerAddShow(false);
        addformik.resetForm();
    }
    const handleBrokerEditClose = () => setBrokerEditShow(false);
    const handleEDocSettingsClose = () => setEDocSettingsShow(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getBrokers(1);
    }, []);

    const getBrokers = (page) => {
        loader.show();
        if(page === 1) {
            setSearchKeyword(null);
        }
        let data = {
            "searchKeyword": searchKeyword !== null ? searchKeyword : null,
            "pageNumber": page,
            "pageSize": perPage
        };
        adminService.searchBrokers(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setBrokerList(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setBrokerList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getPricingPlans = () => {
        adminService.getPricingPlans()
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setPricingPlans(result);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleBrokerAddShow = () => {
        addformik.resetForm();
        getPricingPlans();
        setShowLockedStatus(false);
        setBrokerAddShow(true);
        setCurrentBroker(null);
    }

    //Add Broker
    let addformikValues = {
        adduserName: null,
        addPassword: null,
        addConfirmPassword: null,
        addemailAddress: null,
        addfirstName: null,
        addlastName: null,
        addmiddleInitial: null,
        addisLocked: null,
        addMaxTradeAmount: null,
        addRepCode: null,
        addPricingPlan: null,
        file: null
    };

    let ADD_INITIAL_FORM_STATE = addformikValues;

    const FILE_SIZE = 5242880;
    const ADD_FORM_VALIDATION = Yup.object().shape({
        adduserName: Yup.string().required("Username is required").nullable(),
        addfirstName: Yup.string().required("First name is required").nullable(),
        addlastName: Yup.string().required("Last name is required").nullable(),
        addemailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable(),
        addPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
        addRepCode: Yup.string().required("Rep code is required").nullable(),
        addPricingPlan: Yup.string().required("Select pricing plan").nullable(),
        addMaxTradeAmount: Yup.number().typeError('You must specify a number').min(100, 'Minimum value 100 $').nullable(),

        // file: Yup.mixed().required("You need to provide a file")
        // .test("fileSize", "The file is too large", (value) => {
        //     return value && value.size <= FILE_SIZE
        // })
        // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf .docx and .doc", (value) => {
        //     return value && (value.type === "image/jpeg" || value.type === "image/bmp" 
        //     || value.type === "image/png" 
        //     || value.type === 'application/pdf' 
        //     || value.type === "application/msword");
        // }),
    });

    const addformik = useFormik({
        enableReinitialize: true,
        initialValues: ADD_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let fupload = null;
            if (uploadedFile !== null) {
                if (uploadedFile.length > 0) {
                    fupload = uploadedFile[0];
                }
            }
            let formData = new FormData();
            formData.append("LoggedInUserId", getLoggedInUserInfo().id);
            formData.append("Username", values.adduserName);
            formData.append("Password", values.addPassword);
            formData.append("FirstName", values.addfirstName);
            formData.append("LastName", values.addlastName);
            formData.append("MiddleInitial", values.addmiddleInitial);
            formData.append("EmailAddress", values.addemailAddress);
            formData.append("MaxTradeAmount", values.addMaxTradeAmount);
            formData.append("RepCode", values.addRepCode);
            formData.append("PricingPlanId", values.addPricingPlan);
            formData.append("IsLocked", showLockedStatus);
            //formData.append("file", uploadedFile[0]);
            formData.append("file", fupload);
            accountService.brokerSignup(formData, config)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setBrokerAddShow(false);
                        getBrokers(1);
                        toast.success("Broker has been added successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    else {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const toggleStatusCheckbox = (cbVal) => {
        if (cbVal) {
            setShowLockedStatus(true);
        }
        else {
            setShowLockedStatus(false);
        }
    }

    const handleChangeFile = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFile(chosenFiles);
    }

    const handleUploadFile = files => {
        const uploaded = [];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        });
        setUploadedFile(uploaded);
    }

    const handleBrokerEditShow = (item) => {
        setCurrentBroker(item);
        getPricingPlans();
        toggleStatusCheckbox(item.isLocked);
        setBrokerEditShow(true);
    }

    //Edit Broker 
    let formikValues = {
        userName: currentBroker?.userName,
        emailAddress: currentBroker?.email,
        firstName: currentBroker?.firstName,
        lastName: currentBroker?.lastName,
        middleInitial: currentBroker?.middleInitial,
        isLocked: currentBroker?.isLocked,
        maxTradeAmount: currentBroker?.maxTradeAmount,
        repCode: currentBroker?.repCode,
        pricingPlan: currentBroker?.planId
    };

    const EDIT_FORM_VALIDATION = Yup.object().shape({
        userName: Yup.string().required("Username is required").nullable(),
        firstName: Yup.string().required("First name is required").nullable(),
        lastName: Yup.string().required("Last name is required").nullable(),
        emailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable(),
        repCode: Yup.string().required("Rep code is required").nullable(),
        pricingPlan: Yup.string().required("Select pricing plan").nullable(),
        maxTradeAmount: Yup.number().typeError('You must specify a number').min(100, 'Minimum value 100 $').nullable(),
    });

    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: EDIT_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let fileUpload = uploadedFile != null && uploadedFile.length > 0 ? uploadedFile[0] : null;
            let formData = new FormData();
            formData.append("Id", currentBroker?.userId);
            formData.append("LoggedInUserId", null);
            formData.append("Username", values.userName);
            formData.append("FirstName", values.firstName);
            formData.append("LastName", values.lastName);
            formData.append("MiddleInitial", values.middleInitial);
            formData.append("EmailAddress", values.emailAddress);
            formData.append("MaxTradeAmount", values.maxTradeAmount);
            formData.append("RepCode", values.repCode);
            formData.append("PricingPlanId", values.pricingPlan);
            formData.append("IsLocked", showLockedStatus);
            formData.append("file", fileUpload);
            accountService.updateBroker(formData, config)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setBrokerEditShow(false);
                        getBrokers(1);
                        toast.success("Broker has been updated successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    else {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const redirectToBillingHistory = (brokerInfo) => {
        navigate('/admin/billinghistory/' + brokerInfo.userId);
    }

    const redirectToApplications = (brokerInfo) => {
        navigate('/admin/brokerapplications/' + brokerInfo.userId)
    }

    const redirectToSupervisors = (brokerInfo) => {
        navigate('/admin/supervisors/' + brokerInfo.userId)
    }

    const redirectToRegisterReps = (brokerInfo) => {
        navigate('/admin/registerreps/' + brokerInfo.userId)
    }

    /* Searching criteria starts here */
    let searchFormikValues = {
        searchKeyword: null
    };

    let SEARCH_INITIAL_FORM_STATE = searchFormikValues;
    SEARCH_INITIAL_FORM_STATE = searchFormikValues;
    const searchformik = useFormik({
        enableReinitialize: true,
        initialValues: SEARCH_INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                "searchKeyword": values.searchKeyword !== null ? values.searchKeyword : null,
                "pageNumber": 1,
                "pageSize": perPage
            };
            adminService.searchBrokers(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload
                        setBrokerList(result);
                        setTotalRows(result[0].totalRecords);
                        loader.hide();
                    }
                    else {
                        setBrokerList(null);
                        loader.hide();
                    }
                    setSearchKeyword(values.searchKeyword);
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleResetFields = () => {
        searchformik.resetForm({ values: { searchKeyword: '' } });
        setSearchKeyword(null);
        loader.show();
        let data = {
            "searchKeyword": null,
            "pageNumber": 1,
            "pageSize": 10
        };
        adminService.searchBrokers(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload
                    setBrokerList(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setBrokerList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    /* Searching criteria ends here */

    /* Updte broker e-documents settings */

    const validationSchemaEDocSetting = Yup.object().shape({
        feePerStatement: Yup.string().required("Fee per statement is required").nullable(),
        feePerConfirmation: Yup.string().required("Fee per confirmation is required").nullable()
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ resolver: yupResolver(validationSchemaEDocSetting) });

    const handleEDocSettingsShow = (item) => {
        reset();
        setCurrentBroker(item);
        setValue("feePerStatement", item?.feePerStatement);
        setValue("feePerConfirmation", item?.feePerConfirmation);
        setEDocSettingsShow(true);
    }

    const onSubmit = (data) => {
        loader.show();
        let body = {
            "Id": currentBroker.id, //brokerId
            "feePerStatement": data.feePerStatement,
            "feePerConfirmation": data.feePerConfirmation
        };
        adminService.updateBrokerEDocumentSettings(body)
            .then((json) => {
                var result = json.data.payload;
                if (result) {
                    setEDocSettingsShow(false);
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    getBrokers(1);
                    reset();
                    loader.hide();
                }
                else {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }
    
    const handlePageChange = page => {
        getBrokers(page);
    };

    /* Updte broker e-documents settings ends here */

    const columns = [
        {
            name: 'Logo',
            selector: row => row.logo === null || row.logo.includes('https://wdola.s3.amazonaws.com/?')
                ? <div className='broker-logo'><img src="javascript:;" style={{ width: '40px', height: 'auto' }} /></div>
                : <div className='broker-logo'><img src={row.logo} style={{ width: '40px', height: 'auto' }} /></div>,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: row => <a href={"mailto:" + row.email}>{row.email}</a>,
            sortable: true,
        },
        {
            name: 'Pricing Plan',
            selector: row => row.planName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.isLocked === true ? "Locked" : "UnLocked",
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Broker" onClick={(e) => handleBrokerEditShow(row)}>Edit</Dropdown.Item>
                            <Dropdown.Item title="View Applications" onClick={(e) => redirectToApplications(row)}>View Applications</Dropdown.Item>
                            <Dropdown.Item title="View Billing History" onClick={(e) => redirectToBillingHistory(row)}>View Billing History</Dropdown.Item>

                            <Dropdown.Item title="View Supervisors" onClick={(e) => redirectToSupervisors(row)}>View Supervisors</Dropdown.Item>
                            <Dropdown.Item title="View Register Representatives" onClick={(e) => redirectToRegisterReps(row)}>View Register Reps</Dropdown.Item>
                            {/* <Dropdown.Item title="E-Documents Settings" onClick={(e) => handleEDocSettingsShow(row)}>E-Docs Settings</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Brokers</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Brokers</h2>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={searchformik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                                    onBlur={searchformik.handleBlur} onChange={searchformik.handleChange} value={searchformik.values.searchKeyword}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields(searchformik)} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-2'>
                            <ul className="table_btns float-right">
                                <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handleBrokerAddShow()}>Add Broker</button></li>
                            </ul>
                        </div>
                    </div>

                    {brokerList && <DataTable theme="default" columns={columns} data={brokerList} pagination paginationServer paginationTotalRows={totalRows} onChangePage={handlePageChange} />}
                    {brokerList === null && <DataTable pagination noDataComponent="No record found" />}
                    <div className="row pt-3"></div>
                </section>

                {/* Add Broker */}
                <Modal
                    show={brokerAddShow}
                    onHide={handleBrokerAddClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Add a New Broker</h3>

                                        <form onSubmit={addformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="adduserName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.adduserName} />
                                                                    {addformik.touched.adduserName && addformik.errors.adduserName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.adduserName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Password<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="password" className="inputForm" name="addPassword"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addPassword} />
                                                                    {addformik.touched.addPassword && addformik.errors.addPassword ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addPassword}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addfirstName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addfirstName} />
                                                                    {addformik.touched.addfirstName && addformik.errors.addfirstName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addfirstName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addlastName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addlastName} />
                                                                    {addformik.touched.addlastName && addformik.errors.addlastName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addlastName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addmiddleInitial"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addmiddleInitial} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="email" className="inputForm" name="addemailAddress"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addemailAddress} />
                                                                    {addformik.touched.addemailAddress && addformik.errors.addemailAddress ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addemailAddress}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Max. Trade Amount</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addMaxTradeAmount"
                                                                        maxLength={10} onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addMaxTradeAmount} />
                                                                    {addformik.touched.addMaxTradeAmount && addformik.errors.addMaxTradeAmount ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addMaxTradeAmount}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Rep Code<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addRepCode"
                                                                        maxLength={10} onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addRepCode} />
                                                                    {addformik.touched.addRepCode && addformik.errors.addRepCode ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addRepCode}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Pricing Plan<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <select className="form-select" name="addPricingPlan"
                                                                        onBlur={addformik.handleBlur} onChange={addformik.handleChange} value={addformik.values.addPricingPlan}>
                                                                        <option value="">Select Plan</option>
                                                                        {pricingPlans && pricingPlans.map((option) => (
                                                                            <option value={option.planId}>{option.planName}</option>
                                                                        ))}
                                                                    </select>
                                                                    {addformik.touched.addPricingPlan && addformik.errors.addPricingPlan ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addPricingPlan}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Locked</label>
                                                                <div className="input_area">
                                                                    <div className="form-check pt-5">
                                                                        <input className="form-check-input"
                                                                            onClick={(e) => toggleStatusCheckbox(e.target.checked)}
                                                                            type="checkbox" onBlur={addformik.handleBlur}
                                                                            value={addformik.values.addisLocked}
                                                                            checked={showLockedStatus ? true : false}
                                                                            id="addcbStatus" name="addcbStatus" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-lg-12 pt-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Upload Logo<span className="error-info"></span></label>
                                                                <div className="input_area">
                                                                    <input type="file" onBlur={addformik.handleBlur} onChange={handleChangeFile}
                                                                        className="inputForm" name="file" />
                                                                    {/* {addformik.touched.file && addformik.errors.file ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.file}</p>
                                                                    ) : null} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Add</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleBrokerAddClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Edit Broker */}
                <Modal
                    show={brokerEditShow}
                    onHide={handleBrokerEditClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Broker</h3>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="userName"
                                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} disabled="true"
                                                                        value={formik.values.userName} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="firstName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.firstName} />
                                                                    {formik.touched.firstName && formik.errors.firstName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.firstName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="lastName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.lastName} />
                                                                    {formik.touched.lastName && formik.errors.lastName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.lastName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="middleInitial"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.middleInitial} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="emailAddress"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.emailAddress} />
                                                                    {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.emailAddress}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Max Trade Amount</label>
                                                                <div className="input_area">
                                                                    <input type="number" className="inputForm" name="maxTradeAmount"
                                                                        maxLength="10" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.maxTradeAmount} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Rep Code<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="repCode"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.repCode} />
                                                                    {formik.touched.repCode && formik.errors.repCode ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.repCode}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Pricing Plan</label>
                                                                <div className="input_area">
                                                                    <select className="form-select" name="pricingPlan"
                                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.pricingPlan}>
                                                                        <option value="">Select Plan</option>
                                                                        {pricingPlans && pricingPlans.map((option) => (
                                                                            <option value={option.planId}>{option.planName}</option>
                                                                        ))}
                                                                    </select>
                                                                    {formik.touched.pricingPlan && formik.errors.pricingPlan ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.pricingPlan}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Locked</label>
                                                                <div className="input_area">
                                                                    <div className="form-check pt-5">
                                                                        <input className="form-check-input"
                                                                            onClick={(e) => toggleStatusCheckbox(e.target.checked)}
                                                                            type="checkbox" onBlur={formik.handleBlur}
                                                                            value={formik.values.isLocked}
                                                                            checked={showLockedStatus ? true : false}
                                                                            id="cbStatus" name="cbStatus" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 pt-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Upload Logo<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="file" onBlur={formik.handleBlur} onChange={handleChangeFile}
                                                                        className="inputForm" name="editFile" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleBrokerEditClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* E-Documents Settings for Broker */}
                <Modal
                    show={eDocSettingsShow}
                    onHide={handleEDocSettingsClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>E-Documents Settings</h3>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Fee Per Statement</label>
                                                                <div className="input_area">
                                                                    <input type="number" className="inputForm" name="feePerStatement"
                                                                        {...register("feePerStatement")} min={0} max={100} step={.01} />
                                                                    <p className="error" style={styleLeft}>{errors?.feePerStatement && errors.feePerStatement.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Fee Per Confirmation</label>
                                                                <div className="input_area">
                                                                    <input type="number" className="inputForm" name="feePerConfirmation"
                                                                        {...register("feePerConfirmation")} min={0} max={100} step={.01} />
                                                                  <p className="error" style={styleLeft}>{errors?.feePerConfirmation && errors.feePerConfirmation.message}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Save</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleEDocSettingsClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    );
};

export default Brokers;