import React, { useEffect, useState } from 'react';
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import accountService from "../../services/accountservice";
import { brokerRole } from '../../helpers/constants';
import { checkAuthorization, getLoggedInUserInfo } from '../../common/common';

const ProfileComponent = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isBroker, setIsBroker] = useState(false);
    const [baseUrl, setBaseUrl] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        let locationPath = window.location.href.split('/');
        setBaseUrl(locationPath[0] + "/#/signup?brokerUserId=");
        getUserProfileInfo();
    }, []);

    const getUserProfileInfo = () => {
        loader.show();
        accountService.getUserProfileInfo().then((json) => {
            let result = json.data.payload;
            if (result !== null) {
                setUserInfo(result);
                loader.hide();

                if (getLoggedInUserInfo().userRole === brokerRole) {
                    setIsBroker(true);
                }
            }
            loader.hide();
        })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <h2 className="heading">Profile</h2>
                </div>
            </div>
            <div className="tab-content" id="reviews">
                <div className="formContainer no-mar">
                    <div id="personal" className="container-fluid">
                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        {/* <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">User Id</label>
                                                <div className="input_area">
                                                    {userInfo?.id}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">First Name</label>
                                                <div className="input_area">
                                                    {userInfo?.firstName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Middle Initial</label>
                                                <div className="input_area">
                                                    {userInfo?.middleInitial !== '' ? userInfo?.middleInitial : "-"}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Last Name</label>
                                                <div className="input_area">
                                                    {userInfo?.lastName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Username</label>
                                                <div className="input_area">
                                                    {userInfo?.username}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        
                                        <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Email Address</label>
                                                <div className="input_area">
                                                    <a href={"mailto:" + userInfo?.emailaddress}>{userInfo?.emailaddress}</a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Is Locked</label>
                                                <div className="input_area">
                                                    {userInfo?.isLocked ? "Locked" : "UnLocked"}
                                                </div>
                                            </div>
                                        </div> */}
                                        {isBroker && <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Signup Link</label>
                                                <div className="input_area">
                                                    <a href={baseUrl + userInfo?.id} target="_blank">{baseUrl + userInfo?.id}</a>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='row'>
                                        {isBroker && <div className="col-lg-3">
                                            <div className="input_wrapper">
                                                <label className="profile-label">Profile Icon</label>
                                                <div className="input_area">
                                                    -
                                                </div>
                                            </div>
                                        </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileComponent;