
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import applicationService from '../services/applicationservice';
import lovService from '../services/lovservice';
import OLAEnums from '../helpers/olaenums';
import { defaultCountry } from '../helpers/constants';

const BeneficiariesRows = ({ rowsData, stateRows, deleteTableRows, handleChange, controlDisable }) => {
    const [stateList, setStateList] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [iraBeneficiariesTypeList, setIRABeneficiariesTypeList] = useState(null);

    useEffect(() => {
        getCountries();
        //getStatesByCountry(defaultCountry);
        if (stateRows) {
            setStateList(stateRows);
        }
        else {
            getStatesByCountry(defaultCountry);
        }
        getIRABeneficiaryTypes();
    }, [stateRows]);

    const getStatesByCountry = (index, countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    // setStateList(json.data.payload);
                    rowsData[index].stateList = json.data.payload
                }
            })
            .catch((error) => {
            });
    }

    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
            });
    }

    const getIRABeneficiaryTypes = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.IRABeneficiaryType)
            .then((json) => {
                if (json.data.payload !== null) {
                    setIRABeneficiariesTypeList(json.data.payload);
                }
            })
            .catch((error) => {
            });
    }

    return (
        rowsData.map((data, index) => {
            const { firstName, lastName, dateOfBirth, socialSecurityNumber, physicalAddress, city, postalCode, state, country, share, relationship, beneficiaryType } = data;
            return (
                <div className='add_formArea'>
                    <div className="row" key={index}>
                        <div className='col-lg-11'>
                            <div className='row'>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">First Name<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" className="inputForm" name="firstName" maxLength="50"
                                                value={firstName} onChange={(evnt) => (handleChange(index, evnt))} placeholder="Enter First Name" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Last Name<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" className="inputForm" maxLength="50"
                                                value={lastName} onChange={(evnt) => (handleChange(index, evnt))} name="lastName" placeholder="Enter Last Name" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Date of Birth<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="date" className="inputForm" value={dateOfBirth} onChange={(evnt) => (handleChange(index, evnt))} name="dateOfBirth" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">SSN<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <InputMask mask="999-99-9999" className="inputForm" value={socialSecurityNumber} onChange={(evnt) => (handleChange(index, evnt))} name="socialSecurityNumber" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Address<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" className="inputForm" maxLength="250" value={physicalAddress} onChange={(evnt) => (handleChange(index, evnt))} name="physicalAddress" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">State<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <select className="form-select border-color" name="state" onChange={(evnt) => (handleChange(index, evnt))} onBlur={(evnt) => (handleChange(index, evnt))} disabled={controlDisable ? true : false} value={state}>
                                                <option value="">Select State</option>
                                                {data.stateList && data.stateList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">City<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" className="inputForm" maxLength="50" value={city} onChange={(evnt) => (handleChange(index, evnt))} name="city" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" maxLength="10" className="inputForm" value={postalCode} onChange={(evnt) => (handleChange(index, evnt))} name="postalCode" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <select className="form-select border-color" name="country" disabled={controlDisable ? true : false}
                                                onChange={(evnt) => {
                                                    data.state = ''
                                                    getStatesByCountry(index, evnt.target.value);
                                                    handleChange(index, evnt);
                                                }}
                                                onBlur={(evnt) => (handleChange(index, evnt))} value={country}>
                                                {countryList && countryList.map((option) => (
                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Share %<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="number" min="1" max="100" maxLength="3" value={share} className="inputForm" disabled={controlDisable ? true : false} onChange={(evnt) => (handleChange(index, evnt))} name="share" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Relationship<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" value={relationship} className="inputForm" maxLength="50" disabled={controlDisable ? true : false} onChange={(evnt) => (handleChange(index, evnt))} name="relationship" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Beneficiary Type<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <select className="form-select border-color" name="beneficiaryType" disabled={controlDisable ? true : false} onChange={(evnt) => (handleChange(index, evnt))} onBlur={(evnt) => (handleChange(index, evnt))} value={beneficiaryType}>
                                                <option value="">Select Beneficiary Type</option>
                                                {iraBeneficiariesTypeList && iraBeneficiariesTypeList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-1'>
                            <button type='button' className='del_area' disabled={controlDisable ? true : false} onClick={() => (deleteTableRows(index))}><i className='fa fa-remove'></i></button>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

export default BeneficiariesRows;