import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import { checkAuthorization, toTitleCase } from '../../common/common';
import loader from "super-react-loader";
import OLAEnums from '../../helpers/olaenums';

const LatestApplicationsComponent = () => {

    const [applications, setApplications] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getLatestApplications();
    }, []);

    const getLatestApplications = () => {
        let data = {
            loggedInUserId: null,
            applicationStatus: null,
            userId: null,
            firstName: null,
            lastName: null,
            accountNumber: null,
            accountName: null,
            email: null,
            ssn: null,
            applicationId: null,
            pageNumber: 1,
            pageSize: 10
        };
        loader.show();
        adminService.getLatestApplications(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }
    return (
        <>
            <div className='row pt-4'>
                <div className='col-lg-12'>
                    <table className="table ">
                        <thead>
                            <tr>
                                <th scope="col">Account Number</th>
                                <th scope="col">Account Type</th>
                                <th scope="col">Name</th>
                                <th scope="col">Application Status</th>
                            </tr>
                        </thead>
                        <tbody data-gr-ext-installed="" light-mode="light">
                            {applications !== null && applications.map((item, i) => (
                                <tr className='row-line-height'>
                                    <td>{item.accountNumber !== null ? item.accountNumber : "-"}</td>
                                    <td>{item.accountTypeName + " (" + item.accountSubTypeName + ")"}</td>
                                    <td>{item.accountType === OLAEnums.AccountTypeEnum.EntityAccount && item.entityName !== null ? toTitleCase(item.entityName) : item.firstName !== null ? toTitleCase(item.firstName) + ' ' + toTitleCase(item.lastName) : item.userName}</td>
                                    <td>
                                        <div>{toTitleCase(item.applicationStatusName)}</div>
                                    </td>
                                </tr>
                            ))}

                            {applications === null && (
                                <tr>
                                    <td colSpan={10}>No record(s) found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

export default LatestApplicationsComponent;