import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import { checkAuthorization, convertUTCToLocalTime, getLoggedInUserInfo } from '../../common/common';
import { useNavigate, useParams } from 'react-router';
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import applicationService from '../../services/applicationservice';
import { Link } from "react-router-dom";

const LogFromFISComponent = () => {

    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isBroker, setIsBroker] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isRR, setIsRR] = useState(false);
    const param = useParams();
    const [acctType, setAcctType] = useState(null);
    const [acctSubType, setAcctSubType] = useState(null);
    const [updCus, setUpdcus] = useState(null);
    const [updmcpInd, setUpdmcpInd] = useState(null);
    const [updmcpJoint, setUpdmcpJoint] = useState(null);
    const [iraCus, setIracus] = useState(null);
    const [iracusBen, setIracusBen] = useState(null);

    const smalltextBox20 = {
        width: '20px', marginRight: '8px'
    };
    const smalltextBox40 = {
        width: '40px', marginRight: '8px'
    };
    const smalltextBox60 = {
        width: '60px', marginRight: '8px'
    };
    const smalltextBox80 = {
        width: '80px', marginRight: '8px'
    };
    const smalltextBox100 = {
        width: '100px', marginRight: '8px'
    };
    const mediumtextBox = {
        width: '214px',
    };
    const largetextBox800 = {
        width: '808px',
    };
    const boldLabel = {
        fontWeight: 'bold',
    }
    const boldLabelPadTop = {
        fontWeight: 'bold',
        paddingTop: '10px'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        checkRole();
        getLogfromFIS();
    }, []);

    const getLogfromFIS = () => {
        loader.show();
        applicationService.getLogfromFIS(param.id)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    setAcctType(result?.accountType);
                    setAcctSubType(result?.accountSutType);
                    if (result?.updcusResponse !== null) {
                        setUpdcus(result?.updcusResponse?.body);
                    }
                    if (result?.updmcpIndividualAccountResponse !== null) {
                        setUpdmcpInd(result?.updmcpIndividualAccountResponse?.body);
                    }
                    if (result?.updmcpJointAccountResponse !== null) {
                        setUpdmcpJoint(result?.updmcpJointAccountResponse?.body);
                    }
                    if (result?.iracusResponse !== null) {
                        setIracus(result?.iracusResponse?.body);
                        if (result?.iracusBeneficiaryResponse !== null) {
                            setIracusBen(result?.iracusBeneficiaryResponse);
                        }
                    }
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const checkRole = () => {
        var role = getLoggedInUserInfo().userRole;
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        else if (role === 'Broker') {
            setIsBroker(true);
        }
        else if (role === 'Supervisor24' || role === 'Supervisor4') {
            setIsSupervisor(true);
        }
        else if (role === 'RR') {
            setIsRR(true);
        }
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    {isAdmin && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/applications">Applications</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/applicationsummary">Application Summary</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Log from FIS</li>
                        </ol>
                    </nav>}
                    {isBroker && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/broker/applicationsummary">Application Summary</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Log from FIS</li>
                        </ol>
                    </nav>}
                    {isRR && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/registerrep/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/registerrep/applicationsummary">Application Summary</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Log from FIS</li>
                        </ol>
                    </nav>}
                    {isSupervisor && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/supervisor/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/supervisor/applicationsummary">Application Summary</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Log from FIS</li>
                        </ol>
                    </nav>}
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">FIS Log</h2>
                        </div>
                    </div>
                    <div className="tab-content" id="reviews">
                        <div className="formContainer no-mar">
                            <div className="container-fluid">
                                <div className="formCard">
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="review-heading">UPDCUS</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Additional Next</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Print Card</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OfficeRR</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_OFFRR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Planner</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Close</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ACCT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN/Tax ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_TAXID} disabled={true} />
                                                            <input type="text" className="inputForm" style={mediumtextBox} value={updCus?.updcuS_SSNO} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">IRS Control</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_IRS_CNTRL} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mult RR</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_MULTI_RRS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Short Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_SHORT_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Rel Party</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Stmt Curr</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Scratch Pad</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_SCR_PAD} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DTC #</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updCus?.updcuS_DTC_NBR} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Restr Until</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_RESTR_MM + "" + updCus?.updcuS_RESTR_DD + "" + updCus?.updcuS_RESTR_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Rsn</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ADDR1} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone 1</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_TELCO_CODE_1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_AC_1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_EXCH_1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_BASE_1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_EXT_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={largetextBox800} value={updCus?.updcuS_ADDR2} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone 2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_TELCO_CODE_2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_AC_2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_EXCH_2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_BASE_2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_EXT_2}  disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address Ind</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_ADDR_IND} disabled={true} />
                                                            <input type="text" className="inputForm" style={largetextBox800} value={updCus?.updcuS_ADDR3} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone 3</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_TELCO_CODE_3} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_AC_3} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_EXCH_3} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_TELCO_BASE_3} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ADDR4} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fax</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_FAX_AC} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updCus_FAX_EXCH} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_FAX_BASE} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_EXT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_CITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_STATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Post</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_ZIP} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updCus?.updcuS_ZIP_EXT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Issuer</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ISSUER_STATUS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">PRMBKR</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_PRMBKR_STATUS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CMTA</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_CMTA_ID} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Deliver</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_DEL_INST} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Payment</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_PAY_INST} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Dividend</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_DIV_INST} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Princ</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_PRI_INST} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mature</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_MAT_INST} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Free Sched</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FEE_SCHEDULE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Anu Stm</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_ANNUITY_STM} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">A/C Cat</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_BAL_CAT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">W-9 CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_W9_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Seg CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_SEG_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">IRS CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_IRS_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Nobo CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_NOBO_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Free P/CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Frm Del</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FIRM_DELIVERY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">IRA/KEO</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_IRA} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Bal Cat</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_BAL_CAT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CR SWP</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_CRINT_SWEEP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MF SWP</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_MONEY_SWEEP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">NY Tax</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_NYTAX} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">IA Waive</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_INACT_WAIVE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ITF Free</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_ITF_FREE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">St Tax</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_STATE_TAX} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Inl Adr</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">W-8 CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_W8_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OATS AC</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_OATS_ACCT_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MM Swp</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_MM_SWEEP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Day Trader</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_DAY_TRADER} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Stm Freq</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_STM_FREQ} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Free CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FEE_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fed Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FED_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Portfolio</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_PORTFOLIO} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Discr</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_DISCRETION} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sup Cnf</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_SUPPRESS_CONFIRM} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Print Stm</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_PRINT_STMT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sup Stmt</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_SUPPRESS_STM} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Net</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_NET_LEVEL} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Net Soc</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_NET_SOC_CODE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sys</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_UPDCUS_SYS_PURCH} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Loi</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS__LOI} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Ach Brd</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Entity</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_ENTITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">QSP</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_QSP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FSW</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FSW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Old Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_OLD_ACCT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_OATS_ACCT_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Pros</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_PROSPECTUS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FR Tier</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FR_TIER} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FR Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updcuS_FR_AC_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account Nature</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_ACCT_NAT} disabled={true} />
                                                            <input type="text" className="inputForm" style={largetextBox800} value={updCus?.updagR_ACCT_NAT_DESC} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Type: Cash</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_CSH} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MGN</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_MGN} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OPT</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_OPT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DVP</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_DVP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ACAP Opt-Out</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ICMA ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ICMA_ID} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_FOREIGN_COUNTRY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Div Tax Rate</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_FOREIGN_DIV_RATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Int Tax Rate</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_FOREIGN_INT_RATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State of Res</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_STATE_OF_RES} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Opt:</label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Level</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Exp</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Limits</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Opt</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fwd</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">User</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>ID:</label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Number</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">IIA</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Agent</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Cust</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sic</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-11">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Comm Firm</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_1} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_2} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_3} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} value={updCus?.updagR_STATE_BEF_3} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_4} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} value={updCus?.updagR_STATE_BEF_4} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_5} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} value={updCus?.updagR_STATE_BEF_5} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_6} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_6} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_7} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_7} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_8} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_8} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_9} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_9} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_10} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_10} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_11} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_11} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_12} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_12} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_13} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_13} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_14} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_14} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_15} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_15} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_16} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_16} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_17} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_17} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_18} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_18} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_19} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_19} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_20} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_20} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_21} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_21} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_22} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_22} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_23} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_23} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_24} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_24} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_25} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_25} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_26} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_26} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_27} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_27} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_28} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_28} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_29} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_29} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_30} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_30} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_31} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_31} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_32} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_32} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_33} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_33} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_34} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_34} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_35} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_35} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_36} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_36} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_37} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_37} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_38} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_38} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_39} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" value={updCus?.updagR_STATE_BEF_39} style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label"></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updagR_CODE_BEF_40} style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updCus?.updagR_STATE_BEF_40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Gvt Clr Account Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Gvt Clr Account Location</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Bank</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Reg Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CR Int</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Rt Sch</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Dr Int</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Rt Sch</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Comm</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Maint</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">User</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sched</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Fund</label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Pri</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Int</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mat</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fee</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Rate Class</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Margin</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>A</label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} value={updCus?.updcuS_ALT_ACCT_TYPE_A} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tel/Web</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ABA</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} value={updCus?.updcuS_ABA_NBR_A !== null ? updCus?.updcuS_ABA_NBR_A.substring(0, 4) : ""} /> -
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} value={updCus?.updcuS_ABA_NBR_A !== null ? updCus?.updcuS_ABA_NBR_A.substring(4, 8) : ""} /> -
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} value={updCus?.updcuS_ABA_NBR_A !== null ? updCus?.updcuS_ABA_NBR_A.substring(8, 1) : ""} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ALT_ACCT_A} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Prenote</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>B</label>
                                                        <div className="input_area">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tel/Web</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ABA</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} /> -
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} /> -
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Prenote</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>DDA</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Purch</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sales</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Advice</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sales</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_ADV_SALES} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Div/Int</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_DIV_INT_SW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">At Will</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_AT_WILL_SW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">System Dep</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_SYS_DEP_SW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mat</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_MATURE_SW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Pay</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updCus?.updcuS_PAY_SW} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Kind</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CPM</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Map Account</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Nasd Class</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Deposit</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Trust Receipt</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mkt/Par Ind</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox60} disabled={true} /><input type="text" className="inputForm" style={smalltextBox100} disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Ext No</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">1</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">3</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Split</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-11">
                                                    <div className="input_wrapper">
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Find Acronym</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Alert</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Corp MPID</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Household</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Alt Trustee</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Proprietary</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Suppress Reg</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FSA Ref</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Email</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updCus?.updcuS_EMAIL_ADDRESS} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ADR CONV Agent</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Update User</label>
                                                        <div className="input_area"><input type="text" value={updCus?.updcuS_LAST_UPD_USERID} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mail: Restrict Code</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Restrict Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Update Date</label>
                                                        <div className="input_area"><input type="text" value={updCus?.updcuS_OPEND_MM + "" + updCus?.updcuS_OPEND_DD + "" + updCus?.updcuS_OPEND_YY} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">XSP Transmit</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FINRA 4210</label>
                                                        <div className="input_area"><input type="text" value={updCus?.updcuS_FINRA_4210} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Muni MPID</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Cost Basis</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Cost Basis Method: ETF</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">EQ/BD/OPT</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MF</label>
                                                        <div className="input_area"><input type="text" className="inputForm" style={smalltextBox60} disabled={true} /><input type="text" style={smalltextBox60} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Exclude Wash Sale</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FOI</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fixed Income Method: Rec Mkt Disc</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">St Line Calc</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Authorize Taxable Bond Pre</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox60} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FATCA Status Indicator</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CH4 Satus Code</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sponsored GIIN</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">PTC #</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FDID</label>
                                                        <div className="input_area"><input type="text" value={updCus?.updcuS_FDID} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">GSCC</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Crest</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Bic</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox60} className="inputForm" disabled={true} /><input type="text" style={smalltextBox100} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Info</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox80} className="inputForm" disabled={true} />
                                                            <input type="text" style={smalltextBox80} className="inputForm" disabled={true} /><input type="text" style={smalltextBox80} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MBSCC: Settlement</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Clearing Nbr</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='formCard'>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="review-heading">UPDMCP</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Acct</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone Res</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_RES_PHONE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone Bus</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_BUS_PHONE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone Mob</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Phone Fax</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">

                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 1</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updmcpInd?.updmcP_ADDRESS_1} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updmcpInd?.updmcP_ADDRESS_2} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 3</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updmcpInd?.updmcP_ADDRESS_3} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 4</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updmcpInd?.updmcP_ADDRESS_4} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_CITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_STATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_ZIP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_ZIP_EXT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Emp Phone</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tax ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} value={updmcpInd?.updmcP_TAXID} disabled={true} />
                                                            <input type="text" style={smalltextBox100} className="inputForm" value={updmcpInd?.updmcP_SSNO} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Short</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_SHORT_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Opened</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpInd?.updmcP_OPENED_MM + "" + updmcpInd?.updmcP_OPENED_DD + "" + updmcpInd?.updmcP_OPENED_CC + "" + updmcpInd?.updmcP_OPENED_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Yrs @ Res</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} value={updmcpInd?.updmcP3_YEARS_OF_RES} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mth @ Res</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} value={updmcpInd?.updmcP3_MONTH_OF_RES} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Salutation</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Ext Client ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Acct Info</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">First</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_FIRST_NAME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Middle</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP3_MIDDLE} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_LAST_NAME} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Suffix</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DOB</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_BIRTH_MM + "" + updmcpInd?.updmcP_ACCT_BIRTH_DD + "" + updmcpInd?.updmcP_ACCT_BIRTH_CC + "" + updmcpInd?.updmcP_ACCT_BIRTH_YY} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sex</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ISI</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Adtnl Next</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Legal Entity 1</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_LEGAL_ENTITY1} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Legal Entity 2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Ctzn</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_CTZSHP} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Dual Ctzn</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">US Status</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_CTZN} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Married</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Depend</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_NBR_DEPENDENTS} disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Retire</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_RETIRE} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Acquired</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Class</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OCC</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OCC Type</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_OCCP_TYPE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Yrs @ Emp</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_EMP_YRS} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DOD</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ID Type 1</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ID_TYPE} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ID Nbr</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_ID_NBR} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Other ID</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Issue Date</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_ISSUE_DATE1_MM + "" + updmcpInd?.updmcP3_ISSUE_DATE1_DD + "" + updmcpInd?.updmcP3_ISSUE_DATE1_CC + "" + updmcpInd?.updmcP3_ISSUE_DATE1_YY} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Exp Date</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_EXPIRATION_DATE1_MM + "" + updmcpInd?.updmcP3_EXPIRATION_DATE1_DD + "" + updmcpInd?.updmcP3_EXPIRATION_DATE1_CC + "" + updmcpInd?.updmcP3_EXPIRATION_DATE1_YY } className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ID Type 2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ID Nbr</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Other ID</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Issue Date</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Exp Date</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Finan Info</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Size</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Invest</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Objective</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_OBJECTIVES} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Annual</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ANNUAL_INCOME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Bracket</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_TAX_BRACKET} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Range</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SPL COM</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Est Net Worth</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_EST_NET_WORTH} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Est Liq Net</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_EST_LIQ_NET} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Inv Time Hrz</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_INV_TIME_HRZ} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Objective2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">LIQ: Needs</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP2_BEF_LIQ_NEEDS} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Time</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Amount</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ANN Exp</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Spec Exp</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Spec Exp Time</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>INITIAL:</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">AMT</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">TYPE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">PRIME BRK</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SECOND BRK</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>EXPERIENCE:</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">STK</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_STOCK_EXPERIENCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">BND</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_BOND_EXPERIENCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FND</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_FUND_EXPERIENCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OPT</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_OPTION_EXPERIENCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ANN</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ANNUITY_EXPERIENCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">COMM</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FUTR</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CORP BND</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_CORPBOND_EXPE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MUN BND</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_MUNBOND_EXPE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">TRSY</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MBS/CMO</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">REP/RV REP</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OTHR1</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OTHR2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>TXN PER YR:</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">STK</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">BND</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FND</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OPT</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ANN</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">COMM</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FUTR</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CORP BND</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MUN BND</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">TRSY</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MBS/CMO</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">REP/RV REP</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OTHR1</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OTHR2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FDID EFF DATE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CAIS ACCT TYPE</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} /><input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} /><input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROLE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">FDID EFF DATE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CAIS ACCT TYPE</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} /><input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROLE START RSN</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">CAIS QUAL</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MAT INC</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">NAT PRSN</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROLE END DATE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DISCR</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DBA</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROLE END RSN</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">LAST CAT ACT DATE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">LAST CAIS SUBMIT DATE</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">PR CAT RPTR CRD</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">PR CAT RPTR</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">TID</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_TID} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Employer Name & Address</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_EMPLOYER} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ADDRESS_1} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ADDRESS_2} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ADDRESS_3} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ADDRESS_4} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_CITY} disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_STATE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_ACCT_ZIP} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_ACCT_ZIP_EXT} disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Source Name & Address</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Securities Affiliation Information</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q1</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} value={updmcpInd?.updmcP_SEC_Q1} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q2</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} value={updmcpInd?.updmcP_SEC_Q2} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q3</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} value={updmcpInd?.updmcP_SEC_Q3} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q4</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} value={updmcpInd?.updmcP_SEC_Q4} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q5</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} value={updmcpInd?.updmcP_SEC_Q5} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Q6</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Relshp</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_SEC_RELATIONSHIP} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Risk</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_RISK_TOLERANCE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Hgh Risk</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Risk2</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Person Name</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_PERSON_NAME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Frm/Emp</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_FIRM_NAME}  className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Corp Name</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_CORP_NAME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_SEC_ADDR_1} disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_ADDR_2} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_ADDR_3} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_ADDR_4} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_CITY} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_STATE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_SEC_ZIP} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area"><input type="text" className="inputForm" value={updmcpInd?.updmcP_SEC_ZIP_EXT} disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Approval Info</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Apprd Ind</label>
                                                        <div className="input_area"><input type="text" style={smalltextBox40} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Initials</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Apprd Date</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Resp Id</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Contact Info</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Lst Dte</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Next Date</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">DY/EV</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Chge Date</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_CHNG_MM + "" + updmcpInd?.updmcP_CHNG_DD + "" + updmcpInd?.updmcP_CHNG_CC + "" + updmcpInd?.updmcP_CHNG_YY} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Upd User</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP_USERID} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Referral</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Notes</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Desc</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Seminar</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Email</label>
                                                        <div className="input_area"><input type="text" value={updmcpInd?.updmcP3_EMAIL_ADDRESS} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Role Comm</label>
                                                        <div className="input_area"><input type="text" className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {updmcpJoint && <div className='formCard'>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="review-heading">UPDMCP (Joint Profile Changes)</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP2_SAVE_ACCT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Joint Information: Add/Mod/Del/Ign</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" value={updmcpJoint?.updmcP_JNT_REC_INDICATOR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">First</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_FIRST_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Middle</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_LAST_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Suffix</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Dob</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_BIRTH_MM + "" + updmcpJoint?.updmcP_JNT_BIRTH_DD + "" + updmcpJoint?.updmcP_JNT_BIRTH_CC + "" + updmcpJoint?.updmcP_JNT_BIRTH_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Sex</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">YRS & RES</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP3_JNT_YEARS_OF_RES} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">MTH @ RES</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP3_JNT_MONTH_OF_RES} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">YRS @ EMP</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_YRS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tax Id</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox40} value={updmcpJoint?.updmcP_JNT_SSN_ID} disabled={true} />
                                                            <input type="text" className="inputForm" style={mediumtextBox} value={updmcpJoint?.updmcP_JNT_SSN_NBR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Ctzn</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP3_JNT_CTZN} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Dual Ctzn</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">US Status</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_CTZSHP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Married</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_MARITAL_STATUS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Depend</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_DEPENDENTS} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Retire</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_RETIRE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OCC</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Occ Type</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_OCCP_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Typ</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_ID_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Nbr</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_ID_NBR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Issue Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Expiration Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Ext Client ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Other Id</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Resp Id</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Home Address</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Inl Adr</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_ADDR_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_CITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_STATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_ZIP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_ZIP_EXT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Res</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_RES_PH1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_RES_PH2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_RES_PH3} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Bus</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_BUS_PH1} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_BUS_PH2} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} value={updmcpJoint?.updmcP_JNT_BUS_PH3} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mob</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Fax</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                            <input type="text" className="inputForm" style={smalltextBox80} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Employer Name & Address</label>
                                                        <div className="input_area"></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_ADDR_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_ADDR_2} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_CITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_STATE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_ZIP} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_JNT_EMP_ZIP_EXT} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Chge Date</label>
                                                        <div className="input_area">
                                                            <input type="text" value={updmcpJoint?.updmcP3_JNT_CHNG_MM + "" + updmcpJoint?.updmcP3_JNT_CHNG_DD + "" + updmcpJoint?.updmcP3_JNT_CHNG_CC + "" + updmcpJoint?.updmcP3_JNT_CHNG_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Upd User</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={updmcpJoint?.updmcP_USERID} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Emp Phone</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Email</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Role Comm</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Notes</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Verify</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Prev Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {iraCus && <div className='formCard'>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="review-heading">IRACUS: Customer Profile</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_ACCT_NBR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Plan Type</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} value={iraCus?.iracuS_PLAN_TYPE} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_ACCT_PLAN_DATE_MM + "" + iraCus?.iracuS_ACCT_PLAN_DATE_DD + "" + iraCus?.iracuS_ACCT_PLAN_DATE_CC + "" + iraCus?.iracuS_ACCT_PLAN_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Changed</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CHG_DATE_MM + "" + iraCus?.iracuS_CHG_DATE_DD + "" + iraCus?.iracuS_CHG_DATE_CC + "" + iraCus?.iracuS_CHG_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SIMPLE: Account Type</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" value={iraCus?.iracuS_ACCT_TYPE} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Plan ID</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">OFF/RR</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_OFF_RR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tax ID</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox40} value={iraCus?.iracuS_FED_ID} className="inputForm" disabled={true} />
                                                            <input type="text" style={mediumtextBox} value={iraCus?.iracuS_TAX_ID} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Terminated</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox40} className="inputForm" disabled={true} />
                                                            <input type="text" style={mediumtextBox} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_SOC_SEC_NBR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Origin</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} value={iraCus?.iracuS_ORIGIN} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Short Name</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SHORT_NAME} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_ADDR1} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_ADDR2} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Tel Number</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox80} className="inputForm" disabled={true} value={iraCus?.iracuS_TEL_AC} />
                                                            <input type="text" style={smalltextBox80} className="inputForm" disabled={true} value={iraCus?.iracuS_TEL_EXCH} />
                                                            <input type="text" style={smalltextBox80} className="inputForm" disabled={true} value={iraCus?.iracuS_TEL_BASE} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CITY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox80} value={iraCus?.iracuS_STATE} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox80} value={iraCus?.iracuS_ZIP_CODE} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code Ext</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox80} value={iraCus?.iracuS_ZIP_EXT} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Age Information</label>
                                                        <label className="form-label" style={boldLabelPadTop}>Customer</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Birth</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_BIRTH_DATE_MM + "" + iraCus?.iracuS_CUS_BIRTH_DATE_DD + "" + iraCus?.iracuS_CUS_BIRTH_DATE_CC + "" + iraCus?.iracuS_CUS_BIRTH_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">59-1/2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_59_DATE_MM + "" + iraCus?.iracuS_CUS_59_DATE_DD + "" + iraCus?.iracuS_CUS_59_DATE_CC + "" + iraCus?.iracuS_CUS_59_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Notified</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_NOTE59_DATE_MM + "" + iraCus?.iracuS_CUS_NOTE59_DATE_DD + "" + iraCus?.iracuS_CUS_NOTE59_DATE_CC + "" + iraCus?.iracuS_CUS_NOTE59_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">73</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_70_DATE_MM + "" + iraCus?.iracuS_CUS_70_DATE_DD + "" + iraCus?.iracuS_CUS_70_DATE_CC + "" + iraCus?.iracuS_CUS_70_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Notified</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_NOTE70_DATE_MM + "" + iraCus?.iracuS_CUS_NOTE70_DATE_DD + "" + iraCus?.iracuS_CUS_NOTE70_DATE_CC + "" + iraCus?.iracuS_CUS_NOTE70_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Deceased</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iraCus?.iracuS_CUS_DECEASED_DATE_MM + "" + iraCus?.iracuS_CUS_DECEASED_DATE_DD + "" + iraCus?.iracuS_CUS_DECEASED_DATE_CC + "" + iraCus?.iracuS_CUS_DECEASED_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <label className="form-label" style={boldLabelPadTop}>Spouse</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_BIRTH_DATE_MM + "" + iraCus?.iracuS_SPS_BIRTH_DATE_DD + "" + iraCus?.iracuS_SPS_BIRTH_DATE_CC + "" + iraCus?.iracuS_SPS_BIRTH_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_59_DATE_MM + "" + iraCus?.iracuS_SPS_59_DATE_DD + "" + iraCus?.iracuS_SPS_59_DATE_CC + "" + iraCus?.iracuS_SPS_59_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_NOTE59_DATE_MM + "" + iraCus?.iracuS_SPS_NOTE59_DATE_DD + "" + iraCus?.iracuS_SPS_NOTE59_DATE_CC + "" + iraCus?.iracuS_SPS_NOTE59_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_70_DATE_MM + "" + iraCus?.iracuS_SPS_70_DATE_DD + "" + iraCus?.iracuS_SPS_70_DATE_CC + "" + iraCus?.iracuS_SPS_70_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_NOTE70_DATE_MM + "" + iraCus?.iracuS_SPS_NOTE70_DATE_DD + "" + iraCus?.iracuS_SPS_NOTE70_DATE_CC + "" + iraCus?.iracuS_SPS_NOTE70_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_SPS_DECEASED_DATE_MM + "" + iraCus?.iracuS_SPS_DECEASED_DATE_DD + "" + iraCus?.iracuS_SPS_DECEASED_DATE_CC + "" + iraCus?.iracuS_SPS_DECEASED_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>&nbsp;</label>
                                                        <label className="form-label" style={boldLabelPadTop}>Decendent</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_DECEASED_B_MM + "" + iraCus?.iracuS_DECEASED_B_DD + "" + iraCus?.iracuS_DECEASED_B_CC + "" + iraCus?.iracuS_DECEASED_B_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iraCus?.iracuS_DECEASED_DATE_MM + "" + iraCus?.iracuS_DECEASED_DATE_DD + "" + iraCus?.iracuS_DECEASED_DATE_CC + "" + iraCus?.iracuS_DECEASED_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabelPadTop}>Decendent</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">First</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Inherit CD</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ESA: SPC</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">RESP Party: First</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Free Status</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Free Sch</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Transfer Status</label>
                                                        <div className="input_area">
                                                            <input type="text" style={smalltextBox60} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label" style={boldLabel}>Agreements</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Other Investments</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" style={smalltextBox60} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Spousal Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {iracusBen && <div className='formCard'>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="review-heading">IRA Beneficiaries</div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Account</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iracusBen?.body?.irabeN_ACCT_NBR} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Short Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iracusBen?.body?.irabeN_SHORT_NAME} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Total P%</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iracusBen?.body?.irabeN_TOTAL_PERCENT / 100} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">529 Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iracusBen?.body?.irabeN_529_DATE_MM + "" + iracusBen?.body?.irabeN_529_DATE_DD + "" + iracusBen?.body?.irabeN_529_DATE_CC + "" + iracusBen?.body?.irabeN_529_DATE_YY} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">EFF Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" value={iracusBen?.body?.irabeN_EFF_DATE_MM_1 + "" + iracusBen?.body?.irabeN_EFF_DATE_DD_1 + "" + iracusBen?.body?.irabeN_EFF_DATE_CC_1 + "" + iracusBen?.body?.irabeN_EFF_DATE_YY_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Date of Death</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.body?.irabeN_DATE_OF_DEATH_MM_1 + "" + iracusBen?.body?.irabeN_DATE_OF_DEATH_DD_1 + "" + iracusBen?.body?.irabeN_DATE_OF_DEATH_CC_1 + "" + iracusBen?.body?.irabeN_DATE_OF_DEATH_YY_1} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Beneficiary</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Designation</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_DESIGNATION} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Percentage</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_PERCENT} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_SSNO} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Birth Date</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_BIRTH_DATE_MM + "" + iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_BIRTH_DATE_DD + "" + iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_BIRTH_DATE_CC + "" + iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_BIRTH_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Relationship</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_REL} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Name</label>
                                                        <div className="input_area"><input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_NAME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ADDR1} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ADDR2} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ADDR3} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ADDR4} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_CITY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area"><input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_STATE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ZIP} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code EXT</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_ZIP_EXT} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Children</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_NBR_OF_CHILDREN} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROR</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_RIGHT_TO_REP} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Citizenship</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[0]?.irabeN_CITY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Beneficiary</label>
                                                        <div className="input_area">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Designation</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_DESIGNATION} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Percentage</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_PERCENT} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">SSN</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_SSNO} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Birth Date</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_BIRTH_DATE_MM + "" + iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_BIRTH_DATE_DD + "" + iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_BIRTH_DATE_CC + "" + iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_BIRTH_DATE_YY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Relationship</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_REL} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Name</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_NAME} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ADDR1} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ADDR2} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ADDR3} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">&nbsp;</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ADDR4} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_CITY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State</label>
                                                        <div className="input_area"><input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_STATE} className="inputForm" disabled={true} /></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ZIP} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code EXT</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_ZIP_EXT} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Children</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_NBR_OF_CHILDREN} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-1">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">ROR</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_RIGHT_TO_REP} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Citizenship</label>
                                                        <div className="input_area">
                                                            <input type="text" value={iracusBen?.gridData?.irabeN_INFO[1]?.irabeN_CITY} className="inputForm" disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </>
    );
};
export default LogFromFISComponent;