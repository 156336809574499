import React from 'react';
import WebRoutes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loader from "super-react-loader";
import "react-datepicker/dist/react-datepicker.css";

loader.setPresetLoader({
  preset: 'wave',
  size: '4rem',       
  color: '#215ddf',
  bg: 'rgba(255,255,255,0.2)',
  //bg: 'rgba(0, 255, 0, 0.2)',
})

function App() {
  return (

    <div>
      <WebRoutes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>
  );
}

export default App;
