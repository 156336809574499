import React, { useEffect } from 'react';
import { getLoggedInUserInfo } from '../../common/common';
import ApplicationSummaryComponent from '../../components/shared/ApplicationSummaryComponent';

const RRApplicationSummary = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
           <ApplicationSummaryComponent UserId={getLoggedInUserInfo().id} />
        </>
    );
};

export default RRApplicationSummary;