import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import { toast } from 'react-toastify';
import OLAEnums from '../helpers/olaenums';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useNavigate } from 'react-router-dom';

const FormNav = () => {
    const location = useLocation();

    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [isPersonalAccountType, setIsPersonalAccountType] = useState(null);
    const [isEntityAccountType, setIsEntityAccountType] = useState(null);
    const [isRetirementAccountType, setIsRetirementAccountType] = useState(null);

    const [employmentFlag, setEmploymentFlag] = useState(false);
    const [iraBenFlag, setIRABenFlag] = useState(false);
    const [accountFeaturesFlag, setAccountFeaturesFlag] = useState(false);
    const [investorFlag, setInvestorFlag] = useState(false);
    const [regulatoryFlag, setRegulatoryFlag] = useState(false);
    const [documentFlag, setDocumentFlag] = useState(false);
    const [signatureFlag, setSignatureFlag] = useState(false);
    const [reviewFlag, setReviewFlag] = useState(false);
    const [dueDiligenceFlag, setDueDiligenceFlag] = useState(false);
    const [officerOwnerFlag, setOfficerOwnerFlag] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getApplicationReview();
    }, []);

    const getApplicationReview = () => {
        let userId = null;
        if (getLoggedInUserInfo() !== null) {
            userId = getLoggedInUserInfo().id;
        }
        else {
            navigate('/')
        }
        let data = {
            UserId: userId,
            ApplicationId: getCurrentApplicationId()
        }
        applicationService.getApplicationReview(data)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    setSelectedAccountType(result?.applicationInfo?.accountType);

                    if (result?.applicationInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                        setIsEntityAccountType(true);
                        if (result?.accountFeaturesInfo !== null && result?.accountFeaturesInfo?.exemptionId !== null) {
                            setDueDiligenceFlag(false);
                        }
                        else if (result?.accountFeaturesInfo !== null && result?.accountFeaturesInfo?.exemptLegalCustomer === null) {
                            setDueDiligenceFlag(false);
                        }
                        else {
                            setDueDiligenceFlag(true);
                        }
                        if (result?.entityOfficerOwnerInfoList !== null && result?.entityOfficerOwnerInfoList.length > 0) {
                            setOfficerOwnerFlag(true);
                        }
                        else {
                            setOfficerOwnerFlag(false);
                        }
                    }
                    else {
                        if (result?.applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            setIsRetirementAccountType(true);
                        }
                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Personal) {
                            setIsPersonalAccountType(true);
                        }
                        if (result?.applicantList !== null && result?.employmentInfoList !== null && result?.investorProfileInfo.annualIncome != null
                            && result?.regulatoryItemsInfo?.isAnotherPartyTrading !== null && result?.accountFeaturesInfo?.dividendReinvestment !== null
                            && result?.applicationDocumentsList && result?.applicationSignatureList !== null) {
                            setReviewFlag(true);
                        }
                        else {
                            setReviewFlag(false);
                        }
                    }

                    //Navigation checks starts here
                    //For Personal and Retirement Accounts
                    if (result?.applicantList !== null && result?.applicantList.length > 0 && result?.employmentInfoList !== null && result?.employmentInfoList?.length > 0) {
                        setEmploymentFlag(true);
                    }
                    else {
                        setEmploymentFlag(false);
                    }
                    if (result?.applicationBeneficiaryList !== null && result?.applicationBeneficiaryList.length > 0) {
                        setIRABenFlag(true);
                    }
                    else {
                        setIRABenFlag(false);
                    }
                    if (result?.investorProfileInfo?.annualIncome !== null) {
                        setInvestorFlag(true);
                    }
                    else {
                        setInvestorFlag(false);
                    }
                    if (result?.regulatoryItemsInfo?.isAnotherPartyTrading !== null) {
                        setRegulatoryFlag(true);
                    }
                    else {
                        setRegulatoryFlag(false);
                    }
                    if (result?.accountFeaturesInfo?.dividendReinvestment !== null) {
                        setAccountFeaturesFlag(true);
                        setDocumentFlag(true);
                    }
                    else {
                        setAccountFeaturesFlag(false);
                    }
                    if (result?.applicationSignatureList !== null && result?.applicationSignatureList?.length > 0) {
                        setDocumentFlag(true);
                        setSignatureFlag(true);
                    }
                    else {
                        setSignatureFlag(false);
                    }
                    if (result?.applicationDocumentsList.length === 0) {
                        setSignatureFlag(false);
                        setReviewFlag(false);
                    }
                    else {
                        setReviewFlag(true);
                    }
                    if (result?.applicationSignatureList.length !== 0) {
                        setReviewFlag(true);
                    }
                    else {
                        setReviewFlag(false)
                    }
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/')
                }
            });
    }

    return (
        <>
            <div className="formMenuArea">
                <h2 className="heading">Online Application Form</h2>
                <PerfectScrollbar>
                    <ul className="form-menus">
                        {selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.Personal &&
                            <li className={location.pathname === '/personal-info' ? 'active but' : 'but'}>
                                <NavLink to='/personal-info'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Personal Information</span>
                                    {/* {applicantList && applicantList?.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}

                        {selectedAccountType && selectedAccountType === OLAEnums.AccountTypeEnum.Retirement &&
                            <li className={location.pathname === '/personal-info' ? 'active but' : 'but'}>
                                <NavLink to='/personal-info'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Personal Information</span>
                                    {/* {applicantList && applicantList?.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}

                        {isPersonalAccountType && employmentFlag && <li className={location.pathname === '/employment-info' ? 'active but' : 'but'}>
                            <NavLink to='/employment-info'><span className="formIco"><i className="icon-employment"></i></span>
                                <span className="formLable">Employment Information</span>
                                {/* {employmentList && employmentList.length !== 0 && employmentList[0].employmentStatusId !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isPersonalAccountType && !employmentFlag && <li className={location.pathname === '/employment-info' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-employment"></i></span>
                                <span className="formLable">Employment Information</span>
                            </a>
                        </li>}

                        {isRetirementAccountType && employmentFlag && <li className={location.pathname === '/employment-info' ? 'active but' : 'but'}>
                            <NavLink to='/employment-info'><span className="formIco"><i className="icon-employment"></i></span>
                                <span className="formLable">Employment Information</span>
                                {/* {employmentList && employmentList.length !== 0 && employmentList[0].employmentStatusId !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isRetirementAccountType && !employmentFlag && <li className={location.pathname === '/employment-info' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-employment"></i></span>
                                <span className="formLable">Employment Information</span>
                            </a>
                        </li>}

                        {isRetirementAccountType && iraBenFlag && <li className={location.pathname === '/ira-beneficiaries' ? 'active but' : 'but'}>
                            <NavLink to='/ira-beneficiaries'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">IRA Beneficiaries</span>
                                {/* {iraBenficiary && iraBenficiary?.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isRetirementAccountType && !iraBenFlag && <li className={location.pathname === '/ira-beneficiaries' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">IRA Beneficiaries</span>
                            </a>
                        </li>}

                        {selectedAccountType && isEntityAccountType &&
                            <li className={location.pathname === '/entity-info' ? 'active but' : 'but'}>
                                <NavLink to='/entity-info'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Entity Information</span>
                                    {/* {entityInfo && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}

                        {selectedAccountType && isEntityAccountType && investorFlag &&
                            <li className={location.pathname === '/investor-entity-profile' ? 'active but' : 'but'}>
                                <NavLink to='/investor-entity-profile'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Investor Profile</span>
                                    {/* {investorProfile && investorProfile?.investmentObjective !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}
                        {selectedAccountType && isEntityAccountType && !investorFlag &&
                            <li className={location.pathname === '/investor-entity-profile' ? 'active but' : 'but'}>
                                <a href='javaascript:;'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Investor Profile</span>
                                </a>
                            </li>}

                        {investorFlag && !isEntityAccountType && <li className={location.pathname === '/investor-profile' ? 'active but' : 'but'}>
                            <NavLink to='/investor-profile'><span className="formIco"><i className="icon-investor"></i></span>
                                <span className="formLable">Investor Profile</span>
                                {/* {investorProfile && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {!investorFlag && !isEntityAccountType && <li className={location.pathname === '/investor-profile' ? 'active but' : 'but'}>
                            <a href='javscript:;'><span className="formIco"><i className="icon-investor"></i></span>
                                <span className="formLable">Investor Profile</span></a>
                        </li>}

                        {regulatoryFlag && !isEntityAccountType && <li className={location.pathname === '/regulatory-items' ? 'active but' : 'but'}>
                            <NavLink to='/regulatory-items'><span className="formIco"><i className="icon-regulatory"></i></span>
                                <span className="formLable">Regulatory Items</span>
                                {/* {regulatoryItem && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {!regulatoryFlag && !isEntityAccountType && <li className={location.pathname === '/regulatory-items' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-regulatory"></i></span>
                                <span className="formLable">Regulatory Items</span></a>
                        </li>}

                        {isEntityAccountType && regulatoryFlag && <li className={location.pathname === '/regulatory-entity-items' ? 'active but' : 'but'}>
                            <NavLink to='/regulatory-entity-items'><span className="formIco"><i className="icon-regulatory"></i></span>
                                <span className="formLable">Regulatory Items</span>
                                {/* {regulatoryItem && regulatoryItem?.shareholder !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isEntityAccountType && !regulatoryFlag && <li className={location.pathname === '/regulatory-entity-items' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-regulatory"></i></span>
                                <span className="formLable">Regulatory Items</span>
                            </a>
                        </li>}

                        {isEntityAccountType && accountFeaturesFlag && <li className={location.pathname === '/account-entity-features' ? 'active but' : 'but'}>
                            <NavLink to='/account-entity-features'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                                {/* {accountFeature && accountFeature.eDocuments !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isEntityAccountType && !accountFeaturesFlag && <li className={location.pathname === '/account-entity-features' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                            </a>
                        </li>}

                        {isPersonalAccountType && accountFeaturesFlag && <li className={location.pathname === '/account-features' ? 'active but' : 'but'}>
                            <NavLink to='/account-features'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                                {/* {accountFeature && accountFeature.eDocuments !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isPersonalAccountType && !accountFeaturesFlag && <li className={location.pathname === '/account-features' ? 'active but' : 'but'}>
                            <a href='javasript:;'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                            </a>
                        </li>}

                        {isRetirementAccountType && accountFeaturesFlag && <li className={location.pathname === '/account-features' ? 'active but' : 'but'}>
                            <NavLink to='/account-features'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                                {/* {accountFeature && accountFeature.eDocuments !== null && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {isRetirementAccountType && !accountFeaturesFlag && <li className={location.pathname === '/account-features' ? 'active but' : 'but'}>
                            <a href='javasript:;'><span className="formIco"><i className="icon-account"></i></span>
                                <span className="formLable">Account Features</span>
                            </a>
                        </li>}
                        {selectedAccountType && isEntityAccountType && dueDiligenceFlag &&
                            <li className={location.pathname === '/due-diligence-info' ? 'active but' : 'but'}>
                                <NavLink to='/due-diligence-info'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Due Diligence Form</span>
                                    {/* {dueDiligence && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}
                        {selectedAccountType && isEntityAccountType && !dueDiligenceFlag &&
                            <li className={location.pathname === '/due-diligence-info' ? 'active but' : 'but'}>
                                <a href='javascript:;'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Due Diligence Form</span>
                                </a>
                            </li>}
                        {selectedAccountType && isEntityAccountType && officerOwnerFlag &&
                            <li className={location.pathname === '/owners-officials' ? 'active but' : 'but'}>
                                <NavLink to='/owners-officials'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Owners & Officials</span>
                                    {/* {ownersOfficials && ownersOfficials.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                                </NavLink>
                            </li>}

                        {selectedAccountType && isEntityAccountType && !officerOwnerFlag &&
                            <li className={location.pathname === '/owners-officials' ? 'active but' : 'but'}>
                                <a href='javascript:;'><span className="formIco"><i className="icon-personalInformation"></i></span>
                                    <span className="formLable">Owners & Officials</span>
                                </a>
                            </li>}

                        {documentFlag && !isEntityAccountType && <li className={location.pathname === '/upload-documents' ? 'active but' : 'but'}>
                            <NavLink to='/upload-documents'><span className="formIco"><i className="icon-document"></i></span>
                                <span className="formLable">Document Upload</span>
                                {/* {docList && docList.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {documentFlag && isEntityAccountType && <li className={location.pathname === '/upload-entity-documents' ? 'active but' : 'but'}>
                            <NavLink to='/upload-entity-documents'><span className="formIco"><i className="icon-document"></i></span>
                                <span className="formLable">Document Upload</span>
                                {/* {docList && docList.length !== 0 && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {!documentFlag && <li className={location.pathname === '/upload-documents' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-document"></i></span>
                                <span className="formLable">Document Upload</span></a>
                        </li>}

                        {signatureFlag && <li className={location.pathname === '/disclosures-signatures' ? 'active but' : 'but'}>
                            <NavLink to='/disclosures-signatures'><span className="formIco"><i className="icon-disclosures"></i></span>
                                <span className="formLable">Disclosure & Signatures</span>
                                {/* {disclosureInfo && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {!signatureFlag && <li className={location.pathname === '/disclosures-signatures' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-disclosures"></i></span>
                                <span className="formLable">Disclosure & Signatures</span></a>
                        </li>}

                        {reviewFlag && <li className={location.pathname === '/review' ? 'active but' : 'but'}>
                            <NavLink to='/review'><span className="formIco"><i className="icon-review"></i></span>
                                <span className="formLable">Review & Submit</span>
                                {/* {reviewSubmit && <span className='float-end'><i class="fa fa-check-circle checkMark" aria-hidden="true"></i></span>} */}
                            </NavLink>
                        </li>}
                        {!reviewFlag && <li className={location.pathname === '/review' ? 'active but' : 'but'}>
                            <a href='javascript:;'><span className="formIco"><i className="icon-review"></i></span>
                                <span className="formLable">Review & Submit</span></a>
                        </li>}
                    </ul>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default FormNav;