import React, { useEffect, useState } from 'react';
import applicationService from '../../services/applicationservice';
import lovService from "../../services/lovservice";
import { toast } from 'react-toastify';
import { checkAuthorization, getAlertTypeIdByEnum, getLoggedInUserInfo, hideSSN, hideForeignTaxId, toTitleCase } from '../../common/common';
import { useFormik } from 'formik';
import OLAEnums from '../../helpers/olaenums';
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { rrRole } from '../../helpers/constants';
import { Dropdown } from 'react-bootstrap';

const SubordinateDashboardComponent = () => {

    const [applications, setApplications] = useState(null);
    const [appId, setAppId] = useState(null);
    const [searchKey, setSearchKey] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    const [searchDropdown, setSearchDropdown] = useState(null);
    const [applicationStatusList, setApplicationStatusList] = useState(null);

    const navigate = useNavigate();
    const [closeAppShow, setCloseAppShow] = useState(false);
    const handleCloseAppClose = () => setCloseAppShow(false);

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const styleLeft = {
        textAlign: 'left'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicationStatuses();
        var broker = getLoggedInUserInfo();
        if (broker !== null) {
            getBrokerApplications(1);
        }
    }, []);

    const getBrokerApplications = (page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let data = {
            "applicationStatus": null,
            "userId": userId,
            "firstName": "",
            "lastName": "",
            "accountNumber": "",
            "accountName": "",
            "email": "",
            "ssn": "",
            "applicationId": null,
            "pageNumber": page,
            "pageSize": perPage
        };
        applicationService.getSubordinateDashboardData(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }

            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePageChange = page => {
        getBrokerApplications(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let appStatus = searchStatus;
        if (appStatus === 'Select Status' || appStatus === null) {
            appStatus = null;
        }
        var filterValue = searchDropdown !== null ? parseInt(searchDropdown) : null;
        let data = {
            "applicationStatus": appStatus,
            "userId": userId,
            "firstName": filterValue === 1 ? searchKey : null,
            "lastName": filterValue === 2 ? searchKey : null,
            "accountNumber": filterValue === 3 ? searchKey : null,
            "accountName": "",
            "email": "",
            "ssn": filterValue === 4 ? searchKey : null,
            "applicationId": null,
            "pageNumber": newPerPage,
            "pageSize": perPage
        };
        applicationService.getSubordinateDashboardData(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }

            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };

    //Search starts here
    let formikValues = {
        searchAppStatus: null,
        searchDropdownFilter: null,
        searchKeyword: null
    };
    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let userId = getLoggedInUserInfo().id;
            let appStatus = values.searchAppStatus;
            if (appStatus === 'Select Status' || appStatus === null) {
                appStatus = null;
            }
            var filterValue = parseInt(values.searchDropdownFilter);
            let data = {
                "applicationStatus": appStatus,
                "userId": userId,
                "firstName": filterValue === 1 ? values.searchKeyword : null,
                "lastName": filterValue === 2 ? values.searchKeyword : null,
                "accountNumber": filterValue === 3 ? values.searchKeyword : null,
                "accountName": "",
                "email": "",
                "ssn": filterValue === 4 ? values.searchKeyword : null,
                "applicationId": null,
                "pageNumber": 1,
                "pageSize": perPage
            };
            setSearchKey(values.searchKeyword);
            setSearchStatus(values.searchAppStatus);
            setSearchDropdown(values.searchDropdownFilter);

            applicationService.getSubordinateDashboardData(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload;
                        setApplications(result);
                        setTotalRows(result[0].totalRecords);
                        loader.hide();
                    }
                    else {
                        setApplications(null);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });
    //Search ends here

    //Change app status starts here
    let appformikValues = {
        appStatus: null
    };

    const ADD_FORM_VALIDATION = Yup.object().shape({
        appStatus: Yup.string().required("Please select application status").nullable()
    });

    let APP_INITIAL_FORM_STATE = appformikValues;
    const appformik = useFormik({
        enableReinitialize: true,
        initialValues: APP_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let userId = getLoggedInUserInfo().createdBy;
            let status = values.appStatus;
            let alertTypeId = null;
            if (parseInt(status) === OLAEnums.ApplicationStatusEnum.NotCompleted) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationNotCompleted;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Completed) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationCompleted;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.RRApproved) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.SupervisorApproved) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.RRDeclined) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.SupervisorDeclined) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationPendingApproval;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountApproved;
            }
            // else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Canceled) {
            //     alertTypeId = OLAEnums.AlertTypeEnum;
            // }
            // else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.AccountPending) {
            //     alertTypeId = OLAEnums.AlertTypeEnum;
            // }
            // else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Closed) {
            //     alertTypeId = OLAEnums.AlertTypeEnum;
            // }

            let data = {
                "UserId": userId,
                "ApplicationId": appId,
                "ApplicationStatus": parseInt(values.appStatus),
                "AlertTypeId": alertTypeId,
                "CreatedBy": getLoggedInUserInfo().userRole
            };
            applicationService.updateApplicationStatus(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getBrokerApplications(1);
                        setCloseAppShow(false);
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    //Change app status ends here

    const getApplicationStatuses = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.ApplicationStatus)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    setApplicationStatusList(result);
                }
                else {
                    return null;
                }
            })
            .catch((error) => {
            });
    }
    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        if (getLoggedInUserInfo().userRole === rrRole) {
            navigate('/registerrep/review-application');
        }
        else {
            navigate('/supervisor/review-application');
        }
    }

    const redirectToApplicationSummary = (id) => {
        localStorage.setItem("currentAppId", id);
        if (getLoggedInUserInfo().userRole === rrRole) {
            navigate('/registerrep/applicationsummary');
        }
        else {
            navigate('/supervisor/applicationsummary');
        }
    }

    const handleUpdateStatusShow = (app) => {
        setCloseAppShow(true);
        getApplicationStatuses();
        setAppId(app.applicationId);
    }

    const resetSearchForm = () => {
        formik.resetForm({ values: { searchAppStatus: '', searchDropdownFilter: '', searchKeyword: '' } });
        getBrokerApplications(1);
    }

    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>NA</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName,
            sortable: true,
        },
        {
            name: 'Account Sub Type',
            selector: row => row.accountSubTypeName,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount && row.entityName !== null ? toTitleCase(row.entityName) : row.firstName !== null ? toTitleCase(row.firstName) + ' ' + toTitleCase(row.lastName) : row.userName,
            sortable: true,
        },
        {
            name: 'Social Security Number',
            // selector: row => row.socialSecurityNumber && <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div>,
            selector: row => row.socialSecurityNumber != null ? <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div> : row.foreignTaxId !== null ? <div title={row.foreignTaxId}>{hideForeignTaxId(row.foreignTaxId, 7)}</div> : "-",
            sortable: true,
        },
        {
            name: 'Citizenship',
            selector: row => row.countryName,
            sortable: true,
        },
        {
            name: 'Application Status',
            selector: row => row.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted ? <div className="acc-status statusapproved smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>
                : <div className="acc-status statusprocessing smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: '270px'
        },
        // {
        //     name: 'Investigation Detail',
        //     selector: row => <span className="View_details">View details</span>,
        //     sortable: true,
        // },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                            {/* {row.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted && <Dropdown.Item onClick={(e) => redirectToApplicationSummary(row.applicationId)}>Application Summary</Dropdown.Item>} */}
                            <Dropdown.Item onClick={(e) => redirectToApplicationSummary(row.applicationId)}>Application Summary</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <div className="row">
                <div className="col-lg-2">
                    <h2 className="heading">Applications</h2>
                </div>
                <div className="col-lg-10">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="input_wrapper pad-10">
                                    <div className="input_area">
                                        <select name="searchAppStatus" className="form-select"
                                                    onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.searchAppStatus}>
                                                    <option value="" selected>Select Status</option>
                                                    {applicationStatusList && applicationStatusList.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                    ))}
                                                </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input_wrapper pad-10">
                                    <div className="input_area">
                                        <select className="form-select" aria-label="Default select example" name="searchDropdownFilter"
                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.searchDropdownFilter}>
                                            <option value="">Select Option</option>
                                            <option value="1">First Name</option>
                                            <option value="2">Last Name</option>
                                            <option value="3">Account Number</option>
                                            <option value="4">Social Security Number</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="input_wrapper pad-10">
                                    <div className="input_area">
                                        <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.searchKeyword} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <ul className="search_btns">

                                    <li><span className='searchBtn' style={{ cursor: 'pointer' }} onClick={() => resetSearchForm(formik)}>Clear</span></li>
                                    <li><span><button type='submit' className='btn-primary' style={{ width: "110px", border: "1px solid #215ddf" }}>Search</button></span></li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {applications && <DataTable theme="default" columns={columns} data={applications}
                pagination paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange} />}
            {applications === null && <DataTable theme="default" columns={columns} noDataComponent="No record found" />}

            <Modal
                show={closeAppShow}
                onHide={handleCloseAppClose}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='msgs_Area'>
                                    <h3 className=' pb-3'>Change Application Status</h3>
                                    <form onSubmit={appformik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className="col-lg-12">
                                                        <div className="input_wrapper">
                                                            <label className="form-label cellText">Select Status<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <select name="appStatus" className="form-select"
                                                                    onBlur={appformik.handleBlur} onChange={appformik.handleChange} value={appformik.values.appStatus}>
                                                                    <option value="">Select Status</option>
                                                                    {applicationStatusList && applicationStatusList.map((option) => (
                                                                        <option key={option.id} value={option.id}>{option.name}</option>
                                                                    ))}
                                                                </select>
                                                                {appformik.touched.appStatus && appformik.errors.appStatus ? (
                                                                    <p className="error" style={styleLeft}>{appformik.errors.appStatus}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Change Status</button></div>
                                                <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseAppClose()}>Cancel</button></div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubordinateDashboardComponent;