import React, { useState, useEffect } from "react";
import Header from "../../layouts/header/header";
import LeftNav from "../../layouts/leftnav/leftnav";
import adminService from "../../services/adminservice";
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import moment from "moment";
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";

const AdminUsers = () => {

    const [users, setUsers] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userViewShow, setUserViewShow] = useState(false);

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const styleCursor = {
        cursor: "pointer"
    }

    const handleUserViewShowClose = () => {
        setUserViewShow(false);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getUsers(1);
    }, []);

    const getUsers = (page) => {
        loader.show();
        let data = {
            "searchKeyword": searchKeyword !== null ? searchKeyword : null,
            "pageNumber": page,
            "pageSize": perPage
        }
        adminService.searchUsers(data)
            .then((json) => {
                if (json.data !== null) {
                    var result = json.data.payload;
                    setUsers(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setUsers(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    /* Searching criteria starts here */
    const { register, handleSubmit, resetField } = useForm({});

    const searchSubmit = (val) => {
        if (val.searchKeyword === '' || val.searchKeyword === null) {
            return;
        }
        loader.show();
        let data = {
            "searchKeyword": val.searchKeyword !== null ? val.searchKeyword : null,
            "pageNumber": 1,
            "pageSize": perPage
        };
        adminService.searchUsers(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload
                    setUsers(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setUsers(null);
                    loader.hide();
                }
                //setSearchKeyword(val.searchKeyword);
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleResetFields = () => {
        resetField('searchKeyword');
        //setSearchKeyword('');
        getUsers(1);
    }

    const handlePageChange = page => {
        getUsers(page);
    };
    /* Searching criteria ends here */

    const handleUserView = (user) => {
        setSelectedUser(user);
        setUserViewShow(true);
    }

    const columns = [
        {
            name: 'Id',
            selector: row => <div className='paddingLeft-15'>{row.userId}</div>,
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.userRole,
            sortable: true,
        },
        {
            name: 'Locked',
            selector: row => row.isLocked ? "Locked" : "UnLocked",
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => moment.utc(row.createdDate).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Alert Type" onClick={(e) => handleUserView(row)}>View</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '150px'
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Users</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Users</h2>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={handleSubmit(searchSubmit)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword" {...register("searchKeyword")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields()} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-2'>
                            <ul className="table_btns float-right">
                            </ul>
                        </div>
                    </div>

                    {users && <DataTable theme="default" columns={columns} data={users}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange} />}
                    {users === null && <DataTable pagination noDataComponent="No record found" />}

                    <div className="row pt-3"></div>

                </section>

            </section>

            <Modal
                show={userViewShow}
                onHide={handleUserViewShowClose}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='msgs_Area'>
                                    <h3 className=' pb-3'>User Detail</h3>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {/* <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">User Id</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.userId} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">First Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.firstName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">Last Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.lastName} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">Username</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.userName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">User Role</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.userRole} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">Is Locked</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={selectedUser?.isLocked} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="input_wrapper">
                                                        <label className="form-label cellText">Created Date</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" disabled={true} value={moment.utc(selectedUser?.createdDate).format('DD MMM YYYY hh:mm A')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleUserViewShowClose()}>Close</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AdminUsers;