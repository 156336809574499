import http from "../common/common";
import { authHeader } from "../helpers/auth-headers";
import { handleResponse } from "../helpers/handle-response";

const signupApplicant = (data) => {
    return http.post("/account/signup", data, null)
        .then(handleResponse);
}

const forgotPassword = (data) => {
    return http.post("/account/forgotpassword", data, null)
        .then(handleResponse);
}

const resetPassword = (data) => {
    return http.post("/account/resetpassword", data, null)
        .then(handleResponse);
}

const changePassword = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/changepassword", data, requestOptions)
        .then(handleResponse);
}

const deleteUser = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/deleteuser", data, requestOptions)
        .then(handleResponse);
}

const getRoles = () => {
    return http.get("/account/getroles", null, null)
        .then(handleResponse);
}

const getUsersbyRole = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/getusersbyrole", data, requestOptions)
        .then(handleResponse);
}

const lockunlockUser = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/lockunlockuser", data, requestOptions)
        .then(handleResponse);
}

const updateUser = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/updateuser", data, requestOptions)
        .then(handleResponse);
}

const getUsers = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/account/getusers", requestOptions)
        .then(handleResponse);
}

const getBrokerClients = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/account/getbrokerclients", requestOptions)
        .then(handleResponse);
}

const getUserProfileInfo = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/account/getuserprofileinfo", requestOptions)
        .then(handleResponse);
}

const getBrokersList = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/getbrokerslist", data, requestOptions)
        .then(handleResponse);
} 

const brokerSignup = (data, config) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/brokersignup", data, requestOptions, config)
        .then(handleResponse);
}

const updateBroker = (data, config) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/updatebroker", data, requestOptions, config)
        .then(handleResponse);
}

const updatePassword = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/updatepassword", data, requestOptions)
        .then(handleResponse);
}

const getBrokerByClientUserId = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/account/getbrokerbyclientuserid", data, requestOptions)
        .then(handleResponse);
}

const accountService = {
    signupApplicant,
    forgotPassword,
    resetPassword,
    changePassword,
    getRoles,
    getUsersbyRole,
    lockunlockUser,
    updateUser,
    getUsers,
    getBrokerClients,
    getUserProfileInfo,
    getBrokersList,
    brokerSignup,
    updateBroker,
    updatePassword,
    getBrokerByClientUserId,
    deleteUser
};

export default accountService;