import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { getLoggedInUserInfo, toTitleCase, hideSSN, checkAuthorization } from '../../common/common';
import { useFormik } from 'formik';
import OLAEnums from '../../helpers/olaenums';
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import brokerService from "../../services/brokerservice";
import * as Yup from "yup";
import { rrRole } from '../../helpers/constants';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";

const ApplicationQueue = () => {

    const [applications, setApplications] = useState(null);
    const navigate = useNavigate();
    const [closeAppShow, setCloseAppShow] = useState(false);
    const handleCloseAppClose = () => setCloseAppShow(false);
    const [closedAppId, setClosedAppId] = useState(null);
    const [registerRepShow, setRegisterRepShow] = useState(false);
    const handleCloseRegisterRep = () => setRegisterRepShow(false);
    const [selectedApp, setSelectedApp] = useState(null);
    const [rrList, setRRList] = useState(null);

    //for bulk assignment
    const [assignedApps, setAssignedApps] = useState([]);
    const [bulkAssignShow, setBulkAssignShow] = useState(false);
    const handleCloseBulkAssign = () => {
        setBulkAssignShow(false);
        setAssignedApps([]);
    }

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    //Search form
    const [searchKey, setSearchKey] = useState(null);
    const [searchDropdown, setSearchDropdown] = useState(null);

    const styleLeft = {
        textAlign: 'left'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var broker = getLoggedInUserInfo();
        if (broker !== null) {
            getBrokerApplications(1);
        }
    }, []);

    const styleCursor = {
        cursor: "pointer"
    }

    const styleRightPadding = {
        paddingRight: '25px'
    }

    const getBrokerApplications = (page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(), OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString()];
        let data = {
            //"applicationStatus": OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval,
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": null,
            "searchKeyword": null,
            "pageNumber": page,
            "pageSize": perPage
        };
        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }

            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePageChange = page => {
        getBrokerApplications(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(), OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString()];
        var filterValue = searchDropdown !== null ? parseInt(searchDropdown) : null;
        let data = {
            //"applicationStatus": OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval,
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": filterValue,
            "searchKeyword": searchKey,
            "pageNumber": page,
            "pageSize": newPerPage
        };
        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };

    const { register, handleSubmit, resetField } = useForm({});

    const searchSubmit = (val) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(), OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString()];
        var filterValue = parseInt(val.searchDropdownFilter);
        let data = {
            //"applicationStatus": OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval,
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": filterValue,
            "searchKeyword": val.searchKeyword,
            "pageNumber": 1,
            "pageSize": perPage
        };
        setSearchKey(val.searchKeyword);
        setSearchDropdown(val.searchDropdownFilter);

        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        navigate('/broker/review-application');
    }

    const handleResetFields = (form) => {
        resetField('searchDropdownFilter');
        resetField('searchKeyword');
        getBrokerApplications(1);
    }

    //Register reps methods starts here
    let appformikValues = {
        regrep: null
    };

    const ADD_FORM_VALIDATION = Yup.object().shape({
        regrep: Yup.string().required("Please select register representative").nullable()
    });

    let APP_INITIAL_FORM_STATE = appformikValues;
    const appformik = useFormik({
        enableReinitialize: true,
        initialValues: APP_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                "ApplicationId": selectedApp.applicationId,
                "AssignFrom": getLoggedInUserInfo().id,
                "AssignTo": values.regrep,
                "ApplicationStatus": OLAEnums.ApplicationStatusEnum.PendingReview_RR
            }
            brokerService.createApplicationAssignmentLog(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        let result = json.data.payload;
                        if (result !== null) {
                            toast.success("Application has been assigned successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setRegisterRepShow(false);
                            getBrokerApplications(1);
                            loader.hide();
                        }
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const getRegisterReps = () => {
        //let data = {
        //"brokerUserId": null,
        //"searchKeyword": null,
        //"userRole": rrRole,
        //"pageNumber": 1,
        //"pageSize": 10
        //};
        let data = {
            "brokerUserId": null,
            "userRole": "RR",
        };
        //brokerService.getBrokerSubordinates(data)
        brokerService.getAllBrokerRRAndSupervisorList(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    var reps = result.filter(x => x.roleName === rrRole);
                    setRRList(reps);
                }
                else {
                    return null;
                }
            })
            .catch((error) => {
            });
    }

    const openRegrepModal = (app) => {
        appformik.resetForm();
        getRegisterReps();
        setRegisterRepShow(true);
        setSelectedApp(app);
    }

    //Register reps methods starts here ends here

    //Bulk assignment starts here
    const openBuikAssignModal = () => {
        assignformik.resetForm();
        getRegisterReps();
        setBulkAssignShow(true);
    }

    let bulkAssignformikValues = {
        regrep: null,
        appIds: null
    };

    const ADD_BULKASSIGN_FORM_VALIDATION = Yup.object().shape({
        regrep: Yup.string().required("Please select register representative").nullable()
    });

    let APP_BULKASSIGN_INITIAL_FORM_STATE = bulkAssignformikValues;
    const assignformik = useFormik({
        enableReinitialize: true,
        initialValues: APP_BULKASSIGN_INITIAL_FORM_STATE,
        validationSchema: ADD_BULKASSIGN_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {

            if (assignedApps.length === 0) {
                toast.error("Please select at least one application", {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            // loader.show();
            let selectedAppIds = [];
            assignedApps.forEach(element => {
                selectedAppIds.push(element.applicationId);
            });
            let data = {
                "BulkApplicationIds": selectedAppIds.toString(),
                "AssignFrom": getLoggedInUserInfo().id,
                "AssignTo": values.regrep,
                "ApplicationStatus": OLAEnums.ApplicationStatusEnum.PendingReview_RR,
                "ApplicationId": null
            }
            brokerService.bulkApplicationAssignmentLog(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        let result = json.data.payload;
                        if (result !== null) {
                            toast.success("Application(s) has been assigned successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setBulkAssignShow(false);
                            setAssignedApps([]);
                            getBrokerApplications(1);
                            loader.hide();
                        }
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const assignChecked = (item) => {
        let tempInfo = assignedApps;
        if (tempInfo.some((app) => app.applicationId === item.applicationId)) {
            tempInfo = tempInfo.filter((app) => app.applicationId !== item.applicationId);
        } else {
            tempInfo.push(item);
        }
        setAssignedApps(tempInfo);
    };

    const renderCellContent = (row) => {
        if (row.assignTo === null) {
            return (
                <ul className="table_btns">
                    <li>
                        <button
                            type='button'
                            className='btn-primary btn-sm-autowidth'
                            onClick={() => openRegrepModal(row)}>
                            Assign to
                        </button>
                    </li>
                </ul>
            );
        } else if (row.assignTo !== null && (row.applicationStatus === OLAEnums.ApplicationStatusEnum.PendingReview_RR
            || row.applicationStatus === OLAEnums.ApplicationStatusEnum.UnderReview_RR)) {
            return (
                <>
                    <ul className="table_btns">
                        <li>
                            <button
                                type='button'
                                className='btn-primary btn-sm-autowidth'
                                onClick={() => openRegrepModal(row)}>
                                Re-Assign to
                            </button>
                        </li>
                    </ul></>
            );

        } else {
            return (
                <span>-</span>
            );
        }
    };

    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName + " (" + row.accountSubTypeName + ")",
            sortable: true,
            width:'250px'
        },
        {
            name: 'Name',
            selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount && row.entityName !== null ? toTitleCase(row.entityName) : row.firstName !== null ? toTitleCase(row.firstName) + ' ' + toTitleCase(row.lastName) : row.userName,
            sortable: true,
        },
        {
            name: 'Social Security Number',
            selector: row => row.socialSecurityNumber && <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div>,
            sortable: true,
        },
        {
            name: 'Citizenship',
            selector: row => row.citizenshipCountryName,
            sortable: true,
        },
        {
            name: 'Assigned To',
            selector: row => row.assignToUsername,
            width: '100px'
        },
        {
            //cell: row => (
            // row.assignTo === null && <ul className="table_btns">
            //     <li><button type='button' className='btn-primary btn-sm-autowidth' onClick={(e) => openRegrepModal(row)}>Assign to</button></li>
            // </ul>
            //),
            width: '180px',
            cell: row => renderCellContent(row)
        },
        {
            name: 'Application Status',
            selector: row => <div className="acc-status statusapproved smallFontStatus">{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: '250px'
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Application" onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Approval Queue</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-3">
                            <h2 className="heading">Approval Queue</h2>
                        </div>
                        <div className="col-lg-7">
                            <form onSubmit={handleSubmit(searchSubmit)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <select className="form-select" aria-label="Default select example" name="searchDropdownFilter" {...register('searchDropdownFilter')}>
                                                    <option value="">Selection Option</option>
                                                    <option value="1">First Name</option>
                                                    <option value="2">Last Name</option>
                                                    <option value="3">Account Number</option>
                                                    <option value="4">Social Security Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                                    {...register('searchKeyword')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="search_btns">
                                            <li><span className="searchBtn" onClick={() => handleResetFields()} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary'>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-2" style={styleRightPadding}>
                            <button type="button" className="create_Btn" onClick={openBuikAssignModal}>Bulk Assignment</button></div>
                    </div>

                    {applications && <DataTable theme="default" columns={columns} data={applications}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {applications === null && <DataTable columns={columns} noDataComponent="No record found" />}
                </section>
            </section>

            <Modal
                show={registerRepShow}
                onHide={handleCloseRegisterRep}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='msgs_Area'>
                                    <h3 className=' pb-3'>Select Register Representative</h3>
                                    <form onSubmit={appformik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className="col-lg-12">
                                                        <div className="input_wrapper">
                                                            {/* <label className="form-label cellText">Select Register Representative<span className="error-info">*</span></label> */}
                                                            <div className="input_area">
                                                                <select name="regrep" className="form-select"
                                                                    onBlur={appformik.handleBlur} onChange={appformik.handleChange} value={appformik.values.regrep}>
                                                                    <option value="">Select Register Rep</option>
                                                                    {rrList && rrList.map((option) => (
                                                                        <option key={option.id} value={option.id}>{option.userName}</option>
                                                                    ))}
                                                                </select>
                                                                {appformik.touched.regrep && appformik.errors.regrep ? (
                                                                    <p className="error" style={styleLeft}>{appformik.errors.regrep}</p>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Assign to RR</button></div>
                                                <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseRegisterRep()}>Cancel</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={bulkAssignShow}
                onHide={handleCloseBulkAssign}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='msgs_Area'>
                                    <h3 className=' pb-3'>Bulk Assignment (Register Reps)</h3>
                                    <form onSubmit={assignformik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className="col-lg-12">
                                                        <div className="input_wrapper">
                                                            <div className="input_area">
                                                                <select name="regrep" className="form-select"
                                                                    onBlur={assignformik.handleBlur} onChange={assignformik.handleChange} value={assignformik.values.regrep}>
                                                                    <option value="">Select Register Rep</option>
                                                                    {rrList && rrList.map((option) => (
                                                                        <option key={option.id} value={option.id}>{option.userName}</option>
                                                                    ))}
                                                                </select>
                                                                {assignformik.touched.regrep && assignformik.errors.regrep ? (
                                                                    <p className="error" style={styleLeft}>{assignformik.errors.regrep}</p>
                                                                ) : null}
                                                            </div>
                                                            <div className="input_area">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">
                                                                                {/* <input className="form-check-input" type="checkbox"
                                                                                    onChange={(event) => checkAllAssignment(event)}
                                                                                    onBlur={assignformik.handleBlur} id="cbAllAssignment" /> */}
                                                                            </th>
                                                                            <th scope="col" style={styleLeft}>Account Type</th>
                                                                            <th scope="col" style={styleLeft}>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody data-gr-ext-installed="" light-mode="">
                                                                        {applications !== null && applications.map((item, index) => (
                                                                            <tr key={item.applicationId}>
                                                                                <td>
                                                                                    <input className="form-check-input" type="checkbox"
                                                                                        onChange={(event) => assignChecked(item)}
                                                                                        onBlur={assignformik.handleBlur} value={assignedApps}
                                                                                        id={`cbAssign-${index}`} name={item.applicationId} />
                                                                                </td>
                                                                                <td style={styleLeft}>{item.accountSubTypeName}</td>
                                                                                <td style={styleLeft}>{item.firstName + ' ' + item.lastName}</td>
                                                                            </tr>
                                                                        ))}

                                                                        {applications === null && (
                                                                            <tr>
                                                                                <td colSpan={10}>No record found</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Assign to RR</button></div>
                                                <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseBulkAssign()}>Cancel</button></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default ApplicationQueue;