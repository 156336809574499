import { authService } from "../services/authservice";

// export function handleResponse(response) {
//     const data = response.data;
//     if (data != null) {
//         if ([401, 403].indexOf(data) !== -1) {
//             // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//             authService.logout();
//             //location.reload(true);
//         }
//     }
//     return response;
// }

export function handleResponse(response) {
    const data = response.data;
    if (data != null) {
        if ([401, 403].includes(data.status)) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            authService.logout();
            window.location.href = '/login';
        }
    }
    return response;
}