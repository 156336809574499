import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import lovService from '../services/lovservice';
import { toast } from 'react-toastify';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo, validatePostalCode } from '../common/common';
import InputMask from 'react-input-mask';
import moment from 'moment';
import loader, { show } from 'super-react-loader';
import DatePicker from "react-datepicker";
import { defaultCountry } from '../helpers/constants';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';
const Personal = () => {

    const navigate = useNavigate();
    const [maritalStatusList, setMaritalStatusList] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [showMailing, setShowMailing] = useState(false);
    const [showTrusted, setShowTrusted] = useState(false);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [applicantList, setApplicantList] = useState(null);
    const [trustedContactInfo, setTrustedContactInfo] = useState(null);
    const [isJointAccount, setIsJointAccount] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [idTypeList, setIdTypeList] = useState(null);
    //const [applicantInfoRequestList, setApplicantInfoRequestList] = useState([]);
    const [isRetirementAccountType, setIsRetirementAccountType] = useState(false);
    const [isTraditionalIRA, setIsTraditionalIRA] = useState(false);
    const [isSimpleIRAParticipant, setIsSimpleIRAParticipant] = useState(false);
    const [isBenRothIRA, setIsBenRothIRA] = useState(false);
    //Primery applicant
    const [showCountryCitizenship, setShowCountryCitizenship] = useState(false);
    const [showSSN, setShowSSN] = useState(false);
    const [showUSPermanentResident, setShowUSPermanentResident] = useState(false);
    const [showForeignAccount, setShowForeignAccount] = useState(false);
    const [ctrlIsUSCitizen, setCtrlIsUSCitizen] = useState(null);
    const [ctrlIsUSPermanentRes, setCtrlIsUSPermanentRes] = useState(null);
    const [ctrlIsForeignAccount, setCtrlIsForeignAccount] = useState(null);
    const [ctrlIdType, setCtrlIdType] = useState(null);
    const [showIssuanceState, setShowIssuanceState] = useState(false);
    //Co-applicant
    const [showCoAppCountryCitizenship, setShowCoAppCountryCitizenship] = useState(false);
    const [showCoAppSSN, setShowCoAppSSN] = useState(false);
    const [showCoAppUSPermanentResident, setShowCoAppUSPermanentResident] = useState(false);
    const [showCoAppForeignAccount, setShowCoAppForeignAccount] = useState(false);
    const [ctrlCoAppIsUSCitizen, setCtrlCoAppIsUSCitizen] = useState(null);
    const [ctrlCoAppIsUSPermanentRes, setCtrlCoAppIsUSPermanentRes] = useState(null);
    const [ctrlCoAppIsForeignAccount, setCtrlCoAppIsForeignAccount] = useState(null);
    const [ctrlCoAppIdType, setCtrlCoAppIdType] = useState(null);
    const [showCoAppIssuanceState, setShowCoAppIssuanceState] = useState(false);
    const [showIsSamePrimaryApp, setShowIsSamePrimaryApp] = useState(false);

    const [issuanceStates, setIssuanceStates] = useState([]);
    const [contactStates, setContactStates] = useState([]);
    const [mailingStates, setMailingStates] = useState([]);
    const [trustedStates, setTrustedStates] = useState([]);
    const [coAppIdTypeList, setCoAppIdTypeList] = useState(null);
    const [coAppIssuanceStates, setCoAppIssuanceStates] = useState([]);
    const [coAppContactStates, setCoAppContactStates] = useState(null);
    const [saveOnly, setSaveOnly] = useState(false);
    const idIssueYears = 12; //12 years

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getMaritalStatuses();
        getCountries();
        loadIdTypeList(false);
        loadCoAppIdTypeList(false);
        getApplicantCoApplicantInfo();
    }, []);

    useEffect(() => {
        if (defaultCountry) {
            loadIssuanceStates(defaultCountry);
            loadContactStates(defaultCountry);
            loadMailingStates(defaultCountry);
            loadTrustedStates(defaultCountry);
            loadCoAppIssuanceStates(defaultCountry);
            loadCoAppContactStates(defaultCountry);
        }
    }, [defaultCountry])

    const radioTopMargin = {
        marginTop: '12px',
    };

    const toggleMailingAddressForm = (cbVal) => {
        if (cbVal) {
            setShowMailing(true);
        }
        else {
            setShowMailing(false);
        }
    }

    const toggleIsSameAsPrimaryApplicant = (cbVal) => {
        if (cbVal) {
            setShowIsSamePrimaryApp(true);
            //set the values from primary applicant physical address
            loadCoAppContactStates(formik.values.countryId);
            formik.setFieldValue("coAppPhysicalAddress", formik.values.physicalAddress);
            formik.setFieldValue("coAppPhysicalAddress2", formik.values.physicalAddress2);
            formik.setFieldValue("coAppCountryId", formik.values.countryId);
            formik.setFieldValue("coAppStateId", formik.values.stateId);
            formik.setFieldValue("coAppCity", formik.values.city);
            formik.setFieldValue("coAppPostalCode", formik.values.postalCode);
        }
        else {
            setShowIsSamePrimaryApp(false);
            //set the values to null
            loadCoAppContactStates(defaultCountry);
            formik.setFieldValue("coAppPhysicalAddress", "");
            formik.setFieldValue("coAppPhysicalAddress2", "");
            formik.setFieldValue("coAppCountryId", "");
            formik.setFieldValue("coAppStateId", "");
            formik.setFieldValue("coAppCity", "");
            formik.setFieldValue("coAppPostalCode", "");
        }
    }

    const toggleTrustedContactForm = (cbVal) => {
        if (cbVal) {
            setShowTrusted(true);
        }
        else {
            setShowTrusted(false);
        }
    }

    const getMaritalStatuses = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.MaritalStatus)
            .then((json) => {
                if (json.data.payload !== null) {
                    setMaritalStatusList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    //Load states starts here
    const loadIssuanceStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setIssuanceStates(json.data.payload);
                }
                else {
                    setIssuanceStates([]);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const loadContactStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setContactStates(json.data.payload);
                }
                else {
                    setContactStates([]);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleContactCountryChange = (e) => {
        loadContactStates(e.target.value);
    }
    const loadMailingStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setMailingStates(json.data.payload);
                }
                else {
                    setMailingStates([]);
                }
                // if (json.data.payload.length) {
                //     formik.setFieldValue('mailingState', json.data.payload[0].id)
                // }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleMailingCountryChange = (e) => {
        loadMailingStates(e.target.value);
    }
    const loadTrustedStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setTrustedStates(json.data.payload);
                }
                else {
                    setTrustedStates([]);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleTrustedCountryChange = (e) => {
        loadTrustedStates(e.target.value);
    }
    const loadCoAppIssuanceStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setCoAppIssuanceStates(json.data.payload);
                }
                else {
                    setCoAppIssuanceStates([]);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const loadCoAppContactStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setCoAppContactStates(json.data.payload);
                }
                else {
                    setCoAppContactStates(null);
                    //formik.setFieldValue('coAppStateId', json.data.payload[0].id)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleCoAppContactCountryChange = (e) => {
        loadCoAppContactStates(e.target.value);
    }
    //Check citizenship for primary applicant starts here
    const setIsCitizenshipValue = (radioVal) => {
        if (radioVal === "true") {
            setCtrlIsUSCitizen(true);
            setShowCountryCitizenship(false);
            setShowSSN(true);
            setShowUSPermanentResident(false);
            setShowForeignAccount(false);
        }
        else {
            setCtrlIsUSCitizen(false);
            setShowCountryCitizenship(true);
            setShowSSN(false);
            setShowUSPermanentResident(true);
        }
        setCtrlIsUSPermanentRes(null);
        setCtrlIsForeignAccount(null);
        loadIdTypeList(false);
    }
    const setUSPermanentResidentValue = (radioVal) => {
        if (radioVal === "true") {
            setCtrlIsUSPermanentRes(true);
            setShowCountryCitizenship(true);
            setShowSSN(true);
            setShowForeignAccount(false);
        }
        else {
            setCtrlIsUSPermanentRes(false);
            setShowCountryCitizenship(false);
            setShowSSN(false);
            setShowForeignAccount(true);
        }
        setCtrlIsForeignAccount(null);
        loadIdTypeList(false);
    }
    const setForeignAccountValue = (radioVal) => {
        if (isRetirementAccountType) {
            setCtrlIsForeignAccount(null);
            toast.error("Unfortunately foreign accounts cannot open an IRA account", { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        if (radioVal === "true") {
            setCtrlIsForeignAccount(true);
            setShowCountryCitizenship(true);
            setShowSSN(false);
            loadIdTypeList(true);
        }
        else {
            setCtrlIsForeignAccount(false);
            setShowCountryCitizenship(false);
            setShowSSN(false);
            loadIdTypeList(false);
            toast.error("You must be a US citizen or permanent resident to open an account", { position: toast.POSITION.TOP_RIGHT });
        }
    }
    const setRadioButtonsForPrimaryApplicant = (applicant) => {
        if (applicant.isUSCitizen) {
            setShowSSN(true);
            setShowCountryCitizenship(false);
        }
        else if (applicant.isUSPermanentResident) {
            setShowUSPermanentResident(true);
            setShowSSN(true);
            setShowCountryCitizenship(true);
        }
        else if (applicant.isForeignAccount) {
            setShowUSPermanentResident(true);
            setShowForeignAccount(true);
            setShowSSN(false);
            setShowCountryCitizenship(true);
        }
        else {
            setShowSSN(false);
            setShowCountryCitizenship(false);
            setShowUSPermanentResident(false);
            setShowForeignAccount(false);
        }
    }
    //Check citizenship for primary applicant ends here

    //Check citizenship for co-applicant starts here
    const setCoAppIsCitizenshipValue = (radioVal) => {
        if (radioVal === "true") {
            setCtrlCoAppIsUSCitizen(true);
            setShowCoAppCountryCitizenship(false);
            setShowCoAppSSN(true);
            setShowCoAppUSPermanentResident(false);
            setShowCoAppForeignAccount(false);
            loadCoAppIdTypeList(true);
        }
        else {
            setCtrlCoAppIsUSCitizen(false);
            setShowCoAppCountryCitizenship(true);
            setShowCoAppSSN(false);
            setShowCoAppUSPermanentResident(true);
            loadCoAppIdTypeList(false);
        }
        setCtrlCoAppIsUSPermanentRes(null);
        setCtrlCoAppIsForeignAccount(null);
        loadCoAppIdTypeList(false);
    }
    const setCoAppUSPermanentResidentValue = (radioVal) => {
        if (radioVal === "true") {
            setCtrlCoAppIsUSPermanentRes(true);
            setShowCoAppCountryCitizenship(true);
            setShowCoAppSSN(true);
            setShowCoAppForeignAccount(false);
        }
        else {
            setCtrlCoAppIsUSPermanentRes(false);
            setShowCoAppCountryCitizenship(false);
            setShowCoAppSSN(false);
            setShowCoAppForeignAccount(true);
        }
        setCtrlCoAppIsForeignAccount(null);
        loadCoAppIdTypeList(false);
    }
    const setCoAppForeignAccountValue = (radioVal) => {
        if (radioVal === "true") {
            setCtrlCoAppIsForeignAccount(true);
            setShowCoAppCountryCitizenship(true);
            setShowCoAppSSN(false);
            loadCoAppIdTypeList(true);
        }
        else {
            setCtrlCoAppIsForeignAccount(false);
            setShowCoAppCountryCitizenship(false);
            setShowCoAppSSN(false);
            loadCoAppIdTypeList(false);
            toast.error("Co-applicant must be a US citizen or permanent resident to open an account", { position: toast.POSITION.TOP_RIGHT });
        }
    }
    const setRadioButtonsForCoApplicant = (applicant) => {
        if (applicant.isUSCitizen) {
            setShowCoAppSSN(true);
            setShowCoAppCountryCitizenship(false);
        }
        else if (applicant.isUSPermanentResident) {
            setShowCoAppUSPermanentResident(true);
            setShowCoAppSSN(true);
            setShowCoAppCountryCitizenship(true);
        }
        else if (applicant.isForeignAccount) {
            setShowCoAppUSPermanentResident(true);
            setShowCoAppForeignAccount(true);
            setShowCoAppSSN(false);
            setShowCoAppCountryCitizenship(true);
        }
        else {
            setShowCoAppSSN(false);
            setShowCoAppCountryCitizenship(false);
            setShowCoAppUSPermanentResident(false);
            setShowCoAppForeignAccount(false);
        }
    }
    //Check citizenship for co-applicant ends here

    //Load states ends here
    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handlePrevious = () => {
        navigate('/dashboard');
    }
    //Load Id Types
    const loadIdTypeList = (flag) => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.IdType)
            .then((json) => {
                if (json.data.payload !== null) {
                    if (!flag) {
                        setIdTypeList(json.data.payload);
                    }
                    else {
                        var list = json.data.payload.filter(x => x.id !== parseInt(OLAEnums.DocumentTypeEnum.DriverLicense));
                        setIdTypeList(list);
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const loadCoAppIdTypeList = (flag) => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.IdType)
            .then((json) => {
                if (json.data.payload !== null) {
                    if (!flag) {
                        setCoAppIdTypeList(json.data.payload);
                    }
                    else {
                        var list = json.data.payload.filter(x => x.id !== parseInt(OLAEnums.DocumentTypeEnum.DriverLicense));
                        setCoAppIdTypeList(list);
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleIdTypeChange = (e) => {
        setCtrlIdType(e.target.value);
        if (parseInt(e.target.value) === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
            setShowIssuanceState(true);
        }
        else {
            setShowIssuanceState(false);
        }
        formik.handleChange(e);
    }
    const handleCoAppIdTypeChange = (e) => {
        setCtrlCoAppIdType(e.target.value);
        if (parseInt(e.target.value) === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
            setShowCoAppIssuanceState(true);
        }
        else {
            setShowCoAppIssuanceState(false);
        }
        formik.handleChange(e);
    }

    //Form values set here
    let formikValues = {
        isTrustedContact: showTrusted,
        applicationId: localStorage.getItem("currentAppId"),
        //primary
        firstName: "",
        middleName: "",
        lastName: "",
        yearAtResidence: null,
        isSameAddress: showMailing,
        mailingAddress: "",
        mailingAddress2: "",
        mailingCity: "",
        mailingState: '',
        mailingZipCode: "",
        mailingCountryId: null,
        maritalStatusId: null,
        noOfDependents: null,
        dateofBirth: null,
        socialSecurityNo: "",
        foreignTaxId: "",
        citizenshipCountryId: null,
        isUSCitizen: null,
        isUSPermanentResident: null,
        isForeignAccount: null,
        //Traditional IRA
        traditionalIRAName: null,
        depositorAuthorization: null,
        //contact info section
        physicalAddress: "",
        physicalAddress2: "",
        city: "",
        stateId: null,
        countryId: null,
        postalCode: "",
        homePhoneNumber: "",
        workPhoneNumber: "",
        emailAddress: "",
        idTypeId: null, //select Id type for driving license, govt id or passport number
        idNumber: "",
        idIssueDate: null,
        idExpireDate: null,
        idIssuanceState: null,

        //Co-applicant
        coAppfirstName: "",
        coAppmiddleName: "",
        coApplastName: "",
        coAppyearAtResidence: null,
        coAppisSameAddress: showMailing,
        coAppmailingAddress: "",
        coAppmailingCity: "",
        coAppmailingState: null,
        coAppMailingZipCode: "",
        coAppmailingCountryId: null,
        coAppmaritalStatusId: null,
        coAppnoOfDependents: null,
        coAppdateofBirth: null,
        coAppsocialSecurityNo: "",
        coAppforeignTaxId: "",
        coAppcitizenshipCountryId: null,
        coAppIsUSCitizen: null,
        coAppIsUSPermanentResident: null,
        coAppIsForeignAccount: null,
        coAppIdTypeId: null, //select Id type for driving license, govt id or passport number
        coAppIdNumber: "",
        coAppIdIssueDate: null,
        coAppIdExpireDate: null,
        coAppIdIssuanceState: null,

        //Co-applicant contact info section
        coAppPhysicalAddress: "",
        coAppPhysicalAddress2: "",
        coAppCity: "",
        coAppStateId: null,
        coAppCountryId: null,
        coAppPostalCode: "",
        coApphomePhoneNumber: "",
        coAppworkPhoneNumber: "",
        coAppemailAddress: "",
        isSameAsPrimaryApplicant: null, //checkbox check if co-applicant physical addresss is same as primary applicant physical address

        trustedFirstName: "",
        trustedLastName: "",
        trustedTelephone: "",
        trustedEmailAddress: "",
        trustedMailingAddress: "",
        trustedMailingAddress2: "",
        trustedState: null,
        trustedCountry: null,
        trustedCity: "",
        trustedPostalCode: "",
    };
    formikValues = {
        isTrustedContact: applicationInfo?.isTrustedContact,
        applicationId: applicationInfo?.id,
        applicantTypeId: applicationInfo?.applicantTypeId,
        //primary applicant
        firstName: applicantList && applicantList[0]?.firstName,
        middleName: applicantList && applicantList[0]?.middleName,
        lastName: applicantList && applicantList[0]?.lastName,
        yearAtResidence: applicantList && applicantList[0]?.yearAtResidence,
        isSameAddress: applicantList && applicantList[0]?.isSameAddress,
        mailingAddress: applicantList && applicantList[0]?.mailingAddress,
        mailingAddress2: applicantList && applicantList[0]?.mailingAddress2,
        mailingCity: applicantList && applicantList[0]?.mailingCity,
        mailingState: applicantList && applicantList[0]?.mailingStateId,
        mailingZipCode: applicantList && applicantList[0]?.mailingPostalCode,
        mailingCountryId: applicantList && applicantList[0]?.mailingCountryId,
        maritalStatusId: applicantList && applicantList[0]?.maritalStatusId,
        noOfDependents: applicantList && applicantList[0]?.noofDependents,
        dateofBirth: moment.utc(applicantList && applicantList[0]?.dateofBirth).format('YYYY-MM-DD'),
        socialSecurityNo: applicantList && applicantList[0]?.socialSecurityNo,
        foreignTaxId: applicantList && applicantList[0]?.foreignTaxId,
        citizenshipCountryId: applicantList && applicantList[0]?.citizenshipCountryId,
        isUSCitizen: applicantList && applicantList[0]?.isUSCitizen,
        isUSPermanentResident: applicantList && applicantList[0]?.isUSPermanentResident,
        isForeignAccount: applicantList && applicantList[0]?.isForeignAccount,
        idTypeId: applicantList && applicantList[0]?.idTypeId,
        idNumber: applicantList && applicantList[0]?.idNumber,
        idIssueDate: moment.utc(applicantList && applicantList[0]?.issueDate).format('YYYY-MM-DD'),
        idExpireDate: moment.utc(applicantList && applicantList[0]?.expirationDate).format('YYYY-MM-DD'),
        idIssuanceState: applicantList && applicantList[0]?.issuanceState,

        //primary applicant contact information
        loggedInUserId: getLoggedInUserInfo() !== null ? getLoggedInUserInfo().id : null,
        physicalAddress: applicantList && applicantList[0]?.physicalAddress,
        physicalAddress2: applicantList && applicantList[0]?.physicalAddress2,
        city: applicantList && applicantList[0]?.city,
        stateId: applicantList && applicantList[0]?.stateId,
        countryId: applicantList && applicantList[0]?.countryId,
        postalCode: applicantList && applicantList[0]?.zipCode,
        homePhoneNumber: applicantList && applicantList[0]?.homePhoneNumber,
        workPhoneNumber: applicantList && applicantList[0]?.workPhoneNumber,
        emailAddress: applicantList && applicantList[0]?.emailAddress,

        //Co-applicant personal information
        coAppfirstName: applicantList && applicantList[1]?.firstName,
        coAppmiddleName: applicantList && applicantList[1]?.middleName,
        coApplastName: applicantList && applicantList[1]?.lastName,
        coAppyearAtResidence: applicantList && applicantList[1]?.yearAtResidence,
        coAppisSameAddress: applicantList && applicantList[1]?.isSameAddress,
        coAppmailingAddress: applicantList && applicantList[1]?.mailingAddress,
        coAppmailingCity: applicantList && applicantList[1]?.mailingCity,
        coAppmailingState: applicantList && applicantList[1]?.mailingStateId,
        coAppmailingZipCode: applicantList && applicantList[1]?.mailingPostalCode,
        coAppmailingCountryId: applicantList && applicantList[1]?.mailingCountryId,
        coAppmaritalStatusId: applicantList && applicantList[1]?.maritalStatusId,
        coAppnoOfDependents: applicantList && applicantList[1]?.noofDependents,
        coAppdateofBirth: moment.utc(applicantList && applicantList[1]?.dateofBirth).format('YYYY-MM-DD'),
        coAppsocialSecurityNo: applicantList && applicantList[1]?.socialSecurityNo,
        coAppforeignTaxId: applicantList && applicantList[1]?.foreignTaxId,
        coAppcitizenshipCountryId: applicantList && applicantList[1]?.citizenshipCountryId,
        coAppIsUSCitizen: applicantList && applicantList[1]?.isUSCitizen,
        coAppIsUSPermanentResident: applicantList && applicantList[1]?.isUSPermanentResident,
        coAppIsForeignAccount: applicantList && applicantList[1]?.isForeignAccount,
        coAppIdTypeId: applicantList && applicantList[1]?.idTypeId,
        coAppIdNumber: applicantList && applicantList[1]?.idNumber,
        coAppIdIssueDate: moment.utc(applicantList && applicantList[1]?.issueDate).format('YYYY-MM-DD'),
        coAppIdExpireDate: moment.utc(applicantList && applicantList[1]?.expirationDate).format('YYYY-MM-DD'),
        coAppIdIssuanceState: applicantList && applicantList[1]?.issuanceState,
        isSameAsPrimaryApplicant: applicantList && applicantList[0]?.isSameAsPrimaryApplicant, //get value from first applicant row

        //Co-applicant contact information
        coAppPhysicalAddress: applicantList && applicantList[1]?.physicalAddress,
        coAppPhysicalAddress2: applicantList && applicantList[1]?.physicalAddress2,
        coAppCity: applicantList && applicantList[1]?.city,
        coAppStateId: applicantList && applicantList[1]?.stateId,
        coAppCountryId: applicantList && applicantList[1]?.countryId,
        coAppPostalCode: applicantList && applicantList[1]?.zipCode,
        coApphomePhoneNumber: applicantList && applicantList[1]?.homePhoneNumber,
        coAppworkPhoneNumber: applicantList && applicantList[1]?.workPhoneNumber,
        coAppemailAddress: applicantList && applicantList[1]?.emailAddress,

        //trusted
        trustedFirstName: trustedContactInfo?.firstName,
        trustedLastName: trustedContactInfo?.lastName,
        trustedTelephone: trustedContactInfo?.telephoneNumber,
        trustedEmailAddress: trustedContactInfo?.emailAddress,
        trustedMailingAddress: trustedContactInfo?.physicalAddress,
        trustedMailingAddress2: trustedContactInfo?.trustedAddress2,
        trustedState: trustedContactInfo?.stateId,
        trustedCountry: trustedContactInfo?.countryId,
        trustedCity: trustedContactInfo?.city,
        trustedPostalCode: trustedContactInfo?.zipCode,

        //Traditionl IRA (for Personal Account)
        traditionalIRAName: applicantList && applicantList[0]?.beneficialTraditionalIRA,
        depositorAuthorization: applicantList && applicantList[0]?.depositorAuthorization,
    };
    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        firstName: Yup.string().required("First name is required").min(2, "Mininum 2 characters required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        middleName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        lastName: Yup.string().required("Last name is required").min(2, "Mininum 2 characters required").nullable(),
        dateofBirth: Yup.string().required("Date of birth is required").nullable().test("DOB", "Account holder must be 18 years or older", (value) => {
            return (moment().diff(moment(value), "years") >= 16)
        }).test("DOB", "Please enter a valid date of birth", (value) => {
            return (moment().diff(moment(value), "years") <= 99)
        }),
        noOfDependents: Yup.number().required("Number of dependents required").typeError('You must specify a number').min(0, 'Minimum value 0'),
        maritalStatusId: Yup.string().required("Select marital status").nullable(),
        citizenshipCountryId: defaultCountry === null ? Yup.string().required("Select citizenship").nullable() : Yup.string().nullable(),
        socialSecurityNo: ctrlIsUSCitizen || ctrlIsUSPermanentRes ? Yup.string().required("Enter social security number").nullable().test("len", "Invalid social security number", (val) => {
            const val_length_without_dashes = val === null ? 0 : val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 9;
        }) : Yup.string().nullable(),
        isUSCitizen: Yup.string().required("Please select an option").nullable(),
        isUSPermanentResident: showUSPermanentResident ? Yup.string().required("Please select an option").nullable() : Yup.string().nullable(),
        isForeignAccount: showForeignAccount ? Yup.string().required("Please select an option").nullable() : Yup.string().nullable(),
        idTypeId: Yup.string().required("Select Id type").nullable(),
        idNumber: Yup.string().required("Enter Id number").nullable(),
        idIssueDate: Yup.date().nullable().required('Enter Id issue date').transform((value, originalValue) => {
            const date = new Date(originalValue);
            return isNaN(date) ? undefined : date;
        }),
        idExpireDate: Yup.date().nullable().required('Enter Id expiration date').transform((value, originalValue) => {
            const date = new Date(originalValue);
            return isNaN(date) ? undefined : date;
        }),
        idIssuanceState: showIssuanceState ? Yup.string().required("Select Id issuance state").nullable() : Yup.string().nullable(),
        emailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable(),
        homePhoneNumber: Yup.string().required("Home phone number is required").nullable().test("len", "Invalid home phone number", (val) => {
            const val_length_without_dashes = val === null ? 0 : val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 10 || val_length_without_dashes === 14;
        }),
        city: Yup.string().required("City name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        countryId: defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable(),
        stateId: Yup.string().when('checkState', {
            is: () => contactStates.length > 0,
            then: Yup.string().required('Select state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        postalCode: Yup.string().required("Postal code is required").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)),
        physicalAddress: Yup.string().required("Physical address is required").nullable(),
        mailingAddress: showMailing ? Yup.string().required('Mailing address is required').nullable() : Yup.string().nullable().notRequired(),
        mailingCity: showMailing ? Yup.string().required("Mailing city is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        mailingZipCode: showMailing ? Yup.string().required("Mailing postal code is required").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().nullable().notRequired(),
        mailingCountryId: showMailing && defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable().notRequired(),
        mailingState: Yup.string().nullable()
            .test('chec', 'select state', (value) => { return (showMailing && mailingStates && mailingStates.length) ? !!value : true }),
        trustedFirstName: showTrusted ? Yup.string().required("First name is required").min(2, "Mininum 2 characters required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        trustedLastName: showTrusted ? Yup.string().required("Last name is required").min(2, "Mininum 2 characters required").nullable() : Yup.string().nullable().notRequired(),
        trustedTelephone: showTrusted ? Yup.string().test("len", "Invalid phone number", (val) => {
            const val_length_without_dashes = val !== undefined ? val.replace(/-|_/g, "").length : 0;
            return val_length_without_dashes === 10 || val_length_without_dashes === 14;
        }).required("Telephone number is required").nullable() : Yup.string().nullable().notRequired(),
        trustedEmailAddress: showTrusted ? Yup.string().email('Invalid email format').required('Email address is required').nullable() : Yup.string().nullable().notRequired(),
        trustedMailingAddress: showTrusted ? Yup.string().required("Mailing address is required").nullable() : Yup.string().nullable().notRequired(),
        trustedCity: showTrusted ? Yup.string().required("City name is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        trustedPostalCode: showTrusted ? Yup.string().required("Postal code is required").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().nullable().notRequired(),
        trustedCountry: showTrusted ? defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable().nullable() : Yup.string().nullable().notRequired(),
        //trustedState: showTrusted ? Yup.string().test('checkTrustedState', 'Select state', () => !trustedStates.length).nullable() : Yup.string().nullable().notRequired(),
        trustedState: Yup.string().nullable()
            .test('checc', 'Select state', (value) => { return (showTrusted && trustedStates && trustedStates.length) ? !!value : true }),
        //Co-Applicant validations
        coAppfirstName: isJointAccount ? Yup.string().required('Enter first name for co-applicant').min(2, "Mininum 2 characters required")
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        coAppmiddleName: Yup.string().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable(),
        coApplastName: isJointAccount ? Yup.string().required('Enter last name for co-applicant').min(2, "Mininum 2 characters required").nullable() : Yup.string().nullable().notRequired(),
        coAppdateofBirth: isJointAccount ? Yup.string().required("Date of birth is required for co-applicant").nullable()
            .test("DOB", "Account holder must be 18 years or older", (value) => {
                return (moment().diff(moment(value), "years") >= 16)
            }).test("DOB", "Please enter a valid date of birth", (value) => {
                return (moment().diff(moment(value), "years") <= 99)
            }) : Yup.string().nullable().notRequired(),
        coAppnoOfDependents: isJointAccount ? Yup.number().required("Number of dependents required for co-applicant").typeError('You must specify a number').min(0, 'Minimum value 0') : Yup.string().nullable().notRequired(),
        coAppmaritalStatusId: isJointAccount ? Yup.string().required('Select martial status for co-applicant').nullable() : Yup.string().nullable().notRequired(),
        coAppcitizenshipCountryId: isJointAccount && defaultCountry === null ? Yup.string().required('Select citizenship for co-applicant').nullable() : Yup.string().nullable().notRequired(),
        coAppsocialSecurityNo: isJointAccount && (ctrlCoAppIsUSCitizen || ctrlCoAppIsUSPermanentRes) ? Yup.string().required("Enter social security number for co-applicant").nullable().test("len", "Invalid social security number", (val) => {
            const val_length_without_dashes = val === null ? 0 : val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 9;
        }) : Yup.string().nullable().notRequired(),
        coAppIsUSCitizen: isJointAccount ? Yup.string().required("Please select US citizenship for Co-applicant").nullable() : Yup.string().nullable().notRequired(),
        coAppIsUSPermanentResident: isJointAccount && showCoAppUSPermanentResident ? Yup.string().required("Please select US permanent resident for Co-applicant").nullable() : Yup.string().nullable(),
        coAppIsForeignAccount: isJointAccount && showCoAppForeignAccount ? Yup.string().required("Please select is Co-applicant has foreign account").nullable() : Yup.string().nullable(),

        coAppemailAddress: isJointAccount ? Yup.string().email('Invalid email format').required('Email address is required for co-applicant').nullable() : Yup.string().nullable().notRequired(),
        coApphomePhoneNumber: isJointAccount ? Yup.string().required("Home phone number is required for co-applicant").nullable().test("len", "Invalid home phone number", (val) => {
            const val_length_without_dashes = val === null ? 0 : val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 10 || val_length_without_dashes === 14;
        }) : Yup.string().nullable().notRequired(),
        coAppCountryId: isJointAccount && defaultCountry === null ? Yup.string().required('Select country for co-applicant').nullable() : Yup.string().nullable().notRequired(),
        //coAppStateId: isJointAccount ? Yup.string().test('checkCoAppState', 'Select state', () => !coAppContactStates.length).nullable() : Yup.string().nullable().notRequired(),
        coAppStateId: Yup.string().nullable().test('checkCoAppState', 'Select state for co-applicant', (value) => { return (isJointAccount && coAppContactStates && coAppContactStates.length) ? !!value : true }),
        coAppCity: isJointAccount ? Yup.string().required('Enter city name for co-applicant').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        coAppPostalCode: isJointAccount ? Yup.string().required('Enter postal code for co-applicant').min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().nullable().notRequired(),
        coAppPhysicalAddress: isJointAccount ? Yup.string().required('Enter physical address for co-applicant').nullable() : Yup.string().nullable().notRequired(),
        coAppIdTypeId: isJointAccount ? Yup.string().required("Enter Id number").nullable() : Yup.string().nullable().notRequired(),
        coAppIdNumber: isJointAccount ? Yup.string().required("Enter Id number").nullable() : Yup.string().nullable().notRequired(),
        coAppIdIssueDate: isJointAccount ? Yup.date().nullable().required('Enter Id issue date').transform((value, originalValue) => {
            const date = new Date(originalValue);
            return isNaN(date) ? undefined : date;
        }) : Yup.string().nullable().notRequired(),
        coAppIdExpireDate: isJointAccount ? Yup.date().nullable().required('Enter Id expiration date').transform((value, originalValue) => {
            const date = new Date(originalValue);
            return isNaN(date) ? undefined : date;
        }) : Yup.string().nullable().notRequired(),
        coAppIdIssuanceState: isJointAccount && showCoAppIssuanceState ? Yup.string().required("Select Id issuance state").nullable() : Yup.string().nullable().notRequired()
    });

    const getApplicantCoApplicantInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicantList != null && result.applicantList.length > 0) {
                        setApplicantList(result.applicantList);
                        result.applicantList[0]?.isUSCitizen === null ? setCtrlIsUSCitizen(null) : result.applicantList[0]?.isUSCitizen === true ? setCtrlIsUSCitizen(true) : setCtrlIsUSCitizen(false);
                        result.applicantList[0]?.isUSPermanentResident === null ? setCtrlIsUSPermanentRes(null) : result.applicantList[0]?.isUSPermanentResident === true ? setCtrlIsUSPermanentRes(true) : setCtrlIsUSPermanentRes(false);
                        result.applicantList[0]?.isForeignAccount === null ? setCtrlIsForeignAccount(null) : result.applicantList[0]?.isForeignAccount === true ? setCtrlIsForeignAccount(true) : setCtrlIsForeignAccount(false);
                        if (result.applicantList[0]?.isForeignAccount) {
                            loadIdTypeList(true);
                        }
                        else {
                            loadIdTypeList(false);
                        }
                        setRadioButtonsForPrimaryApplicant(result.applicantList[0]);

                        if (result.applicantList[0].isSameAddress) {
                            setShowMailing(true);
                            loadMailingStates(result.applicantList[0].mailingCountryId);
                        }
                        if (result.applicantList[0].isSameAsPrimaryApplicant) {
                            setShowIsSamePrimaryApp(true);
                        }
                        loadContactStates(result.applicantList[0]?.countryId);
                        if (!result.applicantList[0]?.isForeignAccount && result.applicantList[0]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                            setShowIssuanceState(true);
                        }
                        else {
                            setShowIssuanceState(false);
                        }
                        setCtrlIdType(result.applicantList[0]?.idTypeId);

                        //for co-applicant
                        if (result.applicantList.length > 1) {
                            loadCoAppContactStates(result.applicantList[1]?.countryId);
                            result.applicantList[1]?.isUSCitizen === null ? setCtrlCoAppIsUSCitizen(null) : result.applicantList[1]?.isUSCitizen === true ? setCtrlCoAppIsUSCitizen(true) : setCtrlCoAppIsUSCitizen(false);
                            result.applicantList[1]?.isUSPermanentResident === null ? setCtrlCoAppIsUSPermanentRes(null) : result.applicantList[1]?.isUSPermanentResident === true ? setCtrlCoAppIsUSPermanentRes(true) : setCtrlCoAppIsUSPermanentRes(false);
                            result.applicantList[1]?.isForeignAccount === null ? setCtrlCoAppIsForeignAccount(null) : result.applicantList[1]?.isForeignAccount === true ? setCtrlCoAppIsForeignAccount(true) : setCtrlCoAppIsForeignAccount(false);
                            setRadioButtonsForCoApplicant(result.applicantList[1]);
                            if (result.applicantList[1]?.isForeignAccount) {
                                loadCoAppIdTypeList(true);
                            }
                            else {
                                loadCoAppIdTypeList(false);
                            }
                            if (!result.applicantList[1]?.isForeignAccount && result.applicantList[1]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowCoAppIssuanceState(true);
                            }
                            else {
                                setShowCoAppIssuanceState(false);
                            }
                            setCtrlCoAppIdType(result.applicantList[1]?.idTypeId);
                        }
                    }
                    if (result.applicationInfo != null) {
                        setApplicationInfo(result.applicationInfo);
                        if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }
                        if (result.applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                            setIsJointAccount(true);
                        }
                        if (result.applicationInfo?.isTrustedContact) {
                            setShowTrusted(true);
                        }
                        if (result.trustedContactInfo != null) {
                            setTrustedContactInfo(result.trustedContactInfo);
                            loadTrustedStates(result.trustedContactInfo?.countryId);
                        }
                        //set the sub account type for retirement account type
                        if (result.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            setIsRetirementAccountType(true);
                        }
                        if (result.applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.TraditionalIRA) {
                            setIsTraditionalIRA(true);
                        }
                        else if (result.applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.SimpleIRAParticipant) {
                            setIsSimpleIRAParticipant(true);
                        }
                        else if (result.applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.BeneficiaryRothIRA) {
                            setIsBenRothIRA(true);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        onSubmit: (values) => {
            loader.show();
            let applicantInfoRequestList = [];
            if (isRetirementAccountType && !ctrlIsUSPermanentRes) {
                if (values.isForeignAccount === "false" || values.isForeignAccount === "true") {
                    toast.error("You must be a US citizen or permanent resident to open an account", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
            }
            else {
                if (!isRetirementAccountType && values.isForeignAccount === "false") {
                    toast.error("You must be a US citizen or permanent resident to open an account", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
            }

            let applicationId = localStorage.getItem("currentAppId");
            let loggedInUserId = getLoggedInUserInfo().id;

            let contactStateId = values.stateId;
            if (contactStates === null || contactStates.length === 0) {
                contactStateId = null;
            }

            if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                if (values.socialSecurityNo !== null && values.coAppsocialSecurityNo !== null) {
                    if (values.socialSecurityNo.trim() === values.coAppsocialSecurityNo.trim()) {
                        toast.error("Account holders must have different social security numbers", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                }
                if (values.coAppIsForeignAccount === "false") {
                    toast.error("Co-applicant must be a US citizen or permanent resident to open an account", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
            }

            let applTypeId = isRetirementAccountType ? OLAEnums.ApplicantTypeEnum.IRAApplicant : OLAEnums.ApplicantTypeEnum.PrimaryApplicant;
            let entUSPerminentResident = null;
            let entIsForeignAccount = null;
            let entForeignTaxId = null;
            let primarySSN = null;

            if (ctrlIsUSCitizen) {
                entUSPerminentResident = null;
                entIsForeignAccount = null;
                entForeignTaxId = null;
                primarySSN = values.socialSecurityNo;
            }
            else {
                entUSPerminentResident = values.isUSPermanentResident;
                if (ctrlIsUSPermanentRes) {
                    entIsForeignAccount = null;
                    entForeignTaxId = null;
                    primarySSN = values.socialSecurityNo;
                }
                else if (ctrlIsForeignAccount) {
                    entIsForeignAccount = values.isForeignAccount;
                    entForeignTaxId = "000000000";
                    primarySSN = null;
                }
            }

            let primaryCitizenship = null;
            primaryCitizenship = values.citizenshipCountryId === null ? defaultCountry : values.citizenshipCountryId;
            if (ctrlIsUSCitizen) {
                primaryCitizenship = defaultCountry;
            }

            applicantInfoRequestList.push({
                "applicationId": applicationId, "applicantTypeId": applTypeId, "firstName": values.firstName, "middleName": values.middleName, "lastName": values.lastName, "simpleIRA": "", "beneficialRothIRA": "",
                "beneficialTraditionalIRA": values.traditionalIRAName, "depositorAuthorization": values.depositorAuthorization, "entityName": "", "physicalAddress": values.physicalAddress,
                "physicalAddress2": values.physicalAddress2, "physicalSuite": "", "physicalSuburb": "", "yearAtResidence": 0, "city": values.city, "stateId": contactStateId, "zipCode": values.postalCode,
                "countryId": values.countryId === null ? defaultCountry : values.countryId, "homePhoneNumber": values.homePhoneNumber, "workPhoneNumber": values.workPhoneNumber, "cellPhoneNumber": "",
                "faxNumber": "", "emailAddress": values.emailAddress, "isSameAddress": showMailing, "mailingAddress": values.mailingAddress, "mailingAddress2": values.mailingAddress2, "mailingSuite": "",
                "mailingSuburb": "", "mailingCity": values.mailingCity, "mailingState": values.mailingState, "mailingZipCode": values.mailingZipCode, "mailingCountryId": values.mailingCountryId === null ? defaultCountry : values.mailingCountryId,
                "maritalStatusId": values.maritalStatusId, "noOfDependents": values.noOfDependents, "employmentStatusId": null, "employmentStatusOther": "", "jobTitle": "", "typeofBusiness": "", "employer": "",
                "employerAddress": "", "employerSuite": "", "employerSuburb": "", "businessTelephone": "", "employeeCity": "", "employeeState": null, "employeeZipCode": "", "employeeCountryId": null,
                "dateofBirth": values.dateofBirth, "socialSecurityNo": primarySSN, "foreignTaxId": entForeignTaxId, "visaTypeId": null, "visaExpirationDate": "", "citizenshipCountryId": primaryCitizenship, "citizenshipNonUS": 0,
                "idTypeId": parseInt(ctrlIdType), "idNumber": values.idNumber, "issueDate": values.idIssueDate, "expirationDate": values.idExpireDate, "issuanceState": showIssuanceState ? values.idIssuanceState : null, "idTypeOther": "",
                "issuanceCountry": null, "entityTypeAct": null, "entityTypeActOther": "", "citizenshipStatus": null, "stateOfIncorporation": null, "isUSCitizen": ctrlIsUSCitizen, "isUSPermanentResident": entUSPerminentResident,
                "isForeignAccount": entIsForeignAccount, "isSameAsPrimaryApplicant": showIsSamePrimaryApp
            });

            if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {

                let entCoAppUSPerminentResident = null;
                let entCoAppIsForeignAccount = null;
                let entCoAppForeignTaxId = null;
                let coAppSSN = null;

                if (ctrlCoAppIsUSCitizen) {
                    entCoAppUSPerminentResident = null;
                    entCoAppIsForeignAccount = null;
                    entCoAppForeignTaxId = null;
                    coAppSSN = values.coAppsocialSecurityNo;
                }
                else {
                    entCoAppUSPerminentResident = values.coAppIsUSPermanentResident;
                    if (ctrlCoAppIsUSPermanentRes) {
                        entCoAppIsForeignAccount = null;
                        entCoAppForeignTaxId = null;
                        coAppSSN = values.coAppsocialSecurityNo;
                    }
                    else if (ctrlCoAppIsForeignAccount) {
                        entCoAppIsForeignAccount = values.coAppIsForeignAccount;
                        entCoAppForeignTaxId = "000000000";
                        coAppSSN = null;
                    }
                }

                let coAppCitizenship = null;
                coAppCitizenship = values.coAppcitizenshipCountryId === null ? defaultCountry : values.coAppcitizenshipCountryId;
                if (ctrlCoAppIsUSCitizen) {
                    coAppCitizenship = defaultCountry;
                }

                applicantInfoRequestList.push({
                    "applicationId": applicationId, "applicantTypeId": OLAEnums.ApplicantTypeEnum.CoApplicant, "firstName": values.coAppfirstName, "middleName": values.coAppmiddleName, "lastName": values.coApplastName,
                    "simpleIRA": "", "beneficialRothIRA": "", "beneficialTraditionalIRA": "", "depositorAuthorization": "", "entityName": "", "physicalAddress": values.coAppPhysicalAddress, "physicalAddress2": values.coAppPhysicalAddress2, "physicalSuite": "",
                    "physicalSuburb": "", "yearAtResidence": 0, "city": values.coAppCity, "stateId": values.coAppStateId, "zipCode": values.coAppPostalCode, "countryId": values.coAppCountryId === null ? defaultCountry : values.coAppCountryId,
                    "homePhoneNumber": values.coApphomePhoneNumber, "workPhoneNumber": values.coAppworkPhoneNumber, "cellPhoneNumber": "", "faxNumber": "", "emailAddress": values.coAppemailAddress, "isSameAddress": showMailing,
                    "mailingAddress": values.coAppmailingAddress, "mailingSuite": "", "mailingSuburb": "", "mailingCity": values.coAppmailingCity, "mailingState": values.coAppmailingState, "mailingZipCode": values.coAppmailingZipCode,
                    "mailingCountryId": values.coAppmailingCountryId === null ? defaultCountry : values.coAppmailingCountryId, "maritalStatusId": values.coAppmaritalStatusId, "noOfDependents": values.coAppnoOfDependents, "employmentStatusId": null,
                    "employmentStatusOther": "", "jobTitle": "", "typeofBusiness": "", "employer": "", "employerAddress": "", "employerSuite": "", "employerSuburb": "", "businessTelephone": "", "employeeCity": "", "employeeState": null,
                    "employeeZipCode": "", "employeeCountryId": null, "dateofBirth": values.coAppdateofBirth, "socialSecurityNo": coAppSSN, "foreignTaxId": entCoAppForeignTaxId, "visaTypeId": null, "visaExpirationDate": "",
                    "citizenshipCountryId": coAppCitizenship, "citizenshipNonUS": 0, "idTypeId": parseInt(ctrlCoAppIdType), "idTypeOther": "", "issuanceState": showCoAppIssuanceState ? values.coAppIdIssuanceState : null, "issuanceCountry": null, "idNumber": values.coAppIdNumber,
                    "issueDate": values.coAppIdIssueDate, "expirationDate": values.coAppIdExpireDate, "entityTypeAct": null, "entityTypeActOther": "", "citizenshipStatus": null, "stateOfIncorporation": null,
                    "isUSCitizen": ctrlCoAppIsUSCitizen, "isUSPermanentResident": entCoAppUSPerminentResident, "isForeignAccount": entCoAppIsForeignAccount, "isSameAsPrimaryApplicant": showIsSamePrimaryApp
                });
            }

            let trustStateId = values.trustedState;
            if (trustStateId === null) {
                trustStateId = null;
            }

            let data = {
                "isTrustedContact": showTrusted,
                "applicantInfoRequestList": applicantInfoRequestList,
                "applicationTrustedPersonRequest": {
                    "loggedInUserId": loggedInUserId,
                    "applicationId": applicationId,
                    "firstName": values.trustedFirstName,
                    "middleInitial": "",
                    "lastName": values.trustedLastName,
                    "telephoneNumber": values.trustedTelephone,
                    "cellphoneNumber": "",
                    "emailAddress": values.trustedEmailAddress,
                    "physicalAddress": values.trustedMailingAddress,
                    "trustedAddress2": values.trustedMailingAddress2,
                    "stateId": trustStateId,
                    "countryId": values.trustedCountry === null ? defaultCountry : values.trustedCountry,
                    "city": values.trustedCity,
                    "ZipCode": values.trustedPostalCode
                }
            };

            applicationService.saveApplicantPrimaryCoAppInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getApplicantCoApplicantInfo();
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/employment-info");
                        }
                        else {
                            toast.success("Personal information has been saved successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });
    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <div className="tab-content" id="personal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">{!isJointAccount ? "Personal Information" : "Primary Personal Information"}</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">First Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="firstName" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.firstName}
                                                            maxLength="50" placeholder="Enter a First Name" />
                                                        {formik.touched.firstName && formik.errors.firstName ? (
                                                            <p className="error">{formik.errors.firstName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Middle Name</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="middleName" disabled={ctrlDisable}
                                                            maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.middleName}
                                                            placeholder="Enter a Middle Name" />
                                                        {formik.touched.middleName && formik.errors.middleName ? (
                                                            <p className="error">{formik.errors.middleName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Last Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="lastName" maxLength="50" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.lastName}
                                                            placeholder="Enter a Last Name" />
                                                        {formik.touched.lastName && formik.errors.lastName ? (
                                                            <p className="error">{formik.errors.lastName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Date of Birth<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name="dateofBirth" disabled={ctrlDisable}
                                                            defaultValue={moment.utc(applicantList && applicantList[0]?.dateofBirth).format('YYYY-MM-DD')}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                            value={formik.values.dateofBirth} max={moment().format("YYYY-MM-DD")}
                                                        />
                                                        {/* <DatePicker className="inputForm" name="dateofBirth" disabled={ctrlDisable}
                                                            selected={dobDate} onBlur={formik.handleBlur} onChange={handleDOBChange} dateFormat="MM/dd/yyyy"
                                                            //defaultValue={moment(applicantList && applicantList[0]?.dateofBirth).format('MM/DD/YYYY')} 
                                                            value={formik.values.dateofBirth} 
                                                        /> */}
                                                        {formik.touched.dateofBirth && formik.errors.dateofBirth ? (
                                                            <p className="error">{formik.errors.dateofBirth}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Number of Dependents<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="noOfDependents"
                                                            placeholder="Enter Number of Dependents" maxLength={2} disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.noOfDependents} />
                                                        {formik.touched.noOfDependents && formik.errors.noOfDependents ? (
                                                            <p className="error">{formik.errors.noOfDependents}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Marital Status<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="maritalStatusId" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.maritalStatusId}
                                                        >
                                                            <option value="">Select Marital Status</option>
                                                            {maritalStatusList && maritalStatusList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.maritalStatusId && formik.errors.maritalStatusId ? (
                                                            <p className="error">{formik.errors.maritalStatusId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Email<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="email" className="inputForm" name="emailAddress" disabled={ctrlDisable}
                                                            maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.emailAddress}
                                                            placeholder="Enter an email address" />
                                                        {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                            <p className="error">{formik.errors.emailAddress}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Primary Telephone<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <InputMask className="inputForm" name="homePhoneNumber" disabled={ctrlDisable}
                                                            placeholder="Enter Primary Telephone"
                                                            mask="999-999-9999-9999" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.homePhoneNumber} />
                                                        {formik.touched.homePhoneNumber && formik.errors.homePhoneNumber ? (
                                                            <p className="error">{formik.errors.homePhoneNumber}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Work Telephone<span className="error-info"></span></label>
                                                    <div className="input_area">
                                                        <InputMask className="inputForm" name="workPhoneNumber" disabled={ctrlDisable}
                                                            placeholder="Enter Work Telephone"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.workPhoneNumber}
                                                            mask="999-999-9999-9999" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="input_wrapper">
                                                    <label className="form-label">Are you a US citizen?</label>
                                                    <div className='input_arae' style={radioTopMargin}>
                                                        <input type="radio" className="form-check-input" name="isUSCitizen" onClick={(e) => setIsCitizenshipValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsUSCitizen === null ? false : ctrlIsUSCitizen === true ? true : false}
                                                            value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                        <input type="radio" className="form-check-input" name="isUSCitizen" onClick={(e) => setIsCitizenshipValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsUSCitizen === null ? false : ctrlIsUSCitizen === false ? true : false}
                                                            value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                        {formik.touched.isUSCitizen && formik.errors.isUSCitizen ? (
                                                            <p className="rb-required-error">{formik.errors.isUSCitizen}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {showUSPermanentResident && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Are you a US permanent resident?</label>
                                                    <div className='input_arae' style={radioTopMargin}>
                                                        <input className="form-check-input" name="isUSPermanentResident" type="radio" onClick={(e) => setUSPermanentResidentValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsUSPermanentRes === null ? false : ctrlIsUSPermanentRes === true ? true : false}
                                                            value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                        <input className="form-check-input" type="radio" name="isUSPermanentResident" onClick={(e) => setUSPermanentResidentValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsUSPermanentRes === null ? false : ctrlIsUSPermanentRes === false ? true : false}
                                                            value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                        {formik.touched.isUSPermanentResident && formik.errors.isUSPermanentResident ? (
                                                            <p className="rb-required-error">{formik.errors.isUSPermanentResident}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>}
                                            {showForeignAccount && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Is this a foreign account?</label>
                                                    <div className="input_arae" style={radioTopMargin}>
                                                        <input className="form-check-input" name="isForeignAccount" type="radio" onClick={(e) => setForeignAccountValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsForeignAccount === null ? false : ctrlIsForeignAccount === true ? true : false}
                                                            value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                        <input className="form-check-input" type="radio" name="isForeignAccount" onClick={(e) => setForeignAccountValue(e.target.value)}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            checked={ctrlIsForeignAccount === null ? false : ctrlIsForeignAccount === false ? true : false}
                                                            value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                        {formik.touched.isForeignAccount && formik.errors.isForeignAccount ? (
                                                            <p className="rb-required-error">{formik.errors.isForeignAccount}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="row">
                                            {showCountryCitizenship && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Country of Citizenship<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="citizenshipCountryId" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.citizenshipCountryId}>
                                                            {countryList && countryList.map((option) => (
                                                                <option value={option.id} key={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.citizenshipCountryId && formik.errors.citizenshipCountryId ? (
                                                            <p className="error">{formik.errors.citizenshipCountryId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>}
                                            {showSSN && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Social Security Number<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <InputMask className="inputForm" name="socialSecurityNo" mask="999-99-9999" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.socialSecurityNo}
                                                            placeholder="Enter Social Security Number" />
                                                        {formik.touched.socialSecurityNo && formik.errors.socialSecurityNo ? (
                                                            <p className="error">{formik.errors.socialSecurityNo}</p>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>}
                                            {ctrlIsForeignAccount && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Foreign Tax Id<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="foreignTaxId" maxLength="20" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} mask="999-999-999" readOnly value="000-000-000"
                                                            placeholder="Enter foreign tax Id"  />
                                                    </div>
                                                </div>
                                            </div>}

                                            {/* Id type load here */}
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Select Id Type<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="idTypeId" disabled={ctrlDisable} //onChange={formik.handleChange} value={formik.values.idTypeId}
                                                            onBlur={formik.handleBlur} value={ctrlIdType} onChange={(e) => handleIdTypeChange(e)}>
                                                            <option value="">Select Id Type</option>
                                                            {idTypeList && idTypeList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.idTypeId && formik.errors.idTypeId ? (
                                                            <p className="error">{formik.errors.idTypeId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Id Number<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="idNumber" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.idNumber}
                                                            maxLength="17" placeholder="Enter Id Number" />
                                                        {formik.touched.idNumber && formik.errors.idNumber ? (
                                                            <p className="error">{formik.errors.idNumber}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Id Issue Date<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name="idIssueDate" disabled={ctrlDisable}
                                                            defaultValue={moment.utc(applicantList && applicantList[0]?.issueDate).format('YYYY-MM-DD')}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} min={moment().subtract(idIssueYears, 'years').format('YYYY-MM-DD')}
                                                            max={moment().format("YYYY-MM-DD")} value={formik.values.idIssueDate} />
                                                        {formik.touched.idIssueDate && formik.errors.idIssueDate ? (
                                                            <p className="error">{formik.errors.idIssueDate}</p>
                                                        ) : null}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Id Expiration Date<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="date" format="YYYY-MM-DD" className="inputForm" name="idExpireDate" disabled={ctrlDisable}
                                                            defaultValue={moment.utc(applicantList && applicantList[0]?.expirationDate).format('YYYY-MM-DD')}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                            value={formik.values.idExpireDate} min={moment().format("YYYY-MM-DD")} />
                                                        {formik.touched.idExpireDate && formik.errors.idExpireDate ? (
                                                            <p className="error">{formik.errors.idExpireDate}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {showIssuanceState && <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Id Issuance State<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="idIssuanceState" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                            value={formik.values.idIssuanceState} disabled={ctrlDisable}>
                                                            <option value="">Select State</option>
                                                            {issuanceStates && issuanceStates.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.idIssuanceState && formik.errors.idIssuanceState ? (
                                                            <p className="error">{formik.errors.idIssuanceState}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">{!isJointAccount ? "Physical Address" : "Primary Physical Address"}</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Address 1<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="physicalAddress" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.physicalAddress}
                                                            placeholder="Enter Address 1" />
                                                        {formik.touched.physicalAddress && formik.errors.physicalAddress ? (
                                                            <p className="error">{formik.errors.physicalAddress}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Address 2</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="physicalAddress2" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.physicalAddress2}
                                                            placeholder="Enter Address 2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Country<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="countryId" disabled={ctrlDisable}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("countryId", e.target.value);
                                                                formik.setFieldValue("stateId", '');
                                                                handleContactCountryChange(e);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            defaultValue={defaultCountry}
                                                            value={formik.values.countryId}>
                                                            {countryList && countryList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.countryId && formik.errors.countryId ? (
                                                            <p className="error">{formik.errors.countryId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">State<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select className="form-select" name="stateId" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                            value={formik.values.stateId} disabled={ctrlDisable}>
                                                            <option value="">Select State</option>
                                                            {contactStates && contactStates.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.stateId && formik.errors.stateId ? (
                                                            <p className="error">{formik.errors.stateId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">City<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="city" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.city}
                                                            maxLength="50" placeholder="City" />
                                                        {formik.touched.city && formik.errors.city ? (
                                                            <p className="error">{formik.errors.city}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="postalCode" disabled={ctrlDisable}
                                                            maxLength="10" placeholder="Enter Postal Code" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.postalCode} />
                                                        {formik.touched.postalCode && formik.errors.postalCode ? (
                                                            <p className="error">{formik.errors.postalCode}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isJointAccount &&
                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">Co-Applicant Personal Information</h2>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">First Name<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppfirstName" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppfirstName}
                                                                maxLength="50" placeholder="Enter a First Name" />
                                                            {formik.touched.coAppfirstName && formik.errors.coAppfirstName ? (
                                                                <p className="error">{formik.errors.coAppfirstName}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Middle Name</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppmiddleName" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppmiddleName}
                                                                placeholder="Enter a Middle Name" />
                                                            {formik.touched.coAppmiddleName && formik.errors.coAppmiddleName ? (
                                                                <p className="error">{formik.errors.coAppmiddleName}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Name<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coApplastName" maxLength="50" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coApplastName}
                                                                placeholder="Enter a Last Name" />
                                                            {formik.touched.coApplastName && formik.errors.coApplastName ? (
                                                                <p className="error">{formik.errors.coApplastName}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Date of Birth<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="date" format="YYYY-MM-DD" className="inputForm" name="coAppdateofBirth" disabled={ctrlDisable}
                                                                defaultValue={moment.utc(applicantList && applicantList[0]?.dateofBirth).format('YYYY-MM-DD')}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                value={formik.values.coAppdateofBirth} max={moment().format("YYYY-MM-DD")}
                                                            />
                                                            {formik.touched.coAppdateofBirth && formik.errors.coAppdateofBirth ? (
                                                                <p className="error">{formik.errors.coAppdateofBirth}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Number of Dependents<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppnoOfDependents" maxLength={2} disabled={ctrlDisable}
                                                                placeholder="Enter Number of Dependents"
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppnoOfDependents} />
                                                            {formik.touched.coAppnoOfDependents && formik.errors.coAppnoOfDependents ? (
                                                                <p className="error">{formik.errors.coAppnoOfDependents}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Marital Status<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppmaritalStatusId" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppmaritalStatusId}
                                                            >
                                                                <option value="">Select Marital Status</option>
                                                                {maritalStatusList && maritalStatusList.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppmaritalStatusId && formik.errors.coAppmaritalStatusId ? (
                                                                <p className="error">{formik.errors.coAppmaritalStatusId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Email<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="email" className="inputForm" name="coAppemailAddress" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemailAddress}
                                                                placeholder="Enter an email address" />
                                                            {formik.touched.coAppemailAddress && formik.errors.coAppemailAddress ? (
                                                                <p className="error">{formik.errors.coAppemailAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Primary Telephone<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <InputMask className="inputForm" name="coApphomePhoneNumber"
                                                                placeholder="Enter Primary Telephone" disabled={ctrlDisable}
                                                                mask="999-999-9999-9999" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coApphomePhoneNumber} />
                                                            {formik.touched.coApphomePhoneNumber && formik.errors.coApphomePhoneNumber ? (
                                                                <p className="error">{formik.errors.coApphomePhoneNumber}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Work Telephone</label>
                                                        <div className="input_area">
                                                            <InputMask className="inputForm" name="coAppworkPhoneNumber" disabled={ctrlDisable}
                                                                placeholder="Enter Work Telephone"
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppworkPhoneNumber}
                                                                mask="999-999-9999-9999" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Are you a US citizen?</label>
                                                        <div className='input_arae' style={radioTopMargin}>
                                                            <input type="radio" className="form-check-input" name="coAppIsUSCitizen" onClick={(e) => setCoAppIsCitizenshipValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsUSCitizen === null ? false : ctrlCoAppIsUSCitizen === true ? true : false}
                                                                value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                            <input type="radio" className="form-check-input" name="coAppIsUSCitizen" onClick={(e) => setCoAppIsCitizenshipValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsUSCitizen === null ? false : ctrlCoAppIsUSCitizen === false ? true : false}
                                                                value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                            {formik.touched.coAppIsUSCitizen && formik.errors.coAppIsUSCitizen ? (
                                                                <p className="rb-required-error">{formik.errors.coAppIsUSCitizen}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {showCoAppUSPermanentResident && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Are you a US permanent resident?</label>
                                                        <div className='input_arae' style={radioTopMargin}>
                                                            <input className="form-check-input" name="coAppIsUSPermanentResident" type="radio" onClick={(e) => setCoAppUSPermanentResidentValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsUSPermanentRes === null ? false : ctrlCoAppIsUSPermanentRes === true ? true : false}
                                                                value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                            <input className="form-check-input" type="radio" name="coAppIsUSPermanentResident" onClick={(e) => setCoAppUSPermanentResidentValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsUSPermanentRes === null ? false : ctrlCoAppIsUSPermanentRes === false ? true : false}
                                                                value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                            {formik.touched.coAppIsUSPermanentResident && formik.errors.coAppIsUSPermanentResident ? (
                                                                <p className="rb-required-error">{formik.errors.coAppIsUSPermanentResident}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>}
                                                {showCoAppForeignAccount && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Is this a foreign account?</label>
                                                        <div className="input_arae" style={radioTopMargin}>
                                                            <input className="form-check-input" name="coAppIsForeignAccount" type="radio" onClick={(e) => setCoAppForeignAccountValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsForeignAccount === null ? false : ctrlCoAppIsForeignAccount === true ? true : false}
                                                                value={true} /><label className="form-label">&nbsp;Yes&nbsp;</label>
                                                            <input className="form-check-input" type="radio" name="coAppIsForeignAccount" onClick={(e) => setCoAppForeignAccountValue(e.target.value)}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                                checked={ctrlCoAppIsForeignAccount === null ? false : ctrlCoAppIsForeignAccount === false ? true : false}
                                                                value={false} /><label className="form-label">&nbsp;No&nbsp;</label>
                                                            {formik.touched.coAppIsForeignAccount && formik.errors.coAppIsForeignAccount ? (
                                                                <p className="rb-required-error">{formik.errors.coAppIsForeignAccount}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="row">
                                                {showCoAppCountryCitizenship && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country of Citizenship<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppcitizenshipCountryId" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppcitizenshipCountryId}>
                                                                {countryList && countryList.map((option) => (
                                                                    <option value={option.id} key={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppcitizenshipCountryId && formik.errors.coAppcitizenshipCountryId ? (
                                                                <p className="error">{formik.errors.coAppcitizenshipCountryId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>}
                                                {showCoAppSSN && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Social Security Number<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <InputMask className="inputForm" name="coAppsocialSecurityNo" mask="999-99-9999" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppsocialSecurityNo}
                                                                placeholder="Enter Social Security Number" />
                                                            {formik.touched.coAppsocialSecurityNo && formik.errors.coAppsocialSecurityNo ? (
                                                                <p className="error">{formik.errors.coAppsocialSecurityNo}</p>
                                                            ) : null}

                                                        </div>
                                                    </div>
                                                </div>}
                                                {ctrlCoAppIsForeignAccount && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Foreign Tax Id<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppforeignTaxId" maxLength="12" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} mask="999-999-999" value="000-000-000"
                                                                placeholder="Enter foreign tax Id" readOnly />
                                                        </div>
                                                    </div>
                                                </div>}

                                                {/* Id type for Coapplicants */}
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Select Id Type<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppIdTypeId" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} //onChange={formik.handleChange} value={formik.values.coAppIdTypeId}
                                                                value={ctrlCoAppIdType} onChange={(e) => handleCoAppIdTypeChange(e)}>
                                                                <option value="">Select Id Type</option>
                                                                {coAppIdTypeList && coAppIdTypeList.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppIdTypeId && formik.errors.coAppIdTypeId ? (
                                                                <p className="error">{formik.errors.coAppIdTypeId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Number<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppIdNumber" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppIdNumber}
                                                                maxLength="17" placeholder="Enter Id Number" />
                                                            {formik.touched.coAppIdNumber && formik.errors.coAppIdNumber ? (
                                                                <p className="error">{formik.errors.coAppIdNumber}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Issue Date<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="date" format="YYYY-MM-DD" className="inputForm" name="coAppIdIssueDate" disabled={ctrlDisable}
                                                                defaultValue={moment.utc(applicantList && applicantList[1]?.issueDate).format('YYYY-MM-DD')}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} min={moment().subtract(idIssueYears, 'years').format('YYYY-MM-DD')}
                                                                value={formik.values.coAppIdIssueDate} max={moment().format("YYYY-MM-DD")} />
                                                            {formik.touched.coAppIdIssueDate && formik.errors.coAppIdIssueDate ? (
                                                                <p className="error">{formik.errors.coAppIdIssueDate}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Expiration Date<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="date" format="YYYY-MM-DD" className="inputForm" name="coAppIdExpireDate" disabled={ctrlDisable}
                                                                defaultValue={moment.utc(applicantList && applicantList[1]?.expirationDate).format('YYYY-MM-DD')}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                value={formik.values.coAppIdExpireDate} min={moment().format("YYYY-MM-DD")} />
                                                            {formik.touched.coAppIdExpireDate && formik.errors.coAppIdExpireDate ? (
                                                                <p className="error">{formik.errors.coAppIdExpireDate}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                {showCoAppIssuanceState && <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Id Issuance State<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppIdIssuanceState" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                value={formik.values.coAppIdIssuanceState} disabled={ctrlDisable}>
                                                                <option value="">Select State</option>
                                                                {coAppIssuanceStates && coAppIssuanceStates.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppIdIssuanceState && formik.errors.coAppIdIssuanceState ? (
                                                                <p className="error">{formik.errors.coAppIdIssuanceState}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {isJointAccount &&
                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">Co-Applicant Physical Address</h2>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className='row'>
                                                <div className='col-lg-8'>
                                                    <div className="form-check float-left pb-4">
                                                        <input className="form-check-input"
                                                            onClick={(e) => toggleIsSameAsPrimaryApplicant(e.target.checked)} checked={showIsSamePrimaryApp ? true : false}
                                                            type="checkbox" disabled={ctrlDisable} onBlur={formik.handleBlur}
                                                            value={formik.values.isSameAsPrimaryApplicant} name="cbIsSameAsPrimaryApplicant" />
                                                        <label className="form-check-label">Is same as primary applicant physical address?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 1<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppPhysicalAddress" disabled={ctrlDisable}
                                                                maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppPhysicalAddress}
                                                                placeholder="Enter Address 1" />
                                                            {formik.touched.coAppPhysicalAddress && formik.errors.coAppPhysicalAddress ? (
                                                                <p className="error">{formik.errors.coAppPhysicalAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppPhysicalAddress2" disabled={ctrlDisable}
                                                                maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppPhysicalAddress2}
                                                                placeholder="Enter Address 2" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppCountryId" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} value={formik.values.coAppCountryId}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("coAppCountryId", e.target.value);
                                                                    handleCoAppContactCountryChange(e);
                                                                }}>
                                                                {countryList && countryList.map((option) => (
                                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppCountryId && formik.errors.coAppCountryId ? (
                                                                <p className="error">{formik.errors.coAppCountryId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select className="form-select" name="coAppStateId" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                value={formik.values.coAppStateId} disabled={ctrlDisable}>
                                                                <option value="">Select State</option>
                                                                {coAppContactStates && coAppContactStates.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppStateId && formik.errors.coAppStateId ? (
                                                                <p className="error">{formik.errors.coAppStateId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppCity" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppCity}
                                                                maxLength="50" placeholder="City" />
                                                            {formik.touched.coAppCity && formik.errors.coAppCity ? (
                                                                <p className="error">{formik.errors.coAppCity}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppPostalCode" disabled={ctrlDisable}
                                                                maxLength="10" placeholder="Enter Postal Code" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppPostalCode} />
                                                            {formik.touched.coAppPostalCode && formik.errors.coAppPostalCode ? (
                                                                <p className="error">{formik.errors.coAppPostalCode}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Mailing Address</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="form-check float-left pb-5">
                                                    <input className="form-check-input"
                                                        onClick={(e) => toggleMailingAddressForm(e.target.checked)} type="checkbox" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.isSameAddress}
                                                        checked={showMailing ? true : false}
                                                        id="cbMailingAddress" name="cbMailingAddress" />
                                                    <label className="form-check-label">Would you like to add a different mailing address?</label>
                                                </div>
                                            </div>
                                        </div>
                                        {showMailing && <div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mailing Address 1<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" name="mailingAddress" className="inputForm" disabled={ctrlDisable}
                                                                placeholder="Enter Mailing Address 1" maxLength="250"
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mailingAddress} />
                                                            {formik.touched.mailingAddress && formik.errors.mailingAddress ? (
                                                                <p className="error">{formik.errors.mailingAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mailing Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" name="mailingAddress2" className="inputForm" disabled={ctrlDisable}
                                                                placeholder="Enter Mailing Address 2" maxLength="250"
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mailingAddress2} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="mailingCountryId" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} value={formik.values.mailingCountryId}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("mailingCountryId", e.target.value);
                                                                    formik.setFieldValue('mailingState', '')
                                                                    handleMailingCountryChange(e);
                                                                }}>
                                                                {countryList && countryList.map((option) => (
                                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.mailingCountryId && formik.errors.mailingCountryId ? (
                                                                <p className="error">{formik.errors.mailingCountryId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="mailingState" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mailingState}>
                                                                <option value={''}>Select state</option>
                                                                {mailingStates && mailingStates.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.mailingState && formik.errors.mailingState ? (
                                                                <p className="error">{formik.errors.mailingState}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="mailingCity" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mailingCity}
                                                                placeholder="Enter City" />
                                                            {formik.touched.mailingCity && formik.errors.mailingCity ? (
                                                                <p className="error">{formik.errors.mailingCity}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="mailingZipCode" disabled={ctrlDisable}
                                                                maxLength="10" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.mailingZipCode}
                                                                placeholder="Enter Postal Code" />
                                                            {formik.touched.mailingCity && formik.errors.mailingZipCode ? (
                                                                <p className="error">{formik.errors.mailingZipCode}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Trusted Contact</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-check float-left pb-5">
                                                    <input className="form-check-input" onClick={(e) => toggleTrustedContactForm(e.target.checked)} type="checkbox"
                                                        onBlur={formik.handleBlur} value={formik.values.isTrustedContact} checked={showTrusted ? true : false} disabled={ctrlDisable}
                                                        id="cbtrustedContact" name="cbtrustedContact" />
                                                    <label className="form-check-label">Would you like to add trusted contact?</label>
                                                </div>
                                            </div>
                                        </div>
                                        {showTrusted && <div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">First Name<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" name="trustedFirstName" className="inputForm" placeholder="Enter First Name" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedFirstName} />
                                                            {formik.touched.trustedFirstName && formik.errors.trustedFirstName ? (
                                                                <p className="error">{formik.errors.trustedFirstName}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Last Name<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" name="trustedLastName" className="inputForm" placeholder="Enter Last Name" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedLastName} />
                                                            {formik.touched.trustedLastName && formik.errors.trustedLastName ? (
                                                                <p className="error">{formik.errors.trustedLastName}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Telephone Number<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <InputMask type="text" name="trustedTelephone" className="inputForm" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedTelephone}
                                                                mask="999-999-9999-9999" placeholder="Enter Phone Number" />
                                                            {formik.touched.trustedTelephone && formik.errors.trustedTelephone ? (
                                                                <p className="error">{formik.errors.trustedTelephone}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Email Address<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="email" className="inputForm" name="trustedEmailAddress" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedEmailAddress}
                                                                placeholder="Enter an email address" />
                                                            {formik.touched.trustedEmailAddress && formik.errors.trustedEmailAddress ? (
                                                                <p className="error">{formik.errors.trustedEmailAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mailing Address 1<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="trustedMailingAddress" disabled={ctrlDisable}
                                                                maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedMailingAddress}
                                                                placeholder="Enter Trusted Mailing Address 1" />
                                                            {formik.touched.trustedMailingAddress && formik.errors.trustedMailingAddress ? (
                                                                <p className="error">{formik.errors.trustedMailingAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Mailing Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="trustedMailingAddress2" disabled={ctrlDisable}
                                                                maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedMailingAddress2}
                                                                placeholder="Enter Trusted Mailing Address 2" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="trustedCountry" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} value={formik.values.trustedCountry}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("trustedCountry", e.target.value);
                                                                    formik.setFieldValue('trustedState', '')
                                                                    handleTrustedCountryChange(e);
                                                                }}>
                                                                {countryList && countryList.map((option) => (
                                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.trustedCountry && formik.errors.trustedCountry ? (
                                                                <p className="error">{formik.errors.trustedCountry}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="trustedState" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedState}>
                                                                <option value="">Select State</option>
                                                                {trustedStates && trustedStates.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.trustedState && formik.errors.trustedState ? (
                                                                <p className="error">{formik.errors.trustedState}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="trustedCity" disabled={ctrlDisable}
                                                                maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedCity}
                                                                placeholder="Enter City" />
                                                            {formik.touched.trustedCity && formik.errors.trustedCity ? (
                                                                <p className="error">{formik.errors.trustedCity}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="trustedPostalCode" disabled={ctrlDisable}
                                                                maxLength="10" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.trustedPostalCode}
                                                                placeholder="Enter Postal Code" />
                                                            {formik.touched.trustedPostalCode && formik.errors.trustedPostalCode ? (
                                                                <p className="error">{formik.errors.trustedPostalCode}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            {isRetirementAccountType && <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">IRA Account Information</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        {isTraditionalIRA && <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="mb-3">For beneficial traditional IRA accounts, Enter name of beneficiary</label>
                                                    <div className="input_area">
                                                        <input type="text" name="traditionalIRAName" className="inputForm" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.traditionalIRAName} />
                                                        {/* {formik.touched.traditionalIRAName && formik.errors.traditionalIRAName ? (
                                                                <p className="error">{formik.errors.traditionalIRAName}</p>
                                                            ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {isSimpleIRAParticipant && <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="mb-3">For beneficial traditional IRA accounts, Enter name of beneficiary</label>
                                                    <div className="input_area">
                                                        <input type="text" name="traditionalIRAName" className="inputForm" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.traditionalIRAName} />
                                                        {/* {formik.touched.traditionalIRAName && formik.errors.traditionalIRAName ? (
                                                                <p className="error">{formik.errors.traditionalIRAName}</p>
                                                            ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {isBenRothIRA && <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="mb-3">For beneficial traditional IRA accounts, Enter name of beneficiary</label>
                                                    <div className="input_area">
                                                        <input type="text" name="traditionalIRAName" className="inputForm" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.traditionalIRAName} />
                                                        {/* {formik.touched.traditionalIRAName && formik.errors.traditionalIRAName ? (
                                                                <p className="error">{formik.errors.traditionalIRAName}</p>
                                                            ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="form-label mb-2">Depositor Authorization (All Accounts)</label><br />
                                                    <label className="mt-2 mb-3">I understand that I have the right to direct the investment and reinvestment of contributions
                                                        to my account and hereby appoint the following brokerage firm as my agent to execute my directions, as broker under the
                                                        terms of custodial agreement</label>
                                                    <div className="input_area">
                                                        <input type="text" name="depositorAuthorization" className="inputForm" disabled={ctrlDisable}
                                                            maxLength="250" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.depositorAuthorization} />
                                                        {/* {formik.touched.depositorAuthorization && formik.errors.depositorAuthorization ? (
                                                                <p className="error">{formik.errors.depositorAuthorization}</p>
                                                            ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}
                        </div>
                        {/* </PerfectScrollbar> */}
                    </div>

                    <ul className="btnWrapper">
                        <li><button type='submit' onClick={() => setSaveOnly(false)} className='btn-primary' disabled={ctrlDisable}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' onClick={() => setSaveOnly(true)} className='btn-primary' disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );

};

export default Personal;