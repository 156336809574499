import ChangePasswordComponent from "../../components/shared/ChangePasswordComponent";
import Header from "../../layouts/header/header";
import LeftNav from "../../layouts/leftnav/leftnav";
import { Link } from "react-router-dom";

const ChangePassword = () => {
    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Change Password</li>
                        </ol>
                    </nav>
                    <ChangePasswordComponent />
                </section>
            </section>
        </>
    );
}

export default ChangePassword;