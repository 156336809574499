import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import { checkAuthorization, convertUTCToLocalTime, getCurrentApplicationId, getLoggedInUserInfo, groupByArray, toTitleCase } from '../../common/common';
import { useNavigate } from 'react-router';
import moment from 'moment';
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import applicationService from '../../services/applicationservice';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OLAEnums from '../../helpers/olaenums';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import GiactResponseComponent from './GiactResponseComponent';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import IndIRADocumentsComponent from './IndIRADocumentsComponent';
import EntityDocumentsComponent from './EntityDocumentsComponent';

const ApplicationSummaryComponent = () => {

    const navigate = useNavigate();
    const [applicationUserInfo, setApplicationUserInfo] = useState(null);
    const [applicationEntityInfo, setApplicationEntityInfo] = useState(null);
    const [applicationAlerts, setApplicationAlerts] = useState(null);
    const [applicationAssignmentLogs, setApplicationAssignmentLogs] = useState(null);
    const [applicationDocuments, setApplicationDocuments] = useState(null);
    const [applicationStatusLogs, setApplicationStatusLogs] = useState(null);
    const [mbverificationInfo, setMBVerificationInfo] = useState(null);
    const [mbapiLogs, setMBApiLogs] = useState(null);
    const [giactverificationInfo, setGiactVerificationInfo] = useState(null);
    const [giactApiLogs, setGiactApiLogs] = useState(null);
    const [fisVerificationLogs, setFISVerificationLogs] = useState(null);

    const [key, setKey] = useState('alerts');

    const [allAppAlerts, setAllAppAlerts] = useState(null);
    const [allAppStatusLogs, setAllAppStatusLogs] = useState(null);
    const [allAppAssignLogs, setAllAppAssignLogs] = useState(null);
    const [selectedMBLog, setSelectedMBLog] = useState(null);
    const [selectedMBLogTitle, setSelectedMBLogTitle] = useState(null);
    const [selectedReqObj, setSelectedReqObj] = useState(null);
    const [selectedResObj, setSelectedResObj] = useState(null);
    const [mbPopupShow, setMBPopupShow] = useState(false);
    const [addressNameShow, setAddressNameShow] = useState(false);
    const [phoneNameShow, setPhoneNameShow] = useState(false);
    const [phoneAddressShow, setPhoneAddressShow] = useState(false);
    const [ssnNameVerifyShow, setSSNNameVerifyShow] = useState(false);
    const [ssnAddressVerifyShow, setSSNAddressVerifyShow] = useState(false);
    const [ssnPhoneVerifyShow, setSSNPhoneVerifyShow] = useState(false);
    const [ssnVerifyShow, setSSNVerifyShow] = useState(false);
    const [deathMasterVerifyShow, setDeathMasterVerifyShow] = useState(false);
    const [addressStandardShow, setAddressStandardShow] = useState(false);
    const [ipAddressVerifyShow, setIPAddressVerifyShow] = useState(false);
    const [emailVerifyShow, setEmailVerifyShow] = useState(false);
    const [wlsearchVerifyShow, setWLSearchVerifyShow] = useState(false);
    const [idVerifyShow, setIdVerifyShow] = useState(false);
    const [mlaVerifyShow, setMLAVerifyShow] = useState(false);
    const [abaAccountVerifyShow, setABAAccountVerifyShow] = useState(false);
    const [achCheckPrescreenLiteShow, setACHCHeckPreScreenLiteShow] = useState(false);
    const [achAndCheckPrescreenShow, setACHAndCheckPrescreenShow] = useState(false);
    const [extendedACHCheckPrescreenShow, setExtendedACHPrescreenShow] = useState(false);
    //Giact
    const [selectedGiactLog, setSelectedGiactLog] = useState(null);
    const [selectedGiactLogTitle, setSelectedGiactLogTitle] = useState(null);
    const [gBankAcctPersonShow, setGBankAcctPersonShow] = useState(false);
    const [gOfacPersonShow, setGOfacPersonShow] = useState(false);
    const [gEsiShow, setGEsiShow] = useState(false);
    const [gIdentifyPersonShow, setGIdentifyPersonShow] = useState(false);
    const [gBankAcctBusinessShow, setGBankAcctBusinessShow] = useState(false);
    const [gOfacBusinessShow, setGOfacBusinessShow] = useState(false);
    const [gIdentifyBusinessShow, setGIdentifyBusinessShow] = useState(false);
    const [isEntityAccount, setIsEntityAccount] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isBroker, setIsBroker] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isRR, setIsRR] = useState(false);

    const handleMBPopupClose = () => setMBPopupShow(false);

    const styleLeft = {
        float: 'left'
    }

    const styleLink = {
        cursor: 'pointer'
    }

    const docLink = {
        cursor: 'pointer',
        color: 'blue'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        checkRole();
        getApplicationSummary();

        const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
        if (getActiveTab) {
            setKey(getActiveTab);
        }

    }, []);

    useEffect(() => {
        localStorage.setItem("activeTab", JSON.stringify(key));
    }, [key]);

    const getApplicationSummary = () => {
        loader.show();
        let data = {
            ApplicationId: getCurrentApplicationId()
        };
        applicationService.getApplicationSummary(data)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    if (result?.applicationUserInfo !== undefined || result?.applicationUserInfo !== null) {
                        setApplicationUserInfo(result?.applicationUserInfo);
                        if (result?.applicationUserInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                            setIsEntityAccount(true);
                        }
                        else { setIsEntityAccount(false); }
                    }
                    if (result?.applicationEntityInfo !== undefined || result?.applicationEntityInfo !== null) {
                        setApplicationEntityInfo(result?.applicationEntityInfo);
                        if (result?.applicationEntityInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                            setIsEntityAccount(true);
                        }
                        else { setIsEntityAccount(false); }
                    }
                    if (result?.applicationAlerts !== null && result?.applicationAlerts?.length > 0) {
                        setApplicationAlerts(result?.applicationAlerts);
                        setAllAppAlerts(result?.applicationAlerts);
                    }
                    if (result?.applicationAssignmentLogs !== null && result?.applicationAssignmentLogs?.length > 0) {
                        setApplicationAssignmentLogs(result?.applicationAssignmentLogs);
                        setAllAppAssignLogs(result?.applicationAssignmentLogs);
                    }
                    if (result?.applicationStatusLogs !== null && result?.applicationStatusLogs.length > 0) {
                        setApplicationStatusLogs(result?.applicationStatusLogs);
                        setAllAppStatusLogs(result?.applicationStatusLogs);
                    }
                    if (result?.applicationDocuments !== null && result?.applicationDocuments?.length > 0) {
                        setApplicationDocuments(result?.applicationDocuments);
                    }
                    //Microbilt
                    if (result?.microbiltVerificationInfo !== null) {
                        setMBVerificationInfo(result?.microbiltVerificationInfo);
                    }
                    if (result?.microbiltAPILogs !== null && result?.microbiltAPILogs?.length > 0) {
                        setMBApiLogs(result?.microbiltAPILogs);
                    }
                    //Veriff
                    // if (result?.veriffLogInfos !== null && result?.veriffLogInfos?.length > 0) {
                    //     setAppVerifflogs(result?.veriffLogInfos);
                    // }
                    //Giact
                    if (result?.giactVerificationInfo !== null) {
                        setGiactVerificationInfo(result?.giactVerificationInfo);
                    }
                    if (result?.giactAPILogs !== null && result?.giactAPILogs?.length > 0) {
                        setGiactApiLogs(result?.giactAPILogs);
                    }
                    //FIS
                    if (result?.fisVerificationLogs !== null && result?.fisVerificationLogs?.length > 0) {
                        setFISVerificationLogs(result?.fisVerificationLogs);
                    }

                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const checkRole = () => {
        var role = getLoggedInUserInfo().userRole;
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        else if (role === 'Broker') {
            setIsBroker(true);
        }
        else if (role === 'Supervisor24' || role === 'Supervisor4') {
            setIsSupervisor(true);
        }
        else if (role === 'RR') {
            setIsRR(true);
        }
    }

    /*Tabs searching starts here*/
    const handleFirstTabSearch = (str) => {
        setApplicationAlerts(allAppAlerts);
        if (str.length === 0) {
            setApplicationAlerts(allAppAlerts);
        }
        else {
            let search = applicationAlerts && applicationAlerts.filter(x => x.subject.toLowerCase().match(str.toLowerCase())
                || x.description.toLowerCase().match(str.toLowerCase()));
            setApplicationAlerts(search);
        }
    }
    const handleSecondTabSearch = (str) => {
        setApplicationStatusLogs(allAppStatusLogs);
        if (str.length === 0) {
            setApplicationStatusLogs(allAppStatusLogs);
        }
        else {
            let search = applicationStatusLogs && applicationStatusLogs.filter(x => x.applicationStatusName.toLowerCase().match(str.toLowerCase())
                || x.comment.toLowerCase().match(str.toLowerCase()));
            setApplicationStatusLogs(search);
        }
    }
    const handleThirdTabSearch = (str) => {
        setApplicationAssignmentLogs(allAppAssignLogs);
        if (str.length === 0) {
            setApplicationAssignmentLogs(allAppAssignLogs);
        }
        else {
            let search = applicationAssignmentLogs && applicationAssignmentLogs.filter(x => x.assignFromName.toLowerCase().match(str.toLowerCase())
                || x.assignToName.toLowerCase().match(str.toLowerCase()));
            setApplicationAssignmentLogs(search);
        }
    }
    /*Tabs searching ends here*/

    const displayMicrobiltLog = (appId, apiType) => {
        let selectedLog = mbapiLogs.filter(x => x.applicationId === appId && x.mbapiTypeId === apiType);
        setSelectedMBLog(selectedLog);
        setSelectedReqObj(JSON.parse(selectedLog[0].requestObject));
        setSelectedResObj(JSON.parse(selectedLog[0].responseObject));
        setMBPopupShow(true);

        if (apiType === OLAEnums.MicrobiltAPIEnum.AddressNameVerify) {
            setAddressNameShow(true);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.PhoneNameVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(true);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.PhoneAddressVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(true);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNNameVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(true);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNAddressVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(true);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNPhoneVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(true);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(true);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.DeathMasterVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(true);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.AddressStandardVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(true);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.IPAddressVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(true);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.EmailVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(true);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.WLSearchVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(true);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.IDVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(true);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.MLAVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(true);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ABAAccountVerify) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(true);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ACHCheckPrescreenLite) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(true);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ACHAndCheckPrescreen) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(true);
            setExtendedACHPrescreenShow(false);
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ExtendedACHCheckPrescreen) {
            setAddressNameShow(false);
            setPhoneNameShow(false);
            setPhoneAddressShow(false);
            setSSNNameVerifyShow(false);
            setSSNAddressVerifyShow(false);
            setSSNPhoneVerifyShow(false);
            setSSNVerifyShow(false);
            setDeathMasterVerifyShow(false);
            setAddressStandardShow(false);
            setIPAddressVerifyShow(false);
            setEmailVerifyShow(false);
            setWLSearchVerifyShow(false);
            setIdVerifyShow(false);
            setMLAVerifyShow(false);
            setABAAccountVerifyShow(false);
            setACHCHeckPreScreenLiteShow(false);
            setACHAndCheckPrescreenShow(false);
            setExtendedACHPrescreenShow(true);
        }
        setMBLogTitle(apiType);
    }
    const setMBLogTitle = (apiType) => {
        if (apiType === OLAEnums.MicrobiltAPIEnum.AddressNameVerify) {
            setSelectedMBLogTitle("Address-Name Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.PhoneNameVerify) {
            setSelectedMBLogTitle("Phone-Name Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.PhoneAddressVerify) {
            setSelectedMBLogTitle("Phone-Address Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNNameVerify) {
            setSelectedMBLogTitle("SSN-Name Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNAddressVerify) {
            setSelectedMBLogTitle("SSN-Address Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNPhoneVerify) {
            setSelectedMBLogTitle("SSN-Phone Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.SSNVerify) {
            setSelectedMBLogTitle("SSN Validation");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.DeathMasterVerify) {
            setSelectedMBLogTitle("Death Master File Validation");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.IPAddressVerify) {
            setSelectedMBLogTitle("IP Address Validation");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.EmailVerify) {
            setSelectedMBLogTitle("Email Validation Service");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.WLSearchVerify) {
            setSelectedMBLogTitle("Watchlist Search");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.IDVerify) {
            setSelectedMBLogTitle("ID Verify");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.MLAVerify) {
            setSelectedMBLogTitle("MLA Verify");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.AddressStandardVerify) {
            setSelectedMBLogTitle("Address Validation & Standardization");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ABAAccountVerify) {
            setSelectedMBLogTitle("ABA & Account Schema Verification");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ACHCheckPrescreenLite) {
            setSelectedMBLogTitle("ACH & Check Prescreen Lite");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ACHAndCheckPrescreen) {
            setSelectedMBLogTitle("ACH & Check Prescreen");
        }
        else if (apiType === OLAEnums.MicrobiltAPIEnum.ExtendedACHCheckPrescreen) {
            setSelectedMBLogTitle("Extended ACH & Check Prescreen");
        }
        else {
            setSelectedMBLogTitle("");
        }
    }

    const displayGiactLog = (appId, apiType) => {
        let selectedLog = giactApiLogs.filter(x => x.applicationId === appId && x.giactApiType === apiType);
        setSelectedGiactLog(selectedLog);
        setSelectedReqObj(JSON.parse(selectedLog[0].requestObject));
        setSelectedResObj(JSON.parse(selectedLog[0].responseObject));
        setMBPopupShow(true);

        if (apiType === OLAEnums.GiactAPIEnum.BankAccountVerifyPerson) {
            setGBankAcctPersonShow(true);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(false);
            setGEsiShow(false);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.BankAccountVerifyBusiness) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(true);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(false);
            setGEsiShow(false);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GOfacPerson) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(true);
            setGOfacBusinessShow(false);
            setGEsiShow(false);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GOfacBusiness) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(true);
            setGEsiShow(false);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.ESI) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(false);
            setGEsiShow(true);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GIdentifyPerson) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(false);
            setGEsiShow(false);
            setGIdentifyPersonShow(true);
            setGIdentifyBusinessShow(false);
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GIdentifyBusiness) {
            setGBankAcctPersonShow(false);
            setGBankAcctBusinessShow(false);
            setGOfacPersonShow(false);
            setGOfacBusinessShow(false);
            setGEsiShow(false);
            setGIdentifyPersonShow(false);
            setGIdentifyBusinessShow(true);
        }
        setGiactLogTitle(apiType);
    }
    const setGiactLogTitle = (apiType) => {
        if (apiType === OLAEnums.GiactAPIEnum.BankAccountVerifyPerson || apiType === OLAEnums.GiactAPIEnum.BankAccountVerifyBusiness) {
            setSelectedGiactLogTitle("Bank Account Verification & Authentication");
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GOfacPerson || apiType === OLAEnums.GiactAPIEnum.GOfacBusiness) {
            setSelectedGiactLogTitle("gOfac Verification");
        }
        else if (apiType === OLAEnums.GiactAPIEnum.GIdentifyPerson || apiType === OLAEnums.GiactAPIEnum.GIdentifyBusiness) {
            setSelectedGiactLogTitle("gIdentify Verification");
        }
        else if (apiType === OLAEnums.GiactAPIEnum.ESI) {
            setSelectedGiactLogTitle("ESI Verification");
        }
        else {
            setSelectedGiactLogTitle("");
        }
    }

    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const redirectToLogfromFIS = (uuId) => {
        if (isAdmin) {
            navigate('/admin/logfromfis/' + uuId);
        }
        else if (isBroker) {
            navigate('/broker/logfromfis/' + uuId);
        }
        else if (isSupervisor) {
            navigate('/supervisor/logfromfis/' + uuId);
        }
        else {
            navigate('/registerrep/logfromfis/' + uuId);
        }
    }

    const CustomHeader = ({ title }) => (
        <div style={{ textAlign: 'center' }}>
            {title.split(' ').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </div>
    );

    //FIS data
    const columns = [
        {
            name: <CustomHeader title="Application Id" />,
            selector: row => (
                row.applicationId === null || row.applicationId === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.applicationId}</div>
            ),
            sortable: true,
            width: "120px"
        },
        {
            name: <CustomHeader title="Function Mode" />,
            selector: row => row.functionMode,
            sortable: true,
            width: '120px'
        },
        {
            name: <CustomHeader title="Screen Name" />,
            selector: row => row.screenName,
            sortable: true,
            width: '160px'
        },
        {
            name: <CustomHeader title="Account Number" />,
            selector: row => row.accountNumber,
            sortable: true,
            width: '150px'
        },
        {
            name: <CustomHeader title="Error String" />,
            selector: row => <div className='paddingLeft-15'>{row.errorString}</div>,
            sortable: true,
            width: '350px'
        },
        {
            name: <CustomHeader title="Return Value" />,
            selector: row => row.returnValue,
            sortable: true,
            width: '100px'
        },
        {
            name: <CustomHeader title="Number of Errors" />,
            selector: row => (row.noOfErrors),
            sortable: true,
            width: '100px'
        },
        {
            name: <CustomHeader title="Response DateTime" />,
            selector: row => row.responseDateTime,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => redirectToLogfromFIS(row.uuId)}>Log from FIS</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    {isAdmin && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/applications">Applications</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Application Summary</li>
                        </ol>
                    </nav>}
                    {isBroker && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Application Summary</li>
                        </ol>
                    </nav>}
                    {isRR && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/registerrep/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Application Summary</li>
                        </ol>
                    </nav>}
                    {isSupervisor && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/supervisor/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Application Summary</li>
                        </ol>
                    </nav>}

                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Application Summary</h2>
                        </div>
                    </div>
                    <div className="tab-content" id="reviews">
                        <div className="formContainer no-mar">
                            <div id="personal" className="container-fluid">
                                {applicationUserInfo && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">First Name</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.firstName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Last Name</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.lastName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Email Address</label>
                                                        <div className="input_area">
                                                            <a href={"mailto:" + applicationUserInfo?.emailAddress} target="_blank">{applicationUserInfo?.emailAddress}</a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Application Status</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.applicationStatusName}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Home Phone Number</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.homePhoneNumber}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Work Phone Number</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.workPhoneNumber}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Physical Address</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.physicalAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">City</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.city}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">State</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.stateName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Zip Code</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.zipCode}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Country</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.countryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Date of Birth</label>
                                                        <div className="input_area">
                                                            {moment(convertUTCToLocalTime(applicationUserInfo?.dateOfBirth)).format("DD MMM, YYYY")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Account Number</label>
                                                        <div className="input_area">
                                                            {applicationUserInfo?.accountNumber !== null ? applicationUserInfo?.accountNumber : "-"}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>}

                                {applicationEntityInfo && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Entity Name</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.entityName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">SSNEIN</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.ssnein}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Email Address</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.emailAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Application Status</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.applicationStatusName}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Business Phone Number</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.businessPhone}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Business Address</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.address}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">City</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.city}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">State</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.stateName}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Country</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.countryName}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Date of Birth</label>
                                                        <div className="input_area">
                                                            {moment.utc(convertUTCToLocalTime(applicationEntityInfo?.dateOfBirth)).format("DD MMM, YYYY")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="input_wrapper">
                                                        <label className="profile-label">Account Number</label>
                                                        <div className="input_area">
                                                            {applicationEntityInfo?.accountNumber !== null ? applicationEntityInfo?.accountNumber : "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className='formCard'>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <Tabs
                                                defaultActiveKey="alerts" activeKey={key} onSelect={(k) => setKey(k)}
                                                transition={false}
                                                id="noanim-tab-example"
                                                className="mb-3">
                                                <Tab eventKey="alerts" title="Application Alerts">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="input_wrapper pad-10">
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" placeholder="Search application alerts" name="searchtab1"
                                                                        onChange={(e) => handleFirstTabSearch(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {applicationAlerts !== null && applicationAlerts.map((alert) => (
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='modal-body'>
                                                                                {/* <p className='lending-date-text'>{moment.utc(alert?.createdDate).format("DD MMMM, YYYY")}</p> */}
                                                                                <p className='lending-date-text'>{moment(convertUTCToLocalTime(alert?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                                <p className='tab-paragraph'>{alert?.subject}<br />
                                                                                    <span className='tab-paragraph-bold'>{alert?.description}</span></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            {/* <p className='tab-paragraph-italicbold float-right'>@{moment.utc(alert?.createdDate).format("hh:mm a")} by {alert?.createdByUserName}</p> */}
                                                                            <p className='tab-paragraph-italicbold float-right'>@{moment(convertUTCToLocalTime(alert?.createdDate)).format("hh:mm a")} by {alert?.createdByUserName}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {applicationAlerts === null && <div className='row'>
                                                                <p className='tab-paragraph'>No record found</p>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="statuslogs" title="Application Status Logs">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="input_wrapper pad-10">
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" placeholder="Search application status logs" name="searchtab2"
                                                                        onChange={(e) => handleSecondTabSearch(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {applicationStatusLogs !== null && applicationStatusLogs.map((log) => (
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='modal-body'>
                                                                                <p className='lending-date-text'>{moment(convertUTCToLocalTime(log?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                                <p className='tab-paragraph'>{log?.applicationStatusName}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <p className='tab-paragraph-italicbold float-right'>@{moment(convertUTCToLocalTime(alert?.createdDate)).format("hh:mm a")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {applicationStatusLogs === null && <div className='row'>
                                                        <p className='tab-paragraph'>No record found</p>
                                                    </div>}
                                                </Tab>
                                                <Tab eventKey="assignmentlogs" title="Application Assignment Logs">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="input_wrapper pad-10">
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" placeholder="Search application assignment logs" name="searchtab3"
                                                                        onChange={(e) => handleThirdTabSearch(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {applicationAssignmentLogs && applicationAssignmentLogs.map((assign) => (
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='modal-body'>
                                                                                <p className='lending-date-text'>{moment(convertUTCToLocalTime(assign?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                                <p className='tab-paragraph'>Assign from <span className='tab-bold-text'>{assign?.assignFromName}</span> to <span className='tab-bold-text'>{assign?.assignToName}</span></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <p className='tab-paragraph-italicbold float-right'>@{moment(convertUTCToLocalTime(assign?.createdDate)).format("hh:mm a")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {applicationAssignmentLogs === null && <div className='row'>
                                                        <p className='tab-paragraph'>No record found</p>
                                                    </div>}
                                                </Tab>
                                                <Tab eventKey="uploadedfiles" title="Uploaded Files">
                                                    <><div className='row'>
                                                        <div className='col-lg-12'>
                                                            {applicationDocuments && applicationDocuments.map((doc) => (
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='modal-body'>
                                                                                <p className='lending-date-text'>{moment(convertUTCToLocalTime(doc?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                                <p className='tab-paragraph'><span style={docLink} onClick={(e) => fileDownloader(doc.documentPath)}>{doc?.name}</span></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <p className='tab-paragraph-italicbold float-right'>@{moment(convertUTCToLocalTime(doc?.createdDate)).format("hh:mm a")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                        {applicationDocuments === null && <div className='row'>
                                                            <p className='tab-paragraph'>No record found</p>
                                                        </div>}</>
                                                    {/* {!isRR && <><div className='row'>
                                                        <div className='col-lg-12'>
                                                            {applicationDocuments && applicationDocuments.map((doc) => (
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='modal-body'>
                                                                                <p className='lending-date-text'>{moment(convertUTCToLocalTime(doc?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                                <p className='tab-paragraph'><span style={docLink} onClick={(e) => fileDownloader(doc.documentPath)}>{doc?.name}</span></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <p className='tab-paragraph-italicbold float-right'>@{moment(convertUTCToLocalTime(doc?.createdDate)).format("hh:mm a")}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                        {applicationDocuments === null && <div className='row'>
                                                            <p className='tab-paragraph'>No record found</p>
                                                        </div>}</>}
                                                    {isRR && <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {!isEntityAccount && applicationDocuments && <IndIRADocumentsComponent applicationDocuments={applicationDocuments} />}
                                                            {isEntityAccount && applicationDocuments && <EntityDocumentsComponent applicationDocuments={applicationDocuments} />}
                                                        </div>
                                                    </div>} */}
                                                </Tab>
                                                {/* <Tab eventKey="mbVerification" title="Microbilt Verifications">
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {mbverificationInfo && <div className='row'>
                                                                <div className='col-lg-8'>
                                                                    <div className='modal-body'>
                                                                        <p className='lending-date-text'>{moment.utc(mbverificationInfo?.createdDate).format("DD MMMM, YYYY")}</p>
                                                                        <p className='lending-date-text'>&nbsp;</p>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="row">
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.AddressNameVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Address-Name Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.addressNameVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.PhoneNameVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Phone-Name Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.phoneNameVerify ? "Valid" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.PhoneAddressVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Phone-Address Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.phoneAddressVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.SSNNameVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">SSN-Name Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.ssnNameVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.SSNAddressVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">SSN-Address Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.ssnAddressVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.SSNPhoneVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">SSN-Phone Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.ssnPhoneVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.SSNVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">SSN Validation</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.ssnVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.DeathMasterVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Death Master File Validation</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.deathMasterVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.IPAddressVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">IP Address Validation</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.ipAddressVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.EmailVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Email Validation Service</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.emailVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.WLSearchVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Watchlist Search</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.wlSearchVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.IDVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">ID Verify</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.idVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.MLAVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">MLA Verify</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.mlaVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.AddressStandardVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Address Validation & Standardization</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.addressStandardVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.ABAAccountVerify)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">ABA & Account Schema Verification</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.abaAccountVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.ACHCheckPrescreenLite)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">ACH & Check Prescreen Lite</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.achCheckPrescreenLite ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.ACHAndCheckPrescreen)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">ACH & Check Prescreen</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.achAndCheckPrescreen ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayMicrobiltLog(mbverificationInfo?.applicationId, OLAEnums.MicrobiltAPIEnum.ExtendedACHCheckPrescreen)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Extended ACH & Check Prescreen</label>
                                                                                            <div className="input_area">
                                                                                                {mbverificationInfo?.extendedACHCheckPrescreen ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {mbverificationInfo === null && <div className='row'>
                                                        <p className='tab-paragraph'>No record found</p>
                                                    </div>}
                                                </Tab> */}
                                                {/* <Tab eventKey="giactVerification" title="AML Verifications">
                                                    <div className='row'>
                                                        <div className='col-lg-12'>

                                                            {giactverificationInfo && <div className='row'>
                                                                <div className='col-lg-8'>
                                                                    {!applicationEntityInfo && <div className='modal-body'>
                                                                        <p className='lending-date-text'>{moment.utc(giactverificationInfo?.createdDate).format("DD MMMM, YYYY")}</p>
                                                                        <p className='lending-date-text'>&nbsp;</p>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="row">
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.BankAccountVerifyPerson)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Bank Account Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.bankAccountVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.GOfacPerson)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">gOfac Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.gOfacVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.ESI)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">ESI Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.esiVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.GIdentifyPerson)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">gIdentify Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.gIdentifyVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <hr />
                                                            </div>
                                                            }
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            {giactverificationInfo && <div className='row'>
                                                                <div className='col-lg-8'>
                                                                    {applicationEntityInfo && <div className='modal-body'>
                                                                        <p className='lending-date-text'>{moment(convertUTCToLocalTime(giactverificationInfo?.createdDate)).format("DD MMMM, YYYY")}</p>
                                                                        <p className='lending-date-text'>&nbsp;</p>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="row">
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.BankAccountVerifyBusiness)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">Bank Account Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.bankAccountVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.GOfacBusiness)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">gOfac Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.gOfacVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-3" style={styleLink} onClick={() => displayGiactLog(giactverificationInfo?.applicationId, OLAEnums.GiactAPIEnum.GIdentifyBusiness)}>
                                                                                        <div className="input_wrapper">
                                                                                            <label className="profile-label">gIdentify Verification</label>
                                                                                            <div className="input_area">
                                                                                                {giactverificationInfo?.gIdentifyVerify ? "Verified" : "Not Verified"}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {giactverificationInfo === null && <div className='row'>
                                                        <p className='tab-paragraph'>No record found</p>
                                                    </div>}
                                                </Tab> */}

                                                <Tab eventKey="fisLogs" title="FIS Logs">
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            {fisVerificationLogs && <DataTable responsive={true} theme="default" columns={columns} data={fisVerificationLogs} />}
                                                            {fisVerificationLogs === null && <DataTable responsive={true} columns={columns} noDataComponent="No record found" />}
                                                        </div>
                                                    </div>

                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/* <Modal show={mbPopupShow} onHide={handleMBPopupClose} backdrop="static" keyboard={false} centered size='md' aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        {selectedMBLogTitle && <h2 class="popupHeading">{selectedMBLogTitle}</h2>}
                                        {selectedMBLog && <div class="modalWrapper">
                                            <div id="accordion">

                                                {addressNameShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Address</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Addr1}, {selectedReqObj?.Address?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Zip</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Zip}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Country}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {phoneNameShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.LastName}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {phoneAddressShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Addr1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Zip</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Zip}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PhoneNumber}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {ssnNameVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.SSN}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Name?.LastName}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {ssnAddressVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.SSN}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Addr1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Zip</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Zip}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Country}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {ssnPhoneVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.SSN}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PhoneNumber}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VerificationFlag</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VerificationFlag}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>VCodeDescription</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.VerificationFlags?.VCodeDescription}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {ssnVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.SSN}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RefNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RefNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AreaNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.AreaNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>GroupNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.GroupNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StartDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{moment.utc(selectedResObj?.IssueDateRange?.StartDt).format("DD MMM, YYYY")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EndDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{moment.utc(selectedResObj?.IssueDateRange?.EndDt).format("DD MMM, YYYY")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Code</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.CodeDigit?.Code}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Description</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.CodeDigit?.Description}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSNStatus</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.SSNStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Message</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.Message}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {deathMasterVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.SSN}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DoB</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.DoB}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Deceased</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.Deceased ? "true" : "false"}</td>
                                                                    </tr>

                                                                    {selectedResObj?.Deceased && <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    }
                                                                    {selectedResObj?.Deceased && <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.MiddleName}</td>
                                                                    </tr>
                                                                    }
                                                                    {selectedResObj?.Deceased && <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    }
                                                                    {selectedResObj?.Deceased && <tr>
                                                                        <td className='border'>EffDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.EffDt}</td>
                                                                    </tr>
                                                                    }
                                                                    <tr>
                                                                        <td className='border'>InquirySSN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.InquirySSN}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>InquiryDOB</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.InquiryDOB}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DeceasedStateOnRecord</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.DeceasedStateOnRecord}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {ipAddressVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>IP</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.IP}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>IP</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.IP}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Region</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Region}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Speed</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Speed}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country_c</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Country_c}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border'>Latitude</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Latitude}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Longitude</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.Longitude}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>CountryCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.CountryCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RegionCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.RegionCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>CityCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.IpInfo?.CityCode}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {emailVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddr</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.EmailAddr}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RefNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.Request?.RefNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddr</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.Request?.EmailAddr}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailStatus</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.EmailStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailRiskLevel</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.EmailRiskLevel}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {wlsearchVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Addr1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BirthDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.BirthDt}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {idVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StreetNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StreetNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StreetName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StreetName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StreetType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StreetType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BirthDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.BirthDt}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {mlaVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StreetNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.StreetNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StreetName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.StreetName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BirthDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.BirthDt}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RefNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RefNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FileDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{moment.utc(selectedResObj?.FileDt).format("DD MMMM, YYYY")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>MiddleName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.MiddleName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Source</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PersonInfo?.PersonName?.Source}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EffDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{moment.utc(selectedResObj?.PersonInfo?.PersonName?.EffDt).format("DD MMMM, YYYY")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Message</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.Message}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {addressStandardShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Addr1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Zip</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Zip}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.Address?.Country}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Messages</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.Messages && selectedResObj?.Messages.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>MsgClass</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.MsgClass}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='border'>MsgCode</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.MsgCode}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='border'>Text</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.Text}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {abaAccountVerifyShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BankRoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankRoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BankAccountNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountNumber}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Bank OrgInfo</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.BankInfo?.OrgInfo?.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Bank Contact Info</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} className='border' style={{ wordBreak: 'break-all' }}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.BankInfo?.OrgInfo?.ContactInfo && selectedResObj?.BankInfo?.OrgInfo?.ContactInfo.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        {item?.PostAddr && <>
                                                                                            <tr>
                                                                                                <td className='border'>Addr1</td>
                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PostAddr?.Addr1}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className='border'>City</td>
                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PostAddr?.City}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className='border'>StateProv</td>
                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PostAddr?.StateProv}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td className='border'>PostalCode</td>
                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PostAddr?.PostalCode}</td>
                                                                                            </tr>
                                                                                        </>}
                                                                                        {item?.PhoneNum && <tr>
                                                                                            <td className='border'>PhoneType</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PhoneNum?.PhoneType}</td>
                                                                                        </tr>}
                                                                                        {item?.PhoneNum && <tr>
                                                                                            <td className='border'>Phone</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item?.PhoneNum?.Phone}</td>
                                                                                        </tr>}
                                                                                        {item?.Messsage !== null && <tr>
                                                                                            <td className='border'>Message</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>
                                                                                                <table className='w-100 summarytbl'>
                                                                                                    {item?.Message?.map((msg, msgKey) => {
                                                                                                        return <tbody key={msgKey}>
                                                                                                            <tr>
                                                                                                                <td className='border'>MsgClass</td>
                                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{msg.MsgClass}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border'>Text</td>
                                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{msg.Text}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    })}

                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>}
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DeptDivGrp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.BankInfo?.OrgInfo?.DeptDivGrp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DBAName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.BankInfo?.OrgInfo?.DBAName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BusType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.BankInfo?.OrgInfo?.BusType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Decision Info</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.DecisionInfo?.Decision && selectedResObj?.DecisionInfo?.Decision.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>DecisionCode</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.DecisionCode}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='border'>Text</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.Text}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='border'>EffDt</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{moment(item.EffDt).format("DD MMMM, YYYY")}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Factor</td>
                                                                    </tr>
                                                                    {selectedResObj?.DecisionInfo?.Factor !== null && <tr>
                                                                        <td className='border' colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.DecisionInfo?.Factor !== null && selectedResObj?.DecisionInfo?.Factor.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>Description</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.Description}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>}
                                                                    <tr>
                                                                        <td className='border'>NumOfBranches</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.NumOfBranches}</td>
                                                                    </tr>
                                                                    {selectedResObj?.BranchInfo !== null && <tr>
                                                                        <td className='border' colSpan={2}>Branch Info</td>
                                                                    </tr>}
                                                                    {selectedResObj?.BranchInfo !== null && <tr>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }} colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.BranchInfo !== null && selectedResObj?.BranchInfo.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>Name</td>
                                                                                            <td className='border' style={{ wordBreak: 'break-all' }}>{item.Name}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={2} className='border' style={{ wordBreak: 'break-all' }}>
                                                                                                <table className='w-100 summarytbl'>
                                                                                                    {item?.ContactInfo !== null && item?.ContactInfo.map((branch, brKey) => {
                                                                                                        return <tbody key={brKey}>
                                                                                                            {branch?.PostAddr && <>
                                                                                                                <tr>
                                                                                                                    <td className='border'>Addr1</td>
                                                                                                                    <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PostAddr?.Addr1}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className='border'>City</td>
                                                                                                                    <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PostAddr?.City}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className='border'>StateProv</td>
                                                                                                                    <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PostAddr?.StateProv}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className='border'>PostalCode</td>
                                                                                                                    <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PostAddr?.PostalCode}</td>
                                                                                                                </tr>
                                                                                                            </>}
                                                                                                            {branch?.PhoneNum && <tr>
                                                                                                                <td className='border'>PhoneType</td>
                                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PhoneNum?.PhoneType}</td>
                                                                                                            </tr>}
                                                                                                            {branch?.PhoneNum && <tr>
                                                                                                                <td className='border'>Phone</td>
                                                                                                                <td className='border' style={{ wordBreak: 'break-all' }}>{branch?.PhoneNum?.Phone}</td>
                                                                                                            </tr>}

                                                                                                        </tbody>
                                                                                                    })}

                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>}

                                                                    <tr>
                                                                        <td className='border'>ABANum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.ABANum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ABANumStatus</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.ABANumStatus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ABANumSchema</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.ABANumSchema}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>NewABANum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.NewABANum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ACHStatus</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.ACHStatus}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {achCheckPrescreenLiteShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PhoneNum?.PhoneType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Phone</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PhoneNum?.Phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddr</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.EmailAddr}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Add1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmploymentHistory</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.EmploymentHistory?.OrgInfo?.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Bank Account</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OrgInfo</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.OrgInfo.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.RoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.AccountNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TypeOfBankAcct</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.TypeOfBankAcct}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Response</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Id</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AppName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.appName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OriginationTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.originationTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Header</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Corporation</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.corporation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Company</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.company}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Division</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.division}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Market</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.market}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Status</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ApplicationNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.applicationNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Action</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.action}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Type</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Decision</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Code</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.code}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Value</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.Value}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DecisionTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decisionTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Properties</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES !== null && selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>name</td>
                                                                                            <td className='border'>{item.name}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}

                                                                            </table>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border'>Transaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.transaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SubTransaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.subTransaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TimeStamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.timeStamp}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {achAndCheckPrescreenShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PhoneNum?.PhoneType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Phone</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PhoneNum?.Phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddr</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.EmailAddr}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Add1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo?.PostAddr?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BirthDtSpecified</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.BirthDtSpecified}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BirthDt</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.BirthDt}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmploymentHistory</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.EmploymentHistory?.OrgInfo?.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Bank Account</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OrgInfo</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.OrgInfo.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.RoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.AccountNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TypeOfBankAcct</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount?.TypeOfBankAcct}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Response</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Id</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AppName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.appName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OriginationTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.originationTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Header</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Corporation</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.corporation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Company</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.company}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Division</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.division}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Market</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.market}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Status</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ApplicationNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.applicationNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Action</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.action}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Type</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Decision</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Code</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.code}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Value</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.Value}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DecisionTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decisionTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Properties</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES !== null && selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>name</td>
                                                                                            <td className='border'>{item.name}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Transaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.transaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SubTransaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.subTransaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TimeStamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.timeStamp}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {extendedACHCheckPrescreenShow && <div class="card">
                                                    <div className="card-header">
                                                        <a className="btn">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-left">Created Date:</i>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <i className="accordionHeading txt-right">{moment.utc(selectedMBLog[0]?.createdDate).format("DD MMMM, YYYY")}</i>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.PersonName?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PhoneNum?.PhoneType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Phone</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PhoneNum?.Phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddr</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.EmailAddr}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Add1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.Addr1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StateProv</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.StateProv}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PostalCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.PostalCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.ContactInfo[0]?.PostAddr?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmploymentHistory</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonInfo?.EmploymentHistory[0]?.OrgInfo?.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Bank Account</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OrgInfo</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount[0]?.OrgInfo.Name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount[0]?.RoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountNum</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount[0]?.AccountNum}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TypeOfBankAcct</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccount[0]?.TypeOfBankAcct}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RqUID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.RqUID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Severity</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.Severity}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>StatusDesc</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.MsgRsHdr?.Status?.StatusDesc}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Response</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Id</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AppName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.appName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>OriginationTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.REQUESTINGSYSTEM?.originationTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Header</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Corporation</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.corporation}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Company</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.company}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Division</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.division}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Market</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.HEADER?.HIERARCHY?.market}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Status</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ApplicationNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.applicationNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Action</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.action}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Type</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.STATUS?.type}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Decision</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Code</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.code}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Value</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decision.Value}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DecisionTimestamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.CONTENT?.DECISION?.decisionTimestamp}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>Properties</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}>
                                                                            <table className='w-100 summarytbl'>
                                                                                {selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES !== null && selectedResObj?.RESPONSE?.CONTENT?.DECISION?.PROPERTIES.map((item, key) => {
                                                                                    return <tbody key={key}>
                                                                                        <tr>
                                                                                            <td className='border'>name</td>
                                                                                            <td className='border'>{item.name}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className='border'>value</td>
                                                                                            <td className='border'>{item.value}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                })}
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Transaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.transaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>SubTransaction</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.subTransaction}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TimeStamp</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedResObj?.RESPONSE?.timeStamp}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}

                <Modal show={mbPopupShow} onHide={handleMBPopupClose} backdrop="static" keyboard={false} centered size='md' aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        {selectedGiactLogTitle && <h2 class="popupHeading">{selectedGiactLogTitle}</h2>}
                                        {selectedGiactLog && <div class="modalWrapper">
                                            <div id="accordion">

                                                {gBankAcctPersonShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">BankAccountEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.RoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.AccountNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.AccountType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">PersonEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">AddressEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TaxID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.TaxID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DateOfBirth</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.DateOfBirth}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                    {/* <GiactResponseComponent value={selectedResObj} /> */}
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gOfacPersonShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">PersonEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">AddressEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TaxID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.TaxID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DateOfBirth</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.DateOfBirth}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddress</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.EmailAddress}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>CurrentIPAddress</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.CurrentIPAddress}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gEsiShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">PersonEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">AddressEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TaxID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.TaxID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DateOfBirth</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.DateOfBirth}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>EmailAddress</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.EmailAddress}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>CurrentIPAddress</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.CurrentIPAddress}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gIdentifyPersonShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">PersonEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FirstName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.FirstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>LastName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.LastName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">AddressEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>TaxID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.TaxID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>DateOfBirth</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.PersonEntity?.DateOfBirth}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gBankAcctBusinessShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">BankAccountEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>RoutingNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.RoutingNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.AccountNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AccountType</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BankAccountEntity?.AccountType}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">BusinessEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BusinessName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.BusinessName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">AddressEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine2</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine2}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FEIN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.FEIN}</td>
                                                                    </tr>
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gOfacBusinessShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">BusinessEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BusinessName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.BusinessName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine2</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine2}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FEIN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.FEIN}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                                {gIdentifyBusinessShow && <div class="card">
                                                    <div className='row'>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedReqObj && <tbody>
                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Request Object:</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>UniqueID</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.UniqueID}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ServiceFlags</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj.ServiceFlags.map((item, i) => <><span key={i}>{item}</span><span>, </span></>)}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">BusinessEntity</i></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>BusinessName</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.BusinessName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine1</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>AddressLine2</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.AddressLine2}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>City</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.City}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>State</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.State}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>ZipCode</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.ZipCode}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>Country</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.AddressEntity?.Country}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>PhoneNumber</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.PhoneNumber}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className='border'>FEIN</td>
                                                                        <td className='border' style={{ wordBreak: 'break-all' }}>{selectedReqObj?.BusinessEntity?.FEIN}</td>
                                                                    </tr>

                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                        <div className="card-body">
                                                            <table className='w-100 summarytbl'>
                                                                <thead><th></th><th></th></thead>
                                                                {selectedResObj && <tbody>
                                                                    <GiactResponseComponent value={selectedResObj} value1={selectedGiactLog} />
                                                                </tbody>}
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBPopupClose()}>Close</button></div>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default ApplicationSummaryComponent;