
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { defaultCountry } from '../helpers/constants';
import applicationService from '../services/applicationservice';

const EntityOfficerRows = ({ rowsData, deleteTableRows, handleChange, controlDisable,handleChangeCheckbox }) => {

    const [countryList, setCountryList] = useState(null);
    const [isManagerOfficer, setIsManagerOfficer] = useState(false);
    const [isSameInformation, setIsSameInformation] = useState(false);

    useEffect(() => {
        getStatesByCountry(defaultCountry);
        getCountries();

    }, []);

    const getStatesByCountry = (index,countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    rowsData[index].stateList = json.data.payload
                }
            })
            .catch((error) => {
            });
    }

    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
            });
    }

    const toggerIsManager = (evnt) => {
        if (evnt.target.checked) {
            setIsManagerOfficer(true);
        }
        else {
            setIsManagerOfficer(false);
        }
    }

    const toggleIsSameInfo = (evnt) => {
        if (evnt.target.checked) {
            setIsSameInformation(true);
        }
        else {
            setIsSameInformation(false);
        }
    }

    return (

        rowsData.map((data, index) => {
            const { firstName, lastName, dateOfBirth, ssnppt, address, city, postalCode, state, country, position, isManager, isSameInfo } = data;

            return (

                <div className='add_formArea'>
                    <div className="row" key={index}>
                        <div className='col-lg-11'>
                            <div className='row'>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">First Name<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" className="inputForm" maxLength="50" value={firstName} onChange={(evnt) => (handleChange(index, evnt))} name="firstName" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Last Name<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" maxLength="50" className="inputForm" value={lastName} onChange={(evnt) => (handleChange(index, evnt))} name="lastName" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Date of Birth<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="date" className="inputForm" value={dateOfBirth} onChange={(evnt) => (handleChange(index, evnt))} name="dateOfBirth" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">SSN/PPT<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <InputMask mask="999-99-9999" className="inputForm" value={ssnppt} onChange={(evnt) => (handleChange(index, evnt))} name="ssnppt" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Address<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" maxLength="250" className="inputForm" value={address} onChange={(evnt) => (handleChange(index, evnt))} name="address" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">State<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <select style={{ paddingRight: '2em' }} className="form-select border-color" name="state" onChange={(evnt) => (handleChange(index, evnt))} disabled={controlDisable ? true : false} onBlur={(evnt) => (handleChange(index, evnt))} value={state}>
                                                <option value="">Select State</option>
                                                {data.stateList && data.stateList.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">City<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" maxLength="50" className="inputForm" value={city} onChange={(evnt) => (handleChange(index, evnt))} disabled={controlDisable ? true : false} name="city" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Postal Code<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input type="text" maxLength="10" className="inputForm" value={postalCode} onChange={(evnt) => (handleChange(index, evnt))} disabled={controlDisable ? true : false} name="postalCode" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <select className="form-select border-color" name="country" disabled={controlDisable ? true : false}
                                                // onChange={(evnt) => (handleChange(index, evnt))} 
                                                onChange={(evnt) => {
                                                    data.state = ''
                                                    getStatesByCountry(index,evnt.target.value);
                                                    handleChange(index, evnt);
                                                }}
                                                onBlur={(evnt) => (handleChange(index, evnt))} value={country}>
                                                {countryList && countryList.map((option) => (
                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Position<span className="error-info">*</span></label>
                                        <div className="input_area">
                                            <input className="inputForm" maxLength="20" value={position} onChange={(evnt) => (handleChange(index, evnt))} name="position" disabled={controlDisable ? true : false} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Manager<span className="error-info"></span></label>
                                        <div className="form-check">
                                            <input className="form-check-input mt-12" value={isManagerOfficer} defaultChecked={isManager} disabled={controlDisable ? true : false}
                                                onClick={(e) => toggerIsManager(e)}
                                                type="checkbox" name="isManager"
                                                onBlur={(evnt) => (handleChange(index, evnt))}
                                                onChange={(evnt) => (handleChange(index, evnt))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="input_wrapper">
                                        <label className="form-label">Same for Beneficial<span className="error-info"></span></label>
                                        <div className="form-check">
                                            <input className="form-check-input mt-12" value={isSameInformation} defaultChecked={isSameInfo} disabled={controlDisable ? true : false}
                                                type="checkbox" name="isSameInfo" onClick={(e) => toggleIsSameInfo(e)}
                                                //onBlur={(evnt) => (handleChange(index, evnt))}
                                                onChange={(evnt) => (handleChangeCheckbox(index, evnt))} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-1'>
                            <button type='button' className='del_area' disabled={controlDisable ? true : false} onClick={() => (deleteTableRows(index))}><i className='fa fa-remove'></i></button>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

export default EntityOfficerRows;