import React from 'react'
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import accountService from '../../services/accountservice';
import { toast } from 'react-toastify';
import loader from "super-react-loader";

const DeleteUser = () => {
    let formikValues = {
        username: ""
    };
    let INITIAL_FORM_STATE = formikValues;
    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().required("Username is required")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        onSubmit: (values) => {
            loader.show();
            accountService.deleteUser({
                username: values.username,
            })
                .then((json) => {
                    if (json.data.payload === false) {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    } else {
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        formik.resetForm(formik.initialValues);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    return (
        <>
            <section className="mode" data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid">
                    <div className="light-dark-mod"></div>
                    <section className="trading_card ">
                        <h1>Delete User</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input_wrapper">
                                <label htmlFor='username' className="form-label">Username</label>
                                <div className="input_area">
                                    <input name='username' autoComplete='off' type="text" className="inputForm" onBlur={formik.handleBlur}
                                        onChange={formik.handleChange} value={formik.values.email} placeholder="Enter username" />
                                    {formik.touched.username && formik.errors.username ? (<p className="error">{formik.errors.username}</p>) : null}
                                </div>
                            </div>
                            <button type="submit" className="btn login_btn">Delete User</button>
                        </form>
                        <Link to="/Login" className="signup_area"><i className="color_orange">Back to Sign in</i></Link>
                    </section>
                </div>
            </section>
        </>
    )
}

export default DeleteUser;