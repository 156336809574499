import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import loader from "super-react-loader";
import Header from "../../layouts/header/header";
import LeftNav from "../../layouts/leftnav/leftnav";
import LatestApplicationsComponent from '../../components/shared/LatestApplicationsComponent';
import LatestUsersComponent from '../../components/shared/LatestUsersComponent';
import { useNavigate } from 'react-router';

const AdminDashboard = () => {

    const navigate = useNavigate();
    const [dbStats, setdbStats] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getDashboardStats();
    }, []);

    const getDashboardStats = () => {
        loader.show();
        adminService.getDashboardStats().then((json) => {
            if (json.data.payload !== null) {
                setdbStats(json.data.payload);
                loader.hide();
            }
            loader.hide();
        })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const redirectToUsers = () => {
        navigate('/admin/users');
    }
    const redirectToApplications = () => {
        navigate('/admin/applications');
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">

                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Dashboard</h2>
                        </div>
                    </div>

                    {dbStats && <div className="dashboard-statistic">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Brokers</h2>
                                            <h3>{dbStats[0]?.totalBrokers}</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Applications</h2>
                                            <h3>{dbStats[0]?.totalApplications}</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Applicants</h2>
                                            <h3>{dbStats[0]?.totalApplicants}</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Register Reps</h2>
                                            <h3>{dbStats[0]?.totalRegisterReps}</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Supervisors</h2>
                                            <h3>{dbStats[0]?.totalSupervisors}</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* <div className="row">
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Approved (Clearing Firm)</h2>
                                            <h3>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Submitted (Clearing Firm)</h2>
                                            <h3>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Info Required (Clearing Firm)</h2>
                                            <h3>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Approved (Sup)</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Approved (RR)</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Canceled</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Not Completed</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Closed</h2>
                                            <h3 className='pt-5'>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Submitted Pending Approval</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                           
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Declined (Sup)</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className='statsheading'>Total Accounts Declined (RR)</h2>
                                            <h3 className='pt-4'>0</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </div> */}
                    </div>}
                    <hr />

                    <div className="dashboard-statistic">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card-broker">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="heading">Users</h2>
                                        </div>
                                        <div className='col-lg-6'><span className='float-right blue-link pointer' onClick={() => redirectToUsers()}>View All</span></div>
                                    </div>
                                    <LatestUsersComponent role="Admin" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card-broker">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <h2 className="heading">Applications</h2>
                                        </div>
                                        <div className='col-lg-6'><span className='float-right blue-link pointer' onClick={() => redirectToApplications()}>View All</span></div>
                                    </div>
                                    <LatestApplicationsComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

export default AdminDashboard;