import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { adminRole, brokerRole, rrRole, supervisorRole24, supervisorRole4 } from '../../helpers/constants';

const Header = () => {

    const [userName, setUsername] = useState("");
    const navigate = useNavigate();
    const logoutHandler = () => {
        localStorage.removeItem("currentUser");
        localStorage.clear();
        navigate("/");
    }

    useEffect(() => {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        if (user !== undefined && user !== null) {
            setUsername(user.userName);
        }
        else {
            navigate("/");
        }
    });

    const redirectToProfile = () => {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        if (user.userRole === adminRole) {
            navigate('/admin/profile');
        }
        else if (user.userRole === brokerRole) {
            navigate('/broker/profile');
        }
        else if (user.userRole === supervisorRole24 || user.userRole === supervisorRole4) {
            navigate('/supervisor/profile');
        }
        else if (user.userRole === rrRole) {
            navigate('/registerrep/profile');
        }
        else {
            navigate('/profile');
        }
    }

    const redirectToChangePassword = () => {
        navigate('/changepassword');
    }


    const redirectToDashboard = () => {
        var user = JSON.parse(localStorage.getItem("currentUser"));
        if (user.userRole === adminRole) {
            navigate('/admin/dashboard');
        }
        else if (user.userRole === brokerRole) {
            navigate('/broker/dashboard');
        }
        else if (user.userRole === supervisorRole24 || user.userRole === supervisorRole4) {
            navigate('/supervisor/dashboard');
        }
        else if (user.userRole === rrRole) {
            navigate('/registerrep/dashboard');
        }
        else {
            navigate('/dashboard');
        }
    }

    return (
        <>
            <section className="header" style={{ width: 'calc(100% - 80px)' }} data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid">
                    <div className="light-dark-mod">
                        <span className="switch">
                            {/* <label>Light</label>
                            <input type="checkbox" className="switch" id="switch-id" onclick="toggle_light_mode()" />
                            <label for="switch-id">Dark</label> */}
                            {/* <span className="logout-label" onClick={logoutHandler}>Logout</span> */}
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-lg-3"><a href="javascript:;" onClick={redirectToDashboard} className="logo-name">OLA</a></div>
                        <div className="col-lg-9 d-flex justify-content-end">
                            {/* <ul className="user_area">
                                <li><a href="javascript:;"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></a></li>
                                <li><a href="javascript:;"><span className="user-name">{userName}</span>
                                </a></li>
                            </ul> */}

                            <div className='user_area m-0 noti-drop'>
                                <Dropdown autoClose="outside" className='shadow-lg'>
                                    <Dropdown.Toggle className='px-1 position-relative' id="dropdown-autoclose-outside">
                                        <i style={{ fontSize: '1.2rem' }} className="fa fa-bell" aria-hidden="true"></i>
                                        <i className="fa fa-circle position-absolute" aria-hidden="true"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {[1, 1, 1, 1, 1].map(ele => <Dropdown.Item href="#" className='border-bottom'>
                                            <div className='mb-2'>
                                                <small>Complete your profile</small>
                                            </div>
                                            <div className='mb-1'>
                                                <strong>Please upload your driving license</strong>
                                            </div>
                                            <div className='mb-2'>
                                                <small className='date'>13 Aug 2024</small>
                                            </div>
                                        </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className='user_area m-0'>
                                {/* <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {userName}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}

                                <DropdownButton className='px-1' id="dropdown-basic-button" title={userName}>
                                    <Dropdown.Item onClick={redirectToProfile}>Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={redirectToChangePassword}>Change Password</Dropdown.Item>
                                    <Dropdown.Item onClick={logoutHandler}>Logout</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;