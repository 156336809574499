import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo, validatePostalCode } from '../common/common';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import { useFormik } from "formik";
import * as Yup from "yup";
import loader from 'super-react-loader';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';
import { defaultCountry } from '../helpers/constants';

const Regulatory = () => {
    const navigate = useNavigate();
    const [regulatoryInfo, setRegulatoryInfo] = useState(null);
    const [showPoliticallyExposeAccount, setshowPoliticallyExposeAccount] = useState(null);
    const [showForeignFinancialInstAccount, setshowForeignFinancialInstAccount] = useState(null);
    const [showforeignBankOrgAccount, setshowforeignBankOrgAccount] = useState(null);
    const [showPabAccount, setshowPabAccount] = useState(null);
    const [showThirdPartyTrading, setshowThirdPartyTrading] = useState(null);
    const [rule14b, setRule14b] = useState(false);
    const [isRetirementAccount, setIsRetirementAccount] = useState(false);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [primaryShareHolder, setPrimaryShareHolder] = useState(null);
    const [primaryTradeAuthority, setPrimaryTradeAuthority] = useState(null);
    const [saveOnly, setSaveOnly] = useState(false);
    const [isForeignCitizenship, setIsForeignCitizenship] = useState(false);
    const [countryList, setCountryList] = useState(null);
    const [stateList, setStateList] = useState(null);

    const [showIsInstitutionalTypeAccount, setShowIsInstitutionalTypeAccount] = useState(null);
    const [showIsControlledByPublicCorp, setShowIsControlledByPublicCorp] = useState(null);
    const [showIsUnderBrokerControl, setShowIsUnderBrokerControl] = useState(null);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getCountries();
        loadStates(defaultCountry);
        getApplicantCitizenshipInfo();
        getRegulatoryInfo();
    }, []);

    const handlePrevious = () => {
        navigate('/investor-profile');
    }

    const setpoliticallyExposeAccountVal = (strVal) => {
        if (strVal === "true") {
            setshowPoliticallyExposeAccount(true);
        }
        else {
            setshowPoliticallyExposeAccount(false);
        }
    }

    const setforeignFinancialInstAccountVal = (strVal) => {
        if (strVal === "true") {
            setshowForeignFinancialInstAccount(true);
        }
        else {
            setshowForeignFinancialInstAccount(false);
        }
    }

    const setforeignBankOrgAccountVal = (strVal) => {
        if (strVal === "true") {
            setshowforeignBankOrgAccount(true);
        }
        else {
            setshowforeignBankOrgAccount(false);
        }
    }

    const setPabAccountVal = (strVal) => {
        if (strVal === "true") {
            setshowPabAccount(true);
        }
        else {
            setshowPabAccount(false);
        }
    }

    const setThirdPartyTradingVal = (strVal) => {
        if (strVal === "true") {
            setshowThirdPartyTrading(true);
        }
        else {
            setshowThirdPartyTrading(false);
        }
    }

    const setRule14bVal = (strVal) => {
        if (strVal === "true") {
            setRule14b(true);
        }
        else {
            setRule14b(false);
        }
    }

    const setIsInstitutionalTypeAccountVal = (strVal) => {
        if (strVal === "true") {
            setShowIsInstitutionalTypeAccount(true);
        }
        else {
            setShowIsInstitutionalTypeAccount(false);
        }
    }

    const setIsControlledByPublicCorpVal = (strVal) => {
        if (strVal === "true") {
            setShowIsControlledByPublicCorp(true);
        }
        else {
            setShowIsControlledByPublicCorp(false);
        }
    }

    const setIsUnderBrokerControlVal = (strVal) => {
        if (strVal === "true") {
            setShowIsUnderBrokerControl(true);
        }
        else {
            setShowIsUnderBrokerControl(false);
        }
    }

    const getApplicantCitizenshipInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicantList != null && result.applicantList.length > 0) {
                        if (result.applicantList[0].citizenshipCountryId !== defaultCountry) {
                            setIsForeignCitizenship(true);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getRegulatoryInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 5
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                        setIsRetirementAccount(true);
                    }
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }

                    result.applicationInfo?.shareholder === null ? setPrimaryShareHolder(null) : result.applicationInfo?.shareholder === 1 ? setPrimaryShareHolder(true) : setPrimaryShareHolder(false);
                    result.applicationInfo?.tradeAuthtoAnotherParty === null ? setPrimaryTradeAuthority(null) : result.applicationInfo?.tradeAuthtoAnotherParty === true ? setPrimaryTradeAuthority(true) : setPrimaryTradeAuthority(false);

                    if (result.regulatoryItemsInfo != null) {
                        setRegulatoryInfo(result.regulatoryItemsInfo);

                        result.regulatoryItemsInfo?.isPoliticallyExposePersonAccount === null ? setshowPoliticallyExposeAccount(null) :
                            result.regulatoryItemsInfo?.isPoliticallyExposePersonAccount === true ? setshowPoliticallyExposeAccount(true) : setshowPoliticallyExposeAccount(false);

                        result.regulatoryItemsInfo?.isForeignFinancialInstiutionAccount === null ? setshowForeignFinancialInstAccount(null) :
                            result.regulatoryItemsInfo?.isForeignFinancialInstiutionAccount === true ? setshowForeignFinancialInstAccount(true) : setshowForeignFinancialInstAccount(false);

                        result.regulatoryItemsInfo?.isForeignBankOrganizedAccount === null ? setshowforeignBankOrgAccount(null) :
                            result.regulatoryItemsInfo?.isForeignBankOrganizedAccount === true ? setshowforeignBankOrgAccount(true) : setshowforeignBankOrgAccount(false);

                        result.regulatoryItemsInfo?.isPABAccount === null ? setshowPabAccount(null) :
                            result.regulatoryItemsInfo?.isPABAccount === true ? setshowPabAccount(true) : setshowPabAccount(false);

                        result.regulatoryItemsInfo?.isAnotherPartyTrading === null ? setshowThirdPartyTrading(null) :
                            result.regulatoryItemsInfo?.isAnotherPartyTrading === true ? setshowThirdPartyTrading(true) : setshowThirdPartyTrading(false);

                        result.regulatoryItemsInfo?.isRule14B1CAccepted === null ? setRule14b(null) :
                            result.regulatoryItemsInfo?.isRule14B1CAccepted === true ? setRule14b(true) : setRule14b(false);

                        result.regulatoryItemsInfo?.isInstitutionalTypeAccount === null ? setShowIsInstitutionalTypeAccount(null) :
                            result.regulatoryItemsInfo?.isInstitutionalTypeAccount === true ? setShowIsInstitutionalTypeAccount(true) : setShowIsInstitutionalTypeAccount(false);

                        result.regulatoryItemsInfo?.isControlledByPublicCorp === null ? setShowIsControlledByPublicCorp(null) :
                            result.regulatoryItemsInfo?.isControlledByPublicCorp === true ? setShowIsControlledByPublicCorp(true) : setShowIsControlledByPublicCorp(false);

                        result.regulatoryItemsInfo?.isUnderBrokerControl === null ? setShowIsUnderBrokerControl(null) :
                            result.regulatoryItemsInfo?.isUnderBrokerControl === true ? setShowIsUnderBrokerControl(true) : setShowIsUnderBrokerControl(false);
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const setShareHolderValue = (radioVal) => {
        if (radioVal === "true") {
            setPrimaryShareHolder(true);
        }
        else {
            setPrimaryShareHolder(false);
        }
    }

    const setTradeAuthorityValue = (radioVal) => {
        if (radioVal === "true") {
            setPrimaryTradeAuthority(true);
        }
        else {
            setPrimaryTradeAuthority(false);
        }
    }

    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleCountryChange = (e) => {
        loadStates(e.target.value);
    }

    const loadStates = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setStateList(json.data.payload);
                }
                else {
                    setStateList([]);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    //Form values set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: regulatoryInfo?.applicationId,
        shareholder: null,
        shareholderCompanyAndStockSymbol: "",
        tradeAuthtoAnotherParty: null,
        tradeAuthtoAnotherPartyName: "",
        politicallyExposeAccount: null,
        politicallyExposePersonMembers: '',
        politicallyExposePersonOrganization: '',
        politicallyExposePersonTitle: '',
        foreignFinancialInstAccount: null,
        foreignFinancialInstitutionName: '',
        foreignBankOrgAccount: null,
        foreignBankOrgAccountAgent: '',
        pabAccount: '',
        thirdPartyTrading: null,
        anotherPartyTradingAgent: '',
        rule14B1CAccepted: null,
        //for Traditional IRA Account
        ownerResidenceW8: null,
        ownerClaimW8: null,
        witholdingPercentageW8: null,
        eligibleForRateWithholding: null,

        isInstitutionalTypeAccount: null, //Q3
        isControlledByPublicCorp: null, //Q4
        isUnderBrokerControl: null, //Q5
        q1to3Relationship: null,
        q1to3PersonName: null,
        q1to3FirmEmp: null,
        q4to5CorpName: null,
        q4to5CorpAddress1: null,
        q4to5CorpAddress2: null,
        q4to5CorpCity: null,
        q4to5CorpCountry: null,
        q4to5CorpState: null,
        q4to5CorpZipCode: null,
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        shareholder: Yup.boolean().required("This option is required").nullable(),
        tradeAuthtoAnotherParty: Yup.boolean().required("This option is required").nullable(),
        shareholderCompanyAndStockSymbol: primaryShareHolder ? Yup.string().required('Enter shareholder company and stock symbol').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().nullable().notRequired(),
        //tradeAuthtoAnotherPartyName: primaryTradeAuthority ? Yup.string().required('Enter trade authority party name').nullable() : Yup.string().nullable().notRequired(),
        politicallyExposeAccount: Yup.string().required("Please select an option").nullable(),
        politicallyExposePersonTitle: showPoliticallyExposeAccount ? Yup.string().required('Enter politically exposed person title').nullable() : Yup.string().nullable().notRequired(),
        politicallyExposePersonMembers: showPoliticallyExposeAccount ? Yup.string().required('Enter office or immediate family members').nullable() : Yup.string().nullable().notRequired(),
        politicallyExposePersonOrganization: showPoliticallyExposeAccount ? Yup.string().required('Enter political organization').nullable() : Yup.string().nullable().notRequired(),
        foreignFinancialInstAccount: Yup.string().required("Please select an option").nullable(),
        foreignFinancialInstitutionName: showForeignFinancialInstAccount ? Yup.string().required('Enter name of foreign financial institution').nullable() : Yup.string().nullable().notRequired(),
        foreignBankOrgAccount: Yup.string().required("Please select an option").nullable(),
        foreignBankOrgAccountAgent: showforeignBankOrgAccount ? Yup.string().required('Enter name of agent').nullable() : Yup.string().nullable().notRequired(),
        pabAccount: Yup.string().required("Please select an option").nullable(),
        thirdPartyTrading: Yup.string().required("Please select an option").nullable(),
        anotherPartyTradingAgent: showThirdPartyTrading ? Yup.string().required('Enter name of agent').nullable() : Yup.string().nullable().notRequired(),
        rule14B1CAccepted: Yup.string().required("Please accept the direct communication rule").nullable(),
        //for IRA
        ownerResidenceW8: isForeignCitizenship ? Yup.string().required('Enter resident info').nullable() : Yup.string().nullable().notRequired(),
        // ownerClaimW8: isForeignCitizenship ? Yup.string().required('Enter paragraph info').nullable() : Yup.string().nullable().notRequired(),
        // witholdingPercentageW8: isForeignCitizenship ? Yup.number().typeError('Must be a number').min(1, 'Value must be between 1 and 100')
        // .max(100, 'Value must be between 1 and 100').required('Enter percentage') : Yup.string().nullable().notRequired(),
        // eligibleForRateWithholding: isForeignCitizenship ? Yup.string().required('Enter additional conditions in the article').nullable() : Yup.string().nullable().notRequired(),

        isInstitutionalTypeAccount: Yup.boolean().required("This option is required").nullable(),
        isControlledByPublicCorp: Yup.boolean().required("This option is required").nullable(),
        isUnderBrokerControl: Yup.boolean().required("This option is required").nullable(),
        q1to3Relationship: (primaryShareHolder || primaryTradeAuthority || showIsInstitutionalTypeAccount) ? Yup.string().required('Enter relationship').nullable() : Yup.string().nullable().notRequired(),
        q1to3PersonName: (primaryShareHolder || primaryTradeAuthority || showIsInstitutionalTypeAccount) ? Yup.string().required('Enter person name').nullable() : Yup.string().nullable().notRequired(),
        q1to3FirmEmp: (primaryShareHolder || primaryTradeAuthority || showIsInstitutionalTypeAccount) ? Yup.string().required('Enter firm/employer name').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpName: (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Enter corporation name').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpAddress1: (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Enter address').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpCity: (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Enter city').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpCountry: defaultCountry === null && (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Select country').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpState: (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Select state').nullable() : Yup.string().nullable().notRequired(),
        q4to5CorpZipCode: (showIsControlledByPublicCorp || showIsUnderBrokerControl) ? Yup.string().required('Enter zip code').min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().nullable().notRequired(),
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: regulatoryInfo?.applicationId,
        shareholder: regulatoryInfo && regulatoryInfo?.shareholder,
        shareholderCompanyAndStockSymbol: regulatoryInfo && regulatoryInfo?.shareholderCompanyAndStockSymbol,
        tradeAuthtoAnotherParty: regulatoryInfo && regulatoryInfo?.tradeAuthtoAnotherParty,
        tradeAuthtoAnotherPartyName: null,

        politicallyExposeAccount: regulatoryInfo?.isPoliticallyExposePersonAccount,
        politicallyExposePersonMembers: regulatoryInfo?.politicallyExposePersonMembers,
        politicallyExposePersonOrganization: regulatoryInfo?.politicallyExposePersonOrganization,
        politicallyExposePersonTitle: regulatoryInfo?.politicallyExposePersonPoliticalTitle,
        foreignFinancialInstAccount: regulatoryInfo?.isForeignFinancialInstiutionAccount,
        foreignFinancialInstitutionName: regulatoryInfo?.foreignFinancialInstiutionName,
        foreignBankOrgAccount: regulatoryInfo?.isForeignBankOrganizedAccount,
        foreignBankOrgAccountAgent: regulatoryInfo?.foreignBankOrganizedAccountAgent,
        pabAccount: regulatoryInfo?.isPABAccount,
        thirdPartyTrading: regulatoryInfo?.isAnotherPartyTrading,
        anotherPartyTradingAgent: regulatoryInfo?.anotherPartyTradingAgent,
        rule14B1CAccepted: regulatoryInfo?.isRule14B1CAccepted,
        //For Traditional IRA account
        ownerResidenceW8: regulatoryInfo?.ownerResidenceW8,
        ownerClaimW8: regulatoryInfo?.ownerClaimW8,
        witholdingPercentageW8: regulatoryInfo?.witholdingPercentageW8,
        eligibleForRateWithholding: regulatoryInfo?.eligibleForRateWithholding,

        isInstitutionalTypeAccount: regulatoryInfo?.isInstitutionalTypeAccount, //Q3
        isControlledByPublicCorp: regulatoryInfo?.isControlledByPublicCorp, //Q4
        isUnderBrokerControl: regulatoryInfo?.isUnderBrokerControl, //Q5
        q1to3Relationship: regulatoryInfo?.q1to3Relationship, //required for FIS if anyone from question 1, 2 or 3 is "Y"
        q1to3PersonName: regulatoryInfo?.q1to3PersonName, //required for FIS if anyone from question 1, 2 or 3 is "Y"
        q1to3FirmEmp: regulatoryInfo?.q1to3FirmEmp, //required for FIS if anyone from question 1, 2 or 3 is "Y"
        q4to5CorpName: regulatoryInfo?.q4to5CorpName, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpAddress1: regulatoryInfo?.q4to5CorpAddress1, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpAddress2: regulatoryInfo?.q4to5CorpAddress2, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpCity: regulatoryInfo?.q4to5CorpCity, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpCountry: regulatoryInfo?.q4to5CorpCountry, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpState: regulatoryInfo?.q4to5CorpState, //required for FIS if anyone from question 4 or 5 is "Y"
        q4to5CorpZipCode: regulatoryInfo?.q4to5CorpZipCode //required for FIS if anyone from question 4 or 5 is "Y"
    };

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            let applicationId = localStorage.getItem("currentAppId");
            let data = {
                "loggedInUserId": getLoggedInUserInfo().id,
                "applicationId": applicationId,
                "shareholder": primaryShareHolder === true ? 1 : 0,
                "shareholderCompanyAndStockSymbol": values.shareholderCompanyAndStockSymbol,
                "tradeAuthtoAnotherParty": primaryTradeAuthority,
                "tradeAuthtoAnotherPartyName": null,
                "isPoliticallyExposePersonAccount": values.politicallyExposeAccount,
                "politicallyExposePersonMembers": values.politicallyExposePersonMembers,
                "politicallyExposePersonOrganization": values.politicallyExposePersonOrganization,
                "politicallyExposePersonPoliticalTitle": values.politicallyExposePersonTitle,
                "isForeignFinancialInstiutionAccount": values.foreignFinancialInstAccount,
                "foreignFinancialInstitutionName": values.foreignFinancialInstitutionName,
                "isForeignBankOrganizedAccount": values.foreignBankOrgAccount,
                "foreignBankOrganizedAccountAgent": values.foreignBankOrgAccountAgent,
                "isPABAccount": values.pabAccount,
                "isAnotherPartyTrading": values.thirdPartyTrading,
                "anotherPartyTradingAgent": values.anotherPartyTradingAgent,
                "isRule14B1CAccepted": values.rule14B1CAccepted,
                "isBrokerDealer": null,
                "isInstitutionalTypeAccount": values.isInstitutionalTypeAccount,
                "isControlledByPublicCorp": values.isControlledByPublicCorp, //Q4
                "isUnderBrokerControl": values.isUnderBrokerControl, //Q5
                "q1to3Relationship": values.q1to3Relationship,
                "q1to3PersonName": values.q1to3PersonName,
                "q1to3FirmEmp": values.q1to3FirmEmp,
                "q4to5CorpName": values.q4to5CorpName,
                "q4to5CorpAddress1": values.q4to5CorpAddress1,
                "q4to5CorpAddress2": values.q4to5CorpAddress2,
                "q4to5CorpCity": values.q4to5CorpCity,
                "q4to5CorpCountry": values.q4to5CorpCountry,
                "q4to5CorpState": values.q4to5CorpState,
                "q4to5CorpZipCode": values.q4to5CorpZipCode,

                //For Traditional IRA account
                "ownerResidenceW8": isForeignCitizenship ? values.ownerResidenceW8 : null,
                "ownerClaimW8": isForeignCitizenship ? values.ownerClaimW8 : null,
                "witholdingPercentageW8": isForeignCitizenship ? values.witholdingPercentageW8 : null,
                "eligibleforRatewithHolding": isForeignCitizenship ? values.eligibleForRateWithholding : null,
            };
            applicationService.saveRegulatoryItemsInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getRegulatoryInfo();
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/account-features");
                        }
                        else {
                            toast.success("Regulatory items info has been saved successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });
    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <div className="tab-content" id="regulatory">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">Regulatory Items</h2>
                                    </div>
                                    {/* FIS Question 1 */}
                                    <div className="col-lg-9">
                                        <label className="form-label">Are you or a family member a senior executive or 10% or greater
                                            shareholder of a Publicly/Exposed traded company?<span className="error-info">*</span></label>
                                        {formik.touched.shareholder && formik.errors.shareholder ? (
                                            <p className="error" style={styleError}>{formik.errors.shareholder}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="shareholder"
                                                onClick={(e) => setShareHolderValue(e.target.value)} disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                checked={primaryShareHolder === null ? false : primaryShareHolder === false ? true : false}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="shareholder"
                                                onClick={(e) => setShareHolderValue(e.target.value)} disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                checked={primaryShareHolder === null ? false : primaryShareHolder === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {primaryShareHolder && <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">List name of stock ticker symbol<span className="error-info"><span className="error-info">*</span></span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.shareholderCompanyAndStockSymbol}
                                                            maxLength="50" name="shareholderCompanyAndStockSymbol"
                                                            placeholder="Ticker Symbol" />
                                                        {formik.touched.shareholderCompanyAndStockSymbol && formik.errors.shareholderCompanyAndStockSymbol ? (
                                                            <p className="error">{formik.errors.shareholderCompanyAndStockSymbol}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* FIS Question 2 */}
                                    <div className="col-lg-9">
                                        <label className="form-label">Are you or a family member employed with a member firm of a stock exchange or FINRA registered broker?<span className="error-info">*</span></label>
                                        {formik.touched.tradeAuthtoAnotherParty && formik.errors.tradeAuthtoAnotherParty ? (
                                            <p className="error" style={styleError}>{formik.errors.tradeAuthtoAnotherParty}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="tradeAuthtoAnotherParty"
                                                onClick={(e) => setTradeAuthorityValue(e.target.value)} disabled={ctrlDisable}
                                                checked={primaryTradeAuthority === null ? false : primaryTradeAuthority === false ? true : false}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="tradeAuthtoAnotherParty"
                                                onClick={(e) => setTradeAuthorityValue(e.target.value)} disabled={ctrlDisable}
                                                checked={primaryTradeAuthority === null ? false : primaryTradeAuthority === true ? true : false}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <label className="form-label">Are you or a family member a senior political figure (SPF) or politically exposed
                                            person (PEP)?<span className="error-info">*</span></label>
                                        {formik.touched.politicallyExposeAccount && formik.errors.politicallyExposeAccount ? (
                                            <p className="error" style={styleError}>{formik.errors.politicallyExposeAccount}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="politicallyExposeAccount" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setpoliticallyExposeAccountVal(e.target.value)}
                                                checked={showPoliticallyExposeAccount === null ? false : showPoliticallyExposeAccount === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="politicallyExposeAccount" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setpoliticallyExposeAccountVal(e.target.value)}
                                                checked={showPoliticallyExposeAccount === null ? false : showPoliticallyExposeAccount === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                {showPoliticallyExposeAccount && <div className="row">
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Politically Publicly/Exposed Person Political Title<span className="error-info"><span className="error-info">*</span></span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="politicallyExposePersonTitle" maxLength={50} disabled={ctrlDisable}
                                                    value={formik.values.politicallyExposePersonTitle} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {formik.touched.politicallyExposePersonTitle && formik.errors.politicallyExposePersonTitle ? (
                                                    <p className="error" style={styleError}>{formik.errors.politicallyExposePersonTitle}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Official or Immediate Family Members/Members<span className="error-info">*</span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="politicallyExposePersonMembers" maxLength={250} disabled={ctrlDisable}
                                                    value={formik.values.politicallyExposePersonMembers} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {formik.touched.politicallyExposePersonMembers && formik.errors.politicallyExposePersonMembers ? (
                                                    <p className="error" style={styleError}>{formik.errors.politicallyExposePersonMembers}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Political Organization<span className="error-info">*</span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="politicallyExposePersonOrganization" maxLength={50} disabled={ctrlDisable}
                                                    value={formik.values.politicallyExposePersonOrganization} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {formik.touched.politicallyExposePersonOrganization && formik.errors.politicallyExposePersonOrganization ? (
                                                    <p className="error" style={styleError}>{formik.errors.politicallyExposePersonOrganization}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Is the account maintained for a foreign financial institution
                                            as defined by Title 31 of the Code of Federal Regulations?<span className="error-info">*</span></label>
                                        {formik.touched.foreignFinancialInstAccount && formik.errors.foreignFinancialInstAccount ? (
                                            <p className="error" style={styleError}>{formik.errors.foreignFinancialInstAccount}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="foreignFinancialInstAccount" onBlur={formik.handleBlur}
                                                onChange={formik.handleChange} disabled={ctrlDisable}
                                                onClick={(e) => setforeignFinancialInstAccountVal(e.target.value)}
                                                checked={showForeignFinancialInstAccount === null ? false : showForeignFinancialInstAccount === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="foreignFinancialInstAccount" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setforeignFinancialInstAccountVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showForeignFinancialInstAccount === null ? false : showForeignFinancialInstAccount === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                {showForeignFinancialInstAccount && <div className="row">
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Name of Foreign Financial Institution<span className="error-info">*</span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="foreignFinancialInstitutionName" maxLength={50} disabled={ctrlDisable}
                                                    value={formik.values.foreignFinancialInstitutionName} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {formik.touched.foreignFinancialInstitutionName && formik.errors.foreignFinancialInstitutionName ? (
                                                    <p className="error" style={styleError}>{formik.errors.foreignFinancialInstitutionName}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Is the account a foreign bank organized under foreign law and
                                            located outside of the United States as defined by Title 31 of the code of
                                            Federal Regulations?<span className="error-info">*</span></label>
                                        {formik.touched.foreignBankOrgAccount && formik.errors.foreignBankOrgAccount ? (
                                            <p className="error" style={styleError}>{formik.errors.foreignBankOrgAccount}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="foreignBankOrgAccount" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setforeignBankOrgAccountVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showforeignBankOrgAccount === null ? false : showforeignBankOrgAccount === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="foreignBankOrgAccount" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setforeignBankOrgAccountVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showforeignBankOrgAccount === null ? false : showforeignBankOrgAccount === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                {showforeignBankOrgAccount && <div className="row">
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Provide Name of Agent<span className="error-info">*</span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="foreignBankOrgAccountAgent" maxLength={50} disabled={ctrlDisable}
                                                    value={formik.values.foreignBankOrgAccountAgent} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {formik.touched.foreignBankOrgAccountAgent && formik.errors.foreignBankOrgAccountAgent ? (
                                                    <p className="error" style={styleError}>{formik.errors.foreignBankOrgAccountAgent}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Is the account a PAB account, meaning a proprietary securities
                                            account of a broker or dealer as defined by SEC 15c3-3(a)(16)?<span className="error-info">*</span></label>
                                        {formik.touched.pabAccount && formik.errors.pabAccount ? (
                                            <p className="error" style={styleError}>{formik.errors.pabAccount}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="pabAccount" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setPabAccountVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showPabAccount === null ? false : showPabAccount === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="pabAccount" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setPabAccountVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showPabAccount === null ? false : showPabAccount === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Have you granted trading authority to a third party?<span className="error-info">*</span></label>
                                        {formik.touched.thirdPartyTrading && formik.errors.thirdPartyTrading ? (
                                            <p className="error" style={styleError}>{formik.errors.thirdPartyTrading}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="thirdPartyTrading" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setThirdPartyTradingVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showThirdPartyTrading === null ? false : showThirdPartyTrading === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="thirdPartyTrading" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setThirdPartyTradingVal(e.target.value)} disabled={ctrlDisable}
                                                checked={showThirdPartyTrading === null ? false : showThirdPartyTrading === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                {showThirdPartyTrading && <div className="row">
                                    <div className="col-lg-4">
                                        <div className="input_wrapper">
                                            <p className="paragraph">Provide Name of Agent<span className="error-info"><span className="error-info">*</span></span></p>
                                            <div className="input_area">
                                                <input type="text" className="inputForm" name="anotherPartyTradingAgent" onBlur={formik.handleBlur} disabled={ctrlDisable}
                                                    maxLength={50} onChange={formik.handleChange} value={formik.values.anotherPartyTradingAgent} />
                                                {formik.touched.anotherPartyTradingAgent && formik.errors.anotherPartyTradingAgent ? (
                                                    <p className="error" style={styleError}>{formik.errors.anotherPartyTradingAgent}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {/* FIS Question 3 */}
                                <div className="row">
                                    <div className='col-lg-10'>
                                        <label className="form-label">Is the customer a senior officer of a bank, savings and loan institution, insurance company, registered investment
                                            advisory firm, registered investment company, or a person who could influence the trading of investments in an institutional type of account?<span className="error-info">*</span></label>
                                        {formik.touched.isInstitutionalTypeAccount && formik.errors.isInstitutionalTypeAccount ? (
                                            <p className="error" style={styleError}>{formik.errors.isInstitutionalTypeAccount}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isInstitutionalTypeAccount" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsInstitutionalTypeAccountVal(e.target.value)}
                                                checked={showIsInstitutionalTypeAccount === null ? false : showIsInstitutionalTypeAccount === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isInstitutionalTypeAccount" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsInstitutionalTypeAccountVal(e.target.value)}
                                                checked={showIsInstitutionalTypeAccount === null ? false : showIsInstitutionalTypeAccount === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                {/* FIS Question 4 */}
                                <div className="row">
                                    <div className='col-lg-10'>
                                        <label className="form-label">Is the customers company owned by or controlled by a public corporation?<span className="error-info">*</span></label>
                                        {formik.touched.isControlledByPublicCorp && formik.errors.isControlledByPublicCorp ? (
                                            <p className="error" style={styleError}>{formik.errors.isControlledByPublicCorp}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isControlledByPublicCorp" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsControlledByPublicCorpVal(e.target.value)}
                                                checked={showIsControlledByPublicCorp === null ? false : showIsControlledByPublicCorp === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isControlledByPublicCorp" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsControlledByPublicCorpVal(e.target.value)}
                                                checked={showIsControlledByPublicCorp === null ? false : showIsControlledByPublicCorp === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>

                                </div>
                                {/* FIS Question 5 */}
                                <div className="row">
                                    <div className='col-lg-10'>
                                        <label className="form-label">Is the customers business owned, controlled by, or under common control of a broker?<span className="error-info">*</span></label>
                                        {formik.touched.isUnderBrokerControl && formik.errors.isUnderBrokerControl ? (
                                            <p className="error" style={styleError}>{formik.errors.isUnderBrokerControl}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isUnderBrokerControl" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsUnderBrokerControlVal(e.target.value)}
                                                checked={showIsUnderBrokerControl === null ? false : showIsUnderBrokerControl === true ? false : true}
                                                value={false} />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isUnderBrokerControl" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setIsUnderBrokerControlVal(e.target.value)}
                                                checked={showIsUnderBrokerControl === null ? false : showIsUnderBrokerControl === true ? true : false}
                                                value={true} />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>

                                </div>

                                {/* FIS - if q1 to q3 anyone will be Y, this section will be displayed otherwise not */}
                                <div className='pt-4'>
                                    {(primaryShareHolder || primaryTradeAuthority || showIsInstitutionalTypeAccount) && <div className="row">
                                        <div className="col-lg-4">
                                            <div className="input_wrapper">
                                                <p className="paragraph">Relationship<span className="error-info">*</span></p>
                                                <div className="input_area">
                                                    <input type="text" className="inputForm"
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q1to3Relationship}
                                                        maxLength="50" name="q1to3Relationship" disabled={ctrlDisable}
                                                        placeholder="Enter Relationship" />
                                                    {formik.touched.q1to3Relationship && formik.errors.q1to3Relationship ? (
                                                        <p className="error">{formik.errors.q1to3Relationship}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input_wrapper">
                                                <p className="paragraph">Person Name<span className="error-info">*</span></p>
                                                <div className="input_area">
                                                    <input type="text" className="inputForm"
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q1to3PersonName}
                                                        maxLength="50" name="q1to3PersonName" disabled={ctrlDisable}
                                                        placeholder="Enter Person Name" />
                                                    {formik.touched.q1to3PersonName && formik.errors.q1to3PersonName ? (
                                                        <p className="error">{formik.errors.q1to3PersonName}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input_wrapper">
                                                <p className="paragraph">Firm/Employer<span className="error-info">*</span></p>
                                                <div className="input_area">
                                                    <input type="text" className="inputForm"
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q1to3FirmEmp}
                                                        maxLength="50" name="q1to3FirmEmp" disabled={ctrlDisable}
                                                        placeholder="Enter Firm/Employer" />
                                                    {formik.touched.q1to3FirmEmp && formik.errors.q1to3FirmEmp ? (
                                                        <p className="error">{formik.errors.q1to3FirmEmp}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* FIS - if q4 to q5 anyone will be Y, this section will be displayed otherwise not */}
                                    {(showIsControlledByPublicCorp || showIsUnderBrokerControl) && <>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Corporation Name<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpName}
                                                            maxLength="50" name="q4to5CorpName" disabled={ctrlDisable} placeholder="Enter Corporation Name" />
                                                        {formik.touched.q4to5CorpName && formik.errors.q4to5CorpName ? (
                                                            <p className="error">{formik.errors.q4to5CorpName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Address 1<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpAddress1}
                                                            maxLength="50" name="q4to5CorpAddress1" disabled={ctrlDisable} placeholder="Enter Address 1" />
                                                        {formik.touched.q4to5CorpAddress1 && formik.errors.q4to5CorpAddress1 ? (
                                                            <p className="error">{formik.errors.q4to5CorpAddress1}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Address 2</p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpAddress2}
                                                            maxLength="50" name="q4to5CorpAddress2" disabled={ctrlDisable} placeholder="Enter Address 2" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">City<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpCity}
                                                            maxLength="50" name="q4to5CorpCity" disabled={ctrlDisable} placeholder="Enter City" />
                                                        {formik.touched.q4to5CorpCity && formik.errors.q4to5CorpCity ? (
                                                            <p className="error">{formik.errors.q4to5CorpCity}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Country<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <select className="form-select" name="q4to5CorpCountry" disabled={ctrlDisable}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("q4to5CorpCountry", e.target.value);
                                                                formik.setFieldValue("q4to5CorpState", ''); handleCountryChange(e);
                                                            }}
                                                            onBlur={formik.handleBlur} defaultValue={defaultCountry} value={formik.values.q4to5CorpCountry}>
                                                            {countryList && countryList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.q4to5CorpCountry && formik.errors.q4to5CorpCountry ? (
                                                            <p className="error">{formik.errors.q4to5CorpCountry}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">State<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <select className="form-select" name="q4to5CorpState" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpState} disabled={ctrlDisable}>
                                                            <option value="">Select State</option>
                                                            {stateList && stateList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.q4to5CorpState && formik.errors.q4to5CorpState ? (
                                                            <p className="error">{formik.errors.q4to5CorpState}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <p className="paragraph">Zip Code<span className="error-info">*</span></p>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.q4to5CorpZipCode}
                                                            maxLength="10" name="q4to5CorpZipCode" disabled={ctrlDisable} placeholder="Enter Zip Code" />
                                                        {formik.touched.q4to5CorpZipCode && formik.errors.q4to5CorpZipCode ? (
                                                            <p className="error">{formik.errors.q4to5CorpZipCode}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Direct Communication-Rule 14 B-1(C)</h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-xxl-9 col-7'>
                                        <label className="form-label">Direct Communication Rule (Rule 14b-1(c)) of the Securities Exchange Act, unless you object, requires us 
                                        to disclose to an issuer, upon its request, the names, addresses, and securities positions of our customers who are beneficial owners 
                                        of the issuer’s securities, held by us in nominee name. The issuer would be permitted to use your name and other related information 
                                        for corporation communication only. If you object to this disclosure, Check the options below.<span className="error-info">*</span>
                                        </label>
                                    </div>
                                    <div className='col-xxl-3 col-5'>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="rule14B1CAccepted" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setRule14bVal(e.target.value)} disabled={ctrlDisable}
                                                checked={rule14b === null ? false : rule14b === true ? false : true}
                                                value={false} />I don't object
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="rule14B1CAccepted" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                onClick={(e) => setRule14bVal(e.target.value)} disabled={ctrlDisable}
                                                checked={rule14b === null ? false : rule14b === true ? true : false}
                                                value={true} />I object
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                        {formik.touched.rule14B1CAccepted && formik.errors.rule14B1CAccepted ? (
                                            <p className="error float-end" style={styleError}>{formik.errors.rule14B1CAccepted}</p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10">
                                        {/* <div className="">
                                            <label className="form-label mt-2">Is the account maintained for a foreign financial institution as defined by Title
                                                31 of the code of Federal Regulations?<span className="error-info">*</span></label>
                                        </div> */}

                                    </div>
                                    <div className="col-2">
                                    </div>
                                </div>
                            </div>

                            {// regulatoryInfo && regulatoryInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement &&
                                isForeignCitizenship && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">W-8 BEN<span className="error-info">*</span></h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <p>I certify that beneficial owner is resident of
                                                <div style={{ display: 'inline-grid' }}>
                                                    <input type="text" name="ownerResidenceW8"
                                                        className="innerTextw8" value={formik.values.ownerResidenceW8} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                    {formik.touched.ownerResidenceW8 && formik.errors.ownerResidenceW8 ? (
                                                        <p className="error" style={styleError}>{formik.errors.ownerResidenceW8}</p>
                                                    ) : null}
                                                </div>
                                                within the meaning of income tax treaty between the United State and that country.

                                            </p>
                                            <h3 className="heading">Special rates and conditions: (if applicable)</h3>
                                            <p>The beneficial owner is claiming of provisions of article and paragraph
                                                <div style={{ display: 'inline-grid' }}>
                                                    <input type="text" className="innerTextw8" name="ownerClaimW8" value={formik.values.ownerClaimW8}
                                                        onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                    {/* {formik.touched.ownerClaimW8 && formik.errors.ownerClaimW8 ? (
                                                        <p className="error" style={styleError}>{formik.errors.ownerClaimW8}</p>
                                                    ) : null} */}
                                                </div>
                                                He can claim a
                                                <div style={{ display: 'inline-grid' }}>
                                                    <input type="text" maxLength="3" min={1} max={100} className="innerTextw8" inputMode='numeric' name="witholdingPercentageW8" value={formik.values.witholdingPercentageW8} 
                                                    onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                    {/* {formik.touched.witholdingPercentageW8 && formik.errors.witholdingPercentageW8 ? (
                                                        <p className="error" style={styleError}>{formik.errors.witholdingPercentageW8}</p>
                                                    ) : null} */}
                                                </div>
                                                % rate if withholding on (specific type of income).</p>


                                            <h3 className="heading">Explain the additional conditions in the article and paragraph the beneficial
                                                owner meets to be eligible for the rate of withholding:
                                            </h3>
                                            <div style={{ display: 'inline-grid' }}>
                                                <input type="text" className="innerTextw8Textbox" name="eligibleForRateWithholding" value={formik.values.eligibleForRateWithholding}
                                                    onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                                {/* {formik.touched.eligibleForRateWithholding && formik.errors.eligibleForRateWithholding ? (
                                                    <p className="error" style={styleError}>{formik.errors.eligibleForRateWithholding}</p>
                                                ) : null} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(false)} disabled={ctrlDisable}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );
};

export default Regulatory;