import FormNav from "../../components/FormNav";
import RegulatoryEntityInfo from "../../components/RegulatoryEntityInfo";
import Header from "../../layouts/header/header";
import LeftNav from "../../layouts/leftnav/leftnav";

const RegulatoryEntityItems = () => {
    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <FormNav />
                    <RegulatoryEntityInfo />
                </section>
            </section>
        </>
    );
};

export default RegulatoryEntityItems;