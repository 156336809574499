import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import accountService from '../../services/accountservice';
import { toast } from 'react-toastify';
import loader from "super-react-loader";

const ResetPassword = () => {

    const [isNewPassShow, setIsNewPassShow] = useState(false);
    const [isConPassShow, setIsConPassShow] = useState(false);

    let formikValues = {
        username: "", email: "", password: "", confirmpassword: ""
    };

    let INITIAL_FORM_STATE = formikValues;
    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email("Enter a valid email address").required("Email address is required"),
        password: Yup.string().required("Password is required").min(8),
        confirmpassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref("password"), null], "Password and confirm password doesn’t match")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        //validate: onChangePassword,

        onSubmit: (values) => {
            loader.show();
            let tempUrl = window.location.href.split('=');
            let code = tempUrl[1];
            let data = {
                email: values.email,
                password: values.password,
                confirmpassword: values.confirmpassword,
                verificationCode: code
            };
            accountService.resetPassword(data)
                .then((json) => {
                    if (json.data.errorCode !== 0) {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else {
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        formik.resetForm(formik.initialValues);
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const showNewPassword = () => {
        if (isNewPassShow) {
            setIsNewPassShow(false);
        }
        else {
            setIsNewPassShow(true);
        }
    }

    const showConfirmPassword = () => {
        if (isConPassShow) {
            setIsConPassShow(false);
        }
        else {
            setIsConPassShow(true);
        }
    }

    return (
        <>
            <section className="mode" data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid">
                    <div className="light-dark-mod">

                    </div>
                    <section className="trading_card ">
                        <h1>Reset Password</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input_wrapper">
                                <label htmlFor='email' className="form-label">Email</label>
                                <div className="input_area">
                                    {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                                    <input name='email' id='email' autoComplete='off' type="email" className="inputForm"
                                    onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} placeholder="Enter an email address" />
                                    {formik.touched.email && formik.errors.email ? (<p className="error">{formik.errors.email}</p>) : null}
                                </div>
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor='password' className="form-label">New Password</label>
                                <div className="input_area">
                                {!isNewPassShow && <i className="ico_fa fa fa-eye" aria-hidden="true" onClick={(e) => showNewPassword()}></i>}
                                {isNewPassShow && <i className="ico_fa fa fa-eye-slash" aria-hidden="true" onClick={(e) => showNewPassword()}></i>}
                                    <input name='password' id='password' autoComplete='off' type={isNewPassShow ? "text" : "password"} className="inputForm" maxLength={20}
                                        onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.password} placeholder="Enter a password" />
                                    {formik.touched.password && formik.errors.password ? (
                                        <p className="error">{formik.errors.password}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="input_wrapper">
                                <label htmlFor='confirmpassword' className="form-label">Confirm Password</label>
                                <div className="input_area">
                                {!isConPassShow && <i className="ico_fa fa fa-eye" aria-hidden="true" onClick={(e) => showConfirmPassword()}></i>}
                                {isConPassShow && <i className="ico_fa fa fa-eye-slash" aria-hidden="true" onClick={(e) => showConfirmPassword()}></i>}
                                    <input name='confirmpassword' id='confirmpassword'autoComplete='off' type={isConPassShow ? "text" : "password"}  className="inputForm" onBlur={formik.handleBlur}
                                        onChange={formik.handleChange} value={formik.values.confirmpassword} placeholder="Enter a re-password" />
                                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                        <p className="error">{formik.errors.confirmpassword}</p>
                                    ) : null}
                                </div>
                            </div>
                            <button type="submit" className="btn login_btn">Reset Password</button>
                        </form>
                        <Link to="/Login" className="signup_area">Go to <i className="color_orange">Sign in</i> page</Link>
                    </section>
                </div>
            </section>

        </>
    )
}

export default ResetPassword;