import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import loader from "super-react-loader";
import { toast } from 'react-toastify';
import accountService from "../../services/accountservice";
import { checkAuthorization, getLoggedInUserInfo } from '../../common/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { adminRole, brokerRole, rrRole, supervisorRole24, supervisorRole4 } from '../../helpers/constants';

const ChangePasswordComponent = () => {

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);

    const styleLabelWidth = {
        width: '420px'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getUserProfileInfo();
    }, []);

    const schema = Yup.object().shape({
        oldpassword: Yup.string().required("Old password is required").min(6, "Mininum 6 characters required").nullable(),
        newpassword: Yup.string().required("New password is required").min(6, "Mininum 6 characters required").nullable(),
        confirmpassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref("newpassword"), null], "New password and confirm password doesn't match"),
    });

    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(schema),
    })

    const getUserProfileInfo = () => {
        loader.show();
        accountService.getUserProfileInfo().then((json) => {
            let result = json.data.payload;
            if (result !== null) {
                setUserInfo(result);
                loader.hide();
            }
            loader.hide();
        })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const redirectToDashboard = () => {
        var currentUser = getLoggedInUserInfo();
        if (currentUser.userRole === adminRole) {
            navigate("/admin/dashboard");
        }
        else if (currentUser.userRole === brokerRole) {
            navigate("/broker/dashboard");
        }
        else if (currentUser.userRole === supervisorRole24 || currentUser.userRole === supervisorRole4) {
            navigate("/supervisor/dashboard");
        }
        else if (currentUser.userRole === rrRole) {
            navigate("/registerrep/dashboard");
        }
        else {
            navigate("/dashboard");
        }
    }

    const onSubmit = data => {
        let body = {
            email: userInfo?.emailaddress,
            oldpassword: data.oldpassword,
            newpassword: data.newpassword
        };
        accountService.updatePassword(body)
            .then((json) => {
                if (json.data.payload) {
                    toast.success("Password has been changed successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    reset();
                    loader.hide();
                    redirectToDashboard();
                }
                else {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <h2 className="heading">Change Password</h2>
                </div>
            </div>
            <div className="tab-content" id="reviews">
                <div className="formContainer no-mar">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='row'>
                                            <div className="rows">
                                                <div className="col-lg-4">
                                                    <label className="form-label" style={styleLabelWidth}>Old Password<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="password" {...register('oldpassword')} className="inputForm" name="oldpassword" />
                                                        <p className="error">{errors?.oldpassword && errors.oldpassword.message}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="rows">
                                                <div className="col-lg-4">
                                                    <label className="form-label" style={styleLabelWidth}>New Password<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="password" {...register('newpassword')} className="inputForm" name="newpassword" />
                                                        <p className="error">{errors?.newpassword && errors.newpassword.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rows">
                                                <div className="col-lg-4">
                                                    <label className="form-label" style={styleLabelWidth}>Confirm Password<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="password" {...register('confirmpassword')} className="inputForm" name="confirmpassword" />
                                                        <p className="error">{errors?.confirmpassword && errors.confirmpassword.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' >Change Password</button></li>
                        </ul>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ChangePasswordComponent;