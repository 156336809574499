import moment from 'moment';
import React, { useEffect, useState, CSSProperties } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, convertUTCToLocalTime, getCurrentApplicationId, getLoggedInUserInfo, hideForeignTaxId, hideSSN, strToUpperCase, toTitleCase } from '../common/common';
import applicationService from '../services/applicationservice';
import microbiltService from '../services/microbiltservice';
import OLAEnums from '../helpers/olaenums';
import { defaultCountry, domainURL } from '../helpers/constants';
import loader from "super-react-loader";
import Modal from 'react-bootstrap/Modal';

const Review = () => {

    const navigate = useNavigate();
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [applicantList, setApplicantList] = useState(null);
    const [trustedContactInfo, setTrustedContactInfo] = useState(null);
    const [employmentInfoList, setEmploymentInfoList] = useState(null);
    const [investorProfileInfo, setInvestorProfileInfo] = useState(null);
    const [applicationACHInfo, setApplicationACHInfo] = useState(null);
    const [regulatoryItemsInfo, setRegulatoryItemsInfo] = useState(null);
    const [accountFeaturesInfo, setAccountFeaturesInfo] = useState(null);
    const [showEditButton, setShowEditButton] = useState(false);
    const [appDocumentList, setAppDocumentList] = useState(null);
    const [appSignatureList, setAppSignatureList] = useState(null);
    const [isForeignApplicant, setIsForeignApplicant] = useState(null);

    //For Entity Account Type
    const [entityInformationInfo, setEntityInformationInfo] = useState(null);
    const [isPersonalAccountType, setIsPersonalAccountType] = useState(null);
    const [isEntityAccountType, setIsEntityAccountType] = useState(null);
    const [isRetirementAccountType, setIsRetirementAccountType] = useState(null);
    const [entityDueDiligence, setEntityDueDiligence] = useState(null);
    const [entityOfficerList, setEntityOfficerList] = useState(null);
    const [entityBeneficialOwnerList, setEntityBeneficialOwnerList] = useState(null);
    const [disclosureInfo, setDisclosureInfo] = useState(null);
    const [fullyPaidDocUrl, setFullyPaidDocUrl] = useState(null);
    const [acctAgreementUrl, setAcctAgreementUrl] = useState(null);
    const [marginAgreementUrl, setMarginAgreementUrl] = useState(null);
    const [showFPLOption, setShowFPLOption] = useState(false);
    const [authSigners, setAuthSigners] = useState(null);

    //For Retirement Account Type
    const [beneficiaryList, setBeneficiaryList] = useState(null);
    //Microbilt modal popup
    const [mbLogShow, setMBLogShow] = useState(false);
    const handleMBLogClose = () => setMBLogShow(false);

    const stylesW8 = {
        color: '#212529', fontWeight: 'bold', paddingLeft: '3px', paddingRight: '3px'
    };

    const styles = {
        color: 'blue', fontWeight: '200', cursor: 'pointer',
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicationReview();
    }, []);

    const getApplicationReview = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId()
        }
        applicationService.getApplicationReview(data)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    if (result?.applicationInfo !== null) {
                        setApplicationInfo(result?.applicationInfo);
                        if (result?.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setShowEditButton(false);
                        }
                        else {
                            setShowEditButton(true);
                        }
                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Personal) {
                            setIsPersonalAccountType(true);
                        }
                        //now check if the account type is entity account
                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                            setEntityInformationInfo(result?.entityInformationInfo);
                            setIsEntityAccountType(true);
                        }

                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            setIsRetirementAccountType(true);
                        }
                    }
                    else {
                        setApplicationInfo(null);
                    }
                    if (result?.applicantList !== null) {
                        setApplicantList(result?.applicantList);
                        if (result?.applicantList[0]?.citizenshipCountryId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                    }
                    else {
                        setApplicantList(null);
                    }
                    if (result?.trustedContactInfo !== null) {
                        setTrustedContactInfo(result?.trustedContactInfo);
                    }
                    else {
                        setTrustedContactInfo(null);
                    }
                    if (result?.employmentInfoList !== null) {
                        setEmploymentInfoList(result?.employmentInfoList);
                    }
                    else {
                        setEmploymentInfoList(null);
                    }
                    if (result?.investorProfileInfo !== null) {
                        setInvestorProfileInfo(result?.investorProfileInfo);
                        if (result.investorProfileInfo.investmentExperience === parseInt(OLAEnums.InvestmentExpEnum.None)
                            && result.investorProfileInfo.totalNetWorth === parseInt(OLAEnums.TotalNetWorthEnum['$0-24,999'])) {
                            setShowFPLOption(false);
                        }
                        else {
                            setShowFPLOption(true);
                        }
                    }
                    else {
                        setInvestorProfileInfo(null);
                    }
                    if (result?.applicationACHInfo !== null) {
                        setApplicationACHInfo(result?.applicationACHInfo);
                    }
                    else {
                        setApplicationACHInfo(null);
                    }
                    if (result?.regulatoryItemsInfo !== null) {
                        setRegulatoryItemsInfo(result?.regulatoryItemsInfo);
                    }
                    else {
                        setRegulatoryItemsInfo(null);
                    }
                    if (result?.accountFeaturesInfo !== null) {
                        setAccountFeaturesInfo(result?.accountFeaturesInfo);
                    }
                    else {
                        setAccountFeaturesInfo(null);
                    }

                    if (result?.entityInformationInfo !== null) {
                        if (result?.entityInformationInfo?.citizenshipId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                        else if (result?.entityInformationInfo?.originCountryId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                        else {
                            setIsForeignApplicant(false);
                        }
                    }

                    if (result?.entitySignerInfoList !== null) {
                        setAuthSigners(result?.entitySignerInfoList);
                    }

                    if (result?.entityDueDeligenceInfo !== null) {
                        setEntityDueDiligence(result?.entityDueDeligenceInfo);
                    }
                    else {
                        setEntityDueDiligence(null);
                    }
                    if (result?.entityOfficerOwnerInfoList !== null && result?.entityOfficerOwnerInfoList.length > 0) {
                        setEntityOfficerList(result?.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Official));
                        setEntityBeneficialOwnerList(result?.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Owner));
                    }
                    else {
                        setEntityOfficerList(null);
                        setEntityBeneficialOwnerList(null);
                    }
                    if (result?.applicationBeneficiaryList != null && result?.applicationBeneficiaryList.length > 0) {
                        setBeneficiaryList(result?.applicationBeneficiaryList);
                    }
                    else {
                        setBeneficiaryList(null);
                    }
                    if (result?.applicationDocumentsList != null && result?.applicationDocumentsList.length > 0) {
                        setAppDocumentList(result?.applicationDocumentsList);
                    }
                    else {
                        setAppDocumentList(null);
                    }
                    if (result?.applicationDisclousersInfo != null) {
                        setDisclosureInfo(result?.applicationDisclousersInfo);
                    }
                    else {
                        setDisclosureInfo(null);
                    }
                    if (result?.applicationSignatureList != null && result?.applicationSignatureList.length > 0) {
                        setAppSignatureList(result?.applicationSignatureList);
                        setFullyPaidDocUrl(result?.fullyPaidAgreementUrl);
                        setAcctAgreementUrl(result?.accountAgreementUrl);
                        setMarginAgreementUrl(result?.marginCashAgreementUrl);
                    }
                    else {
                        setAppSignatureList(null);
                    }
                    loader.hide();
                }
                else {
                    setApplicationInfo(null);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //For Microbilt
    // const submitReview = () => {
    //     loader.show();
    //     setMBLogShow(true);
    //     let data = {
    //         ApplicationId: getCurrentApplicationId(),
    //         UserId: getLoggedInUserInfo().id,
    //     }
    //     microbiltService.sendRequesttoMicrobilt(data)
    //         .then((json) => {
    //             if (json.data.payload === true) {
    //                 submitAfterVerification();
    //                 loader.hide();
    //                 setMBLogShow(false);
    //             }
    //             else {
    //                 toast.error("Application has not verified by the Microbilt", {
    //                     position: toast.POSITION.TOP_RIGHT
    //                 });
    //                 loader.hide();
    //                 setMBLogShow(false);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error(error, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //             loader.hide();
    //         });
    // }
    //For Giact
    // const submitReview = () => {
    //     loader.show();
    //     setMBLogShow(true);
    //     let data = {
    //         ApplicationId: getCurrentApplicationId(),
    //         UserId: getLoggedInUserInfo().id,
    //     }
    //     applicationService.sendRequestToGiact(data)
    //         .then((json) => {
    //             if (json.data.payload === true) {
    //                 submitAfterVerification();
    //                 loader.hide();
    //                 setMBLogShow(false);
    //             }
    //             else {
    //                 toast.error(json.data.message, {
    //                     position: toast.POSITION.TOP_RIGHT
    //                 });
    //                 loader.hide();
    //                 setMBLogShow(false);
    //             }
    //         })
    //         .catch((error) => {
    //             checkAuthorization(error);
    //             toast.error(error, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //             loader.hide();
    //         });
    // }

    //Submit without sending to any third party vendor

    const submitReview = () => {
        submitAfterVerification();
    }

    const submitAfterVerification = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            ApplicationStatus: OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval,
            AlertTypeId: OLAEnums.AlertTypeEnum.ApplicationPendingApproval,
            Comment: "",
            CreatedBy: "Applicant"
        }
        applicationService.updateApplicationStatus(data)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    loader.hide();
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const editPersonalInfo = () => {
        navigate('/personal-info');
    }

    const editEmploymentInfo = () => {
        navigate('/employment-info');
    }

    const editInvestorProfile = () => {
        navigate('/investor-profile');
    }

    const editInvestorEntityProfile = () => {
        navigate('/investor-entity-profile');
    }

    const editRegulatoryItems = () => {
        navigate('/regulatory-items');
    }

    const editRegulatoryEntityItems = () => {
        navigate('/regulatory-entity-items');
    }

    const editAccountFeatures = () => {
        navigate('/account-features');
    }

    const gotoDashboard = () => {
        navigate('/dashboard');
    }

    const editEntityInfo = () => {
        navigate('/entity-info');
    }

    const editDueDiligenceForm = () => {
        navigate('/due-diligence-info')
    }

    const editOwnersOfficials = () => {
        navigate('/owners-officials');
    }

    const editIRABeneficiaries = () => {
        navigate('/ira-beneficiaries');
    }

    const editUploadedDocuments = () => {
        navigate('/upload-documents');
    }

    const editDisclosureAndSign = () => {
        navigate('/disclosures-signatures')
    }

    const handlePrint = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            isSendEmail: null
        }
        applicationService.generateApplicationPdf(data)
            .then((json) => {
                let fileName = json.data.payload;
                if (fileName !== null) {
                    //window.open(domainURL + "pdf/" + fileName, "PRINT", "height=600,width=800&embedded=true");
                    applicationService.getPrintedPdfFile(fileName)
                        .then((json) => {
                            if (json.data !== null) {
                                var blob = json.data;
                                const url = window.URL.createObjectURL(new Blob([blob]));
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((error) => {
                            checkAuthorization(error);
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });

                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const gotoPreviousPage = () => {
        navigate('/disclosures-signatures');
    }

    const openFullyPaidAgreementUrl = () => {
        window.open(fullyPaidDocUrl, '_blank');
    }

    const openAccountAgreementUrl = () => {
        window.open(acctAgreementUrl, '_blank');
    }

    const openMarginAgreementUrl = () => {
        window.open(marginAgreementUrl, '_blank');
    }

    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    return (
        <>
            <div className="tab-content sweet-loading" id="reviews">
                <div className="formContainer">
                    <div id="personal" className="container-fluid">
                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2 className="heading">Review Application & Submit</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading">Account Information</div>
                                    <table className="table border ">
                                        <tbody>
                                            <tr>
                                                <td className="review-label">Account Type</td>
                                                <td>{applicationInfo && applicationInfo?.accountTypeName}</td>
                                                <td className="review-label">Ownership Type</td>
                                                <td colSpan={3}>{applicationInfo && applicationInfo?.ownershipType}</td>
                                            </tr>
                                            {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint &&
                                                <tr>
                                                    <td className="review-label">Joint Account Sub Type</td>
                                                    <td>{applicationInfo && applicationInfo?.jointSubTypeName}</td>
                                                    <td colSpan={3}></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {!isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Personal Information </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editPersonalInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>
                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Personal Information</h2></td>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Physical Address</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">First Name</td><td>{applicantList && applicantList[0]?.firstName}</td>
                                                    <td className="review-label">Address 1</td><td>{applicantList && applicantList[0]?.physicalAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Middle Name</td><td>{applicantList && applicantList[0]?.middleName !== '' ? applicantList[0]?.middleName : "-"}</td>
                                                    <td className="review-label">Address 2</td><td>{applicantList && applicantList[0]?.physicalAddress2 ? applicantList[0]?.physicalAddress2 : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Last Name</td><td>{applicantList && applicantList[0]?.lastName}</td>
                                                    <td className="review-label">Country</td><td>{applicantList && applicantList[0]?.countryFullName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Date of Birth</td><td>{applicantList && moment(applicantList[0]?.dateofBirth).format("MM/DD/YYYY")}</td>
                                                    {applicantList && applicantList[0] && <>
                                                        <td className="review-label">State</td><td>{applicantList && applicantList[0]?.stateName ? applicantList[0]?.stateName : "-"}</td>
                                                    </>}
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Number of Dependents</td><td>{applicantList && applicantList[0]?.noofDependents}</td>
                                                    <td className="review-label">City</td><td>{applicantList && applicantList[0]?.city}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Marital Status</td><td>{applicantList && applicantList[0]?.maritalStatusName}</td>
                                                    <td className="review-label">Postal Code</td><td>{applicantList && applicantList[0]?.zipCode}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Country of Citizenship</td><td>{applicantList && applicantList[0]?.citizenshipCountry}</td>
                                                </tr>
                                                <tr>
                                                    {applicantList && applicantList[0] &&
                                                        <>
                                                            <td className="review-label">Social Security Number</td>
                                                            <td title={applicantList && applicantList[0]?.socialSecurityNo !== null ? applicantList[0]?.socialSecurityNo : ""}>
                                                                {applicantList && applicantList[0]?.socialSecurityNo !== null ? hideSSN(applicantList[0]?.socialSecurityNo, 7) : "-"}</td></>
                                                    }
                                                </tr>
                                                <tr>
                                                    {applicantList && applicantList[0] &&
                                                        <>
                                                            <td className="review-label">Foreign Tax Id</td>
                                                            <td title={applicantList && applicantList[0]?.foreignTaxId !== null ? applicantList[0]?.foreignTaxId : ""}>
                                                                {applicantList && applicantList[0]?.foreignTaxId !== null ? applicantList[0]?.foreignTaxId : "-"}</td></>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Email</td><td>{applicantList && applicantList[0]?.emailAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Primary Telephone</td><td>{applicantList && applicantList[0]?.homePhoneNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Work Telephone</td><td>{applicantList && applicantList[0]?.workPhoneNumber ? applicantList[0]?.workPhoneNumber : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Are you a US citizen?</td>
                                                    <td>{applicantList && applicantList[0]?.isUSCitizen ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Are you a US permanent resident?</td>
                                                    <td>{applicantList && applicantList[0]?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is this a foreign account?</td>
                                                    <td>{applicantList && applicantList[0]?.isForeignAccount ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Type</td>
                                                    <td>{applicantList && applicantList[0]?.idTypeName !== null ? applicantList[0]?.idTypeName : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Number</td>
                                                    <td>{applicantList && applicantList[0]?.idNumber !== null ? applicantList[0]?.idNumber : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Issue Date</td>
                                                    <td>{applicantList && applicantList[0]?.issueDate !== null ? moment(applicantList[0]?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Expiration Date</td>
                                                    <td>{applicantList && applicantList[0]?.expirationDate !== null ? moment(applicantList[0]?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Issuance State</td>
                                                    <td>{applicantList && applicantList[0]?.issuanceStateName !== null ? applicantList[0]?.issuanceStateName : "-"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Entity Information </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editEntityInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>
                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Entity Information</h2></td>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Mailing Preference</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Entity Name</td><td>{entityInformationInfo && entityInformationInfo?.entityName}</td>
                                                    <td style={{ wordBreak: 'break-word' }} className="review-label">Mailing Address 1</td><td>{entityInformationInfo && entityInformationInfo?.mailingAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Mailing Address 2</td><td>{entityInformationInfo && entityInformationInfo?.mailingAddress2 ? entityInformationInfo?.mailingAddress2 : "-"}</td>
                                                    <td className="review-label">EIN/SSN</td><td title={entityInformationInfo && entityInformationInfo?.ssnein}>
                                                        {entityInformationInfo && hideSSN(entityInformationInfo?.ssnein, 7)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">State</td><td>{entityInformationInfo && entityInformationInfo?.mailingStateName ? entityInformationInfo?.mailingStateName : "-"}</td>
                                                    <td className="review-label">Type of Entity</td><td>{entityInformationInfo && entityInformationInfo?.taxClassificationName}</td>

                                                </tr>
                                                <tr>
                                                    <td className="review-label">City</td><td>{entityInformationInfo && entityInformationInfo?.mailingCity}</td>
                                                    <td className="review-label">Business Phone</td><td>{entityInformationInfo && entityInformationInfo?.businessPhone}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Postal Code</td><td>{entityInformationInfo && entityInformationInfo?.mailingPostalCode}</td>
                                                    <td className="review-label">Origin Country</td><td>{entityInformationInfo && entityInformationInfo?.originCountryName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Country</td><td>{entityInformationInfo && entityInformationInfo?.mailingCountryName}</td>
                                                    <td className="review-label">Origin State</td><td>{entityInformationInfo && entityInformationInfo?.originStateName ? entityInformationInfo?.originStateName : "-"}</td>

                                                </tr>
                                                <tr>
                                                    <td className="review-label">Large Trader Id</td><td>{entityInformationInfo && entityInformationInfo?.largeTradeId}</td>
                                                    <td className="review-label">Entity Resolution Date</td><td>{entityInformationInfo && moment(entityInformationInfo?.resolutionAdoptionDate).format("MM/DD/YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Effective Date</td><td>{entityInformationInfo && moment(entityInformationInfo?.effectiveDate).format("MM/DD/YYYY")}</td>
                                                    <td className="review-label">Business Address 1</td><td>{entityInformationInfo && entityInformationInfo?.businessAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Business Address 2</td><td>{entityInformationInfo && entityInformationInfo?.businessAddress2 ? entityInformationInfo?.businessAddress2 : "-"}</td>
                                                    <td className="review-label">LEI Number</td><td>{entityInformationInfo && entityInformationInfo?.leiNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Business State</td><td>{entityInformationInfo && entityInformationInfo?.stateName}</td>
                                                    <td className="review-label">Business City</td><td>{entityInformationInfo && entityInformationInfo?.city}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Business Postal Code</td><td>{entityInformationInfo && entityInformationInfo?.postalCode}</td>
                                                    <td className="review-label">Business Country</td><td>{entityInformationInfo && entityInformationInfo?.countryName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <table className="table border ">
                                        <tbody>
                                            {authSigners && authSigners.map((signerItem, index) => (
                                                <>
                                                    <tr>
                                                        <td colSpan={4} className="sub_headingTd"><h2>Authorized Signer {index + 1}</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td><td>{signerItem?.firstName}</td>
                                                        <td className="review-label">Middle Name</td><td>{signerItem?.middleName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Last Name</td><td>{signerItem.lastName}</td>
                                                        <td className="review-label">Date of Birth</td><td>{moment(signerItem?.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Email Address</td><td>{signerItem?.emailAddress}</td>
                                                        <td className="review-label">Citizenship</td><td>{signerItem?.citizenshipName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Social Security Number</td><td title={signerItem?.ssn}>{hideSSN(signerItem?.ssn, 7)}</td>
                                                        <td className="review-label">ITIN/Foreign Tax ID </td><td>{signerItem?.foreignTaxId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Are you a US citizen?</td><td>{signerItem?.isUSCitizen ? "Yes" : "No"}</td>
                                                        <td className="review-label">Are you a US permanent resident?</td><td>{signerItem?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is this a foreign account?</td><td>{signerItem?.isForeignAccount ? "Yes" : "No"}</td>
                                                        <td className="review-label">Id Type</td>
                                                        <td>{signerItem?.idTypeName != null ? signerItem?.idTypeName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Number</td>
                                                        <td>{signerItem?.idNumber != null ? signerItem?.idNumber : "-"}</td>
                                                        <td className="review-label">Id Issuance Date</td>
                                                        <td>{signerItem?.issueDate !== null ? moment(signerItem?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Expiration Date</td>
                                                        <td>{signerItem?.expirationDate !== null ? moment(signerItem?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                        <td className="review-label">Id Issuance State</td>
                                                        <td>{signerItem?.issuanceStateName !== null ? signerItem?.issuanceStateName : "-"}</td>
                                                    </tr>
                                                </>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-lg-12'>
                                    <table className="table border ">
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} className="sub_headingTd"><h2>Industrial Classification</h2></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={1} className="review-label">Industrial Classification</td>
                                                <td colSpan={3}>{entityInformationInfo && entityInformationInfo?.industrialClassificationName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>}

                        {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Co-Applicant (Personal Information)</i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editPersonalInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>
                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Personal Information</h2></td>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Physical Address</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">First Name</td><td>{applicantList && applicantList[1]?.firstName}</td>
                                                    <td className="review-label">Address 1</td><td>{applicantList && applicantList[1]?.physicalAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Middle Name</td><td>{applicantList && applicantList[1]?.middleName !== '' ? applicantList[1]?.middleName : "-"}</td>
                                                    <td className="review-label">Address 2</td><td>{applicantList && applicantList[1]?.physicalAddress2 ? applicantList[1]?.physicalAddress2 : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Last Name</td><td>{applicantList && applicantList[1]?.lastName}</td>
                                                    <td className="review-label">Country</td><td>{applicantList && applicantList[1]?.countryFullName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Date of Birth</td><td>{applicantList && moment(applicantList[1]?.dateofBirth).format("MM/DD/YYYY")}</td>
                                                    {applicantList && applicantList[1] && <>
                                                        <td className="review-label">State</td><td>{applicantList && applicantList[1]?.stateName}</td>
                                                    </>}
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Number of Dependents</td><td>{applicantList && applicantList[1]?.noofDependents}</td>
                                                    <td className="review-label">City</td><td>{applicantList && applicantList[1]?.city}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Marital Status</td><td>{applicantList && applicantList[1]?.maritalStatusName}</td>
                                                    <td className="review-label">Postal Code</td><td>{applicantList && applicantList[1]?.zipCode}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Country of Citizenship</td><td>{applicantList && applicantList[1]?.citizenshipCountry}</td>
                                                </tr>
                                                {applicantList && applicantList[1] && <tr>
                                                    <td className="review-label">Social Security Number</td>
                                                    <td title={applicantList && applicantList[1]?.socialSecurityNo !== null ? applicantList[1]?.socialSecurityNo : ""}>
                                                        {applicantList && applicantList[1]?.socialSecurityNo !== null ? hideSSN(applicantList[1]?.socialSecurityNo, 7) : "-"}</td>
                                                </tr>}
                                                {applicantList && applicantList[1] && <tr>
                                                    <td className="review-label">Foreign Tax Id</td>
                                                    <td title={applicantList && applicantList[1]?.foreignTaxId !== null ? applicantList[1]?.foreignTaxId : ""}>
                                                        {applicantList && applicantList[1]?.foreignTaxId !== null ? applicantList[1]?.foreignTaxId : "-"}</td>
                                                </tr>}
                                                <tr>
                                                    <td className="review-label">Email</td><td>{applicantList && applicantList[1]?.emailAddress}</td>
                                                </tr>
                                                <tr>

                                                    <td className="review-label">Primary Telephone</td><td>{applicantList && applicantList[1]?.homePhoneNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Work Telephone</td><td>{applicantList && applicantList[1]?.workPhoneNumber ? applicantList[1]?.workPhoneNumber : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Are you a US citizen?</td>
                                                    <td>{applicantList && applicantList[1]?.isUSCitizen ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Are you a US permanent resident?</td>
                                                    <td>{applicantList && applicantList[1]?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is this a foreign account?</td>
                                                    <td>{applicantList && applicantList[1]?.isForeignAccount ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Type</td>
                                                    <td>{applicantList && applicantList[1]?.idTypeName !== null ? applicantList[1]?.idTypeName : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Number</td>
                                                    <td>{applicantList && applicantList[1]?.idNumber !== null ? applicantList[1]?.idNumber : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Issue Date</td>
                                                    <td>{applicantList && applicantList[1]?.issueDate !== null ? moment(applicantList[1]?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Expiration Date</td>
                                                    <td>{applicantList && applicantList[1]?.expirationDate !== null ? moment(applicantList[1]?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Id Issuance State</td>
                                                    <td>{applicantList && applicantList[1].issuanceStateName !== null ? applicantList[1].issuanceStateName : "-"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {!isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Mailing Address </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editPersonalInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4} className="sub_headingTd"><h2>Mailing Address</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Mailing Address 1</td>
                                                    <td>{applicantList && applicantList[0]?.mailingAddress !== '' ? applicantList[0]?.mailingAddress : "-"}</td>
                                                    <td className="review-label">Mailing Address 2</td>
                                                    <td>{applicantList && applicantList[0]?.mailingAddress2 !== '' ? applicantList[0]?.mailingAddress2 : "-"}</td>

                                                </tr>
                                                <tr>
                                                    <td className="review-label">State</td>
                                                    <td>{applicantList && applicantList[0]?.mailingStateName !== '' ? applicantList[0]?.mailingStateName : "-"}</td>
                                                    <td className="review-label">City</td>
                                                    <td>{applicantList && applicantList[0]?.mailingCity !== '' ? applicantList[0]?.mailingCity : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Country</td>
                                                    <td>{applicantList && applicantList[0]?.mailingCountryName !== '' ? applicantList[0]?.mailingCountryName : "-"}</td>
                                                    <td className="review-label">Postal Code</td>
                                                    <td>{applicantList && applicantList[0]?.mailingPostalCode !== '' ? applicantList[0]?.mailingPostalCode : "-"}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>}

                        {!isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Trusted Contact </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editPersonalInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4} className="sub_headingTd"><h2>Trusted Contact</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">First Name</td><td>{trustedContactInfo && trustedContactInfo?.firstName}</td>
                                                    <td className="review-label">Last Name</td><td>{trustedContactInfo && trustedContactInfo?.lastName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Telephone</td><td>{trustedContactInfo && trustedContactInfo?.telephoneNumber}</td>
                                                    <td className="review-label">Email Address</td><td>{trustedContactInfo && trustedContactInfo?.emailAddress}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Mailing Address 1</td><td>{trustedContactInfo && trustedContactInfo?.physicalAddress}</td>
                                                    <td className="review-label">Mailing Address 2</td><td>{trustedContactInfo && trustedContactInfo?.trustedAddress2 ? trustedContactInfo?.trustedAddress2 : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Country</td><td>{trustedContactInfo && trustedContactInfo?.countryName}</td>
                                                    <td className="review-label">State</td><td>{trustedContactInfo && trustedContactInfo?.stateName ? trustedContactInfo?.stateName : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">City</td><td>{trustedContactInfo && trustedContactInfo?.city}</td>
                                                    <td className="review-label">Postal Code</td><td>{trustedContactInfo && trustedContactInfo?.zipCode}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {isRetirementAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">IRA Account Information </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editPersonalInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.TraditionalIRA && <tr>
                                                    <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA === "" ? "-" : applicantList[0]?.beneficialTraditionalIRA}</td>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                </tr>}
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.SimpleIRAParticipant && <tr>
                                                    <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA === "" ? "-" : applicantList[0]?.beneficialTraditionalIRA}</td>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                </tr>}
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.BeneficiaryRothIRA && <tr>
                                                    <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA === "" ? "-" : applicantList[0]?.beneficialTraditionalIRA}</td>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                </tr>}

                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.RothIRA && <tr>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                    <td></td><td></td>
                                                </tr>}
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.BeneficiaryTraditionalIRA && <tr>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                    <td></td><td></td>
                                                </tr>}
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.RolloverIRA && <tr>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                    <td></td><td></td>
                                                </tr>}
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.SEPIRA && <tr>
                                                    <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization === "" ? "-" : applicantList[0]?.depositorAuthorization}</td>
                                                    <td></td><td></td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {!isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Employment Information </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editEmploymentInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                {employmentInfoList && employmentInfoList[0]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed &&
                                                    <>
                                                        <tr>
                                                            <td className="review-label">Employer</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employer}</td>
                                                            <td className="review-label">Years Employed</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.yearswithEmployer}</td>
                                                            <td className="review-label">Position</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.jobTitle}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">Employer Address 1</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerAddress}</td>
                                                            <td className="review-label">Employer Address 2</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerAddress2}</td>
                                                            <td className="review-label">Country</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerCountryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">State</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerStateName}</td>
                                                            <td className="review-label">City</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerCity}</td>
                                                            <td className="review-label">Zip Code</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employerZipCode}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">Employment Status</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employmentStatusName}</td>
                                                            <td></td> <td></td>
                                                            <td></td> <td></td>
                                                        </tr>
                                                    </>
                                                }

                                                {employmentInfoList && employmentInfoList[0]?.employmentStatusId !== OLAEnums.EmploymentStatusEnum.Employed &&
                                                    <>
                                                        <tr>
                                                            <td className="review-label">Employment Status</td>
                                                            <td>{employmentInfoList && employmentInfoList[0]?.employmentStatusName}</td>
                                                            <td colSpan={2}></td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Employment Information (Co-Applicant) </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editEmploymentInfo}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            {employmentInfoList && employmentInfoList[1]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed && <tbody>
                                                <tr>
                                                    <td className="review-label">Employer</td><td>{employmentInfoList && employmentInfoList[1]?.employer}</td>
                                                    <td className="review-label">Years Employed</td><td>{employmentInfoList && employmentInfoList[1]?.yearswithEmployer}</td>
                                                    <td className="review-label">Position</td><td>{employmentInfoList && employmentInfoList[1]?.jobTitle}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Employer Address 1</td><td>{employmentInfoList && employmentInfoList[1]?.employerAddress}</td>
                                                    <td className="review-label">Employer Address 2</td><td>{employmentInfoList && employmentInfoList[1]?.employerAddress2}</td>
                                                    <td className="review-label">Country</td><td>{employmentInfoList && employmentInfoList[1]?.employerCountryName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">State</td><td>{employmentInfoList && employmentInfoList[1]?.employerStateName}</td>
                                                    <td className="review-label">City</td><td>{employmentInfoList && employmentInfoList[1]?.employerCity}</td>
                                                    <td className="review-label">Zip Code</td><td>{employmentInfoList && employmentInfoList[1]?.employerZipCode}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Employment Status</td><td>{employmentInfoList && employmentInfoList[1]?.employmentStatusName}</td>
                                                    <td></td> <td></td> <td></td> <td></td>
                                                </tr>

                                            </tbody>}

                                            {employmentInfoList && employmentInfoList[1]?.employmentStatusId !== OLAEnums.EmploymentStatusEnum.Employed && <tbody>
                                                <tr>
                                                    <td className="review-label">Employment Status</td><td>{employmentInfoList && employmentInfoList[1]?.employmentStatusName}</td>
                                                    <td></td><td></td>
                                                </tr>
                                            </tbody>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {isRetirementAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">IRA Beneficiaries </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editIRABeneficiaries}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td className="review-label">First Name</td><td className="review-label">Last Name</td><td className="review-label">Date of Birth</td>
                                                    <td className="review-label">SSN</td><td className="review-label">Address</td><td className="review-label">Postal Code</td>
                                                    <td className="review-label">State</td><td className="review-label">City</td><td className="review-label">Country</td>
                                                    <td className="review-label">Share %</td><td className="review-label">Relationship</td><td className="review-label">Beneficiary Type</td>
                                                </tr>
                                                {beneficiaryList && beneficiaryList.map((benItem) => (
                                                    <tr>
                                                        <td>{benItem.firstName}</td>
                                                        <td>{benItem.lastName}</td>
                                                        <td>{moment(benItem.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                        <td title={benItem.socialSecurityNumber}>{hideSSN(benItem.socialSecurityNumber, 7)}</td>
                                                        <td>{benItem.physicalAddress}</td>
                                                        <td>{benItem.postalCode}</td>
                                                        <td>{benItem.stateName}</td>
                                                        <td>{benItem.city}</td>
                                                        <td>{benItem.countryName}</td>
                                                        <td>{benItem.share}%</td>
                                                        <td>{benItem.relationship}</td>
                                                        <td>{toTitleCase(benItem.beneficiaryTypeName)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Investor Profile </i>
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Personal && <button type="button" className="review-btns" onClick={editInvestorProfile}>Edit</button>}
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount && <button type="button" className="review-btns" onClick={editInvestorEntityProfile}>Edit</button>}
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement && <button type="button" className="review-btns" onClick={editInvestorProfile}>Edit</button>}
                                    </div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Investment Profile</h2></td>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Financial Suitability</h2></td>
                                                    <td colSpan={2} className="sub_headingTd"><h2>Prior Investment Experience</h2>(Number Of Trades Per Year)</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Investment Objective</td><td>{investorProfileInfo && investorProfileInfo?.investmentObjectName}</td>
                                                    <td className="review-label">Annual Income</td><td>{investorProfileInfo && investorProfileInfo?.annualIncomeName}</td>
                                                    <td className="review-label">Stock</td><td>{investorProfileInfo && investorProfileInfo?.stockInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Investment Experience</td><td>{investorProfileInfo && investorProfileInfo?.investmentExperienceName}</td>
                                                    <td className="review-label">Total Net Worth</td><td>{investorProfileInfo && investorProfileInfo?.totalNetWorthName}</td>
                                                    <td className="review-label">Funds</td><td>{investorProfileInfo && investorProfileInfo?.fundsInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Risk Tolerance</td><td>{investorProfileInfo && investorProfileInfo?.investmentRiskToleranceName}</td>
                                                    <td className="review-label">Liquid Net Worth</td><td>{investorProfileInfo && investorProfileInfo?.liquiedNetWorthName}</td>
                                                    <td className="review-label">Margin Account</td><td>{investorProfileInfo && investorProfileInfo?.marginAccountInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Time Horizon</td><td>{investorProfileInfo && investorProfileInfo?.investmentTimePeriodName}</td>
                                                    <td className="review-label">Liquidity Needs</td><td>{investorProfileInfo && investorProfileInfo?.liquidityNeedsName}</td>
                                                    <td className="review-label">Municipal Bonds</td><td>{investorProfileInfo && investorProfileInfo?.municipalBondsInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Tax Bracket</td><td>{investorProfileInfo && investorProfileInfo?.taxBracketName}</td>
                                                    <td></td><td></td>
                                                    <td className="review-label">Commodities</td><td>{investorProfileInfo && investorProfileInfo?.commoditiesInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td></td><td></td>
                                                    <td></td><td></td>
                                                    <td className="review-label">Govt. Bonds</td><td>{investorProfileInfo && investorProfileInfo?.govtBondsInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td></td><td></td>
                                                    <td></td><td></td>
                                                    <td className="review-label">Corporate Bonds</td><td>{investorProfileInfo && investorProfileInfo?.corporateBondsInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td></td><td></td>
                                                    <td></td><td></td>
                                                    <td className="review-label">Options</td><td>{investorProfileInfo && investorProfileInfo?.optionsInvestmentExperience}</td>
                                                </tr>
                                                <tr>
                                                    <td></td><td></td>
                                                    <td></td><td></td>
                                                    <td className="review-label">Tax Shelters</td><td>{investorProfileInfo && investorProfileInfo?.taxSheltersInvestmentExperience}</td>
                                                </tr>

                                                <tr>
                                                    <td className="sub_headingTd" colSpan={6}><h2>ACH Relationship</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Account Owner Name</td><td>{applicationACHInfo && applicationACHInfo?.nameonBankAccount}</td>
                                                    <td className="review-label">Bank Name</td><td>{applicationACHInfo && applicationACHInfo?.bankName}</td>
                                                    <td className="review-label">Bank Account Number</td><td>{applicationACHInfo && applicationACHInfo?.bankAccountNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Bank Routing Number</td><td>{applicationACHInfo && applicationACHInfo?.bankRoutingNumber}</td>
                                                    <td className="review-label">ACH Account Type</td><td colSpan={3}>{applicationACHInfo && strToUpperCase(applicationACHInfo?.bankAccountTypeName)}</td>
                                                </tr>

                                                {/* <tr>
                                                <td className="sub_headingTd" colSpan={6}><h2>Option Approval Level</h2></td>
                                            </tr>
                                            <tr>
                                                <td className="review-label">Option Level</td>
                                                <td colSpan={5}>{investorProfileInfo && investorProfileInfo?.optionStrategyLevelName}</td>
                                            </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Regulatory Items </i>
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Personal && <button type="button" className="review-btns" onClick={editRegulatoryItems}>Edit</button>}
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount && <button type="button" className="review-btns" onClick={editRegulatoryEntityItems}>Edit</button>}
                                        {showEditButton && applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement && <button type="button" className="review-btns" onClick={editRegulatoryItems}>Edit</button>}
                                    </div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td className="review-label">Are you or a family member a senior executive or 10% or greater shareholder of a publicaly traded company?</td>
                                                    <td>{applicationInfo && applicationInfo?.shareholder === 1 ? "Yes" : "No"}</td>
                                                    <td className="review-label">List name of stock ticker symbol</td>
                                                    <td colSpan={3}>{applicationInfo && applicationInfo?.shareholderCompanyStockSymbol !== '' ? applicationInfo?.shareholderCompanyStockSymbol : "-"}</td>
                                                </tr>
                                                {!isEntityAccountType && <tr>
                                                    <td className="review-label">Are you or a family member employed with a member firm of a stock exchange or FINRA registered broker?</td>
                                                    <td colSpan={5}>{applicationInfo && applicationInfo?.tradeAuthtoAnotherParty ? "Yes" : "No"}</td>
                                                </tr>}
                                                {isEntityAccountType && <tr>
                                                    <td className="review-label">Are you or a family member employed with a member firm of a stock exchange or FINRA registered broker?</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.affiliateWithFinra ? "Yes" : "No"}</td>
                                                </tr>}
                                                <tr>
                                                    <td colSpan={1} className="review-label">Are you or a family member a senior political figure (SPF) or politically exposed person (PEP)?</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isPoliticallyExposePersonAccount ? "Yes" : "No"}</td>
                                                </tr>

                                                {applicationInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount && <tr>
                                                    <td className="review-label">Politically Expose Person Legal Name</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonLeagalName !== null ? regulatoryItemsInfo?.politicallyExposePersonLeagalName : "-"}</td>
                                                    <td className="review-label">Politically Expose Person Role</td>
                                                    <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonRole !== null ? regulatoryItemsInfo?.politicallyExposePersonRole : "-"}</td>
                                                </tr>}

                                                <tr>
                                                    <td className="review-label">Politically Expose Person Political Title</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonPoliticalTitle !== null ? regulatoryItemsInfo?.politicallyExposePersonPoliticalTitle : "-"}</td>
                                                    <td className="review-label">Offical or Immediate Family Members/Members</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonMembers !== null ? regulatoryItemsInfo?.politicallyExposePersonMembers : "-"}</td>
                                                    <td className="review-label">Political Organization</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonOrganization !== null ? regulatoryItemsInfo?.politicallyExposePersonOrganization : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is the account maintained for a foreign financial institution as defined by Title 31 of the Code of Federal Regulations? </td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignFinancialInstiutionAccount ? "Yes" : "No"}</td>
                                                    <td className="review-label">Name of Foreign Financial Institution</td>
                                                    <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.foreignFinancialInstiutionName !== '' ? regulatoryItemsInfo?.foreignFinancialInstiutionName : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is the account a foreign bank organized under foreign law and located outside of the United States as defined by Title 31
                                                        of the Code of Federal Regulations? </td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignBankOrganizedAccount ? "Yes" : "No"}</td>
                                                    <td className="review-label">Provide Name of Agent</td>
                                                    <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.foreignBankOrganizedAccountAgent !== '' ? regulatoryItemsInfo?.foreignBankOrganizedAccountAgent : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is the account a PAB account, meaning a proprietary securities account of a broker or dealer as defined by SEC 15c3-3(a)(16)? </td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isPABAccount ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Have you granted trading authority to a third party? </td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isAnotherPartyTrading ? "Yes" : "No"}</td>
                                                    <td className="review-label">Provide Name of Agent</td>
                                                    <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.anotherPartyTradingAgent !== '' ? regulatoryItemsInfo?.anotherPartyTradingAgent : "-"}</td>
                                                </tr>

                                                {isEntityAccountType && <tr>
                                                    <td className="review-label">Is this account for a foreign shell bank? </td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignShellBank ? "Yes" : "No"}</td>
                                                    <td className="review-label">Does this firm offer services to a foreign shell bank? </td>
                                                    <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.isOffersServicesToForeignShellBank ? "Yes" : "No"}</td>
                                                </tr>}

                                                {/* Add FIS related questionaires values i.e relationship, corporation name etc starts here */}
                                                <tr>
                                                    <td className="review-label">Is the customer a senior officer of a bank, savings and loan institution, insurance company, registered investment
                                                        advisory firm, registered investment company, or a person who could influence the trading of investments in an institutional type of account?</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isInstitutionalTypeAccount ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Relationship</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3Relationship !== '' ? regulatoryItemsInfo?.q1to3Relationship : "-"}</td>
                                                    <td className="review-label">Person Name</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3PersonName !== '' ? regulatoryItemsInfo?.q1to3PersonName : "-"}</td>
                                                    <td className="review-label">Firm / Employer</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3FirmEmp !== '' ? regulatoryItemsInfo?.q1to3FirmEmp : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is the customers company owned by or controlled by a public corporation?</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isControlledByPublicCorp ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Is the customers business owned, controlled by, or under common control of a broker?</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isUnderBrokerControl ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Corporation Name</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpName !== '' ? regulatoryItemsInfo?.q4to5CorpName : "-"}</td>
                                                    <td className="review-label">Address1</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpAddress1 != '' ? regulatoryItemsInfo?.q4to5CorpAddress1 : "-"}</td>
                                                    <td className="review-label">Address2</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpAddress2 !== '' ? regulatoryItemsInfo?.q4to5CorpAddress2 : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">City</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpCity !== '' ? regulatoryItemsInfo?.q4to5CorpCity : "-"}</td>
                                                    <td className="review-label">State</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5StateShortName !== '' ? regulatoryItemsInfo?.q4to5StateShortName : "-"}</td>
                                                    <td className="review-label">Zip Code</td>
                                                    <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpZipCode !== '' ? regulatoryItemsInfo?.q4to5CorpZipCode : "-"}</td>
                                                </tr>

                                                {/* FIS related questionaires values i.e relationship, corporation name etc ends here */}
                                                <tr>
                                                    <td className="sub_headingTd" colSpan={6}><h2>Direct Communication-Rule 14 B-1(C)</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Direct Communication Rule (Rule 14b-1(c)) of the Securities Exchange Act, unless you object, requires us
                                                        to disclose to an issuer, upon its request, the names, addresses, and securities positions of our customers who are beneficial owners
                                                        of the issuer’s securities, held by us in nominee name. The issuer would be permitted to use your name and other related information
                                                        for corporation communication only.</td>
                                                    <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isRule14B1CAccepted ? "Yes" : "No"}</td>
                                                </tr>

                                                {isForeignApplicant && <><tr>
                                                    <td className="sub_headingTd" colSpan={6}><h2>W-8 BEN</h2></td>
                                                </tr>
                                                    <tr>
                                                        <td className="review-label" colSpan={6}>
                                                            I certify that beneficial owner is resident of <span style={stylesW8}>{regulatoryItemsInfo?.ownerResidenceW8}</span> with in the mening of
                                                            income tax treaty between the United States and that country. <br />
                                                            <br />Special rates and condition: <br />
                                                            The beneficial owner is claiming of provisions of article and paragraph 
                                                            <span style={stylesW8}>{regulatoryItemsInfo?.ownerClaimW8 !== null ? regulatoryItemsInfo?.ownerClaimW8 : "_____"}</span>.
                                                            He can claim a <span style={stylesW8}>{regulatoryItemsInfo?.witholdingPercentageW8 !== null ? regulatoryItemsInfo?.witholdingPercentageW8 : "_____"}</span>% rage if withholding on (specific type of income).
                                                            Explain the additional condition in the article and paragraph the beneficial owner meets to be eligible for the rate of withholding
                                                            <span style={stylesW8}>{regulatoryItemsInfo?.eligibleForRateWithholding !== null ? regulatoryItemsInfo?.eligibleForRateWithholding : "_____"}</span>
                                                        </td>
                                                    </tr></>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Account Features </i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editAccountFeatures}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td className="review-label">Dividend reinvestment </td>
                                                    <td>{accountFeaturesInfo && accountFeaturesInfo?.dividendReinvestment ? "Yes" : "No"}</td>
                                                    <td className="review-label">E-Documents</td>
                                                    <td>{accountFeaturesInfo && accountFeaturesInfo?.eDocuments ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Would You like to add Margin to your account?</td>
                                                    <td>{accountFeaturesInfo && accountFeaturesInfo?.isMarginEnable ? "Yes" : "No"}</td>
                                                    {showFPLOption ? <><td className="review-label">Would You like to Participate in Fully Paid Security Lending?</td>
                                                        <td>{accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending ? "Yes" : "No"}</td></> : <><td colSpan={2}></td></>}
                                                </tr>

                                                {isEntityAccountType && <tr>
                                                    <td className="review-label">Is the account maintained for Legal Entity Customers who <br />are exempt from identifying and verifying beneficial owners.<br />As defined under 31 Cfr 1010 230 (E) (2-3)?</td>
                                                    <td colSpan={3}>{accountFeaturesInfo && accountFeaturesInfo?.exemptLegalCustomer ? "Yes" : "No"}</td>

                                                </tr>}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isEntityAccountType && accountFeaturesInfo && !accountFeaturesInfo?.exemptLegalCustomer && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Due Diligence Form</i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editDueDiligenceForm}>Edit</button>}</div>
                                    <div className='overflow-auto'>

                                        <table className="table border ">
                                            <tbody>
                                                <tr>
                                                    <td className="review-label">Account Name</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.accountName}</td>
                                                    <td className="review-label">Country of Incorporation</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.countryOfIncorporationName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Business Location</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.businessLocation}</td>
                                                    <td className="review-label">Primary Entity Account Activity</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.primaryEntityActivityName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Expected Withdrawal Frequency</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.expectedWithdrawalFreqName}</td>
                                                    <td className="review-label">Initial Deposit Amount</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.depositAmount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Initial Deposit Source</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.depositSource}</td>
                                                    <td className="review-label">Institution Name</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.institutionName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Scope of Business</td>
                                                    <td>{entityDueDiligence && toTitleCase(entityDueDiligence?.scopeOfBusinessName)}</td>
                                                    <td className="review-label">Primary ongoing Funding Source</td>
                                                    <td>{entityDueDiligence && toTitleCase(entityDueDiligence?.fundingSourceName)}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Based on your due diligence the entity is assessed the risk</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.assessedRiskName}</td>
                                                    <td className="review-label"></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Will Transact Foreign Bonds?</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.transactForeignBonds ? "Yes" : "No"}</td>
                                                    <td className="review-label">Will Transact Low Priced Securities?</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.lowPricedSecurities ? "Yes" : "No"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">You certify that this entity does not issue bearer share?</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.confirmEntityDoesNotIssueBearerShares ? "Yes" : "No"}</td>
                                                    <td className="review-label">Are you aware of any negative information concerning the entity,<br />
                                                        associated individuals (i.e Senior Management, beneficial owner, etc)<br /> or related entities?</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.entityOrAssociatesHaveNegativeNews ? "Yes" : "No"}</td>
                                                </tr>
                                                {entityDueDiligence && entityDueDiligence?.entityOrAssociatesHaveNegativeNews && <tr>
                                                    <td className="review-label">Entity or Association Negative News Info</td>
                                                    <td>{entityDueDiligence && entityDueDiligence?.entityOrAssociatesNegativeNewsInfo}</td>
                                                    <td className="review-label"></td>
                                                    <td></td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {isEntityAccountType && <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Owners & Officials</i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editOwnersOfficials}>Edit</button>}</div>
                                    <div className='overflow-auto'>
                                        <table className="table border">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={12} className="sub_headingTd"><h2>Entity Officers</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">First Name</td>
                                                    <td className="review-label">Last Name</td>
                                                    <td className="review-label">Date Of Birth</td>
                                                    <td className="review-label">SSN/PPT</td>
                                                    <td className="review-label">Address</td>
                                                    <td className="review-label">Postal Code</td>
                                                    <td className="review-label">State</td>
                                                    <td className="review-label">City</td>
                                                    <td className="review-label">Country</td>
                                                    <td className="review-label">Position</td>
                                                    <td className="review-label">Manager</td>
                                                    <td className="review-label">Same for Beneficial</td>
                                                </tr>
                                                {entityOfficerList && entityOfficerList.map((officer) => (
                                                    <tr>
                                                        <td>{officer.firstName}</td>
                                                        <td>{officer.lastName}</td>
                                                        <td>{moment(officer.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                        <td title={officer.ssnppt}>{hideSSN(officer.ssnppt, 7)}</td>
                                                        <td>{officer.address}</td>
                                                        <td>{officer.postalCode}</td>
                                                        <td>{officer.stateName}</td>
                                                        <td>{officer.city}</td>
                                                        <td>{officer.countryName}</td>
                                                        <td>{officer.position}</td>
                                                        <td>{officer.isManager ? "Yes" : "No"}</td>
                                                        <td>{officer.isSameInfo ? "Yes" : "No"}</td>
                                                    </tr>
                                                ))}

                                                <tr>
                                                    <td className="sub_headingTd" colSpan={12}><h2>Certification Of Beneficial Owners</h2></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">First Name</td>
                                                    <td className="review-label">Last Name</td>
                                                    <td colSpan={2} className="review-label">Date Of Birth</td>
                                                    <td colSpan={2} className="review-label">SSN/PPT</td>
                                                    <td colSpan={2} className="review-label">Address</td>
                                                    <td className="review-label">Postal Code</td>
                                                    <td className="review-label">State</td>
                                                    <td className="review-label">City</td>
                                                    <td className="review-label">Country</td>
                                                </tr>
                                                {entityBeneficialOwnerList && entityBeneficialOwnerList.map((owner) => (
                                                    <tr>
                                                        <td>{owner.firstName}</td>
                                                        <td>{owner.lastName}</td>
                                                        <td colSpan={2}>{moment(owner.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                        <td colSpan={2} title={owner.ssnppt}>{hideSSN(owner.ssnppt, 7)}</td>
                                                        <td colSpan={2}>{owner.address}</td>
                                                        <td>{owner.postalCode}</td>
                                                        <td>{owner.stateName}</td>
                                                        <td>{owner.city}</td>
                                                        <td>{owner.countryName}</td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Uploaded Documents</i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editUploadedDocuments}>Edit</button>}</div>
                                    <table className="table border">
                                        <tbody>
                                            {appDocumentList && appDocumentList.map((doc) => (
                                                <tr>
                                                    <td style={{ width: "50%" }}><span style={styles} onClick={(e) => fileDownloader(doc.key)}>{doc.name}</span></td>
                                                    <td>{doc.documentTypeName}</td>
                                                </tr>
                                            ))}

                                            {appDocumentList === null &&
                                                <tr>
                                                    <td>No document found</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="formCard">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="review-heading"><i className="title">Disclosures & Signatures</i>
                                        {showEditButton && <button type="button" className="review-btns" onClick={editDisclosureAndSign}>Edit</button>}</div>
                                    <table className="table border ">
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} className="sub_headingTd"><h2>User reviewed the following documents</h2></td>
                                            </tr>
                                            {!isRetirementAccountType && <tr>
                                                <td className="review-label">
                                                    {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashCheck &&
                                                        <a href='../../assets/disclosures/account_agreement.pdf' rel="noreferrer" target="_blank">Account Agreement</a>
                                                    }
                                                    {disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck &&
                                                        <a href='javascript:;' onClick={openAccountAgreementUrl}>Account Agreement</a>
                                                    }

                                                </td>
                                                <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck === true ? "Read" : "Unread"}</td>
                                                <td className="review-label">

                                                    {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashMarginCheck &&
                                                        <a href='../../assets/disclosures/account_agreement_cash_and_margin_domestic.pdf' rel="noreferrer" target="_blank">Account Agreement Cash & Margin Domestic</a>
                                                    }
                                                    {disclosureInfo && disclosureInfo?.wdAccountAgreementCashMarginCheck &&
                                                        <a href='javascript:;' onClick={openMarginAgreementUrl}>Account Agreement Cash & Margin Domestic</a>
                                                    }

                                                </td>
                                                <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashMarginCheck === true ? "Read" : "Unread"}</td>

                                            </tr>}
                                            {isRetirementAccountType && <tr>
                                                <td className="review-label">
                                                    {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashCheck &&
                                                        <a href='../../assets/disclosures/account_agreement.pdf' rel="noreferrer" target="_blank">Account Agreement</a>
                                                    }
                                                    {disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck &&
                                                        <a href='javascript:;' onClick={openAccountAgreementUrl}>Account Agreement</a>
                                                    }

                                                </td>
                                                <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck === true ? "Read" : "Unread"}</td>
                                                <td className="review-label" colSpan={2}></td>
                                            </tr>}
                                            {showFPLOption && <tr>
                                                {/* <td className="review-label">
                                                    {disclosureInfo && !disclosureInfo?.wdFullyPaidSecLoadAgreementCheck &&
                                                        <a href='../../assets/disclosures/fully_paid_securities_loan_agreement.pdf' rel="noreferrer" target="_blank">Fully Paid Securities Loan Agreement</a>
                                                    }
                                                    {disclosureInfo && disclosureInfo?.wdFullyPaidSecLoadAgreementCheck &&
                                                        <a href='javascript:;' onClick={openFullyPaidAgreementUrl}>Fully Paid Securities Loan Agreement</a>
                                                    }
                                                </td>
                                                <td>{disclosureInfo && disclosureInfo?.wdFullyPaidSecLoadAgreementCheck === true ? "Read" : "Unread"}</td>
                                                <td colSpan={2}></td> */}

                                                <td className="review-label">
                                                    {accountFeaturesInfo && !accountFeaturesInfo?.participateSecuritiesLending &&
                                                        <a href='../../assets/disclosures/fully_paid_securities_loan_agreement.pdf' rel="noreferrer" target="_blank">Fully Paid Securities Loan Agreement</a>
                                                    }
                                                    {accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending &&
                                                        <a href='javascript:;' onClick={openFullyPaidAgreementUrl}>Fully Paid Securities Loan Agreement</a>
                                                    }
                                                </td>
                                                <td>{accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending === true ? "Read" : "Unread"}</td>
                                                <td colSpan={2}></td>
                                            </tr>}

                                            {!isEntityAccountType && <>
                                                <tr>
                                                    <td className="sub_headingTd" colSpan={4}><h2>Primary Applicant Signature</h2></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={6}><img className='sign-img' src={appSignatureList != null && appSignatureList[0].signature} /></td>
                                                </tr>
                                                <tr>
                                                    <td className="review-label">Created Date</td>
                                                    <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSignatureList[0]?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                    <td className="review-label">Printed Name</td>
                                                    <td>{appSignatureList != null && appSignatureList[0].printedName}</td>
                                                </tr>
                                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint &&
                                                    <>
                                                        <tr>
                                                            <td className="sub_headingTd" colSpan={6}><h2>Co-Applicant Signature</h2></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}><img className='sign-img' src={appSignatureList != null && appSignatureList[1]?.signature} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">Created Date</td>
                                                            <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSignatureList[1]?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                            <td className="review-label">Printed Name</td>
                                                            <td>{appSignatureList != null && appSignatureList[1]?.printedName}</td>
                                                        </tr>
                                                    </>
                                                }
                                            </>}

                                            {isEntityAccountType && <>
                                                {appSignatureList && appSignatureList.map((appSign) => (
                                                    <>
                                                        <tr>
                                                            <td className="sub_headingTd" colSpan={4}><h2>Signature for {appSign.printedName}</h2></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}><img className='sign-img' src={appSign.signature} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">Created Date</td>
                                                            <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSign?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                            <td className="review-label">Printed Name</td>
                                                            <td>{appSign.printedName}</td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </>}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {applicationInfo && applicationInfo?.applicationStatus === OLAEnums.ApplicationStatusEnum.NotCompleted && <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' onClick={(e) => submitReview()}>Submit</button></li>
                            <li><button type='submit' className='btn-primary' onClick={(e) => gotoPreviousPage()}>Previous</button></li>
                        </ul>}

                        {applicationInfo && applicationInfo?.applicationStatus === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval && <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' onClick={(e) => gotoDashboard()}>OK</button></li>
                            <li><button type='submit' className='btn-primary' onClick={(e) => handlePrint()}>Print</button></li>
                        </ul>}

                        {applicationInfo && applicationInfo?.applicationStatus === OLAEnums.ApplicationStatusEnum.Declined_RR && <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' onClick={(e) => gotoDashboard()}>OK</button></li>
                            <li><button type='submit' className='btn-primary' onClick={(e) => handlePrint()}>Print</button></li>
                        </ul>}

                        {applicationInfo && applicationInfo?.applicationStatus === OLAEnums.ApplicationStatusEnum.Approved && <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' onClick={(e) => gotoDashboard()}>OK</button></li>
                            <li><button type='submit' className='btn-primary' onClick={(e) => handlePrint()}>Print</button></li>
                        </ul>}

                        {applicationInfo && applicationInfo?.applicationStatus === OLAEnums.ApplicationStatusEnum.CLOSED_BY_FIRM && <ul className="btnWrapper">
                            <li><button type='submit' className='btn-primary' onClick={(e) => gotoDashboard()}>OK</button></li>
                        </ul>}
                    </div>
                </div>
            </div>

            <Modal
                show={mbLogShow}
                onHide={handleMBLogClose}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='msgs_Area'>
                                    <h3 className='heading pb-3'>Please wait as the solutions for financial risk management validate your application....</h3>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            {/* <button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleMBLogClose()}>Close</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Review;