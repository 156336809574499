import applicationService from "../services/applicationservice";

//Roles
export const adminRole = 'Admin';
export const brokerRole = 'Broker';
export const supervisorRole24 = 'Supervisor24';
export const supervisorRole4 = 'Supervisor4';
export const rrRole = 'RR';
export const applicantRole = 'Applicant';

//Default broker userId
export const defaultBrokerUserId = 0;
export const pageSize = 10;
export const defaultCountry = 233;
export const getDefaultStates = async () => await applicationService.getStatesByCountryId(defaultCountry)

// export const baseURL = "https://localhost:44326/api/";
// export const domainURL = 'https://localhost:44326/';

//Staging server
// export const baseURL = "https://olaapi-staging.atlascleartrading.com/api/";
// export const domainURL = "https://olaapi-staging.atlascleartrading.com/";

// //Production server
export const baseURL = "https://olaapi.atlascleartrading.com/api/";
export const domainURL = "https://olaapi.atlascleartrading.com/";