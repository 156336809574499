import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import accountService from '../../services/accountservice';
import { toast } from 'react-toastify';
import { applicantRole, defaultBrokerUserId } from '../../helpers/constants';
import loader from "super-react-loader";

const Register = () => {
    const [brokerUserId, setBrokerUserId] = useState(null);
    let search = useLocation().search;

    useEffect(() => {
        let id = new URLSearchParams(search).get("brokerUserId");
        setBrokerUserId(id);
    }, []);

    let formikValues = {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repassword: ""
    };

    const [isShow, setIsShow] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().required("Username is required").min(3, "Username must be minimum 3 characters"),
        firstName: Yup.string().required("First name is required").min(2, "First name must be minimum 2 characters"),
        lastName: Yup.string().required("Last name is required").min(2, "Last name must be minimum 2 characters"),
        email: Yup.string().email("Enter valid email address").required("Email address is required"),
        password: Yup.string().required("Password is required").min(8),
        repassword: Yup.string().required("Re-enter password is required")
            .oneOf([Yup.ref("password"), null], "Password and re-password don’t match")
    });

    const formik = useFormik({
        //enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            //let broUserId = brokerUserId === null ? defaultBrokerUserId : brokerUserId;
            let broUserId = brokerUserId;
            accountService.signupApplicant({
                username: values.username,
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.email,
                password: values.password,
                roleName: applicantRole,
                //brokerUserId: defaultBrokerUserId
                brokerUserId: broUserId
            })
                .then((json) => {
                    if (json.data.payload === null) {
                        toast.warn(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    } else {
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        formik.resetForm(formik.initialValues);
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const showPassword = () => {
        if (isShow) {
            setIsShow(false);
        }
        else {
            setIsShow(true);
        }
    }

    const showConfirmPassword = () => {
        if (isShowConfirm) {
            setIsShowConfirm(false);
        }
        else {
            setIsShowConfirm(true);
        }
    }

    return (
        <>
            <section className="mode" data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid position-relative overflow-auto">
                    <div className="light-dark-mod">
                    </div>

                    <section className="trading_card">
                        <h1>Create account</h1>
                        <form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div clclassNameass="input_wrapper">
                                        <label htmlFor='firstName' className="form-label">First Name</label>
                                        <div className="input_area">
                                            <input name='firstName' id='firstName' autoComplete='off' type="text" className="inputForm" maxLength={50}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.firstName} placeholder="Enter first name" />
                                            {formik.touched.firstName && formik.errors.firstName ? (
                                                <p className="error position-absolute">{formik.errors.firstName}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div clclassNameass="input_wrapper">
                                        <label htmlFor='lastName' className="form-label">Last Name</label>
                                        <div className="input_area">
                                            <input name='lastName' id='lastName' autoComplete='off' type="text" className="inputForm" maxLength={50}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.lastName} placeholder="Enter last name" />
                                            {formik.touched.lastName && formik.errors.lastName ? (
                                                <p className="error position-absolute">{formik.errors.lastName}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div clclassNameass="input_wrapper">
                                        <label htmlFor='username' className="form-label">Username</label>
                                        <div className="input_area">
                                            {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                                            <input name='username' id='username' autoComplete='off' type="text" className="inputForm" maxLength={50}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.username} placeholder="Enter username" />
                                            {formik.touched.username && formik.errors.username ? (
                                                <p className="error position-absolute">{formik.errors.username}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="input_wrapper">
                                        <label htmlFor='email' className="form-label">Email</label>
                                        <div className="input_area">
                                            {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                                            <input name='email' id='email' autoComplete='off' type="email" className="inputForm" maxLength={50}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.email} placeholder="Enter email address" />
                                            {formik.touched.email && formik.errors.email ? (
                                                <p className="error position-absolute">{formik.errors.email}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="input_wrapper">
                                        <label htmlFor='password' className="form-label">Password</label>
                                        <div className="input_area">
                                            {!isShow && <i className="ico_fa fa fa-eye" aria-hidden="true" onClick={(e) => showPassword()}></i>}
                                            {isShow && <i className="ico_fa fa fa-eye-slash" aria-hidden="true" onClick={(e) => showPassword()}></i>}
                                            <input name='password' id='password' autoComplete='off' type={isShow ? "text" : "password"} className="inputForm" onBlur={formik.handleBlur}
                                                onChange={formik.handleChange} value={formik.values.password} placeholder="Enter password" maxLength={20} />
                                            {formik.touched.password && formik.errors.password ? (
                                                <p className="error position-absolute">{formik.errors.password}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="input_wrapper">
                                        <label htmlFor='repassword' className="form-label">Re-enter password</label>
                                        <div className="input_area">
                                            {!isShowConfirm && <i className="ico_fa fa fa-eye" aria-hidden="true" onClick={(e) => showConfirmPassword()}></i>}
                                            {isShowConfirm && <i className="ico_fa fa fa-eye-slash" aria-hidden="true" onClick={(e) => showConfirmPassword()}></i>}
                                            <input name='repassword' id='repassword' autoComplete='off' type={isShowConfirm ? "text" : "password"} className="inputForm"
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.repassword} placeholder="Re-enter password" />
                                            {formik.touched.repassword && formik.errors.repassword ? (
                                                <p className="error position-absolute">{formik.errors.repassword}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn login_btn">Sign Up</button>
                        </form>
                        <Link to="/Login" className="signup_area">Already have an account? <i className="color_orange">Sign in</i></Link>
                    </section>
                </div>
            </section>
        </>
    )
}

export default Register;