import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { convertUTCToLocalTime, getCurrentApplicationId, getLoggedInUserInfo, hideForeignTaxId, hideSSN, strToUpperCase, toTitleCase } from '../../common/common';
import OLAEnums from '../../helpers/olaenums';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { brokerRole, defaultCountry, domainURL, rrRole, supervisorRole24, supervisorRole4 } from '../../helpers/constants';
import loader from "super-react-loader";
import Modal from 'react-bootstrap/Modal';
import * as Yup from "yup";
import { useFormik } from 'formik';
import lovService from "../../services/lovservice";
import { Link } from "react-router-dom";

const ReviewComponent = (UserId) => {

    const navigate = useNavigate();
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [applicantList, setApplicantList] = useState(null);
    const [trustedContactInfo, setTrustedContactInfo] = useState(null);
    const [employmentInfoList, setEmploymentInfoList] = useState(null);
    const [investorProfileInfo, setInvestorProfileInfo] = useState(null);
    const [applicationACHInfo, setApplicationACHInfo] = useState(null);
    const [regulatoryItemsInfo, setRegulatoryItemsInfo] = useState(null);
    const [accountFeaturesInfo, setAccountFeaturesInfo] = useState(null);
    const [appDocumentList, setAppDocumentList] = useState(null);
    const [appSignatureList, setAppSignatureList] = useState(null);
    const [isForeignApplicant, setIsForeignApplicant] = useState(null);

    //For Entity Account Type
    const [entityInformationInfo, setEntityInformationInfo] = useState(null);
    const [isEntityAccountType, setIsEntityAccountType] = useState(null);
    const [isRetirementAccountType, setIsRetirementAccountType] = useState(null);
    const [entityDueDiligence, setEntityDueDiligence] = useState(null);
    const [entityOfficerList, setEntityOfficerList] = useState(null);
    const [entityBeneficialOwnerList, setEntityBeneficialOwnerList] = useState(null);
    const [disclosureInfo, setDisclosureInfo] = useState(null);
    const [authSigners, setAuthSigners] = useState(null);
    //For Retirement Account Type
    const [beneficiaryList, setBeneficiaryList] = useState(null);

    //Used for application status popup
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [showStatusButton, setShowStatusButton] = useState(false);
    const [appId, setAppId] = useState(null);
    const [applicationStatusList, setApplicationStatusList] = useState(null);
    const [closeAppShow, setCloseAppShow] = useState(false);
    const handleCloseAppClose = () => setCloseAppShow(false);
    const [startClearingFirmShow, setStartClearingFirmShow] = useState(false);
    const handleCloseStartClearingFirm = () => setStartClearingFirmShow(false);
    const [showCommentTextArea, setShowCommentTextArea] = useState(false);
    const [fullyPaidDocUrl, setFullyPaidDocUrl] = useState(null);
    const [acctAgreementUrl, setAcctAgreementUrl] = useState(null);
    const [marginAgreementUrl, setMarginAgreementUrl] = useState(null);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isBroker, setIsBroker] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isRR, setIsRR] = useState(false);
    const [showFPLOption, setShowFPLOption] = useState(false);

    const styles = {
        color: 'blue', fontWeight: '200', cursor: 'pointer',
    };

    const stylesW8 = {
        color: '#212529', fontWeight: 'bold', paddingLeft: '3px', paddingRight: '3px'
    };

    const styleCaseNone = {
        textTransform: 'none'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        checkRole();
        getApplicationReview();
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const checkRole = () => {
        var role = getLoggedInUserInfo().userRole;
        if (role === 'Admin') {
            setIsAdmin(true);
        }
        else if (role === 'Broker') {
            setIsBroker(true);
        }
        else if (role === 'Supervisor24' || role === 'Supervisor4') {
            setIsSupervisor(true);
        }
        else if (role === 'RR') {
            setIsRR(true);
        }
    }

    const getApplicationReview = () => {
        loader.show();
        let reviewParams = JSON.stringify(UserId);
        let data = {
            UserId: JSON.parse(reviewParams).UserId,
            ApplicationId: getCurrentApplicationId()
        };
        applicationService.getApplicationReview(data)
            .then((json) => {
                let result = json.data.payload;
                if (result !== null) {
                    if (result?.applicationInfo !== null) {
                        setApplicationInfo(result?.applicationInfo);
                        let currentRole = getLoggedInUserInfo().userRole;
                        if (result?.applicationInfo.applicationStatus === OLAEnums.ApplicationStatusEnum.UnderReview_RR
                            && currentRole === rrRole) {
                            setShowStatusButton(true);
                        }
                        else if (result?.applicationInfo.applicationStatus === OLAEnums.ApplicationStatusEnum.UnderReview_Sup
                            && currentRole === supervisorRole24 || currentRole === supervisorRole4) {
                            setShowStatusButton(true);
                        }
                        //now check if the account type is entity account
                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                            setEntityInformationInfo(result?.entityInformationInfo);
                            setIsEntityAccountType(true);
                        }

                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            setIsRetirementAccountType(true);
                        }
                    }

                    if (result?.applicantList !== null) {
                        setApplicantList(result?.applicantList);
                        if (result?.applicantList[0]?.citizenshipCountryId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                    }
                    if (result?.trustedContactInfo !== null) {
                        setTrustedContactInfo(result?.trustedContactInfo);
                    }
                    if (result?.employmentInfoList !== null) {
                        setEmploymentInfoList(result?.employmentInfoList);
                    }
                    if (result?.investorProfileInfo !== null) {
                        setInvestorProfileInfo(result?.investorProfileInfo);
                        if (result?.investorProfileInfo.investmentExperience === parseInt(OLAEnums.InvestmentExpEnum.None)
                            && result?.investorProfileInfo.totalNetWorth === parseInt(OLAEnums.TotalNetWorthEnum['$0-24,999'])) {
                            setShowFPLOption(false);
                        }
                        else {
                            setShowFPLOption(true);
                        }
                    }
                    if (result?.applicationACHInfo !== null) {
                        setApplicationACHInfo(result?.applicationACHInfo);
                    }
                    if (result?.regulatoryItemsInfo !== null) {
                        setRegulatoryItemsInfo(result?.regulatoryItemsInfo);
                    }
                    if (result?.accountFeaturesInfo !== null) {
                        setAccountFeaturesInfo(result?.accountFeaturesInfo);
                    }
                    if (result?.entityInformationInfo !== null) {
                        if (result?.entityInformationInfo?.citizenshipId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                        else if (result?.entityInformationInfo?.originCountryId !== defaultCountry) {
                            setIsForeignApplicant(true);
                        }
                        else {
                            setIsForeignApplicant(false);
                        }
                    }
                    if (result?.entitySignerInfoList !== null) {
                        setAuthSigners(result?.entitySignerInfoList);
                    }

                    if (result?.entityDueDeligenceInfo !== null) {
                        setEntityDueDiligence(result?.entityDueDeligenceInfo);
                    }
                    if (result?.entityOfficerOwnerInfoList !== null && result?.entityOfficerOwnerInfoList.length > 0) {
                        setEntityOfficerList(result?.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Official));
                        setEntityBeneficialOwnerList(result?.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Owner));
                    }
                    if (result?.applicationBeneficiaryList != null && result?.applicationBeneficiaryList.length > 0) {
                        setBeneficiaryList(result?.applicationBeneficiaryList);
                    }
                    if (result?.applicationDocumentsList != null && result?.applicationDocumentsList.length > 0) {
                        setAppDocumentList(result?.applicationDocumentsList);
                    }
                    if (result?.applicationDisclousersInfo != null) {
                        setDisclosureInfo(result?.applicationDisclousersInfo);
                    }
                    if (result?.applicationSignatureList != null && result?.applicationSignatureList.length > 0) {
                        setAppSignatureList(result?.applicationSignatureList);
                        setFullyPaidDocUrl(result?.fullyPaidAgreementUrl);
                        setAcctAgreementUrl(result?.accountAgreementUrl);
                        setMarginAgreementUrl(result?.marginCashAgreementUrl);
                    }
                    loader.hide();
                }
                else {
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const gotoApplications = () => {

        let userRole = getLoggedInUserInfo().userRole;
        if (userRole === brokerRole) {
            navigate('/broker/dashboard');
        }
        else if (userRole === rrRole) {
            navigate('/registerrep/applications');
        }
        else if (userRole === supervisorRole24 || userRole === supervisorRole4) {
            navigate('/supervisor/applications');
        }
        else {
            navigate('/admin/applications');
        }
    }

    const handlePrint = () => {
        loader.show();
        let reviewParams = JSON.stringify(UserId);
        let data = {
            UserId: JSON.parse(reviewParams).UserId,
            ApplicationId: getCurrentApplicationId()
        };
        applicationService.generateApplicationPdf(data)
            .then((json) => {
                let fileName = json.data.payload;
                if (fileName !== null) {
                    //window.open(domainURL + "pdf/" + fileName, "PRINT", "height=600,width=800&embedded=true");

                    applicationService.getPrintedPdfFile(fileName)
                        .then((json) => {
                            if (json.data !== null) {
                                var blob = json.data;
                                const url = window.URL.createObjectURL(new Blob([blob]));
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                            }
                        })
                        .catch((error) => {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });

                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const openFullyPaidAgreementUrl = () => {
        window.open(fullyPaidDocUrl, '_blank');
    }

    const openAccountAgreementUrl = () => {
        window.open(acctAgreementUrl, '_blank');
    }

    const openMarginAgreementUrl = () => {
        window.open(marginAgreementUrl, '_blank');
    }

    //////Change app status starts here

    const appformikValues = {
        appStatus: "",
        comment: ""
    };

    const ADD_FORM_VALIDATION = Yup.object().shape({
        appStatus: Yup.string().required("Please select status").nullable()
    });

    let APP_INITIAL_FORM_STATE = appformikValues;
    const appformik = useFormik({
        enableReinitialize: true,
        initialValues: APP_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let currentUser = getLoggedInUserInfo();
            let userId = currentUser.id;
            let currentUserRole = currentUser.userRole;
            let status = selectedStatus;
            let alertTypeId = null;

            if (parseInt(status) === OLAEnums.ApplicationStatusEnum.NotCompleted) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationNotCompleted;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Closed) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountClosed;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationPendingApproval;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Canceled) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountCanceled;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.PendingReview_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountPendingReview_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.UnderReview_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountUnderReview_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.ActionRequired_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountActionRequired_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.PendingReview_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountPendingReview_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.UnderReview_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountUnderReview_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.ActionRequired_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountActionRequired_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountSubmitted_ClearingFirm;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.InfoRequired_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountInfoRequired_ClearingFirm;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountApproved_ClearingFirm;
            }

            if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_Sup
                || parseInt(status) === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm) {

                setStartClearingFirmShow(true);
                setCloseAppShow(false);
                loader.show();
                let userId = getLoggedInUserInfo().id;
                let data = {
                    "UserId": userId,
                    "ApplicationId": getCurrentApplicationId()
                };
                applicationService.sendRequestToFIS(data)
                    .then((json) => {
                        if (json.data.payload !== null) {
                            if (json.data.payload) {

                                toast.success("Application has been submitted to the clearing firm", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                generateAccountNumber();
                                //updateAppliationFinalStatus(userId, getCurrentApplicationId(), currentUserRole, parseInt(selectedStatus), alertTypeId, values.comment);
                                updateAppliationFinalStatus(userId, getCurrentApplicationId(), currentUserRole, OLAEnums.ApplicationStatusEnum.Approved, alertTypeId, values.comment);
                                setStartClearingFirmShow(false);
                                gotoApplications();
                                loader.hide();
                            }
                            else {
                                toast.error(json.data.message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                setStartClearingFirmShow(false);
                                loader.hide()
                            }
                        }
                        loader.hide();
                    })
                    .catch((error) => {
                        toast.error(error, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    });
            }
            else {
                updateAppliationFinalStatus(userId, getCurrentApplicationId(), currentUserRole, parseInt(selectedStatus), alertTypeId, values.comment);
            }
        }
    });

    const updateAppliationFinalStatus = (userId, applicationId, userRole, statusId, alertTypeId, comment) => {
        let data = {
            "UserId": userId,
            "ApplicationId": applicationId,
            "ApplicationStatus": statusId,
            "AlertTypeId": alertTypeId,
            "Comment": comment,
            "CreatedBy": userRole
        };
        applicationService.updateApplicationStatus(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    setCloseAppShow(false);
                    if (parseInt(statusId) === OLAEnums.ApplicationStatusEnum.Approved_Sup || parseInt(statusId) === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm) {
                        setStartClearingFirmShow(true);
                    }
                    else {
                        gotoApplications();
                        loader.hide();
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleUpdateStatusShow = () => {
        appformik.resetForm();
        setShowCommentTextArea(false);
        setCloseAppShow(true);
        getApplicationStatuses();
    }

    const getApplicationStatuses = () => {
        let currentRole = getLoggedInUserInfo().userRole;
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.ApplicationStatus)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (currentRole === rrRole) {
                        let rrList = result.filter(x => x.id === OLAEnums.ApplicationStatusEnum.NotCompleted || x.id === OLAEnums.ApplicationStatusEnum.Approved_RR
                            || x.id === OLAEnums.ApplicationStatusEnum.Declined_RR || x.id === OLAEnums.ApplicationStatusEnum.Canceled
                            //|| x.id === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval
                            //|| x.id === OLAEnums.ApplicationStatusEnum.PendingReview_RR || x.id === OLAEnums.ApplicationStatusEnum.UnderReview_RR
                        );
                        setApplicationStatusList(rrList);
                    }
                    else if (currentRole === supervisorRole24 || currentRole === supervisorRole4) {
                        let supList = result.filter(x => x.id === OLAEnums.ApplicationStatusEnum.Approved_Sup
                            || x.id === OLAEnums.ApplicationStatusEnum.Declined_Sup || x.id === OLAEnums.ApplicationStatusEnum.Closed
                            || x.id === OLAEnums.ApplicationStatusEnum.Canceled || x.id === OLAEnums.ApplicationStatusEnum.ActionRequired_RR
                            //|| x.id === OLAEnums.ApplicationStatusEnum.PendingReview_Sup || x.id === OLAEnums.ApplicationStatusEnum.UnderReview_Sup || x.id === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm
                        );
                        setApplicationStatusList(supList);
                    }


                }
                else {
                    return null;
                }
            })
            .catch((error) => {
            });
    }

    // const submitToClearingFirm = () => {
    //     //change the status to submitted (clearing firm) and then send the request to FIS or any other third party vendor
    //     loader.show();
    //     let userId = getLoggedInUserInfo().id;
    //     let data = {
    //         "UserId": userId,
    //         "ApplicationId": getCurrentApplicationId(),
    //         "ApplicationStatus": OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm,
    //         "AlertTypeId": OLAEnums.AlertTypeEnum.AccountSubmitted_ClearingFirm,
    //         "Comment": '',
    //         "CreatedBy": getLoggedInUserInfo().userRole
    //     };
    //     applicationService.updateApplicationStatus(data)
    //         .then((json) => {
    //             if (json.data.payload !== null) {
    //                 generateAccountNumber();
    //                 setStartClearingFirmShow(false);
    //                 loader.hide();
    //                 toast.success("Application has been submitted to the clearing firm", {
    //                     position: toast.POSITION.TOP_RIGHT
    //                 });
    //                 gotoApplications();
    //                 loader.hide();
    //             }
    //             loader.hide();
    //         })
    //         .catch((error) => {
    //             toast.error(error, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //             loader.hide();
    //         });
    // }

    const generateAccountNumber = () => {
        loader.show();
        let data = {
            "LoggedUserId": getLoggedInUserInfo().id,
            "ApplicationId": getCurrentApplicationId(),
            "AccountNumber": null,
            "RepCode": null,
            "CommissionCode": null,
            "Program": null
        };
        applicationService.generateAccountNumber(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    setStartClearingFirmShow(false);
                    gotoApplications();
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleStatusChange = (val) => {
        if (val === '') {
            setShowCommentTextArea(false);
        }
        else {
            setShowCommentTextArea(true);
        }
        setSelectedStatus(val);
    }

    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    //////Change app status ends here

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">

                    {isAdmin && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/applications">Applications</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Review Application</li>
                        </ol>
                    </nav>}
                    {isBroker && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Review Application</li>
                        </ol>
                    </nav>}
                    {isRR && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/registerrep/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Review Application</li>
                        </ol>
                    </nav>}
                    {isSupervisor && <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/supervisor/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Review Application</li>
                        </ol>
                    </nav>}

                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Review Application</h2>
                        </div>
                    </div>
                    <div className="tab-content" id="reviews">
                        <div className="formContainer no-mar">
                            <div id="personal" className="container-fluid">
                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">Review Application & Submit - ({applicationInfo && applicationInfo?.applicationStatusName})</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading">Account Information</div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td className="review-label">Account Type</td>
                                                        <td>{applicationInfo && applicationInfo?.accountTypeName}</td>
                                                        <td className="review-label">Ownership Type</td>
                                                        <td colSpan={3}>{applicationInfo && applicationInfo?.ownershipType}</td>
                                                    </tr>
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint &&
                                                        <tr>
                                                            <td className="review-label">Joint Account Sub Type</td>
                                                            <td>{applicationInfo && applicationInfo?.jointSubTypeName}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {!isEntityAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Personal Information </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Personal Information</h2></td>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Physical Address</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td><td>{applicantList && applicantList[0]?.firstName}</td>
                                                        <td className="review-label">Address 1</td><td>{applicantList && applicantList[0]?.physicalAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Middle Name</td><td>{applicantList && applicantList[0]?.middleName !== '' ? applicantList[0]?.middleName : "-"}</td>
                                                        <td className="review-label">Address 2</td><td>{applicantList && applicantList[0]?.physicalAddress2 ? applicantList[0]?.physicalAddress2 : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Last Name</td><td>{applicantList && applicantList[0]?.lastName}</td>
                                                        <td className="review-label">Country</td><td>{applicantList && applicantList[0]?.countryFullName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Date of Birth</td><td>{applicantList && moment(applicantList[0]?.dateofBirth).format("MM/DD/YYYY")}</td>
                                                        <td className="review-label">State</td><td>{applicantList && applicantList[0]?.stateName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Number of Dependents</td><td>{applicantList && applicantList[0]?.noofDependents}</td>
                                                        <td className="review-label">City</td><td>{applicantList && applicantList[0]?.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Marital Status</td><td>{applicantList && applicantList[0]?.maritalStatusName}</td>
                                                        <td className="review-label">Postal Code</td><td>{applicantList && applicantList[0]?.zipCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Country of Citizenship</td><td>{applicantList && applicantList[0]?.citizenshipCountry}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Social Security Number</td>
                                                        {(isAdmin || isBroker) && <td title={applicantList && applicantList[0]?.socialSecurityNo !== null ? applicantList[0]?.socialSecurityNo : ""}>
                                                            {applicantList && applicantList[0]?.socialSecurityNo !== null ? applicantList[0]?.socialSecurityNo : "-"}</td>}
                                                        {(isRR || isSupervisor) && <td title={applicantList && applicantList[0]?.socialSecurityNo !== null ? hideSSN(applicantList[0]?.socialSecurityNo, 7) : ""}>
                                                            {applicantList && applicantList[0]?.socialSecurityNo !== null ? hideSSN(applicantList[0]?.socialSecurityNo, 7) : "-"}</td>}
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Foreign Tax Id</td>
                                                        <td title={applicantList && applicantList[0]?.foreignTaxId !== null ? applicantList[0]?.foreignTaxId : ""}>
                                                            {applicantList && applicantList[0]?.foreignTaxId !== null ? applicantList[0]?.foreignTaxId : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Email</td><td>{applicantList && applicantList[0]?.emailAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Primary Telephone</td><td>{applicantList && applicantList[0]?.homePhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Work Telephone</td><td>{applicantList && applicantList[0]?.workPhoneNumber ? applicantList[0]?.workPhoneNumber : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Are you a US citizen?</td>
                                                        <td>{applicantList && applicantList[0]?.isUSCitizen ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Are you a US permanent resident?</td>
                                                        <td>{applicantList && applicantList[0]?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is this a foreign account?</td>
                                                        <td>{applicantList && applicantList[0]?.isForeignAccount ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Type</td>
                                                        <td>{applicantList && applicantList[0]?.idTypeName !== null ? applicantList[0]?.idTypeName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Number</td>
                                                        <td>{applicantList && applicantList[0]?.idNumber !== null ? applicantList[0]?.idNumber : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Issue Date</td>
                                                        <td>{applicantList && applicantList[0]?.issueDate !== null ? moment(applicantList[0]?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Expiration Date</td>
                                                        <td>{applicantList && applicantList[0]?.expirationDate !== null ? moment(applicantList[0]?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Issuance State</td>
                                                        <td>{applicantList && applicantList[0]?.issuanceStateName !== null ? applicantList[0]?.issuanceStateName : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {isEntityAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Entity Information </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Entity Information</h2></td>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Mailing Preference</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Entity Name</td><td>{entityInformationInfo && entityInformationInfo?.entityName}</td>
                                                        <td className="review-label">Mailing Address</td><td>{entityInformationInfo && entityInformationInfo?.mailingAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">EIN/SSN</td><td>{entityInformationInfo && entityInformationInfo?.ssnein}</td>
                                                        <td className="review-label">City</td><td>{entityInformationInfo && entityInformationInfo?.mailingCity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Type of Entity</td><td>{entityInformationInfo && entityInformationInfo?.taxClassificationName}</td>
                                                        <td className="review-label">State</td><td>{entityInformationInfo && entityInformationInfo?.mailingStateName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Business Phone</td><td>{entityInformationInfo && entityInformationInfo?.businessPhone}</td>
                                                        <td className="review-label">Postal Code</td><td>{entityInformationInfo && entityInformationInfo?.mailingPostalCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Origin Country</td><td>{entityInformationInfo && entityInformationInfo?.originCountryName}</td>
                                                        <td className="review-label">Country</td><td>{entityInformationInfo && entityInformationInfo?.mailingCountryName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Origin State</td><td>{entityInformationInfo && entityInformationInfo?.originStateName}</td>
                                                        <td className="review-label">Large Trader Id</td><td>{entityInformationInfo && entityInformationInfo?.largeTradeId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Entity Resolution Date</td><td>{entityInformationInfo && moment(entityInformationInfo?.resolutionAdoptionDate).format("MM/DD/YYYY")}</td>
                                                        <td className="review-label">Effective Date</td><td>{entityInformationInfo && moment(entityInformationInfo?.effectiveDate).format("MM/DD/YYYY")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Business Address</td><td>{entityInformationInfo && entityInformationInfo?.businessAddress}</td>
                                                        <td className="review-label">LEI Number</td><td>{entityInformationInfo && entityInformationInfo?.leiNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Business City</td><td>{entityInformationInfo && entityInformationInfo?.city}</td>
                                                        <td className="review-label">Business State</td><td>{entityInformationInfo && entityInformationInfo?.stateName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Business Postal Code</td><td>{entityInformationInfo && entityInformationInfo?.postalCode}</td>
                                                        <td className="review-label">Business Country</td><td>{entityInformationInfo && entityInformationInfo?.countryName}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col-lg-12'>
                                            <table className="table border ">
                                                <tbody>
                                                    {authSigners && authSigners.map((signerItem, index) => (
                                                        <>
                                                            <tr>
                                                                <td colSpan={4} className="sub_headingTd"><h2>Authorized Signer {index + 1}</h2></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">First Name</td><td>{signerItem?.firstName}</td>
                                                                <td className="review-label">Middle Name</td><td>{signerItem?.middleName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Last Name</td><td>{signerItem.lastName}</td>
                                                                <td className="review-label">Date of Birth</td><td>{moment(signerItem?.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Email Address</td><td>{signerItem?.emailAddress}</td>
                                                                <td className="review-label">Citizenship</td><td>{signerItem?.citizenshipName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Social Security Number</td>
                                                                {(isAdmin || isBroker) && <td title={signerItem?.ssn}>{signerItem?.ssn}</td>}
                                                                {(isRR || isSupervisor) && <td title={hideSSN(signerItem?.ssn, 7)}>{hideSSN(signerItem?.ssn, 7)}</td>}
                                                                <td className="review-label">ITIN/Foreign Tax ID </td><td>{signerItem?.foreignTaxId}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Are you a US citizen?</td><td>{signerItem?.isUSCitizen ? "Yes" : "No"}</td>
                                                                <td className="review-label">Are you a US permanent resident?</td><td>{signerItem?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Is this a foreign account?</td><td>{signerItem?.isForeignAccount ? "Yes" : "No"}</td>
                                                                <td className="review-label">Id Type</td>
                                                                <td>{signerItem?.idTypeName != null ? signerItem?.idTypeName : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Id Number</td>
                                                                <td>{signerItem?.idNumber != null ? signerItem?.idNumber : "-"}</td>
                                                                <td className="review-label">Id Issuance Date</td>
                                                                <td>{signerItem?.issueDate !== null ? moment(signerItem?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Id Expiration Date</td>
                                                                <td>{signerItem?.expirationDate !== null ? moment(signerItem?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                                <td className="review-label">Id Issuance State</td>
                                                                <td>{signerItem?.issuanceStateName !== null ? signerItem?.issuanceStateName : "-"}</td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col-lg-12'>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} className="sub_headingTd"><h2>Industrial Classification</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={1} className="review-label">Industrial Classification</td>
                                                        <td colSpan={3}>{entityInformationInfo && entityInformationInfo?.industrialClassificationName}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Co-Applicant (Personal Information)</i>
                                            </div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Personal Information</h2></td>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Physical Address</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td><td>{applicantList && applicantList[1]?.firstName}</td>
                                                        <td className="review-label">Address 1</td><td>{applicantList && applicantList[1]?.physicalAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Middle Name</td><td>{applicantList && applicantList[1]?.middleName !== '' ? applicantList[1]?.middleName : "-"}</td>
                                                        <td className="review-label">Address 2</td><td>{applicantList && applicantList[1]?.physicalAddress2 ? applicantList[1]?.physicalAddress2 : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Last Name</td><td>{applicantList && applicantList[1]?.lastName}</td>
                                                        <td className="review-label">Country</td><td>{applicantList && applicantList[1]?.countryFullName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Date of Birth</td><td>{applicantList && moment(applicantList[1]?.dateofBirth).format("MM/DD/YYYY")}</td>
                                                        <td className="review-label">State</td><td>{applicantList && applicantList[1]?.stateName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Number of Dependents</td><td>{applicantList && applicantList[1]?.noofDependents}</td>
                                                        <td className="review-label">City</td><td>{applicantList && applicantList[1]?.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Marital Status</td><td>{applicantList && applicantList[1]?.maritalStatusName}</td>
                                                        <td className="review-label">Postal Code</td><td>{applicantList && applicantList[1]?.zipCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Country of Citizenship</td><td>{applicantList && applicantList[1]?.citizenshipCountry}</td>
                                                    </tr>
                                                    <tr>
                                                        {(isAdmin || isBroker) && <><td className="review-label">Social Security Number</td><td title={applicantList && applicantList[1]?.socialSecurityNo !== null ? applicantList[1]?.socialSecurityNo : ""}>
                                                            {applicantList && applicantList[1]?.socialSecurityNo !== null ? applicantList[1]?.socialSecurityNo : "-"}</td></>}
                                                        {(isRR || isSupervisor) && <><td className="review-label">Social Security Number</td><td title={applicantList && applicantList[1]?.socialSecurityNo !== null ? hideSSN(applicantList[1]?.socialSecurityNo, 7) : ""}>
                                                            {applicantList && applicantList[1]?.socialSecurityNo !== null ? hideSSN(applicantList[1]?.socialSecurityNo, 7) : "-"}</td></>}
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Foreign Tax Id</td><td title={applicantList && applicantList[1]?.foreignTaxId !== null ? applicantList[1]?.socialSecurityNo : ""}>
                                                            {applicantList && applicantList[1]?.foreignTaxId !== null ? applicantList[1]?.foreignTaxId : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Email</td><td>{applicantList && applicantList[1]?.emailAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Primary Telephone</td><td>{applicantList && applicantList[1]?.homePhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Work Telephone</td><td>{applicantList && applicantList[1]?.workPhoneNumber ? applicantList[1]?.workPhoneNumber : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Are you a US citizen?</td>
                                                        <td>{applicantList && applicantList[1]?.isUSCitizen ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Are you a US permanent resident?</td>
                                                        <td>{applicantList && applicantList[1]?.isUSPermanentResident ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is this a foreign account?</td>
                                                        <td>{applicantList && applicantList[1]?.isForeignAccount ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Type</td>
                                                        <td>{applicantList && applicantList[1]?.idTypeName !== null ? applicantList[1]?.idTypeName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Number</td>
                                                        <td>{applicantList && applicantList[1]?.idNumber !== null ? applicantList[1]?.idNumber : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Issue Date</td>
                                                        <td>{applicantList && applicantList[1]?.issueDate !== null ? moment(applicantList[1]?.issueDate).format("MM/DD/YYYY") : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Expiration Date</td>
                                                        <td>{applicantList && applicantList[1]?.expirationDate !== null ? moment(applicantList[1]?.expirationDate).format("MM/DD/YYYY") : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Id Issuance State</td>
                                                        <td>{applicantList && applicantList[1]?.issuanceStateName !== null ? applicantList[1]?.issuanceStateName : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {!isEntityAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Mailing Address </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} className="sub_headingTd"><h2>Mailing Address</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Mailing Address 1</td>
                                                        <td>{applicantList && applicantList[0]?.mailingAddress !== '' ? applicantList[0]?.mailingAddress : "-"}</td>
                                                        <td className="review-label">Mailing Address 2</td>
                                                        <td>{applicantList && applicantList[0]?.mailingAddress2 !== '' ? applicantList[0]?.mailingAddress2 : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">City</td>
                                                        <td>{applicantList && applicantList[0]?.mailingCity !== '' ? applicantList[0]?.mailingCity : "-"}</td>
                                                        <td className="review-label">State</td>
                                                        <td>{applicantList && applicantList[0]?.mailingStateName !== '' ? applicantList[0]?.mailingStateName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Country</td>
                                                        <td>{applicantList && applicantList[0]?.mailingCountryName !== '' ? applicantList[0]?.mailingCountryName : "-"}</td>
                                                        <td className="review-label">Postal Code</td>
                                                        <td>{applicantList && applicantList[0]?.mailingPostalCode !== '' ? applicantList[0]?.mailingPostalCode : "-"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Trusted Contact </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} className="sub_headingTd"><h2>Trusted Contact</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td><td>{trustedContactInfo && trustedContactInfo?.firstName}</td>
                                                        <td className="review-label">Last Name</td><td>{trustedContactInfo && trustedContactInfo?.lastName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Telephone</td><td>{trustedContactInfo && trustedContactInfo?.telephoneNumber}</td>
                                                        <td className="review-label">Email Address</td><td>{trustedContactInfo && trustedContactInfo?.emailAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Mailing Address 1</td><td>{trustedContactInfo && trustedContactInfo?.physicalAddress}</td>
                                                        <td className="review-label">Mailing Address 2</td><td>{trustedContactInfo && trustedContactInfo?.trustedAddress2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Country</td><td>{trustedContactInfo && trustedContactInfo?.countryName}</td>
                                                        <td className="review-label">City</td><td>{trustedContactInfo && trustedContactInfo?.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Postal Code</td><td>{trustedContactInfo && trustedContactInfo?.zipCode}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>}

                                {isRetirementAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">IRA Account Information </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.TraditionalIRA && <tr>
                                                        <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA}</td>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                    </tr>}
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.SimpleIRAParticipant && <tr>
                                                        <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA}</td>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                    </tr>}
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.BeneficiaryRothIRA && <tr>
                                                        <td className="review-label">Name of Beneficiary</td><td>{applicantList && applicantList[0]?.beneficialTraditionalIRA}</td>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                    </tr>}

                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.RothIRA && <tr>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                        <td></td><td></td>
                                                    </tr>}
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.BeneficiaryTraditionalIRA && <tr>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                        <td></td><td></td>
                                                    </tr>}
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.RolloverIRA && <tr>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                        <td></td><td></td>
                                                    </tr>}
                                                    {applicationInfo && applicationInfo?.accountSubType === OLAEnums.RetirementTypeEnum.SEPIRA && <tr>
                                                        <td className="review-label">Depositor Authorization</td><td>{applicantList && applicantList[0]?.depositorAuthorization}</td>
                                                        <td></td><td></td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {!isEntityAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Employment Information </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    {employmentInfoList && employmentInfoList[0]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed &&
                                                        <>
                                                            <tr>
                                                                <td className="review-label">Employer</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employer}</td>
                                                                <td className="review-label">Years Employed</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.yearswithEmployer}</td>
                                                                <td className="review-label">Position</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.jobTitle}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Employer Address 1</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerAddress}</td>
                                                                <td className="review-label">Employer Address 2</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerAddress2}</td>
                                                                <td className="review-label">Country</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerCountryName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">City</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerCity}</td>
                                                                <td className="review-label">State</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerStateName}</td>
                                                                <td className="review-label">Zip Code</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employerZipCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="review-label">Employment Status</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employmentStatusName}</td>
                                                                <td></td><td></td><td></td>
                                                            </tr>
                                                        </>
                                                    }

                                                    {employmentInfoList && employmentInfoList[0]?.employmentStatusId !== OLAEnums.EmploymentStatusEnum.Employed &&
                                                        <>
                                                            <tr>
                                                                <td className="review-label">Employment Status</td>
                                                                <td>{employmentInfoList && employmentInfoList[0]?.employmentStatusName}</td>
                                                                <td colSpan={2}></td>
                                                            </tr>
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Employment Information (Co-Applicant) </i></div>
                                            <table className="table border ">
                                                {employmentInfoList && employmentInfoList[1]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed && <tbody>
                                                    <tr>
                                                        <td className="review-label">Employer</td><td>{employmentInfoList && employmentInfoList[1]?.employer}</td>
                                                        <td className="review-label">Years Employed</td><td>{employmentInfoList && employmentInfoList[1]?.yearswithEmployer}</td>
                                                        <td className="review-label">Position</td><td>{employmentInfoList && employmentInfoList[1]?.jobTitle}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Employer Address 1</td><td>{employmentInfoList && employmentInfoList[1]?.employerAddress}</td>
                                                        <td className="review-label">Employer Address 2</td><td>{employmentInfoList && employmentInfoList[1]?.employerAddress2}</td>
                                                        <td className="review-label">Country</td><td>{employmentInfoList && employmentInfoList[1]?.employerCountryName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">City</td><td>{employmentInfoList && employmentInfoList[1]?.employerCity}</td>
                                                        <td className="review-label">State</td><td>{employmentInfoList && employmentInfoList[1]?.employerStateName}</td>
                                                        <td className="review-label">Zip Code</td><td>{employmentInfoList && employmentInfoList[1]?.employerZipCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Employment Status</td><td>{employmentInfoList && employmentInfoList[1]?.employmentStatusName}</td>
                                                        <td></td><td></td><td></td>
                                                    </tr>

                                                </tbody>}

                                                {employmentInfoList && employmentInfoList[1]?.employmentStatusId !== OLAEnums.EmploymentStatusEnum.Employed && <tbody>
                                                    <tr>
                                                        <td className="review-label">Employment Status</td><td>{employmentInfoList && employmentInfoList[1]?.employmentStatusName}</td>
                                                        <td></td><td></td>
                                                    </tr>
                                                </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {isRetirementAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">IRA Beneficiaries </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td className="review-label">First Name</td><td className="review-label">Last Name</td><td className="review-label">Date of Birth</td>
                                                        <td className="review-label">SSN</td><td className="review-label">Address</td><td className="review-label">City</td>
                                                        <td className="review-label">Postal Code</td><td className="review-label">State</td><td className="review-label">Country</td>
                                                        <td className="review-label">Share %</td><td className="review-label">Relationship</td><td className="review-label">Beneficiary Type</td>
                                                    </tr>
                                                    {beneficiaryList && beneficiaryList.map((benItem) => (
                                                        <tr>
                                                            <td>{benItem.firstName}</td>
                                                            <td>{benItem.lastName}</td>
                                                            <td>{moment(benItem.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                            {(isAdmin || isBroker) && <td>{benItem.socialSecurityNumber}</td>}
                                                            {(isRR || isSupervisor) && <td>{hideSSN(benItem.socialSecurityNumber, 7)}</td>}
                                                            <td>{benItem.physicalAddress}</td>
                                                            <td>{benItem.city}</td>
                                                            <td>{benItem.postalCode}</td>
                                                            <td>{benItem.stateName}</td>
                                                            <td>{benItem.countryName}</td>
                                                            <td>{benItem.share}%</td>
                                                            <td>{benItem.relationship}</td>
                                                            <td>{toTitleCase(benItem.beneficiaryTypeName)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Investor Profile </i>
                                            </div>

                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Investment Profile</h2></td>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Financial Suitability</h2></td>
                                                        <td colSpan={2} className="sub_headingTd"><h2>Prior Investment Experience</h2>(Number Of Trades Per Year)</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Investment Objective</td><td>{investorProfileInfo && investorProfileInfo?.investmentObjectName}</td>
                                                        <td className="review-label">Annual Income</td><td>{investorProfileInfo && investorProfileInfo?.annualIncomeName}</td>
                                                        <td className="review-label">Stock</td><td>{investorProfileInfo && investorProfileInfo?.stockInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Investment Experience</td><td>{investorProfileInfo && investorProfileInfo?.investmentExperienceName}</td>
                                                        <td className="review-label">Total Net Worth</td><td>{investorProfileInfo && investorProfileInfo?.totalNetWorthName}</td>
                                                        <td className="review-label">Funds</td><td>{investorProfileInfo && investorProfileInfo?.fundsInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Risk Tolerance</td><td>{investorProfileInfo && investorProfileInfo?.investmentRiskToleranceName}</td>
                                                        <td className="review-label">Liquid Net Worth</td><td>{investorProfileInfo && investorProfileInfo?.liquiedNetWorthName}</td>
                                                        <td className="review-label">Margin Account</td><td>{investorProfileInfo && investorProfileInfo?.marginAccountInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Time Horizon</td><td>{investorProfileInfo && investorProfileInfo?.investmentTimePeriodName}</td>
                                                        <td className="review-label">Liquidity Needs</td><td>{investorProfileInfo && investorProfileInfo?.liquidityNeedsName}</td>
                                                        <td className="review-label">Municipal Bonds</td><td>{investorProfileInfo && investorProfileInfo?.municipalBondsInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Tax Bracket</td><td>{investorProfileInfo && investorProfileInfo?.taxBracketName}</td>
                                                        <td></td><td></td>
                                                        <td className="review-label">Commodities</td><td>{investorProfileInfo && investorProfileInfo?.commoditiesInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td className="review-label">Govt. Bonds</td><td>{investorProfileInfo && investorProfileInfo?.govtBondsInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td className="review-label">Corporate Bonds</td><td>{investorProfileInfo && investorProfileInfo?.corporateBondsInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td className="review-label">Options</td><td>{investorProfileInfo && investorProfileInfo?.optionsInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td><td></td>
                                                        <td></td><td></td>
                                                        <td className="review-label">Tax Shelters</td><td>{investorProfileInfo && investorProfileInfo?.taxSheltersInvestmentExperience}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="sub_headingTd" colSpan={6}><h2>ACH Relationship</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Account Owner Name</td><td>{applicationACHInfo && applicationACHInfo?.nameonBankAccount}</td>
                                                        <td className="review-label">Bank Name</td><td>{applicationACHInfo && applicationACHInfo?.bankName}</td>
                                                        <td className="review-label">Bank Account Number</td><td>{applicationACHInfo && applicationACHInfo?.bankAccountNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Bank Routing Number</td><td>{applicationACHInfo && applicationACHInfo?.bankRoutingNumber}</td>
                                                        <td className="review-label">ACH Account Type</td><td colSpan={3}>{applicationACHInfo && strToUpperCase(applicationACHInfo?.bankAccountTypeName)}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Regulatory Items </i>
                                            </div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td className="review-label">Are you or a family member a senior executive or 10% or greater shareholder<br /> of a publicaly traded company?</td>
                                                        <td>{applicationInfo && applicationInfo?.shareholder === 1 ? "Yes" : "No"}</td>
                                                        <td className="review-label">List name of stock ticker symbol</td>
                                                        <td colSpan={3}>{applicationInfo && applicationInfo?.shareholderCompanyStockSymbol !== '' ? applicationInfo?.shareholderCompanyStockSymbol : "-"}</td>
                                                    </tr>
                                                    {!isEntityAccountType && <tr>
                                                        <td className="review-label">Are you or a family member employed with a member firm of a <br />stock exchange or FINRA registered broker?</td>
                                                        <td colSpan={5}>{applicationInfo && applicationInfo?.tradeAuthtoAnotherParty ? "Yes" : "No"}</td>
                                                    </tr>}
                                                    {isEntityAccountType && <tr>
                                                        <td className="review-label">Are you or a family member employed with a member firm of a <br />stock exchange or FINRA registered broker?</td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.affiliateWithFinra ? "Yes" : "No"}</td>
                                                    </tr>}
                                                    <tr>
                                                        <td colSpan={1} className="review-label">Are you or a family member a senior political figure (SPF) or politically<br /> exposed person (PEP)? </td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isPoliticallyExposePersonAccount ? "Yes" : "No"}</td>
                                                    </tr>

                                                    {applicationInfo?.accountType === OLAEnums.AccountTypeEnum.EntityAccount && <tr>
                                                        <td className="review-label">Politically Expose Person Legal Name</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonLeagalName !== null ? regulatoryItemsInfo?.politicallyExposePersonLeagalName : "-"}</td>
                                                        <td className="review-label">Politically Expose Person Role</td>
                                                        <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonRole !== null ? regulatoryItemsInfo?.politicallyExposePersonRole : "-"}</td>
                                                    </tr>}

                                                    <tr>
                                                        <td className="review-label">Politically Expose Person Political Title</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonPoliticalTitle !== null ? regulatoryItemsInfo?.politicallyExposePersonPoliticalTitle : "-"}</td>
                                                        <td className="review-label">Offical or Immediate Family Members/Members</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonMembers !== null ? regulatoryItemsInfo?.politicallyExposePersonMembers : "-"}</td>
                                                        <td className="review-label">Political Organization</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.politicallyExposePersonOrganization !== null ? regulatoryItemsInfo?.politicallyExposePersonOrganization : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is the account maintained for a foreign financial institution as defined by Title 31 of the <br /> Code of Federal Regulations? </td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignFinancialInstiutionAccount ? "Yes" : "No"}</td>
                                                        <td className="review-label">Name of Foreign Financial Institution</td>
                                                        <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.foreignFinancialInstiutionName !== '' ? regulatoryItemsInfo?.foreignFinancialInstiutionName : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is the account a foreign bank organized under foreign law and located outside of the <br />United States as defined by Title 31
                                                            of the Code of Federal Regulations? </td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignBankOrganizedAccount ? "Yes" : "No"}</td>
                                                        <td className="review-label">Provide Name of Agent</td>
                                                        <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.foreignBankOrganizedAccountAgent !== '' ? regulatoryItemsInfo?.foreignBankOrganizedAccountAgent : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is the account a PAB account, meaning a proprietary securities account of a broker <br />or dealer as defined by SEC 15c3-3(a)(16)? </td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isPABAccount ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Have you granted trading authority to a third party? </td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isAnotherPartyTrading ? "Yes" : "No"}</td>
                                                        <td className="review-label">Provide Name of Agent</td>
                                                        <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.anotherPartyTradingAgent !== '' ? regulatoryItemsInfo?.anotherPartyTradingAgent : "-"}</td>
                                                    </tr>

                                                    {isEntityAccountType && <tr>
                                                        <td className="review-label">Is this account for a foreign shell bank? </td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.isForeignShellBank ? "Yes" : "No"}</td>
                                                        <td className="review-label">Does this firm offer services to a foreign shell bank? </td>
                                                        <td colSpan={3}>{regulatoryItemsInfo && regulatoryItemsInfo?.isOffersServicesToForeignShellBank ? "Yes" : "No"}</td>
                                                    </tr>}

                                                    {/* Add FIS related questionaires values i.e relationship, corporation name etc starts here */}
                                                    <tr>
                                                        <td className="review-label">Is the customer a senior officer of a bank, savings and loan institution, insurance company, registered investment
                                                            advisory firm, registered investment company, or a person who could influence the trading of investments in an institutional type of account?</td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isInstitutionalTypeAccount ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Relationship</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3Relationship !== '' ? regulatoryItemsInfo?.q1to3Relationship : "-"}</td>
                                                        <td className="review-label">Person Name</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3PersonName !== '' ? regulatoryItemsInfo?.q1to3PersonName : "-"}</td>
                                                        <td className="review-label">Firm / Employer</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q1to3FirmEmp !== '' ? regulatoryItemsInfo?.q1to3FirmEmp : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is the customers company owned by or controlled by a public corporation?</td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isControlledByPublicCorp ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Is the customers business owned, controlled by, or under common control of a broker?</td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isUnderBrokerControl ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Corporation Name</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpName !== '' ? regulatoryItemsInfo?.q4to5CorpName : "-"}</td>
                                                        <td className="review-label">Address1</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpAddress1 != '' ? regulatoryItemsInfo?.q4to5CorpAddress1 : "-"}</td>
                                                        <td className="review-label">Address2</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpAddress2 !== '' ? regulatoryItemsInfo?.q4to5CorpAddress2 : "-"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">City</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpCity !== '' ? regulatoryItemsInfo?.q4to5CorpCity : "-"}</td>
                                                        <td className="review-label">State</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5StateShortName !== '' ? regulatoryItemsInfo?.q4to5StateShortName : "-"}</td>
                                                        <td className="review-label">Zip Code</td>
                                                        <td>{regulatoryItemsInfo && regulatoryItemsInfo?.q4to5CorpZipCode !== '' ? regulatoryItemsInfo?.q4to5CorpZipCode : "-"}</td>
                                                    </tr>

                                                    {/* FIS related questionaires values i.e relationship, corporation name etc ends here */}

                                                    <tr>
                                                        <td className="sub_headingTd" colSpan={6}><h2>Direct Communication-Rule 14 B-1(C)</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Direct Communication Rule (Rule 14b-1(c)) of the Securities Exchange Act, unless you object, requires us
                                                            to disclose to an issuer, upon its request, the names, addresses, and securities positions of our customers who are beneficial owners
                                                            of the issuer’s securities, held by us in nominee name. The issuer would be permitted to use your name and other related information
                                                            for corporation communication only.</td>
                                                        <td colSpan={5}>{regulatoryItemsInfo && regulatoryItemsInfo?.isRule14B1CAccepted ? "Yes" : "No"}</td>
                                                    </tr>

                                                    {isForeignApplicant && <><tr>
                                                        <td className="sub_headingTd" colSpan={6}><h2>W-8 BEN</h2></td>
                                                    </tr>

                                                        <tr>
                                                            <td className="review-label" colSpan={6}>
                                                                I certify that beneficial owner is resident of <span style={stylesW8}>{regulatoryItemsInfo?.ownerResidenceW8}</span> with in the mening of
                                                                income tax treaty between the United States and that country. <br /><br />
                                                                Special rates and condition: <br />
                                                                The beneficial owner is claiming of provisions of article and paragraph <span style={stylesW8}>{regulatoryItemsInfo?.ownerClaimW8 !== null ? regulatoryItemsInfo?.ownerClaimW8 : "_____"}</span>.
                                                                He can claim a <span style={stylesW8}>{regulatoryItemsInfo?.witholdingPercentageW8 !== null ? regulatoryItemsInfo?.witholdingPercentageW8 : "_____"}</span>% rage if withholding on (specific type of income).
                                                                Explain the additional condition in the article and paragraph the beneficial owner meets to be eligible for the rate of withholding
                                                                <span style={stylesW8}>{regulatoryItemsInfo?.eligibleForRateWithholding !== null ? regulatoryItemsInfo?.eligibleForRateWithholding : "_____"}</span>
                                                            </td>
                                                        </tr></>}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Account Features </i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td className="review-label">Dividend reinvestment </td>
                                                        <td>{accountFeaturesInfo && accountFeaturesInfo?.dividendReinvestment ? "Yes" : "No"}</td>
                                                        <td className="review-label">E-Documents</td>
                                                        <td>{accountFeaturesInfo && accountFeaturesInfo?.eDocuments ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Would You like to add Margin to your account?</td>
                                                        <td>{accountFeaturesInfo && accountFeaturesInfo?.isMarginEnable ? "Yes" : "No"}</td>
                                                        {showFPLOption && <><td className="review-label">Would You like to Participate in Fully Paid Security Lending?</td>
                                                            <td>{accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending ? "Yes" : "No"}</td></>}
                                                    </tr>

                                                    {isEntityAccountType && <tr>
                                                        <td className="review-label">Is the account maintained for Legal Entity Customers who are exempt from identifying and verifying beneficial owners. As defined under 31 Cfr 1010 230 (E) (2-3)?</td>
                                                        <td colSpan={3}>{accountFeaturesInfo && accountFeaturesInfo?.exemptLegalCustomer ? "Yes" : "No"}</td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                {isEntityAccountType && accountFeaturesInfo && !accountFeaturesInfo?.exemptLegalCustomer && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Due Diligence Form</i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td className="review-label">Account Name</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.accountName}</td>
                                                        <td className="review-label">Country of Incorporation</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.countryOfIncorporationName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Business Location</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.businessLocation}</td>
                                                        <td className="review-label">Primary Entity Account Activity</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.primaryEntityActivityName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Expected Withdrawal Frequency</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.expectedWithdrawalFreqName}</td>
                                                        <td className="review-label">Initial Deposit Amount</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.depositAmount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Initial Deposit Source</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.depositSource}</td>
                                                        <td className="review-label">Institution Name</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.institutionName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Scope of Business</td>
                                                        <td>{entityDueDiligence && toTitleCase(entityDueDiligence?.scopeOfBusinessName)}</td>
                                                        <td className="review-label">Primary ongoing Funding Source</td>
                                                        <td>{entityDueDiligence && toTitleCase(entityDueDiligence?.fundingSourceName)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Based on your due diligence the entity is assessed the risk</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.assessedRiskName}</td>
                                                        <td className="review-label"></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">Will Transact Foreign Bonds?</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.transactForeignBonds ? "Yes" : "No"}</td>
                                                        <td className="review-label">Will Transact Low Priced Securities?</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.lowPricedSecurities ? "Yes" : "No"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">You certify that this entity does not issue bearer share?</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.confirmEntityDoesNotIssueBearerShares ? "Yes" : "No"}</td>
                                                        <td className="review-label">Are you aware of any negative information concerning the entity,<br />
                                                            associated individuals (i.e Senior Management, beneficial owner, etc)<br /> or related entities?</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.entityOrAssociatesHaveNegativeNews ? "Yes" : "No"}</td>
                                                    </tr>
                                                    {entityDueDiligence && entityDueDiligence?.entityOrAssociatesHaveNegativeNews && <tr>
                                                        <td className="review-label">Entity or Association Negative News Info</td>
                                                        <td>{entityDueDiligence && entityDueDiligence?.entityOrAssociatesNegativeNewsInfo}</td>
                                                        <td className="review-label"></td>
                                                        <td></td>
                                                    </tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                {isEntityAccountType && <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Owners & Officials</i></div>
                                            <table className="table border">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={12} className="sub_headingTd"><h2>Entity Officers</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td>
                                                        <td className="review-label">Last Name</td>
                                                        <td className="review-label">Date Of Birth</td>
                                                        <td className="review-label">SSN/PPT</td>
                                                        <td className="review-label">Address</td>
                                                        <td className="review-label">City</td>
                                                        <td className="review-label">Postal Code</td>
                                                        <td className="review-label">State</td>
                                                        <td className="review-label">Country</td>
                                                        <td className="review-label">Position</td>
                                                        <td className="review-label">Manager</td>
                                                        <td className="review-label">Same for Beneficial</td>
                                                    </tr>
                                                    {entityOfficerList && entityOfficerList.map((officer) => (
                                                        <tr>
                                                            <td>{officer.firstName}</td>
                                                            <td>{officer.lastName}</td>
                                                            <td>{moment(officer.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                            {(isAdmin || isBroker) && <td>{officer.ssnppt}</td>}
                                                            {(isRR || isSupervisor) && <td>{officer.ssnppt}</td>}
                                                            <td>{officer.address}</td>
                                                            <td>{officer.city}</td>
                                                            <td>{officer.postalCode}</td>
                                                            <td>{officer.stateName}</td>
                                                            <td>{officer.countryName}</td>
                                                            <td>{officer.position}</td>
                                                            <td>{officer.isManager ? "Yes" : "No"}</td>
                                                            <td>{officer.isSameInfo ? "Yes" : "No"}</td>
                                                        </tr>
                                                    ))}

                                                    <tr>
                                                        <td className="sub_headingTd" colSpan={12}><h2>Certification Of Beneficial Owners</h2></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="review-label">First Name</td>
                                                        <td className="review-label">Last Name</td>
                                                        <td colSpan={2} className="review-label">Date Of Birth</td>
                                                        <td colSpan={2} className="review-label">SSN/PPT</td>
                                                        <td colSpan={2} className="review-label">Address</td>
                                                        <td className="review-label">City</td>
                                                        <td className="review-label">Postal Code</td>
                                                        <td className="review-label">State</td>
                                                        <td className="review-label">Country</td>
                                                    </tr>
                                                    {entityBeneficialOwnerList && entityBeneficialOwnerList.map((owner) => (
                                                        <tr>
                                                            <td>{owner.firstName}</td>
                                                            <td>{owner.lastName}</td>
                                                            <td colSpan={2}>{moment(owner.dateOfBirth).format("MM/DD/YYYY")}</td>
                                                            <td colSpan={2}>{owner.ssnppt}</td>
                                                            <td colSpan={2}>{owner.address}</td>
                                                            <td>{owner.city}</td>
                                                            <td>{owner.postalCode}</td>
                                                            <td>{owner.stateName}</td>
                                                            <td>{owner.countryName}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>}

                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Uploaded Documents</i></div>
                                            <table className="table border">
                                                <tbody>
                                                    {appDocumentList && appDocumentList.map((doc) => (
                                                        <tr>
                                                            {/* <td><a href={doc.documentPath} rel="noreferrer" alt="" target="_blank">{doc.name}</a></td> */}
                                                            <td style={{ width: "50%" }}><span style={styles} onClick={(e) => fileDownloader(doc.key)}>{doc.name}</span></td>
                                                            <td>{doc.documentTypeName}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="formCard">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="review-heading"><i className="title">Disclosures & Signatures</i></div>
                                            <table className="table border ">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4} className="sub_headingTd"><h2>User reviewed the following documents</h2></td>
                                                    </tr>
                                                    {!isRetirementAccountType && <tr>
                                                        <td className="review-label">
                                                            {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashCheck &&
                                                                <a href='../../assets/disclosures/account_agreement.pdf' rel="noreferrer" target="_blank">Account Agreement</a>
                                                            }
                                                            {disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck &&
                                                                <a href='javascript:;' onClick={openAccountAgreementUrl}>Account Agreement</a>}
                                                        </td>
                                                        <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck === true ? "Read" : "Unread"}</td>
                                                        <td className="review-label">
                                                            {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashMarginCheck &&
                                                                <a href='../../assets/disclosures/account_agreement_cash_and_margin_domestic.pdf' rel="noreferrer" target="_blank">Account Agreement Cash & Margin Domestic</a>
                                                            }
                                                            {disclosureInfo && disclosureInfo?.wdAccountAgreementCashMarginCheck &&
                                                                <a href='javascript:;' onClick={openMarginAgreementUrl}>Account Agreement Cash & Margin Domestic</a>
                                                            }
                                                        </td>
                                                        <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashMarginCheck === true ? "Read" : "Unread"}</td>
                                                    </tr>}
                                                    {isRetirementAccountType && <tr>
                                                        <td className="review-label">
                                                            {disclosureInfo && !disclosureInfo?.wdAccountAgreementCashCheck &&
                                                                <a href='../../assets/disclosures/account_agreement.pdf' rel="noreferrer" target="_blank">Account Agreement</a>
                                                            }
                                                            {disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck &&
                                                                <a href='javascript:;' onClick={openAccountAgreementUrl}>Account Agreement</a>}
                                                        </td>
                                                        <td>{disclosureInfo && disclosureInfo?.wdAccountAgreementCashCheck === true ? "Read" : "Unread"}</td>
                                                        <td className="review-label" colSpan={2}></td>
                                                    </tr>}
                                                    {showFPLOption && <tr>
                                                        {/* <td className="review-label">
                                                            {disclosureInfo && !disclosureInfo?.wdFullyPaidSecLoadAgreementCheck &&
                                                                <a href='../../assets/disclosures/fully_paid_securities_loan_agreement.pdf' rel="noreferrer" target="_blank">Fully Paid Securities Loan Agreement</a>
                                                            }
                                                            {disclosureInfo && disclosureInfo?.wdFullyPaidSecLoadAgreementCheck &&
                                                                <a href='javascript:;' onClick={openFullyPaidAgreementUrl}>Fully Paid Securities Loan Agreement</a>
                                                            }
                                                        </td>
                                                        <td>{disclosureInfo && disclosureInfo?.wdFullyPaidSecLoadAgreementCheck === true ? "Read" : "Unread"}</td>
                                                        <td colSpan={2}></td> */}
                                                        <td className="review-label">
                                                            {accountFeaturesInfo && !accountFeaturesInfo?.participateSecuritiesLending &&
                                                                <a href='../../assets/disclosures/fully_paid_securities_loan_agreement.pdf' rel="noreferrer" target="_blank">Fully Paid Securities Loan Agreement</a>
                                                            }
                                                            {accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending &&
                                                                <a href='javascript:;' onClick={openFullyPaidAgreementUrl}>Fully Paid Securities Loan Agreement</a>
                                                            }
                                                        </td>
                                                        <td>{accountFeaturesInfo && accountFeaturesInfo?.participateSecuritiesLending === true ? "Read" : "Unread"}</td>
                                                        <td colSpan={2}></td>
                                                    </tr>}
                                                    {!isEntityAccountType && <>
                                                        <tr>
                                                            <td className="sub_headingTd" colSpan={4}><h2>Primary Applicant Signature</h2></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={6}><img src={appSignatureList != null && appSignatureList[0].signature} style={{ width: 'auto' }} alt="" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="review-label">Created Date</td>
                                                            <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSignatureList[0]?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                            <td className="review-label">Printed Name</td>
                                                            <td>{appSignatureList != null && appSignatureList[0].printedName}</td>
                                                        </tr>
                                                        {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint &&
                                                            <>
                                                                <tr>
                                                                    <td className="sub_headingTd" colSpan={6}><h2>Co-Applicant Signature</h2></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}><img src={appSignatureList != null && appSignatureList[1].signature} style={{ width: 'auto' }} alt="" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="review-label">Created Date</td>
                                                                    <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSignatureList[1]?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                                    <td className="review-label">Printed Name</td>
                                                                    <td>{appSignatureList != null && appSignatureList[1].printedName}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>}

                                                    {isEntityAccountType && <>
                                                        {appSignatureList && appSignatureList.map((appSign) => (
                                                            <>
                                                                <tr>
                                                                    <td className="sub_headingTd" colSpan={4}><h2>Signature for {appSign.printedName}</h2></td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={6}><img className='sign-img' src={appSign.signature} /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="review-label">Created Date</td>
                                                                    <td>{appSignatureList != null && moment(convertUTCToLocalTime(appSign?.createdDate)).format("MM/DD/YYYY hh:mm A")}</td>
                                                                    <td className="review-label">Printed Name</td>
                                                                    <td>{appSign.printedName}</td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <ul className="btnWrapper">
                                    {showStatusButton && <li><button type='button' className='btn-orange' onClick={(e) => handleUpdateStatusShow()}>Action Required</button></li>}
                                    <li><button type='submit' className='btn-primary' onClick={(e) => gotoApplications()}>OK</button></li>
                                    <li><button type='submit' className='btn-primary' onClick={(e) => handlePrint()}>Print</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    show={closeAppShow}
                    onHide={handleCloseAppClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Change Application Status</h3>
                                        <form onSubmit={appformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <div className="input_area">
                                                                    <select name="appStatus" className="form-select"
                                                                        onBlur={appformik.handleBlur} onClick={(e) => handleStatusChange(e.target.value)} onChange={appformik.handleChange}
                                                                        value={appformik.values.appStatus}>
                                                                        <option value="">Select Status</option>
                                                                        {applicationStatusList && applicationStatusList.map((option) => (
                                                                            <option key={option.id} value={option.id}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {appformik.touched.appStatus && appformik.errors.appStatus ? (
                                                                        <p className="error" style={styleLeft}>{appformik.errors.appStatus}</p>
                                                                    ) : null}
                                                                </div>
                                                                {showCommentTextArea && <div className="input_area">
                                                                    <textarea className="inputFormTextArea" name="comment" rows={5}
                                                                        onBlur={appformik.handleBlur} onChange={appformik.handleChange} value={appformik.values.comment}
                                                                        maxLength="500" placeholder="Enter comments here" />
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Change Status</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseAppClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* <Modal
                    show={startClearingFirmShow}
                    onHide={handleCloseStartClearingFirm}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3' style={styleCaseNone}>Do you want to submit the application now to the clearing firm?</h3>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn' onClick={submitToFIS}>Yes</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseStartClearingFirm()}>No</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal> */}

                <Modal show={startClearingFirmShow}
                    onHide={handleCloseStartClearingFirm}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className='heading pb-3'>Please wait as your application is submitting to the clearing firm...</h3>
                                        <div className='row'>
                                            <div className='col-lg-12'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default ReviewComponent;