import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import accountService from '../services/accountservice';
import { useFormik } from "formik";
import * as Yup from "yup";
import OLAEnums from '../helpers/olaenums';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";

const AccountType = () => {

    const navigate = useNavigate();
    const [accountFeaturesInfo, setAccountFeaturesInfo] = useState(null);
    const [showDividendReinvestment, setshowDividendReinvestment] = useState(null);
    const [showeDocuments, setshoweDocuments] = useState(null);
    const [showisMarginEnable, setshowisMarginEnable] = useState(null);
    const [showisSecuritiesLending, setshowisSecuritiesLending] = useState(null);
    const [isFPLShow, setIsFPLShow] = useState(false);
    const [eDocumentsDiv, seteDocumentsDiv] = useState(false);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [show, setShow] = useState(false);
    const [showEDocumentsPopup, setShowEDocumentsPopup] = useState(false);
    const [showMarginOption, setShowMarginOption] = useState(false);
    const handleClose = () => setShow(false);
    const handleDocumentsPopupClose = () => setShowEDocumentsPopup(false);
    const [investorProfileInfo, setInvestorProfileInfo] = useState(null);
    const [showFPLOption, setShowFPLOption] = useState(null);
    const [saveOnly, setSaveOnly] = useState(false);
    const [isJointAccount, setIsJointAccount] = useState(false);
    const [showSweepProgram, setShowSweepProgram] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        //getBrokerByClientUserId();
        getInvestorProfileInfo();
        checkIsJointAccount();
        getAccountFeaturesInfo();
    }, []);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };

    const styleHoverBullets = {
        fontSize: 14, marginTop: 5, marginBottom: 10, lineHeight: 1.5, listStyleType: 'square',
        fontWeight: 500, textAlign: 'left', fontSize: '16px'
    }

    const styleEDocDiv = {
        paddingLeft: 15, fontSize: 12, fontWeight: 'bold', color: 'red'
    }

    const styleEDocSpan = {
        paddingLeft: 10, fontSize: 14, fontWeight: 'normal'
    }

    const styleblueFont = {
        color: '#215ddf', fontWeight: 'bold'
    }

    const styleLineHeight = {
        lineHeight: 1.5,
        textAlign: 'justify'
    }

    const handlePrevious = () => {
        navigate('/regulatory-items');
    }

    const setdividendReinvestment = (strVal) => {
        if (strVal === "true") {
            setshowDividendReinvestment(true);
        }
        else {
            setshowDividendReinvestment(false);
        }
    }

    const seteDocuments = (strVal) => {
        if (strVal === "true") {
            setshoweDocuments(true);
            seteDocumentsDiv(true);
            setShowEDocumentsPopup(false);
        }
        else {
            setshoweDocuments(false);
            seteDocumentsDiv(false);
            setShowEDocumentsPopup(true);
        }
    }
    const setSweepProgram = (strVal) => {
        if (strVal === "true") {
            setShowSweepProgram(true);
        }
        else {
            setShowSweepProgram(false);
        }
    }
    const handleKeepDocuments = () => {
        formik.setFieldValue("eDocuments", true);
        setshoweDocuments(true);
        setShowEDocumentsPopup(false);
    }

    const setisMarginEnable = (strVal) => {
        if (strVal === "true") {
            setshowisMarginEnable(true);
        }
        else {
            setshowisMarginEnable(false);
        }
    }

    const setSecuritiesLending = (strVal) => {
        if (strVal === "true") {
            setshowisSecuritiesLending(true);
        }
        else {
            setshowisSecuritiesLending(false);
        }
    }

    const getAccountFeaturesInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 6
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                        setShowMarginOption(false);
                    }
                    else {
                        setShowMarginOption(true);
                    }
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }

                    if (result.accountFeaturesInfo !== null) {
                        setAccountFeaturesInfo(result.accountFeaturesInfo);

                        if (result.accountFeaturesInfo?.eDocuments === false) {
                            setshoweDocuments(false);
                        }
                        else if (result.accountFeaturesInfo?.eDocuments === true) {
                            setshoweDocuments(true);
                        }
                        else {
                            setshoweDocuments(true); //by default yes
                        }

                        if (result.accountFeaturesInfo?.isMarginEnable === false) {
                            setshowisMarginEnable(false);
                        }
                        else if (result.accountFeaturesInfo?.isMarginEnable === true) {
                            setshowisMarginEnable(true);
                        }
                        else {
                            setshowisMarginEnable(true); //by default yes
                        }

                        if (result.accountFeaturesInfo?.participateSecuritiesLending === false) {
                            setshowisSecuritiesLending(false);
                        }
                        else if (result.accountFeaturesInfo?.participateSecuritiesLending === true) {
                            setshowisSecuritiesLending(true);
                        }
                        else {
                            setshowisSecuritiesLending(true); //by default yes
                        }
                        if (result.accountFeaturesInfo?.dividendReinvestment === false) {
                            setshowDividendReinvestment(false);
                        }
                        else if (result.accountFeaturesInfo?.dividendReinvestment === true) {
                            setshowDividendReinvestment(true);
                        }
                        else {
                            setshowDividendReinvestment(false); //by default no
                        }
                        if (result.accountFeaturesInfo?.sweepProgram === false) {
                            setShowSweepProgram(false);
                        }
                        else if (result.accountFeaturesInfo?.sweepProgram === true) {
                            setShowSweepProgram(true);
                        }
                        else {
                            setShowSweepProgram(true); //by default yes
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    // const getBrokerByClientUserId = () => {
    //     loader.show();
    //     let data = {
    //         UserId: getLoggedInUserInfo().id
    //     };
    //     accountService.getBrokerByClientUserId(data)
    //         .then((json) => {
    //             if(json.data.payload !== null) {
    //                 setBrokerInfo(json.data.payload);
    //             }
    //             loader.hide();
    //         })
    //         .catch((error) => {
    //             toast.error(error, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //             loader.hide();
    //         });
    // }

    //Form values set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: accountFeaturesInfo?.applicationId,
        dividendReinvestment: null,
        sweepProgram: null,
        householdingServices: null,
        eDocuments: null,
        isMarginEnable: null,
        participateSecuritiesLending: null
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        // dividendReinvestment: Yup.string()
        //     .required("Please select an option").nullable(),
        // eDocuments: Yup.string()
        //     .required("Please select an option").nullable(),
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: accountFeaturesInfo?.applicationId,
        dividendReinvestment: accountFeaturesInfo?.dividendReinvestment,
        sweepProgram: accountFeaturesInfo?.sweepProgram,
        householdingServices: accountFeaturesInfo?.householdingServices,
        eDocuments: accountFeaturesInfo?.eDocuments,
        isMarginEnable: accountFeaturesInfo?.isMarginEnable,
        participateSecuritiesLending: accountFeaturesInfo?.participateSecuritiesLending
    };

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            let applicationId = localStorage.getItem("currentAppId");
            let isMargin = false;
            if (!showMarginOption) {
                isMargin = null;
            }
            else {
                isMargin = values.isMarginEnable === null ? true : values.isMarginEnable;
            }

            let isParticipateFPL = null;
            if (!showFPLOption) {
                isParticipateFPL = null;
            }
            else if (values.participateSecuritiesLending === null) {
                isParticipateFPL = true;
            }
            else {
                isParticipateFPL = values.participateSecuritiesLending
            }

            let data = {
                "loggedInUserId": getLoggedInUserInfo().id,
                "applicationId": applicationId,
                "dividendReinvestment": values.dividendReinvestment === null ? false : values.dividendReinvestment,
                "sweepProgram": values.sweepProgram === null ? true : values.sweepProgram,
                "householdingServices": null,
                "eDocuments": values.eDocuments === null ? true : values.eDocuments,
                "isMarginEnable": isMargin,
                //"participateSecuritiesLending": values.participateSecuritiesLending === null ? true : values.participateSecuritiesLending
                "participateSecuritiesLending": isParticipateFPL
            };
            applicationService.saveAccountFeaturesInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getAccountFeaturesInfo();
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/upload-documents");
                        }
                        else {
                            toast.success("Account features info has been saved successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleShow = () => {
        setShow(true);
    }

    const getInvestorProfileInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 4
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.investorProfileInfo !== null) {
                        setInvestorProfileInfo(result.investorProfileInfo);
                        if (result.investorProfileInfo.investmentExperience === parseInt(OLAEnums.InvestmentExpEnum.None)
                            && result.investorProfileInfo.totalNetWorth === parseInt(OLAEnums.TotalNetWorthEnum['$0-24,999'])) {
                            setShowFPLOption(false);
                        }
                        else {
                            setShowFPLOption(true);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const checkIsJointAccount = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo != null) {
                        if (result.applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                            setIsJointAccount(true);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    return (
        <>
            <div className="tab-content" id="account">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <h2 className="heading">Account Features</h2>
                                    </div>
                                    <div className="col-lg-2">
                                        {/* {showFPLOption && <h2 className="heading right-text"><button type='button' className='btn-primary' onClick={handleShow}>Help me decide</button></h2>} */}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">E-Documents<span className="error-info">*</span>
                                            {isJointAccount && <span style={styleEDocSpan}>(Goes to the primary account holder)</span>}
                                            {/* {eDocumentsDiv || !showeDocuments && <span style={styleEDocDiv}>Quarterly charged of 10$</span>} */}
                                        </label>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="eDocuments" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                onClick={(e) => seteDocuments(e.target.value)}
                                                checked={showeDocuments === null ? false : showeDocuments === true ? false : true}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="eDocuments" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                onClick={(e) => seteDocuments(e.target.value)}
                                                checked={showeDocuments === null ? false : showeDocuments === true ? true : false}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {formik.touched.eDocuments && formik.errors.eDocuments ? (
                                        <p className="error" style={styleError}>{formik.errors.eDocuments}</p>
                                    ) : null}
                                </div>
                                {/* Money market sweep */}
                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Money Market Sweep<span className="error-info">*</span></label>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="sweepProgram" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                onClick={(e) => setSweepProgram(e.target.value)}
                                                checked={showSweepProgram === null ? false : showSweepProgram === true ? false : true}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="sweepProgram" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                onClick={(e) => setSweepProgram(e.target.value)}
                                                checked={showSweepProgram === null ? false : showSweepProgram === true ? true : false}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {formik.touched.sweepProgram && formik.errors.sweepProgram ? (
                                        <p className="error" style={styleError}>{formik.errors.sweepProgram}</p>
                                    ) : null}
                                </div>
                                {showMarginOption && <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Would you like to add margin to your account?<span className="error-info">*</span></label>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isMarginEnable" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                onClick={(e) => setisMarginEnable(e.target.value)}
                                                checked={showisMarginEnable === null ? false : showisMarginEnable === true ? false : true}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="isMarginEnable" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                onClick={(e) => setisMarginEnable(e.target.value)}
                                                checked={showisMarginEnable === null ? false : showisMarginEnable === true ? true : false}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {formik.touched.isMarginEnable && formik.errors.isMarginEnable ? (
                                        <p className="error" style={styleError}>{formik.errors.isMarginEnable}</p>
                                    ) : null}
                                </div>}

                                {showFPLOption && <div className="row">
                                    <div className="col-lg-10" //onMouseEnter={() => setIsFPLShow(true)} onMouseLeave={() => setIsFPLShow(false)}
                                    >
                                        <label className="form-label">Would you like to participate in <span className='lending-text' onClick={handleShow}>Fully Paid Securities Lending</span>?<span className="error-info">*</span></label>
                                        {isFPLShow && (
                                            <div>
                                                <ul style={styleHoverBullets}>
                                                    <li>Additional monthly income potential / continued revenue each day the stock is on loan</li>
                                                    <li>No action required after successful enrollment</li>
                                                    <li>No additional cost to participate</li>
                                                    <li>Maintain ownership of your shares</li>
                                                    <li>Opt out of the program at any time / No long-term commitments – you can sell your securities on loan at any time</li>
                                                    <li>Loans are back backed by 102% of cash collateral held at a 3rd party bank, aimed at reducing risk. All loans are mark to
                                                        market daily.</li>
                                                </ul>
                                                <a className='review-label' style={styleHoverBullets} href='../../assets/disclosures/fpl-proposal.pdf' rel="noreferrer" target="_blank">What is Fully Paid Securities Lending?</a>
                                            </div>

                                        )}
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="participateSecuritiesLending" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                onClick={(e) => setSecuritiesLending(e.target.value)}
                                                checked={showisSecuritiesLending === null ? false : showisSecuritiesLending === true ? false : true}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="participateSecuritiesLending" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                onClick={(e) => setSecuritiesLending(e.target.value)}
                                                checked={showisSecuritiesLending === null ? false : showisSecuritiesLending === true ? true : false}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {formik.touched.participateSecuritiesLending && formik.errors.participateSecuritiesLending ? (
                                        <p className="error" style={styleError}>{formik.errors.participateSecuritiesLending}</p>
                                    ) : null}
                                </div>}

                                <div className="row">
                                    <div className="col-lg-10">
                                        <label className="form-label">Dividend reinvestment<span className="error-info">*</span></label>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="dividendReinvestment" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={false}
                                                onClick={(e) => setdividendReinvestment(e.target.value)}
                                                checked={showDividendReinvestment === null ? false : showDividendReinvestment === true ? false : true}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name="dividendReinvestment" disabled={ctrlDisable}
                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={true}
                                                onClick={(e) => setdividendReinvestment(e.target.value)}
                                                checked={showDividendReinvestment === null ? false : showDividendReinvestment === true ? true : false}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                    </div>
                                    {formik.touched.dividendReinvestment && formik.errors.dividendReinvestment ? (
                                        <p className="error" style={styleError}>{formik.errors.dividendReinvestment}</p>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(false)} disabled={ctrlDisable}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <h4 className="modal-title">Fully Paid Securities Lending?</h4>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="modal-heading" style={styleLineHeight}>
                                            <ul style={styleHoverBullets}>
                                                <li className="paragraph-item">Additional monthly income potential / continued revenue each day the stock is on loan</li>
                                                <li className="paragraph-item">No action required after successful enrollment</li>
                                                <li className="paragraph-item">No additional cost to participate</li>
                                                <li className="paragraph-item">Maintain ownership of your shares</li>
                                                <li className="paragraph-item">Opt out of the program at any time / No long-term commitments – you can sell your securities on loan at any time</li>
                                                <li className="paragraph-item">Loans are back backed by 102% of cash collateral held at a 3rd party bank, aimed at reducing risk. All loans are mark to
                                                    market daily.</li>
                                            </ul>
                                            <a className='review-label' style={styleHoverBullets} href='../../assets/disclosures/fpl-proposal.pdf' rel="noreferrer" target="_blank">What is Fully Paid Securities Lending?</a>

                                        </div>
                                    </div>
                                </div> */}

                                <div className='imgArea'>
                                    <img src='../assets/images/info_img.png' />
                                </div>
                                <div className='msgs_Area'>
                                    <h3>Fully Paid Securities Lending</h3>
                                    <div className='row'>

                                        <div className='col-lg-12'>
                                            <ul className='list_area'>
                                                <li>Additional monthly income potential/continued revenue each day the stock is on loan</li>
                                                <li>No action required after successful enrollment</li>
                                                <li>No additional cost to participate</li>
                                                <li>Maintain ownership of your shares</li>
                                                <li>Opt out of the program at any time/No long-term commitments – you can sell your securities on loan at any time</li>
                                                <li>Loans are back backed by 102% of cash collateral held at a 3rd party bank, aimed at reducing risk. All loans are mark to
                                                    market daily.</li>
                                            </ul>
                                            <a className='reviewBtnsArea' href='../../assets/disclosures/fpl-proposal.pdf' rel="noreferrer" target="_blank">What is Fully Paid Securities Lending?</a>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'><button type='submit' className='yes_noBtn cancel_btn' onClick={(e) => handleClose()}>Close</button></div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showEDocumentsPopup}
                onHide={handleDocumentsPopupClose} backdrop="static" keyboard={false} centered size='md' aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='imgArea'>
                                    <img src='../assets/images/info_img.png' />
                                </div>
                                <div className='msgs_Area'>
                                    <h3>Physical Documents?</h3>
                                    <p>If you choose to receive physical documents, you will be charged a $10 fee for paper statements. ($10 per statement)</p>
                                    {/* <p>If you choose to receive physical documents, you will be charged a ${brokerInfo?.feePerStatement} fee per statement and ${brokerInfo?.feePerConfirmation} per confirmation.</p> */}
                                    <div className='row'>
                                        <div className='col-lg-6'><button type='button' className='yes_noBtn' onClick={(e) => handleDocumentsPopupClose()}>Accept Charge</button></div>
                                        <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleKeepDocuments()}>Keep Documents</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AccountType;