import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import OLAEnums from '../helpers/olaenums';
import lovService from '../services/lovservice';
import applicationService from '../services/applicationservice';
import loader from 'super-react-loader';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';

const InvestorEntityInfo = () => {
    const navigate = useNavigate();
    const [investmentObjectiveList, setInvestmentObjectiveList] = useState(null);
    const [investmentExperienceList, setInvestmentExperienceList] = useState(null);
    const [riskToleranceList, setRiskToleranceList] = useState(null);
    const [timeHorizonList, setTimeHorizonList] = useState(null);
    const [taxBracketList, setTaxBracketList] = useState(null);
    const [annualIncomeList, setAnnualIncomeList] = useState(null);
    const [netWorthList, setNetWorthList] = useState(null);
    const [liquidNetWorthList, setLiquidNetWorthList] = useState(null);
    const [liquidityNeedsList, setLiquidityNeedsList] = useState(null);
    //const [optionApprovalLevel, setOptionApprovalLevel] = useState(null);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [investorProfileInfo, setInvestorProfileInfo] = useState(null);
    const [applicationACHInfo, setApplicationACHInfo] = useState(null);
    const [focusedClass, setFocusedClass] = useState("levelBox focused");
    const [bankAccountTypes, setBankAccountTypes] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);
    const [entityInfo, setEntityInfo] = useState(null);
    const [showACHSection, setShowACHSection] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getInvestmentObjectives();
        getInvestmentExperience();
        getRiskTolerance();
        getTimeHorizons();
        getTaxBrackets();
        getAnnualIncomes();
        getNetWorths();
        getLiquidNetWorths();
        getLiquidityNeeds();
        getInvestorProfileInfo();
        getBankAccountTypes();
        getEntityInformation();
    }, []);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };
    const radioTopMargin = {
        marginTop: '12px',
    };
    const achImageStyle = {
        width: '700px', height: 'auto',
    };

    const getEntityInformation = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }
                    if (result?.entityInformationInfo !== null) {
                        setEntityInfo(result?.entityInformationInfo);
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getInvestmentObjectives = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.InvestmentObjective)
            .then((json) => {
                if (json.data.payload !== null) {
                    let invObj = json.data.payload.sort((a, b) => a.sequenceNo > b.sequenceNo ? 1 : -1)
                    setInvestmentObjectiveList(invObj);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getInvestmentExperience = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.InvestmentExperience)
            .then((json) => {
                if (json.data.payload !== null) {
                    setInvestmentExperienceList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getRiskTolerance = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.RiskTolerance)
            .then((json) => {
                if (json.data.payload !== null) {
                    setRiskToleranceList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getTimeHorizons = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.TimeHorizon)
            .then((json) => {
                if (json.data.payload !== null) {
                    setTimeHorizonList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getTaxBrackets = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.TaxRate)
            .then((json) => {
                if (json.data.payload !== null) {
                    setTaxBracketList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getAnnualIncomes = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.AnnualIncome)
            .then((json) => {
                if (json.data.payload !== null) {
                    setAnnualIncomeList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getNetWorths = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.NetWorth)
            .then((json) => {
                if (json.data.payload !== null) {
                    var list = json.data.payload.sort((a, b) => (a.sequenceNo - b.sequenceNo))
                    setNetWorthList(list);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getLiquidNetWorths = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.LiquidNetWorth)
            .then((json) => {
                if (json.data.payload !== null) {
                    var list = json.data.payload.sort((a, b) => (a.sequenceNo - b.sequenceNo))
                    setLiquidNetWorthList(list);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getLiquidityNeeds = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.LiquidityNeeds)
            .then((json) => {
                if (json.data.payload !== null) {
                    setLiquidityNeedsList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    // const setOptionApprovalLevelValue = (strVal) => {
    //     setOptionApprovalLevel(strVal);
    // }

    const handlePrevious = () => {
        navigate('/entity-info');
    }

    const getBankAccountTypes = () => { //used in ACH form
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.BankAccountType)
            .then((json) => {
                if (json.data.payload !== null) {
                    setBankAccountTypes(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    //Form vaalues set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: applicationInfo?.applicationId,
        investmentObjective: null,
        investmentExperience: null,
        riskTolerance: null,
        timeHorizon: null,
        taxBracket: null,
        annualIncome: null,
        netWorth: null,
        liquidNetWorth: null,
        liquidityNeed: null,
        stockExp: null,
        fundsExp: null,
        marginAccountExp: null,
        municipalBondsExp: null,
        commoditiesExp: null,
        govtBondsExp: null,
        corporateBondsExp: null,
        optionsExp: null,
        taxShelterExp: null,
        optionStrategyLevel: null,
        isOptionEnable: null,
        isMarginEnable: null,
        isACHEnable: showACHSection,
        accountOwnerName: null,
        bankName: null,
        bankAccountNumber: null,
        bankRoutingNumber: null,
        bankAccountType: null,
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        investmentObjective: Yup.string().required("Please select investment objective").nullable(),
        investmentExperience: Yup.string().required("Please select investment experience").nullable(),
        riskTolerance: Yup.string().required("Please select risk tolerance").nullable(),
        timeHorizon: Yup.string().required("Please select time horizon").nullable(),
        taxBracket: Yup.string().required("Please select tax rate").nullable(),
        annualIncome: Yup.string().required("Please select annual income").nullable(),
        netWorth: Yup.string().required("Please select net worth").nullable(),
        liquidNetWorth: Yup.string().required("Please select liquid net worth").nullable(),
        liquidityNeed: Yup.string().required("Please select liquidity needs").nullable(),
        stockExp: Yup.string().required("Enter stock experience").nullable(),
        fundsExp: Yup.string().required("Enter mutual funds experience").nullable(),
        marginAccountExp: Yup.string().required("Enter margin account experience").nullable(),
        municipalBondsExp: Yup.string().required("Enter municipal bonds experience").nullable(),
        commoditiesExp: Yup.string().required("Enter commodities experience").nullable(),
        govtBondsExp: Yup.string().required("Enter govt bonds experience").nullable(),
        corporateBondsExp: Yup.string().required("Enter corporate bonds experience").nullable(),
        optionsExp: Yup.string().required("Enter options experience").nullable(),
        taxShelterExp: Yup.string().required("Enter tax shelters experience").nullable(),
        accountOwnerName: showACHSection ? Yup.string().required("Account owner name is required").nullable() : Yup.string().nullable().notRequired(),
        bankName: showACHSection ? Yup.string().required("Bank name is required").nullable() : Yup.string().nullable().notRequired(),
        bankAccountNumber: showACHSection ? Yup.string().required("Bank account number is required")
            .matches(/^[0-9]+$/, "You must specify a number").min(4, "Mininum 4 characters required").nullable() : Yup.string().nullable().notRequired(),
        bankRoutingNumber: showACHSection ? Yup.string().required("Routing number is required").min(9, "ABA number is 9 digits long").nullable().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Routing number is not valid"
        ) : Yup.string().nullable().notRequired(),
        bankAccountType: showACHSection ? Yup.string().required("Please select bank account type").nullable() : Yup.string().nullable().notRequired()
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: applicationInfo?.id,
        investmentObjective: investorProfileInfo?.investmentObjective,
        investmentExperience: investorProfileInfo?.investmentExperience,
        riskTolerance: investorProfileInfo?.investmentRiskTolerance,
        timeHorizon: investorProfileInfo?.investmentTimePeriod,
        taxBracket: investorProfileInfo?.taxBracket,
        annualIncome: investorProfileInfo?.annualIncome,
        netWorth: investorProfileInfo?.totalNetWorth,
        liquidNetWorth: investorProfileInfo?.liquidNetWorth,
        liquidityNeed: investorProfileInfo?.liquidityNeeds,
        stockExp: investorProfileInfo?.stockInvestmentExperience,
        fundsExp: investorProfileInfo?.fundsInvestmentExperience,
        marginAccountExp: investorProfileInfo?.marginAccountInvestmentExperience,
        municipalBondsExp: investorProfileInfo?.municipalBondsInvestmentExperience,
        commoditiesExp: investorProfileInfo?.commoditiesInvestmentExperience,
        govtBondsExp: investorProfileInfo?.govtBondsInvestmentExperience,
        corporateBondsExp: investorProfileInfo?.corporateBondsInvestmentExperience,
        optionsExp: investorProfileInfo?.optionsInvestmentExperience,
        taxShelterExp: investorProfileInfo?.taxSheltersInvestmentExperience,
        //optionStrategyLevel: investorProfileInfo.optionStrategyLevel
        isACHEnable: applicationInfo?.isACHEnable,
        accountOwnerName: entityInfo && entityInfo?.entityName,
        bankName: applicationACHInfo && applicationACHInfo?.bankName,
        bankAccountNumber: applicationACHInfo && applicationACHInfo?.bankAccountNumber,
        bankRoutingNumber: applicationACHInfo && applicationACHInfo?.bankRoutingNumber,
        bankAccountType: applicationACHInfo && applicationACHInfo?.bankAccountType,
    };

    const toggleACHEnable = (cbVal) => {
        if (cbVal) {
            setShowACHSection(true);
        }
        else {
            setShowACHSection(false);
        }
    }

    //Get Investor Profile Info
    const getInvestorProfileInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 4
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;

                    if (result.applicationInfo !== null) {
                        localStorage.setItem("currentAppId", result.applicationInfo.applicationId);
                        setApplicationInfo(result.applicationInfo);
                        if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }
                    }
                    if (result.investorProfileInfo !== null) {
                        setInvestorProfileInfo(result.investorProfileInfo);
                        //setOptionApprovalLevel(result.investorProfileInfo.optionStrategyLevel);
                    }
                    if (result.applicationACHInfo !== null) {
                        setApplicationACHInfo(result.applicationACHInfo);
                    }
                    if (result.applicationInfo.isACHEnable) {
                        setShowACHSection(true);
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            // if (optionApprovalLevel === null) {
            //     toast.error("Please select option approval level", {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     return;
            // };
            let applicationId = localStorage.getItem("currentAppId");
            let data = {
                "loggedInUserId": getLoggedInUserInfo().id,
                "applicationId": applicationId,
                "investmentObjective": values.investmentObjective,
                "investmentTimePeriod": values.timeHorizon,
                "investmentRiskTolerance": values.riskTolerance,
                "investmentExperience": values.investmentExperience,
                "annualIncome": values.annualIncome,
                "netWorth": values.netWorth,
                "liquidNetWorth": values.liquidNetWorth,
                "liquidityNeeds": values.liquidityNeed,
                "taxRate": values.taxBracket,
                "stockInvestmentExperience": values.stockExp,
                "fundsInvestmentExperience": values.fundsExp,
                "marginAccountInvestmentExperience": values.marginAccountExp,
                "municipalBondsInvestmentExperience": values.municipalBondsExp,
                "commoditiesInvestmentExperience": values.commoditiesExp,
                "govtBondsInvestmentExperience": values.govtBondsExp,
                "corporateBondsInvestmentExperience": values.corporateBondsExp,
                "optionsInvestmentExperience": values.optionsExp,
                "taxSheltersInvestmentExperience": values.taxShelterExp,
                "optionStrategyLevel": null, //optionApprovalLevel,
                "isOptionEnable": null,
                "isMarginEnable": null,
                "isACHEnable": showACHSection,
                "applicationACHInfoRequest": {
                    "loggedInUserId": getLoggedInUserInfo().id,
                    "applicationId": applicationId,
                    "applicantId": null,
                    "applicantTypeId": parseInt(OLAEnums.ApplicantTypeEnum.EntityApplicant),
                    "htsAccountNumber": null,
                    "homePhoneNumber": null,
                    "achRequestType": null,
                    "nameonBankAccount": values.accountOwnerName,
                    "bankName": values.bankName,
                    "bankAccountType": values.bankAccountType,
                    "bankRoutingNumber": values.bankRoutingNumber,
                    "bankAccountNumber": values.bankAccountNumber,
                    "recurringTransferDividend": null,
                    "recurringDividendOption": null,
                    "recurringMonthlyTransfer": null,
                    "recurringMonthlyOption": null,
                    "bankAmount": null,
                    "bankDayofMonth": null,
                    "bankExpiration": null,
                    "attachVoidedCheck": null
                }
            };
            applicationService.saveInvestorProfileEntityInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getInvestorProfileInfo();
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/regulatory-entity-items");
                        }
                        else {
                            toast.success("Investor profile info has been updated successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                    loader.hide();
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });
    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <div className="tab-content" id="investor">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">Investment Profile</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Investment Objective<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="investmentObjective" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.investmentObjective}>
                                                            <option value="" selected>Select</option>
                                                            {investmentObjectiveList && investmentObjectiveList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.investmentObjective && formik.errors.investmentObjective ? (
                                                            <p className="error">{formik.errors.investmentObjective}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Investment Experience<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="investmentExperience" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.investmentExperience}>
                                                            <option value="" selected>Select</option>
                                                            {investmentExperienceList && investmentExperienceList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.investmentExperience && formik.errors.investmentExperience ? (
                                                            <p className="error">{formik.errors.investmentExperience}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Risk Tolerance<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="riskTolerance" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.riskTolerance}>
                                                            <option value="" selected>Select</option>
                                                            {riskToleranceList && riskToleranceList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.riskTolerance && formik.errors.riskTolerance ? (
                                                            <p className="error">{formik.errors.riskTolerance}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Time Horizon<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="timeHorizon" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.timeHorizon}>
                                                            <option value="" selected>Select</option>
                                                            {timeHorizonList && timeHorizonList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.timeHorizon && formik.errors.timeHorizon ? (
                                                            <p className="error">{formik.errors.timeHorizon}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Tax Bracket<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="taxBracket" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.taxBracket}>
                                                            <option value="" selected>Select</option>
                                                            {taxBracketList && taxBracketList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.taxBracket && formik.errors.taxBracket ? (
                                                            <p className="error">{formik.errors.taxBracket}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Financial Suitability</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Annual Income<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="annualIncome" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.annualIncome}>
                                                            <option value="" selected>Select</option>
                                                            {annualIncomeList && annualIncomeList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.annualIncome && formik.errors.annualIncome ? (
                                                            <p className="error">{formik.errors.annualIncome}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Total Net Worth<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="netWorth" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.netWorth}>
                                                            <option value="" selected>Select</option>
                                                            {netWorthList && netWorthList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.netWorth && formik.errors.netWorth ? (
                                                            <p className="error">{formik.errors.netWorth}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Liquid Net Worth<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="liquidNetWorth" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.liquidNetWorth}>
                                                            <option value="" selected>Select</option>
                                                            {liquidNetWorthList && liquidNetWorthList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.liquidNetWorth && formik.errors.liquidNetWorth ? (
                                                            <p className="error">{formik.errors.liquidNetWorth}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Liquidity Needs<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="liquidityNeed" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.liquidityNeed}>
                                                            <option value="" selected>Select</option>
                                                            {liquidityNeedsList && liquidityNeedsList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.liquidityNeed && formik.errors.liquidityNeed ? (
                                                            <p className="error">{formik.errors.liquidityNeed}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Prior Investment Experience <label className='form-label'></label></h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Stock (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="stockExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.stockExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.stockExp && formik.errors.stockExp ? (
                                                            <p className="error">{formik.errors.stockExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Options (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="optionsExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.optionsExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.optionsExp && formik.errors.optionsExp ? (
                                                            <p className="error">{formik.errors.optionsExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Mutual Funds (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="fundsExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.fundsExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.fundsExp && formik.errors.fundsExp ? (
                                                            <p className="error">{formik.errors.fundsExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Margin Account (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="marginAccountExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.marginAccountExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.marginAccountExp && formik.errors.marginAccountExp ? (
                                                            <p className="error">{formik.errors.marginAccountExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Municipal Bonds (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="municipalBondsExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.municipalBondsExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.municipalBondsExp && formik.errors.municipalBondsExp ? (
                                                            <p className="error">{formik.errors.municipalBondsExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Commodities / Futures (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="commoditiesExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.commoditiesExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.commoditiesExp && formik.errors.commoditiesExp ? (
                                                            <p className="error">{formik.errors.commoditiesExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Government Bonds (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="govtBondsExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.govtBondsExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.govtBondsExp && formik.errors.govtBondsExp ? (
                                                            <p className="error">{formik.errors.govtBondsExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Corporate Bonds (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="corporateBondsExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.corporateBondsExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.corporateBondsExp && formik.errors.corporateBondsExp ? (
                                                            <p className="error">{formik.errors.corporateBondsExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Tax Shelters (# of Years)<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="taxShelterExp" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.taxShelterExp} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            <option value="0">0 / None</option>
                                                            <option value="1-4">1-4</option>
                                                            <option value="5-9">5-9</option>
                                                            <option value="10-14">10-14</option>
                                                            <option value="15-19">15-19</option>
                                                            <option value="20-24">20-24</option>
                                                            <option value="25+">25+</option>
                                                        </select>
                                                        {formik.touched.taxShelterExp && formik.errors.taxShelterExp ? (
                                                            <p className="error">{formik.errors.taxShelterExp}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">ACH Relationship</h2>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="form-check float-left pb-5">
                                                    <input className="form-check-input"
                                                        onClick={(e) => toggleACHEnable(e.target.checked)} type="checkbox" disabled={ctrlDisable}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.isACHEnable}
                                                        checked={showACHSection ? true : false}
                                                        id="cbIsACHEnable" name="cbIsACHEnable" />
                                                    <label className="form-check-label">Would you like to add ACH relationship?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showACHSection && <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Account Owner Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="accountOwnerName" readOnly placeholder="Account Owner Name" maxLength={50} disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.accountOwnerName} />
                                                        {formik.touched.accountOwnerName && formik.errors.accountOwnerName ? (
                                                            <p className="error">{formik.errors.accountOwnerName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Bank Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="bankName" placeholder="Bank Name" maxLength={20} disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.bankName} />
                                                        {formik.touched.bankName && formik.errors.bankName ? (
                                                            <p className="error">{formik.errors.bankName}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Bank Account Number<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="bankAccountNumber" placeholder="Bank Account Number" maxLength={17} disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.bankAccountNumber} />
                                                        {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber ? (
                                                            <p className="error">{formik.errors.bankAccountNumber}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">ABA Bank Routing Number<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="bankRoutingNumber" placeholder="Bank Routing Number" maxLength={9} disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.bankRoutingNumber} />
                                                        {formik.touched.bankRoutingNumber && formik.errors.bankRoutingNumber ? (
                                                            <p className="error">{formik.errors.bankRoutingNumber}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Bank Account Type<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="bankAccountType" className="form-select"
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.bankAccountType} disabled={ctrlDisable}>
                                                            <option value="" selected>Select</option>
                                                            {bankAccountTypes && bankAccountTypes.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.bankAccountType && formik.errors.bankAccountType ? (
                                                            <p className="error">{formik.errors.bankAccountType}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <img src='../assets/images/ach_check.png' className='ach-check' style={achImageStyle} />
                                        </div>
                                    </div>}
                                </div>

                            </div>

                            {/* <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Option Approval Level</h2>
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <p className='form-label paragraph'>Please select the appropriate level for the type of options you are looking to trade</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level0 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level0)}>
                                                    <span className="levelTitle">Level 0</span>
                                                    <span className="levelName">No Options Trading</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level1 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level1)}>
                                                    <span className="levelTitle">Level 1</span>
                                                    <span className="levelName">Covered Calls<br />Buy Writes</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level2 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level2)}>
                                                    <span className="levelTitle">Level 2</span>
                                                    <span className="levelBold">Everything in Level 1 plus:</span><br /><span className="levelName">Long Calls Long Puts <br />Covered Puts <br />Long
                                                        Straddles <br />Long Strangles</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level3 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level3)}>
                                                    <span className="levelTitle">Level 3</span>
                                                    <span className="levelBold">Everything in Level 2 plus:</span><br /><span className="levelName">Equity Spreads <br />Index Spreads</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level4 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level4)}>
                                                    <span className="levelTitle">Level 4</span>
                                                    <span className="levelBold">Everything in Level 3 plus:</span><br /><span className="levelName">Naked Equity Puts</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={investorProfileInfo && investorProfileInfo?.optionStrategyLevel === OLAEnums.OptionStrategyLevelEnum.Level5 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level5)}>
                                                    <span className="levelTitle">Level 5</span>
                                                    <span className="levelBold">Everything in Level 4 plus:</span><br /><span className="levelName">Naked Equity Calls</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className={optionApprovalLevel === OLAEnums.OptionStrategyLevelEnum.Level6 ? focusedClass : "levelBox"}
                                                    onClick={(e) => setOptionApprovalLevelValue(OLAEnums.OptionStrategyLevelEnum.Level6)}>
                                                    <span className="levelTitle">Level 6</span>
                                                    <span className="levelBold">Everything in Level 5 plus:</span><br /><span className="levelName">Naked Index Calls<br /> Naked Index Puts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="paragraph">Options Agreement Required for Level 1 + 2 <br /> Margin &
                                            Option Agreements Required for Level 3 + 4 + 5 + 6</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(false)}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(true)}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );
};
export default InvestorEntityInfo;