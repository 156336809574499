import axios from "axios";
import { baseURL } from "../helpers/constants";
import authService from "../services/authservice";
import moment from 'moment/moment';

export default axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

export function truncateText(text, number) {
  if (text.length > 15) {
    return text.substring(0, number) + "...";
  }
  return text;
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export function getLoggedInUserInfo() {
  const currentUser = localStorage.getItem("currentUser");
  if (currentUser !== null && currentUser !== undefined) {
    return JSON.parse(currentUser);
  }
  else {
    window.location.href = '/'; //Redirect to the login page
    return null;
  }
}

export function getCurrentApplicationId() {
  if (localStorage.getItem("currentAppId") !== null && localStorage.getItem("currentAppId") !== undefined) {
    return JSON.parse(localStorage.getItem("currentAppId"));
  }
  return null;
}

export function removeFromArray(array, docType) {
  return array.filter(x => x.docType !== docType);
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function strToUpperCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.toUpperCase();
    }
  );
}

export function checkIfFilesAreTooBig(files) {
  let valid = true
  if (files) {
    files.map(file => {
      const size = file.size / 1024 / 1024
      if (size > 10) {
        valid = false
      }
    })
  }
  return valid
}

export function checkIfFilesAreCorrectType(files) {
  let valid = true
  if (files) {
    files.map(file => {
      if (!['application/pdf', 'image/jpeg', 'image/png', 'application/doc', 'application/docx', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        valid = false
      }
    })
  }
  return valid
}

export function hideSSN(str, num) {
  if (str !== undefined) {
    let result = str.slice(num);
    return 'XXX-XX-' + result;
  }
  return '';
}
export function hideForeignTaxId(str, num) {
  if (str !== undefined) {
    let result = str.slice(num);
    return 'XXX-XX-' + result;
  }
  return '';
}

export function groupByArray(arr) {
  var groups = {};
  arr.forEach(function (val) {
    var date = val.createdDate.split('T')[0];
    if (date in groups) {
      groups[date].push(val);
    } else {
      groups[date] = new Array(val);
    }
  });
  return groups;
}

export function checkAuthorization(response) {
  if (response.response.status === 401) {
    authService.logout();
  }
}

export function calculateAage(dob1) {
  var today = new Date();
  var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
}

export function countCharacters(str) {
  var string_length = str.length;
  return string_length;
}

export function validateString(name) {
  var isValidName = true;
  if (/[!@#$%^&*(),.?":{}|<>]/g.test(name) || !/^[a-zA-Z]/.test(name) || /\d+/g.test(name)) {
    isValidName = false;
  }
  return isValidName;
}

export function validateNumber(str) {
  if (/^[0-9]*$/.test(str))
    return true;
  else
    return false;
}

export function convertUTCToLocalTime(date) {
  var date = moment.utc(date).format('DD MMM, yyyy hh:mm a');
  var local = moment.utc(date).local().format('DD MMM, yyyy hh:mm a');
  return local;
}

export function validatePostalCode(postalCode) {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(postalCode);
};