import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

const PricingPlans = () => {

    const [pricingPlans, setPricingPlans] = useState(null);
    const [pricingPlanAddShow, setPricingPlanAddShow] = useState(false);
    const [pricingPlanEditShow, setPricingPlanEditShow] = useState(false);
    const [currentPricingPlan, setCurrentPricingPlan] = useState(null);
    const [showIsDefault, setShowIsDefault] = useState(false);

    const handlePricingPlanAddClose = () => {
        setPricingPlanAddShow(false);
        addformik.resetForm();
    }

    const handlePricingPlanEditClose = () => setPricingPlanEditShow(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getPricingPlans();
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const getPricingPlans = () => {
        loader.show();
        adminService.getPricingPlans()
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setPricingPlans(result);
                    loader.hide();
                }
                else {
                    setPricingPlans(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePricingPlanAddShow = () => {
        addformik.resetForm();
        setPricingPlanAddShow(true);
        setCurrentPricingPlan(null);
    }

    const handlePricingPlanEditShow = (item) => {
        setCurrentPricingPlan(item);
        toggleDefaultCheckbox(item.isDefault);
        setPricingPlanEditShow(true);
    }

    //Add Pricing Plan
    let addformikValues = {
        addPlanName: null,
        addPlanPrice: null,
        addisDefault: null
    };

    let ADD_INITIAL_FORM_STATE = addformikValues;
    const ADD_FORM_VALIDATION = Yup.object().shape({
        addPlanName: Yup.string()
            .required("Plan name is required").nullable(),
        addPlanPrice: Yup.string()
            .required("Price is required").nullable()
    });

    const addformik = useFormik({
        enableReinitialize: true,
        initialValues: ADD_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            let isDefaultValue = showIsDefault ? 1 : 0;
            loader.show();
            let data = {
                planName: values.addPlanName,
                planprice: values.addPlanPrice.toFixed(2),
                isDefault: isDefaultValue
            };
            adminService.createPricingPlan(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setPricingPlanAddShow(false);
                        getPricingPlans();
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    else {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    //Edit supervisor 
    let formikValues = {
        planName: currentPricingPlan?.planName,
        planPrice: currentPricingPlan?.planPrice,
        isDefault: showIsDefault
    };

    let INITIAL_FORM_STATE = formikValues;
    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let isDefaultValue = values.isDefault ? 1 : 0;
            let data = {
                planId: currentPricingPlan?.planId,
                planName: values.planName,
                planPrice: values.planPrice.toFixed(2),
                isDefault: isDefaultValue
            };
            adminService.updatePricingPlan(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setPricingPlanEditShow(false);
                        getPricingPlans();
                        toast.success("Pricing plan has been updated successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const toggleDefaultCheckbox = (cbVal) => {
        if (cbVal) {
            setShowIsDefault(true);
        }
        else {
            setShowIsDefault(false);
        }
    }

    const handleDeletePricingPlan = (row) => {
        loader.show();
        let data = {
            planId: row.planId
        };

        adminService.deletePricingPlan(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    getPricingPlans();
                    toast.success("Pricing plan has been deleted successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const columns = [
        {
            name: 'Plan Name',
            selector: row => <div className='paddingLeft-15'>{row.planName}</div>,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => '$ ' + row.planPrice.toFixed(2),
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => moment.utc(row.createdDate).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            name: 'Is Default',
            selector: row => row.isDefault ? "Yes" : "No",
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Pricing Plan" onClick={(e) => handlePricingPlanEditShow(row)}>Edit</Dropdown.Item>
                            <Dropdown.Item title="Delete Pricing Plan" onClick={(e) => handleDeletePricingPlan(row)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Pricing Plans</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-10">
                            <h2 className="heading">Pricing Plans</h2>
                        </div>
                        <div className='col-lg-2'>
                            <ul className="table_btns float-right">
                                <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handlePricingPlanAddShow()}>Add Pricing Plan</button></li>
                            </ul>
                        </div>
                    </div>
                    {pricingPlans && <DataTable theme="default" columns={columns} data={pricingPlans} pagination />}
                    {pricingPlans === null && <DataTable pagination noDataComponent="No record found" />}
                    <div className="row pt-3"></div>
                </section>

                {/* Add Pricing Plan */}
                <Modal
                    show={pricingPlanAddShow}
                    onHide={handlePricingPlanAddClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Add a New Pricing Plan</h3>

                                        <form onSubmit={addformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Plan Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addPlanName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addPlanName} />
                                                                    {addformik.touched.addPlanName && addformik.errors.addPlanName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addPlanName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Price<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="number" className="inputForm" name="addPlanPrice" min={1}
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addPlanPrice} />
                                                                    {addformik.touched.addPlanPrice && addformik.errors.addPlanPrice ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addPlanPrice}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Default</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleDefaultCheckbox(e.target.checked)}
                                                                        type="checkbox" onBlur={addformik.handleBlur}
                                                                        value={addformik.values.addisDefault}
                                                                        checked={showIsDefault ? true : false}
                                                                        id="addcbisDefault" name="addcbisDefault" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Add</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handlePricingPlanAddClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Edit Broker */}
                <Modal
                    show={pricingPlanEditShow}
                    onHide={handlePricingPlanEditClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Pricing Plan</h3>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Plan Name</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="planName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.planName} />
                                                                    {formik.touched.planName && formik.errors.planName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.planName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Price<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="number" className="inputForm" name="planPrice" min={1}
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.planPrice} />
                                                                    {formik.touched.planPrice && formik.errors.planPrice ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.planPrice}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-lg-3">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Default</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleDefaultCheckbox(e.target.checked)}
                                                                        type="checkbox" onBlur={formik.handleBlur}
                                                                        value={formik.values.isDefault}
                                                                        checked={showIsDefault ? true : false}
                                                                        id="cbIsDefault" name="cbIsDefault" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handlePricingPlanEditClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    );
};

export default PricingPlans;