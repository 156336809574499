import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import applicationService from '../services/applicationservice';
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo, validatePostalCode } from '../common/common';
import OLAEnums from '../helpers/olaenums';
import loader from 'super-react-loader';
import { defaultCountry } from '../helpers/constants';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';

const Employment = () => {

    const navigate = useNavigate();
    const [stateList, setStateList] = useState(null);
    const [coAppStateList, setCoAppStateList] = useState(null);
    const [countryList, setCountryList] = useState(null);

    const [employeeStatus, setEmployeeStatus] = useState(null);
    const [coAppEmployeeStatus, setCoAppEmployeeStatus] = useState(null);
    const [showEmployeeForm, setShowEmployeeForm] = useState(null);
    const [focusedClass, setFocusedClass] = useState("levelBox focused");
    const [showCoAppEmployeeForm, setShowCoAppEmployeeForm] = useState(null);

    const [applicationInfo, setApplicationInfo] = useState(null);
    const [employmentInfoList, setEmploymentInfoList] = useState(null);
    const [employmentInfoRequestList, setEmploymentInfoRequestList] = useState([]);
    const [isJointAccount, setIsJointAccount] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [empInfoMissing, setEmpInfo] = useState(true);
    const [coAppempInfoMissing, setCoAppEmpInfo] = useState(true);
    const [isRetirementAccountType, setIsRetirementAccountType] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);

    const styleError = {
        color: 'red', fontSize: 12, fontWeight: 'bold', marginTop: 3
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getCountries();
        getStatesbyCountryId(defaultCountry);
        getCoAppStatesbyCountryId(defaultCountry);
        getEmploymentInfo();
    }, []);

    // const getStates = () => {
    //     applicationService.getStates()
    //         .then((json) => {
    //             if (json.data.payload !== null) {
    //                 setStateList(json.data.payload);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error(error, {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         });
    // }

    const getStatesbyCountryId = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setStateList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const getCoAppStatesbyCountryId = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setCoAppStateList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handleCountryChange = (e) => {
        getStatesbyCountryId(e.target.value);
    }

    const handleCoAppCountryChange = (e) => {
        getCoAppStatesbyCountryId(e.target.value);
    }

    const getCountries = () => {
        applicationService.getCountries()
            .then((json) => {
                if (json.data.payload !== null) {
                    setCountryList(json.data.payload);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const handlePrevious = () => {
        navigate('/personal-info');
    }

    //Form vaalues set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: applicationInfo?.applicationId,
        //primary applicant employment info
        applicantTypeId: applicationInfo?.applicantTypeId,
        employmentStatusId: null,
        jobTitle: "",
        employer: "",
        yearsEmployed: null,
        employerAddress: "",
        employerAddress2: "",
        employerCity: "",
        employerStateId: null,
        employerZipCode: "",
        employerCountryId: null,
        businessTelephone: "",

        //Co-applicant employment info
        coAppjobTitle: "",
        coAppemployer: "",
        coAppyearsEmployed: null,
        coAppemployerAddress: "",
        coAppemployerAddress2: "",
        coAppemployerCity: "",
        coAppemployerStateId: null,
        coAppemployerZipCode: "",
        coAppemployerCountryId: null,
        coAppbusinessTelephone: "",
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({

        employer: employeeStatus === 28 ? Yup.string().required("Employer name is required").nullable() : Yup.string().optional(),
        yearswithEmployer: employeeStatus === 28 ? Yup.string().required("Number of years required").nullable()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(1, 'Must be exactly 1 digit minimum')
            .max(2, 'Must be exactly 2 digits maximum')
            : Yup.number().nullable().notRequired(),
        jobTitle: employeeStatus === 28 ? Yup.string().required("Position is required") : Yup.string().optional(),
        employerAddress: employeeStatus === 28 ? Yup.string().required("Employer address is required").nullable() : Yup.string().optional(),
        employerCountryId: employeeStatus === 28 && defaultCountry === null ? Yup.string().required("Select country").nullable() : Yup.string().nullable().notRequired(),
        //employerStateId: employeeStatus === 28 ? Yup.string().test('checkPrimEmployeeStateId', 'Select state', () => !stateList.length).nullable() : Yup.string().nullable().notRequired(),
        employerStateId: Yup.string().when('checkPrimEmployeeStateId', {
            is: () => employeeStatus === 28 && stateList.length > 0,
            then: Yup.string().required('Select state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        employerCity: employeeStatus === 28 ? Yup.string().required("Enter employer city name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().optional(),
        employerZipCode: employeeStatus === 28 ? Yup.string().required("Enter employer zip code").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().optional(),

        coAppjobTitle: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant position is required") : Yup.string().optional(),
        coAppemployer: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant employer name is required").nullable() : Yup.string().optional(),
        coAppyearswithEmployer: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant employer number of years required").nullable()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(1, 'Must be exactly 1 digit minimum')
            .max(2, 'Must be exactly 2 digits maximum')
            : Yup.number().nullable().notRequired(),
        coAppemployerAddress: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant employer address is required").nullable() : Yup.string().optional(),
        coAppemployerCity: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant employer city is required").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field").nullable() : Yup.string().optional(),
        //coAppemployerStateId: coAppEmployeeStatus === 28 ? Yup.string().test('checkCoAppEmpState', 'Select state', () => !coAppStateList.length).nullable() : Yup.string().nullable().notRequired(),
        coAppemployerStateId: Yup.string().when('checkCoAppEmpState', {
            is: () => coAppEmployeeStatus === 28 && coAppStateList.length > 0,
            then: Yup.string().required('Select Co-applicant state').nullable(),
            otherwise: Yup.string().nullable()
        }),
        coAppemployerZipCode: coAppEmployeeStatus === 28 ? Yup.string().required("Co-applicant employer zip code required").min(2, "Mininum 2 characters required").nullable()
        .test('is-valid-postal-code', 'Postal code should not contain special characters', value => validatePostalCode(value)) : Yup.string().optional(),
        coAppemployerCountryId: coAppEmployeeStatus === 28 && defaultCountry === null ? Yup.string().required("Select Co-applicant country").nullable() : Yup.string().nullable().notRequired(),
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
        applicationId: applicationInfo?.id,
        applicantTypeId: applicationInfo?.applicantTypeId,
        employmentStatusId: employmentInfoList && employmentInfoList[0]?.employmentStatusId,
        jobTitle: employmentInfoList && employmentInfoList[0]?.jobTitle,
        employer: employmentInfoList && employmentInfoList[0]?.employer,
        yearswithEmployer: employmentInfoList && employmentInfoList[0]?.yearswithEmployer,
        employerAddress: employmentInfoList && employmentInfoList[0]?.employerAddress,
        employerAddress2: employmentInfoList && employmentInfoList[0]?.employerAddress2,
        employerCity: employmentInfoList && employmentInfoList[0]?.employerCity,
        employerStateId: employmentInfoList && employmentInfoList[0]?.employerStateId,
        employerZipCode: employmentInfoList && employmentInfoList[0]?.employerZipCode,
        employerCountryId: employmentInfoList && employmentInfoList[0]?.employerCountryId,
        businessTelephone: employmentInfoList && employmentInfoList[0]?.businessTelephone,

        //Co-Applicant employment info
        coAppjobTitle: employmentInfoList && employmentInfoList[1]?.jobTitle,
        coAppemployer: employmentInfoList && employmentInfoList[1]?.employer,
        coAppyearswithEmployer: employmentInfoList && employmentInfoList[1]?.yearswithEmployer,
        coAppemployerAddress: employmentInfoList && employmentInfoList[1]?.employerAddress,
        coAppemployerAddress2: employmentInfoList && employmentInfoList[1]?.employerAddress2,
        coAppemployerCity: employmentInfoList && employmentInfoList[1]?.employerCity,
        coAppemployerStateId: employmentInfoList && employmentInfoList[1]?.employerStateId,
        coAppemployerZipCode: employmentInfoList && employmentInfoList[1]?.employerZipCode,
        coAppemployerCountryId: employmentInfoList && employmentInfoList[1]?.employerCountryId,
        coAppbusinessTelephone: employmentInfoList && employmentInfoList[1]?.businessTelephone,
    };

    const getEmploymentInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 3
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo != null) {
                        setApplicationInfo(result.applicationInfo);
                        if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }
                        localStorage.setItem("currentAppId", result.applicationInfo.applicationId);
                        if (result.applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                            setIsJointAccount(true);
                        }
                        //Check either it is Traditional IRA account
                        if (result.applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            setIsRetirementAccountType(true);
                        }
                    }

                    if (result.employmentInfoList != null) {
                        setEmploymentInfoList(result.employmentInfoList);
                        result.employmentInfoList[0]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed ? setShowEmployeeForm(true) : setShowEmployeeForm(false);
                        setEmployeeStatus(result.employmentInfoList[0]?.employmentStatusId);
                        getStatesbyCountryId(result.employmentInfoList[0]?.employerCountryId);

                        if (result.employmentInfoList?.length > 1) {
                            result.employmentInfoList[1]?.employmentStatusId === OLAEnums.EmploymentStatusEnum.Employed ? setShowCoAppEmployeeForm(true) : setShowCoAppEmployeeForm(false);
                            setCoAppEmployeeStatus(result.employmentInfoList[1]?.employmentStatusId);
                            showCoAppEmployeeForm(true);
                            getCoAppStatesbyCountryId(result.employmentInfoList[1]?.employerCountryId);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleEmployeementStatus = (statusId) => {
        if (ctrlDisable) return;
        if (statusId === OLAEnums.EmploymentStatusEnum.Employed) {
            setShowEmployeeForm(true);
        }
        else {
            setShowEmployeeForm(false);
        }
        setEmpInfo(true);
        setEmployeeStatus(statusId);
    }

    const handleCoAppEmployeementStatus = (statusId) => {
        if(ctrlDisable) return;
        if (statusId === OLAEnums.EmploymentStatusEnum.Employed) {
            setShowCoAppEmployeeForm(true);
        }
        else {
            setShowCoAppEmployeeForm(false);
        }
        setCoAppEmpInfo(true);
        setCoAppEmployeeStatus(statusId);
    }

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            let isValid = true;
            if (employeeStatus == null) {
                setEmpInfo(false);
                isValid = false;
            }
            if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && coAppEmployeeStatus == null) {
                setCoAppEmpInfo(false);
                isValid = false;
            }

            if (!isValid) {
                return;
            }
            loader.show();
            let applicationId = localStorage.getItem("currentAppId");

            let empStateId = values.employerStateId;
            if (empStateId === null) {
                empStateId = null;
            }

            let applTypeId = isRetirementAccountType ? OLAEnums.ApplicantTypeEnum.IRAApplicant : OLAEnums.ApplicantTypeEnum.PrimaryApplicant;

            employmentInfoRequestList.push({
                "applicationId": applicationId, "applicantTypeId": applTypeId, "employmentStatusId": employeeStatus, "jobTitle": values.jobTitle,
                "employer": values.employer, "yearsEmployed": values.yearswithEmployer, "employerAddress": values.employerAddress, "employerAddress2": values.employerAddress2, "employerCity": values.employerCity,
                "employerStateId": empStateId, "employerZipCode": values.employerZipCode, "employerCountryId": values.employerCountryId === null ? defaultCountry : values.employerCountryId,
                "businessTelephone": values.businessTelephone
            });

            if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {

                let coAppeEmpStateId = values.coAppemployerStateId;
                if (coAppeEmpStateId === null) {
                    coAppeEmpStateId = null;
                }

                employmentInfoRequestList.push({
                    "applicationId": applicationId, "applicantTypeId": OLAEnums.ApplicantTypeEnum.CoApplicant, "employmentStatusId": coAppEmployeeStatus, "jobTitle": values.coAppjobTitle,
                    "employer": values.coAppemployer, "yearsEmployed": values.coAppyearswithEmployer, "employerAddress": values.coAppemployerAddress, "employerAddress2": values.coAppemployerAddress2, "employerCity": values.coAppemployerCity,
                    "employerStateId": coAppeEmpStateId, "employerZipCode": values.coAppemployerZipCode, "employerCountryId": values.coAppemployerCountryId === null ? defaultCountry : values.coAppemployerCountryId,
                    "businessTelephone": values.coAppbusinessTelephone
                });
            }

            let data = {
                "loggedInUserId": getLoggedInUserInfo().id,
                "employmentInfoRequestList": employmentInfoRequestList
            };
            applicationService.saveEmploymentInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getEmploymentInfo();
                        loader.hide();
                        if (!saveOnly) {
                            if (isRetirementAccountType) {
                                navigate("/ira-beneficiaries");
                            }
                            else {
                                navigate("/investor-profile");
                            }
                        }
                        else {
                            toast.success("Employment information has been save successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <div className="tab-content" id="employment">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div className="container-fluid">
                            <div className="formCard">
                                {showEmployeeForm && <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">Employment Information (Choose one) <span className="error-info">*</span></h2>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Employer<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="employer" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employer}
                                                            maxLength="50" placeholder="Enter Employer" />
                                                        {formik.touched.employer && formik.errors.employer ? (
                                                            <p className="error">{formik.errors.employer}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Years Employed<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="number" pattern="^[0-9\b]+$" className="inputForm" id="yearswithEmployer" name="yearswithEmployer" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} min={0} onChange={formik.handleChange} value={formik.values.yearswithEmployer}
                                                            placeholder="Enter Years Employed" />
                                                        {formik.touched.yearswithEmployer && formik.errors.yearswithEmployer ? (
                                                            <p className="error">{formik.errors.yearswithEmployer}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Position<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="jobTitle" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.jobTitle}
                                                            maxLength="50" placeholder="Enter Position" />
                                                        {formik.touched.jobTitle && formik.errors.jobTitle ? (
                                                            <p className="error">{formik.errors.jobTitle}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Employer Address 1<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="employerAddress" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employerAddress}
                                                            maxLength="250" placeholder="Enter Employer Address 1" />
                                                        {formik.touched.employerAddress && formik.errors.employerAddress ? (
                                                            <p className="error">{formik.errors.employerAddress}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Employer Address 2</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="employerAddress2" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employerAddress2}
                                                            maxLength="250" placeholder="Enter Employer Address 2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Country<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="employerCountryId" id="employerCountryId" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} value={formik.values.employerCountryId}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("employerCountryId", e.target.value);
                                                                handleCountryChange(e);
                                                            }}>
                                                            {countryList && countryList.map((option) => (
                                                                <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.employerCountryId && formik.errors.employerCountryId ? (
                                                            <p className="error">{formik.errors.employerCountryId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">State<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <select name="employerStateId" className="form-select" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employerStateId}>
                                                            <option value="">Select State</option>
                                                            {stateList && stateList.map((option) => (
                                                                <option value={option.id}>{option.name}</option>
                                                            ))}
                                                        </select>
                                                        {formik.touched.employerStateId && formik.errors.employerStateId ? (
                                                            <p className="error">{formik.errors.employerStateId}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">City<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="employerCity" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employerCity}
                                                            maxLength="50" placeholder="Enter Employer City" />
                                                        {formik.touched.employerCity && formik.errors.employerCity ? (
                                                            <p className="error">{formik.errors.employerCity}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Zip Code<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" name="employerZipCode" disabled={ctrlDisable}
                                                            onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.employerZipCode}
                                                            maxLength="10" placeholder="Enter Employer Zip Code" />
                                                        {formik.touched.employerZipCode && formik.errors.employerZipCode ? (
                                                            <p className="error">{formik.errors.employerZipCode}</p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <ul className="chooseBtns">
                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Employed)} disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Employed ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                    className="icon-employment"></i></span><span
                                                        className="chooseBtnLable">Employed<span className="error-info">*</span></span></a></li>
                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Retired)} name="employeeStatusButton" disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Retired ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                    className="icon-retired"></i></span><span
                                                        className="chooseBtnLable">Retired<span className="error-info">*</span></span></a></li>
                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Unemployed)} name="employeeStatusButton" disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Unemployed ? focusedClass : "levelBox"} ><span className="chooseBtnIco"><i
                                                    className="icon-unemployed"></i></span><span
                                                        className="chooseBtnLable">Unemployed<span className="error-info">*</span></span></a></li>
                                        </ul>
                                    </div>
                                </div>}
                                {!showEmployeeForm && <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Employment Information (Choose one) <span className="error-info">*</span></h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <ul className="chooseBtns">
                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Employed)} disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Employed ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                    className="icon-employment"></i></span><span
                                                        className="chooseBtnLable">Employed*</span></a></li>

                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Retired)} disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Retired ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                    className="icon-retired"></i></span><span
                                                        className="chooseBtnLable">Retired*</span></a></li>

                                            <li onClick={(e) => handleEmployeementStatus(OLAEnums.EmploymentStatusEnum.Unemployed)} disabled={ctrlDisable}>
                                                <a href="javascript:;" className={employeeStatus === OLAEnums.EmploymentStatusEnum.Unemployed ? focusedClass : "levelBox"} ><span className="chooseBtnIco"><i
                                                    className="icon-unemployed"></i></span><span
                                                        className="chooseBtnLable">Unemployed*</span></a></li>
                                        </ul>

                                    </div>
                                    {empInfoMissing === false && <div className="col-lg-12">
                                        <span style={styleError}>Please choose employement type</span>
                                    </div>}
                                </div>}
                            </div>

                            {isJointAccount &&
                                <div className="formCard">
                                    {showCoAppEmployeeForm && <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">Co-Applicant Employment Information (Choose one) <span className="error-info">*</span></h2>
                                        </div>
                                        <div className="col-lg-9">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Employer<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppemployer" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployer}
                                                                maxLength="50" placeholder="Enter Employer" />
                                                            {formik.touched.coAppemployer && formik.errors.coAppemployer ? (
                                                                <p className="error">{formik.errors.coAppemployer}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Years Employed<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="number" pattern="^[0-9\b]+$" className="inputForm" name="coAppyearswithEmployer" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} min={0} onChange={formik.handleChange} value={formik.values.coAppyearswithEmployer}
                                                                placeholder="Enter Years Employed" />
                                                            {formik.touched.coAppyearswithEmployer && formik.errors.coAppyearswithEmployer ? (
                                                                <p className="error">{formik.errors.coAppyearswithEmployer}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Position<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppjobTitle" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppjobTitle}
                                                                maxLength="50" placeholder="Enter Position" />
                                                            {formik.touched.coAppjobTitle && formik.errors.coAppjobTitle ? (
                                                                <p className="error">{formik.errors.coAppjobTitle}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Employer Address 1<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppemployerAddress" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployerAddress}
                                                                maxLength="250" placeholder="Enter Employer Address 1" />
                                                            {formik.touched.coAppemployerAddress && formik.errors.coAppemployerAddress ? (
                                                                <p className="error">{formik.errors.coAppemployerAddress}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Employer Address 2</label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppemployerAddress2" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployerAddress2}
                                                                maxLength="250" placeholder="Enter Employer Address 2" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Country<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="coAppemployerCountryId" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} value={formik.values.coAppemployerCountryId}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("coAppemployerCountryId", e.target.value);
                                                                    handleCoAppCountryChange(e);
                                                                }}>
                                                                {countryList && countryList.map((option) => (
                                                                    <option value={option.id} defaultValue={defaultCountry}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppemployerCountryId && formik.errors.coAppemployerCountryId ? (
                                                                <p className="error">{formik.errors.coAppemployerCountryId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">State<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <select name="coAppemployerStateId" className="form-select" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployerStateId}>
                                                                <option value="">Select State</option>
                                                                {coAppStateList && coAppStateList.map((option) => (
                                                                    <option value={option.id}>{option.name}</option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.coAppemployerStateId && formik.errors.coAppemployerStateId ? (
                                                                <p className="error">{formik.errors.coAppemployerStateId}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">City<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppemployerCity" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployerCity}
                                                                maxLength="50" placeholder="Enter Employer City" />
                                                            {formik.touched.coAppemployerCity && formik.errors.coAppemployerCity ? (
                                                                <p className="error">{formik.errors.coAppemployerCity}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="input_wrapper">
                                                        <label className="form-label">Zip Code<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="text" className="inputForm" name="coAppemployerZipCode" disabled={ctrlDisable}
                                                                onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.coAppemployerZipCode}
                                                                maxLength="10" placeholder="Enter Employer Zip Code" />
                                                            {formik.touched.coAppemployerZipCode && formik.errors.coAppemployerZipCode ? (
                                                                <p className="error">{formik.errors.coAppemployerZipCode}</p>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <ul className="chooseBtns">
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Employed)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Employed ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                        className="icon-employment"></i></span><span
                                                            className="chooseBtnLable">Employed<span className="error-info">*</span></span></a></li>
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Retired)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Retired ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                        className="icon-retired"></i></span><span
                                                            className="chooseBtnLable">Retired<span className="error-info">*</span></span></a></li>
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Unemployed)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Unemployed ? focusedClass : "levelBox"} ><span className="chooseBtnIco"><i
                                                        className="icon-unemployed"></i></span><span
                                                            className="chooseBtnLable">Unemployed<span className="error-info">*</span></span></a></li>
                                            </ul>
                                        </div>
                                    </div>}
                                    {!showCoAppEmployeeForm && <div className="row">
                                        <div className="col-lg-12">
                                            <h2 className="heading">Co-Applicant Employment Information (Choose one) <span className="error-info">*</span></h2>
                                        </div>
                                        <div className="col-lg-12">
                                            <ul className="chooseBtns">
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Employed)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Employed ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                        className="icon-employment"></i></span><span
                                                            className="chooseBtnLable">Employed*</span></a></li>
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Retired)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Retired ? focusedClass : "levelBox"}><span className="chooseBtnIco"><i
                                                        className="icon-retired"></i></span><span
                                                            className="chooseBtnLable">Retired*</span></a></li>
                                                <li onClick={(e) => handleCoAppEmployeementStatus(OLAEnums.EmploymentStatusEnum.Unemployed)} disabled={ctrlDisable}>
                                                    <a href="javascript:;" className={coAppEmployeeStatus === OLAEnums.EmploymentStatusEnum.Unemployed ? focusedClass : "levelBox"} ><span className="chooseBtnIco"><i
                                                        className="icon-unemployed"></i></span><span
                                                            className="chooseBtnLable">Unemployed*</span></a></li>
                                            </ul>
                                        </div>

                                        {coAppempInfoMissing === false && <div className="col-lg-12">
                                            <span style={styleError}>Please choose employement type</span>
                                        </div>}

                                    </div>}
                                </div>
                            }

                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' onClick={() => setSaveOnly(false)} className='btn-primary' disabled={ctrlDisable}>Save and Continue</button></li>
                        {formik.isValid && <li><button type='submit' onClick={() => setSaveOnly(true)} className='btn-primary' disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );
};

export default Employment;