import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { checkAuthorization, getLoggedInUserInfo, hideSSN, toTitleCase } from '../../common/common';
import { useFormik } from 'formik';
import OLAEnums from '../../helpers/olaenums';
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import { brokerRole, rrRole } from '../../helpers/constants';
import * as Yup from "yup";
import lovService from "../../services/lovservice";
import brokerService from '../../services/brokerservice';
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

const RRApplications = () => {
    const [applications, setApplications] = useState(null);
    const navigate = useNavigate();
    const [closeAppShow, setCloseAppShow] = useState(false);
    const handleCloseAppClose = () => setCloseAppShow(false);
    const [startReviewShow, setStartReviewShow] = useState(false);
    const handleCloseStartReview = () => setStartReviewShow(false);

    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchKey, setSearchKey] = useState(null);
    const [searchDropdown, setSearchDropdown] = useState(null);
    const [appId, setAppId] = useState(null);
    const [applicationStatusList, setApplicationStatusList] = useState(null);
    const [showCommentTextArea, setShowCommentTextArea] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var broker = getLoggedInUserInfo();
        if (broker !== null) {
            getBrokerApplications(1);
        }
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const styleCursor = {
        cursor: 'pointer'
    }

    const styleCaseNone = {
        textTransform: 'none'
    }

    const styleCase = {
        textTransform: 'capitalize'
    }

    const getBrokerApplications = (page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        setCurrentUserId(getLoggedInUserInfo().id);
        let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(),
        OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString(), OLAEnums.ApplicationStatusEnum.ActionRequired_RR.toString()];
        let data = {
            "loggedInUserId": null,
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "firstName": "",
            "lastName": "",
            "accountNumber": "",
            "accountName": "",
            "email": "",
            "ssn": "",
            "applicationId": null,
            "pageNumber": page,
            "pageSize": perPage
        };
        applicationService.getRRApplications(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }

            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePageChange = page => {
        getBrokerApplications(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        var filterValue = searchDropdown !== null ? parseInt(searchDropdown) : null;
        let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(),
        OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString(), OLAEnums.ApplicationStatusEnum.ActionRequired_RR.toString()];
        let data = {
            "loggedInUserId": null,
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "firstName": filterValue === 1 ? searchKey : null,
            "lastName": filterValue === 2 ? searchKey : null,
            "accountNumber": filterValue === 3 ? searchKey : null,
            "accountName": "",
            "email": "",
            "ssn": filterValue === 4 ? searchKey : null,
            "applicationId": null,
            "pageNumber": newPerPage,
            "pageSize": perPage
        };
        // applicationService.getSubordinateApplications(data)
        applicationService.getRRApplications(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };

    let formikValues = {
        searchDropdownFilter: null,
        searchKeyword: null
    };

    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let userId = getLoggedInUserInfo().id;
            let appStatus = values.searchAppStatus;
            if (appStatus === 'Select Status' || appStatus === null) {
                appStatus = null;
            }
            let statusArray = [OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval.toString(), OLAEnums.ApplicationStatusEnum.PendingReview_RR.toString(),
            OLAEnums.ApplicationStatusEnum.UnderReview_RR.toString(), OLAEnums.ApplicationStatusEnum.ActionRequired_RR.toString()];
            let filterValue = parseInt(values.searchDropdownFilter);
            let data = {
                "loggedInUserId": null,
                "applicationStatus": statusArray.toString(),
                "userId": userId,
                "firstName": filterValue === 1 ? values.searchKeyword : null,
                "lastName": filterValue === 2 ? values.searchKeyword : null,
                "accountNumber": filterValue === 3 ? values.searchKeyword : null,
                "accountName": "",
                "email": "",
                "ssn": filterValue === 4 ? values.searchKeyword : null,
                "applicationId": null,
                "pageNumber": 1,
                "pageSize": perPage
            };
            setSearchKey(values.searchKeyword);
            setSearchDropdown(values.searchDropdownFilter);

            //applicationService.getSubordinateApplications(data)
            applicationService.getRRApplications(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload;
                        setApplications(result);
                        setTotalRows(result[0].totalRecords);
                        loader.hide();
                    }
                    else {
                        setApplications(null);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        if (getLoggedInUserInfo().userRole === brokerRole) {
            navigate('/broker/review-application');
        }
        else if (getLoggedInUserInfo().userRole === rrRole) {
            navigate('/registerrep/review-application');
        }
        else {
            navigate('/supervisor/review-application');
        }
    }

    const handleResetFields = (form) => {
        formik.resetForm({ values: { searchDropdownFilter: '', searchKeyword: '' } });
        getBrokerApplications(1);
    }

    //Change app status starts here
    let appformikValues = {
        appStatus: null,
        comment: '',
    };

    const ADD_FORM_VALIDATION = Yup.object().shape({
        appStatus: Yup.string().required("Please select status").nullable()
    });

    let APP_INITIAL_FORM_STATE = appformikValues;
    const appformik = useFormik({
        enableReinitialize: true,
        initialValues: APP_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let userId = getLoggedInUserInfo().id;
            let status = values.appStatus;
            let alertTypeId = null;
            if (parseInt(status) === OLAEnums.ApplicationStatusEnum.NotCompleted) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationNotCompleted;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorApproved;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.RRDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Declined_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.SupervisorDeclined;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Closed) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountClosed;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval) {
                alertTypeId = OLAEnums.AlertTypeEnum.ApplicationPendingApproval;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Canceled) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountCanceled;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.PendingReview_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountPendingReview_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.UnderReview_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountUnderReview_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.ActionRequired_RR) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountActionRequired_RR;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.PendingReview_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountPendingReview_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.UnderReview_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountUnderReview_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.ActionRequired_Sup) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountActionRequired_Sup;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Submitted_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountSubmitted_ClearingFirm;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.InfoRequired_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountInfoRequired_ClearingFirm;
            }
            else if (parseInt(status) === OLAEnums.ApplicationStatusEnum.Approved_ClearingFirm) {
                alertTypeId = OLAEnums.AlertTypeEnum.AccountApproved_ClearingFirm;
            }

            let data = {
                "UserId": userId,
                "ApplicationId": appId,
                "ApplicationStatus": parseInt(selectedStatus),
                "AlertTypeId": alertTypeId,
                "Comment": values.comment,
                "CreatedBy": getLoggedInUserInfo().userRole
            };
            applicationService.updateApplicationStatus(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getBrokerApplications(1);
                        setCloseAppShow(false);
                        loader.hide();
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleUpdateStatusShow = (app) => {
        appformik.resetForm();
        setCloseAppShow(true);
        setShowCommentTextArea(false);
        getApplicationStatuses();
        setAppId(app.applicationId);
    }

    const getApplicationStatuses = () => {
        lovService.getListbyParentId(OLAEnums.ParentLovEnum.ApplicationStatus)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    let list = result.filter(x => x.id === OLAEnums.ApplicationStatusEnum.NotCompleted || x.id === OLAEnums.ApplicationStatusEnum.Approved_RR
                        || x.id === OLAEnums.ApplicationStatusEnum.Declined_RR || x.id === OLAEnums.ApplicationStatusEnum.Canceled
                        //|| x.id === OLAEnums.ApplicationStatusEnum.SubmittedPendingApproval 
                        //|| x.id === OLAEnums.ApplicationStatusEnum.PendingReview_RR || x.id === OLAEnums.ApplicationStatusEnum.UnderReview_RR
                    );
                    setApplicationStatusList(list);
                }
                else {
                    return null;
                }
            })
            .catch((error) => {
            });
    }

    const assignToMe = (app) => {
        loader.show();
        let data = {
            "ApplicationId": app.applicationId,
            "AssignFrom": getLoggedInUserInfo().createdBy,
            "AssignTo": getLoggedInUserInfo().id,
            "ApplicationStatus": OLAEnums.ApplicationStatusEnum.PendingReview_RR
        }
        brokerService.createApplicationAssignmentLog(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result !== null) {
                        toast.success("Application has been assigned successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        getBrokerApplications(1);
                        loader.hide();
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleStartReviewModalShow = (app) => {
        setStartReviewShow(true);
        setAppId(app.applicationId);
    }

    const changeStatustoUnderReviewRR = () => {
        let data = {
            "UserId": getLoggedInUserInfo().id,
            "ApplicationId": appId,
            "ApplicationStatus": OLAEnums.ApplicationStatusEnum.UnderReview_RR,
            "AlertTypeId": OLAEnums.AlertTypeEnum.AccountUnderReview_RR,
            "Comment": '',
            "CreatedBy": "RR"
        };
        applicationService.updateApplicationStatus(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    getBrokerApplications(1);
                    setStartReviewShow(false);
                    loader.hide();
                    redirectToReviewApplication(appId);
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleStatusChange = (val) => {
        if (val === '') {
            setShowCommentTextArea(false);
        }
        else {
            setShowCommentTextArea(true);
        }
        setSelectedStatus(val);
    }

    //Change app status ends here
    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
            width: "200px"
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName + ' (' + row.accountSubTypeName + ')',
            sortable: true,
            width: "300px"
        },
        {
            name: 'Name',
            //selector: row => row.firstName + ' ' + row.lastName,
            selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount && row.entityName !== null ? toTitleCase(row.entityName) : row.firstName !== null ? toTitleCase(row.firstName) + ' ' + toTitleCase(row.lastName) : row.userName,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Social Security Number',
            selector: row => row.socialSecurityNumber && <div className='paddingLeft-15' title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Citizenship',
            selector: row => <div className='paddingLeft-15'>{row.citizenshipCountryName}</div>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Assigned To',
            selector: row => (
                row.assignToUsername === null || row.assignToUsername === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.assignToUsername}</div>
            ),
            sortable: true,
            width: '200px'
        },
        {
            name: 'Application Status',
            // selector: row => <div className="acc-status statusapproved smallFontStatus" style={styleCase}>{row.applicationStatusName}</div>,
            selector: row => <div className="acc-status statusapproved smallFontStatus" style={styleCase}>{row.applicationStatusName}</div>,
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                            {row.assignToUsername === null && <Dropdown.Item onClick={(e) => assignToMe(row)}>Assign to me</Dropdown.Item>}
                            {/* {row.applicationStatus === OLAEnums.ApplicationStatusEnum.PendingReview_RR && <Dropdown.Item onClick={(e) => handleStartReviewModalShow(row)}>Start Review</Dropdown.Item>}
                            {row.applicationStatus === OLAEnums.ApplicationStatusEnum.UnderReview_RR && <Dropdown.Item onClick={(e) => handleUpdateStatusShow(row)}>Action Required</Dropdown.Item>}
                            {row.applicationStatus === OLAEnums.ApplicationStatusEnum.ActionRequired_RR && <Dropdown.Item onClick={(e) => handleUpdateStatusShow(row)}>Action Required</Dropdown.Item>} */}

                            {(currentUserId === row.assignTo && row.applicationStatus === OLAEnums.ApplicationStatusEnum.PendingReview_RR) ? <Dropdown.Item onClick={(e) => handleStartReviewModalShow(row)}>Start Review</Dropdown.Item> : ""}
                            {(currentUserId === row.assignTo && row.applicationStatus === OLAEnums.ApplicationStatusEnum.UnderReview_RR) ? <Dropdown.Item onClick={(e) => handleUpdateStatusShow(row)}>Action Required</Dropdown.Item> : ""}
                            {(currentUserId === row.assignTo && row.applicationStatus === OLAEnums.ApplicationStatusEnum.ActionRequired_RR) ? <Dropdown.Item onClick={(e) => handleUpdateStatusShow(row)}>Action Required</Dropdown.Item> : ""}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            button: true
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/registerrep/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Approval Queue</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-3">
                            <h2 className="heading">Approval Queue</h2>
                        </div>
                        <div className="col-lg-9">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <select className="form-select" aria-label="Default select example" name="searchDropdownFilter"
                                                    onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.searchDropdownFilter}>
                                                    <option value="">Selection Option</option>
                                                    <option value="1">First Name</option>
                                                    <option value="2">Last Name</option>
                                                    <option value="3">Account Number</option>
                                                    <option value="4">Social Security Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword"
                                                    onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.searchKeyword} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="search_btns">
                                            {/* <li><span className="searchBtn">Clear</span></li>
                                        <li><span className="searchBtn">Search</span></li> */}
                                            <li><span className="searchBtn" style={styleCursor} onClick={() => handleResetFields()}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary'>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {applications && <DataTable theme="default" columns={columns} data={applications}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {applications === null && <DataTable columns={columns} noDataComponent="No record found" />}
                </section>

                <Modal
                    show={closeAppShow}
                    onHide={handleCloseAppClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Change Application Status</h3>
                                        <form onSubmit={appformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <div className="input_area">
                                                                    <select name="appStatus" className="form-select"
                                                                        onBlur={appformik.handleBlur} onClick={(e) => handleStatusChange(e.target.value)} onChange={appformik.handleChange} value={appformik.values.appStatus}>
                                                                        <option value="">Select Status</option>
                                                                        {applicationStatusList && applicationStatusList.map((option) => (
                                                                            <option key={option.id} value={option.id}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {appformik.touched.appStatus && appformik.errors.appStatus ? (
                                                                        <p className="error" style={styleLeft}>{appformik.errors.appStatus}</p>
                                                                    ) : null}
                                                                </div>
                                                                {showCommentTextArea && <div className="input_area">
                                                                    <textarea className="inputFormTextArea" name="comment" rows={5}
                                                                        onBlur={appformik.handleBlur} onChange={appformik.handleChange} value={appformik.values.comment}
                                                                        maxLength="500" placeholder="Enter comments here" />
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Change Status</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseAppClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={startReviewShow}
                    onHide={handleCloseStartReview}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3' style={styleCaseNone}>Do you want to start the process of reviewing the application?</h3>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn' onClick={(e) => changeStatustoUnderReviewRR()}>Yes</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleCloseStartReview()}>No</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    );
};

export default RRApplications;