import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminservice';
import { toast } from 'react-toastify';
import { checkAuthorization, toTitleCase } from '../../common/common';
import loader from "super-react-loader";

const LatestUsersComponent = (role) => {

    const [users, setUsers] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getLatestUsers();
    }, []);

    const getLatestUsers = () => {
        loader.show();
        let data = {
            roleName: role.role
        };
        adminService.getLatestUsers(data)
            .then((json) => {
                if (json.data !== null) {
                    var result = json.data;
                    setUsers(result);
                    loader.hide();
                }
                else {
                    setUsers(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }
    return (
        <>
            <div className='row pt-4'>
                <div className='col-lg-12'>
                    <table className="table ">
                        <thead>
                            <tr>
                                <th scope="col">Username</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope='col'>Role</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody data-gr-ext-installed="" light-mode="light">
                            {users !== null && users.map((item, i) => (
                                <tr className='row-line-height'>
                                    <td>{item.userName}</td>
                                    <td>{item.firstName !== null ? item.firstName + " " + item.lastName : item.userName}</td>
                                    <td><a href={"mailto:" + item?.email}>{item?.email}</a></td>
                                    <td>{item.userRole}</td>
                                    <td></td>
                                </tr>
                            ))}

                            {users === null && (
                                <tr>
                                    <td colSpan={10}>No record(s) found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
};

export default LatestUsersComponent;