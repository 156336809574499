import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import OLAEnums from '../helpers/olaenums';
import applicationService from '../services/applicationservice';
import { calculateAage, getCurrentApplicationId, getLoggedInUserInfo, countCharacters, validateString, validateNumber, checkAuthorization } from '../common/common';
import BeneficiariesRows from '../common/beneficiaries';
import moment from 'moment/moment';
import loader from 'super-react-loader';
import { defaultCountry, getDefaultStates } from '../helpers/constants';

const IRABeneficiaries = () => {

    const navigate = useNavigate();
    const styleItalic = {
        fontStyle: 'italic', lineHeight: 1.5
    };

    const [iraBeneficiariesList, setiraBeneficiariesList] = useState([]);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [rowsData, setRowsData] = useState([]);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [buttonText, setButtonText] = useState('Add Beneficiary');

    const [stateRows, setStateRows] = useState(null);
    const [saveOnly, setSaveOnly] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getIRABeneficiaries();
    }, []);

    const getStatesByCountry = (countryId) => {
        applicationService.getStatesByCountryId(countryId)
            .then((json) => {
                if (json.data.payload !== null) {
                    setStateRows(json.data.payload);
                }
            })
            .catch((error) => {
            });
    }
    const getstateList = async (countryId) => await applicationService.getStatesByCountryId(countryId)
    const getIRABeneficiaries = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 11
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    setApplicationInfo(result.applicationInfo);
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }
                    if (result.applicationBeneficiaryList !== null && result.applicationBeneficiaryList.length > 0) {
                        setButtonText('Add Another');
                        let benList = result.applicationBeneficiaryList;
                        setiraBeneficiariesList(benList);
                        const rowsInput = [];
                        setRowsData([])
                        let sumPrimary = 0;
                        let sumContingent = 0;
                        let promisearr = []
                        for (let i = 0; i < benList.length; i++) {
                            getStatesByCountry(benList[i].countryId);
                            promisearr.push(getstateList(benList[i].countryId))

                        }
                        Promise.all(promisearr).then(res => {
                            for (let i = 0; i < benList.length; i++) {
                                setRowsData(prev => [...prev, {
                                    "id": benList[i].id,
                                    "applicationId": benList[i].applicationId,
                                    "firstName": benList[i].firstName,
                                    "lastName": benList[i].lastName,
                                    "dateOfBirth": moment.utc(benList[i].dateOfBirth).format('YYYY-MM-DD'),
                                    "socialSecurityNumber": benList[i].socialSecurityNumber,
                                    "physicalAddress": benList[i].physicalAddress,
                                    "city": benList[i].city,
                                    //"state": benList[i].stateId,
                                    stateList: res[i].data.payload,
                                    "state": benList[i].stateId,
                                    "postalCode": benList[i].postalCode,
                                    "country": benList[i].countryId,
                                    "share": benList[i].share,
                                    "relationship": benList[i].relationship,
                                    "beneficiaryType": benList[i].beneficiaryType
                                }])
                                if (benList[i].beneficiaryType === OLAEnums.BeneficiaryTypeEnum.Primary) {
                                    sumPrimary = sumPrimary + parseInt(benList[i].share);
                                }
                                else {
                                    sumContingent = sumContingent + parseInt(benList[i].share);
                                }
                            }
                        })
                        loader.hide();
                    }
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Entity Officers starts here
    const addTableRows = () => {
        let rowsInput = null
        getDefaultStates().then(res => {
            rowsInput = {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                socialSecurityNumber: '',
                physicalAddress: '',
                city: '',
                state: '',
                stateList: res.data ? res.data.payload : [],
                postalCode: '',
                country: defaultCountry,
                share: '',
                relationship: '',
                beneficiaryType: ''
            }

            setRowsData([...rowsData, rowsInput]);
            rowsData.length === 0 ? setButtonText('Add Beneficiary') : setButtonText('Add Another');
        })
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        let selectedDelRow = rows.filter((_, ind) => ind === index);
        deleteIRABenById(selectedDelRow, index);
        rows.splice(index, 1);
        setRowsData(rows);

        rows.length === 0 ? setButtonText('Add Beneficiary') : setButtonText('Add Another');
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    const handlePrevious = () => {
        navigate('/employment-info');
    }

    const deleteIRABenById = (selRow, index) => {
        loader.show();
        let data = {
            Id: selRow[0].id,
            ApplicationId: selRow[0].applicationId
        }
        applicationService.deleteIRABeneficiaryById(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Form vaalues set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        // investmentObjective: Yup.string()
        //     .required("Please select investment objective").nullable(),
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
    };

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            let applicationId = localStorage.getItem("currentAppId");
            let sumPrimary = 0;
            let sumContingent = 0;
            let insertIRABenList = [];
            if (rowsData.length === 0) {
                navigate("/investor-profile");
            }
            else if (rowsData.length > 10) {
                toast.error("Can't insert more than 10 beneficiaries", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }
            else {
                for (let i = 0; i < rowsData.length; i++) {
                    if ((rowsData[i].firstName === (null || undefined || "")) || (rowsData[i].lastName === (null || undefined || ""))
                        || (rowsData[i].dateOfBirth === (null || undefined || "")) || (rowsData[i].socialSecurityNumber === (null || undefined || ""))
                        || (rowsData[i].physicalAddress === (null || undefined || "")) || (rowsData[i].city === (null || undefined || ""))
                        || (rowsData[i].postalCode === (null || undefined || ""))
                        || (rowsData[i].country === (null || undefined || "")) || (rowsData[i].share === (null || undefined || ""))
                        || (rowsData[i].relationship === (null || undefined || "")) || (rowsData[i].beneficiaryType === (null || undefined || ""))) {
                        toast.error("Please fill all fields for Beneficial Owners before moving forward", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (rowsData[i].socialSecurityNumber.split('-').join("").replace("_", "").length != 9) {
                        toast.error("Please enter valid ssn", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (calculateAage(rowsData[i].dateOfBirth) < 18) {
                        toast.error("Please choose a valid date of birth", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (calculateAage(rowsData[i].dateOfBirth) >= 100) {
                        toast.error("Please enter a valid date of birth", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (countCharacters(rowsData[i].postalCode) < 2) {
                        toast.error("Postal code length at least 2 characters", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (!validateString(rowsData[i].city)) {
                        toast.error("City contains characters only", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (!validateNumber(rowsData[i].postalCode)) {
                        toast.error("Postal code contains numeric digits only", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    if (!validateString(rowsData[i].relationship)) {
                        toast.error("Relationship contains characters only", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                }

                for (let i = 0; i < rowsData.length; i++) {
                    insertIRABenList.push({
                        "loggedInUserId": getLoggedInUserInfo().id,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.IRAApplicant,
                        "firstName": rowsData[i].firstName,
                        "lastName": rowsData[i].lastName,
                        "dateOfBirth": rowsData[i].dateOfBirth,
                        "ssn": rowsData[i].socialSecurityNumber,
                        "address": rowsData[i].physicalAddress,
                        "city": rowsData[i].city,
                        "stateId": rowsData[i].state,
                        "postalCode": rowsData[i].postalCode,
                        "countryId": rowsData[i].country === null || rowsData[i].country === undefined ? defaultCountry : rowsData[i].country,
                        "share": rowsData[i].share,
                        "relationship": rowsData[i].relationship,
                        "beneficiaryType": rowsData[i].beneficiaryType
                    });
                    if (rowsData[i].beneficiaryType == OLAEnums.BeneficiaryTypeEnum.Primary) {
                        sumPrimary = sumPrimary + parseInt(rowsData[i].share);
                    }
                    else {
                        sumContingent = sumContingent + parseInt(rowsData[i].share);
                    }
                }
                let data = {
                    "IRABeneficiariesList": insertIRABenList,
                    "applicationId": applicationId
                };

                let primaryList = insertIRABenList.filter(x => x.beneficiaryType == OLAEnums.BeneficiaryTypeEnum.Primary);
                let contingentList = insertIRABenList.filter(x => x.beneficiaryType == OLAEnums.BeneficiaryTypeEnum.Contingent);

                if (primaryList.length > 0 && sumPrimary < 100) {
                    toast.error("Total share percentage of primary beneficiary type must be equal to 100", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (primaryList.length > 0 && sumPrimary > 100) {
                    toast.error("Total share percentage of primary beneficiary type must be equal to 100", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (contingentList.length > 0 && sumContingent < 100) {
                    toast.error("Total share percentage of contingent beneficiary type must be equal to 100", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (contingentList.length > 0 && sumContingent > 100) {
                    toast.error("Total share percentage of contingent beneficiary type must be equal to 100", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                else {
                    applicationService.saveIRABeneficiaryInfo(data)
                        .then((json) => {
                            if (json.data.payload !== null) {
                                if (!saveOnly) {
                                    getIRABeneficiaries();
                                    loader.hide();
                                    navigate("/investor-profile");
                                }
                                else {
                                    toast.success("IRA beneficiaries info has been saved successfully", {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                    loader.hide();
                                }
                            }
                        })
                        .catch((error) => {
                            checkAuthorization(error);
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            loader.hide();
                        });
                }

            }
            insertIRABenList = [];
            setiraBeneficiariesList([]);
        }
    });

    return (
        <>
            <div className="tab-content" id="investor">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">IRA Beneficiaries (Optional)</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <p className="paragraph1" style={styleItalic}>Maximum of 10 beneficiaries</p>
                                            <p className="paragraph1" style={styleItalic}>Share Percentage must be equal to 100%</p>
                                        </div>
                                    </div>
                                    <span className='spacingArea'></span>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                            </div>
                                            <div className="col-lg-6"><button type="button" className="addAnother" disabled={ctrlDisable} onClick={addTableRows} title="Add Beneficiary">{buttonText}</button></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <BeneficiariesRows rowsData={rowsData} stateRows={stateRows} deleteTableRows={deleteTableRows} handleChange={handleChange} controlDisable={ctrlDisable} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(false)}>Save and Continue</button></li>
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(true)}>Save</button></li>
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );

};

export default IRABeneficiaries;