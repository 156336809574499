import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import OLAEnums from '../helpers/olaenums';
import applicationService from '../services/applicationservice';
import EntityOfficerRows from '../common/entityofficerrows';
import { calculateAage, checkAuthorization, countCharacters, getCurrentApplicationId, getLoggedInUserInfo, validateNumber, validateString } from '../common/common';
import BeneficialOwnerRows from '../common/beneficialownersrows';
import loader from 'super-react-loader';
import { defaultCountry, getDefaultStates } from '../helpers/constants';

const OwnersOfficials = () => {
    const navigate = useNavigate();
    const styleItalic = {
        fontStyle: 'italic',
        lineHeight: 1.5
    };

    const [entityOfficerAndOwnerInfoList, setEntityOfficerAndOwnerInfoList] = useState([]);
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [rowsData, setRowsData] = useState([]);
    const [benRowsData, setBenRowsData] = useState([]);
    const [isExemptLegalCustomer, setIsExemptLegalCustomer] = useState(false);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getAccountFeaturesInfo();
        getEntityOfficersAndOfficials();
    }, []);
    const getstateList = async (countryId) => await applicationService.getStatesByCountryId(countryId)
    const getEntityOfficersAndOfficials = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 10
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    setApplicationInfo(result.applicationInfo);
                    if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                        setCtrlDisable(true);
                    }
                    else {
                        setCtrlDisable(false);
                    }
                    if (result.entityOfficerOwnerInfoList !== null && result.entityOfficerOwnerInfoList.length > 0) {
                        //get entify officers
                        let entityOfficers = result.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Official);
                        const rowsInput = [];
                        setRowsData([])
                        for (let i = 0; i < entityOfficers.length; i++) {
                            getstateList(entityOfficers[i].countryId).then(res => {
                                setRowsData(prev => [...prev, {
                                    "id": entityOfficers[i].id,
                                    "applicationId": entityOfficers[i].applicationId,
                                    "firstName": entityOfficers[i].firstName,
                                    "lastName": entityOfficers[i].lastName,
                                    "dateOfBirth": entityOfficers[i].dateOfBirth,
                                    "ssnppt": entityOfficers[i].ssnppt,
                                    "address": entityOfficers[i].address,
                                    "city": entityOfficers[i].city,
                                    "state": entityOfficers[i].stateId,
                                    stateList: res.data ? res.data.payload : [],
                                    "postalCode": entityOfficers[i].postalCode,
                                    "country": entityOfficers[i].countryId,
                                    "position": entityOfficers[i].position,
                                    "isManager": entityOfficers[i].isManager,
                                    "isSameInfo": entityOfficers[i].isSameInfo
                                }])
                            })

                        }

                        //now get beneficial owners
                        let beneficialOwners = result.entityOfficerOwnerInfoList.filter(x => x.officerOwnerTypeId === OLAEnums.OwnerfficialsTypeEnum.Owner);
                        const benRowsInput = [];
                        setBenRowsData([])
                        for (let j = 0; j < beneficialOwners.length; j++) {
                            getstateList(beneficialOwners[j].countryId).then(res => {
                                setBenRowsData(prev => [...prev, {
                                    "id": beneficialOwners[j].id,
                                    "applicationId": beneficialOwners[j].applicationId,
                                    "firstName": beneficialOwners[j].firstName,
                                    "lastName": beneficialOwners[j].lastName,
                                    "dateOfBirth": beneficialOwners[j].dateOfBirth,
                                    "ssnppt": beneficialOwners[j].ssnppt,
                                    "address": beneficialOwners[j].address,
                                    "city": beneficialOwners[j].city,
                                    "state": beneficialOwners[j].stateId,
                                    stateList: res.data ? res.data.payload : [],
                                    "postalCode": beneficialOwners[j].postalCode,
                                    "country": beneficialOwners[j].countryId,
                                    "position": null,
                                    "isManager": null,
                                    "isSameInfo": null
                                }])
                            })

                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Entity Officers starts here
    const addTableRows = () => {
        if (rowsData.length < 10) {
            let rowsInput = null
            getDefaultStates().then(res => {
                rowsInput = {
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    ssnppt: '',
                    address: '',
                    city: '',
                    state: '',
                    stateList: res.data ? res.data.payload : [],
                    postalCode: '',
                    country: defaultCountry,
                    position: '',
                    isManager: '',
                    isSameInfo: ''
                }
                setRowsData([...rowsData, rowsInput]);
            })

        }
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        let selectedDelRow = rows.filter((_, ind) => ind === index);
        deleteEntityOffAndOwnerById(selectedDelRow, index);
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const handleChangeCheckbox = (index, evnt) => {
        const { name, checked } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = checked;
        setRowsData(rowsInput);
        //if (name === 'isSameInfo' && value === 'false') {
        if (name === 'isSameInfo' && evnt.target.checked) {
            let benRowsInput = {
                firstName: rowsInput[index].firstName,
                lastName: rowsInput[index].lastName,
                dateOfBirth: rowsInput[index].dateOfBirth,
                ssnppt: rowsInput[index].ssnppt,
                address: rowsInput[index].address,
                city: rowsInput[index].city,
                state: rowsInput[index].state,
                postalCode: rowsInput[index].postalCode,
                country: rowsInput[index].country,
                position: null,
                isManager: null,
                isSameInfo: null
            }
            setBenRowsData([...benRowsData, benRowsInput]);
        }
    }
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        //if (name === 'isSameInfo' && value === 'false') {
        if (name === 'isSameInfo' && evnt.target.checked) {
            let benRowsInput = {
                firstName: rowsInput[index].firstName,
                lastName: rowsInput[index].lastName,
                dateOfBirth: rowsInput[index].dateOfBirth,
                ssnppt: rowsInput[index].ssnppt,
                address: rowsInput[index].address,
                city: rowsInput[index].city,
                state: rowsInput[index].state,
                postalCode: rowsInput[index].postalCode,
                country: rowsInput[index].country,
                position: null,
                isManager: null,
                isSameInfo: null
            }
            setBenRowsData([...benRowsData, benRowsInput]);
        }
    }

    //Entity Officers ends here

    //Beneficial owners starts here
    const addBenTableRows = () => {
        if (benRowsData.length < 10) {
            let rowsInput = null
            getDefaultStates().then(res => {
                rowsInput = {
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    ssnppt: '',
                    address: '',
                    city: '',
                    state: '',
                    stateList: res.data ? res.data.payload : [],
                    postalCode: '',
                    country: defaultCountry,
                    position: '',
                    isManager: '',
                    isSameInfo: ''
                }
                setBenRowsData([...benRowsData, rowsInput]);
            })

        }
    }

    const deleteBenTableRows = (index) => {
        const rows = [...benRowsData];
        let selectedDelRow = rows.filter((_, ind) => ind === index);
        deleteEntityOffAndOwnerById(selectedDelRow, index);
        rows.splice(index, 1);
        setBenRowsData(rows);
    }

    const handleBeneficialOwnerChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...benRowsData];
        rowsInput[index][name] = value;
        setBenRowsData(rowsInput);
    }

    //Beneficial owners ends here

    const deleteEntityOffAndOwnerById = (selRow, index) => {
        loader.show();
        let data = {
            Id: selRow[0].id,
            ApplicationId: selRow[0].applicationId
        }
        applicationService.deleteEntityOfficerandOwnerById(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePrevious = () => {
        if (!isExemptLegalCustomer) {
            navigate('/due-diligence-info');
        }
        else {
            navigate('/account-entity-features');
        }
    }

    const getAccountFeaturesInfo = () => {
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 6
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.accountFeaturesInfo !== null) {
                        setIsExemptLegalCustomer(result.accountFeaturesInfo?.exemptLegalCustomer);
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    //Form vaalues set here
    let formikValues = {
        loggedInUserId: getLoggedInUserInfo().id
    };

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
    });

    formikValues = {
        loggedInUserId: getLoggedInUserInfo().id,
    };

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            let applicationId = localStorage.getItem("currentAppId");
            let entityOffAndBenList = [];

            if (rowsData.length > 10) {
                toast.error("Can't insert more than 10 entity officers", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }

            for (let i = 0; i < rowsData.length; i++) {
                if ((rowsData[i].firstName === (null || undefined || "")) || (rowsData[i].lastName === (null || undefined || ""))
                    || (rowsData[i].dateOfBirth === (null || undefined || "")) || (rowsData[i].ssnppt === (null || undefined || ""))
                    || (rowsData[i].address === (null || undefined || "")) || (rowsData[i].city === (null || undefined || ""))
                    || (rowsData[i].postalCode === (null || undefined || ""))
                    || (rowsData[i].country === (null || undefined || "")) || (rowsData[i].position === (null || undefined || ""))) {
                    toast.error("Please fill all fields for Entity Owners before moving forward", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (calculateAage(rowsData[i].dateOfBirth) < 18) {
                    toast.error("Please choose a valid date of birth", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (calculateAage(rowsData[i].dateOfBirth) >= 100) {
                    toast.error("Please enter a valid date of birth", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (countCharacters(rowsData[i].postalCode) < 2) {
                    toast.error("Postal code length at least 2 characters", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (!validateString(rowsData[i].city)) {
                    toast.error("City contains characters only", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (!validateNumber(rowsData[i].postalCode)) {
                    toast.error("Postal code contains numeric digits only", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
            }

            if (benRowsData.length > 10) {
                toast.error("Can't insert more than 10 benecicial owners", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }

            for (let i = 0; i < benRowsData.length; i++) {
                if ((benRowsData[i].firstName === (null || undefined || "")) || (benRowsData[i].lastName === (null || undefined || ""))
                    || (benRowsData[i].dateOfBirth === (null || undefined || "")) || (benRowsData[i].ssnppt === (null || undefined || ""))
                    || (benRowsData[i].address === (null || undefined || "")) || (benRowsData[i].city === (null || undefined || ""))
                    || (benRowsData[i].postalCode === (null || undefined || ""))
                    || (benRowsData[i].country === (null || undefined || ""))
                    //|| (rowsData[i].position === (null || undefined || ""))
                ) {
                    toast.error("Please fill all fields for Beneficial Owners before moving forward", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (calculateAage(benRowsData[i].dateOfBirth) < 18) {
                    toast.error("Please choose a valid date of birth", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (calculateAage(benRowsData[i].dateOfBirth) >= 100) {
                    toast.error("Please enter a valid date of birth", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (countCharacters(benRowsData[i].postalCode) < 2) {
                    toast.error("Postal code length at least 2 characters", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (!validateString(benRowsData[i].city)) {
                    toast.error("City contains characters only", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                if (!validateNumber(benRowsData[i].postalCode)) {
                    toast.error("Postal code contains numeric digits only", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
            }

            for (let i = 0; i < rowsData.length; i++) {
                entityOffAndBenList.push({
                    "loggedInUserId": getLoggedInUserInfo().id,
                    "applicationId": applicationId,
                    "OfficerOwnerTypeId": OLAEnums.OwnerfficialsTypeEnum.Official,
                    "firstName": rowsData[i].firstName,
                    "lastName": rowsData[i].lastName,
                    "dateOfBirth": rowsData[i].dateOfBirth,
                    "SSNPPT": rowsData[i].ssnppt,
                    "address": rowsData[i].address,
                    "city": rowsData[i].city,
                    "state": rowsData[i].state,
                    "postalCode": rowsData[i].postalCode,
                    "country": rowsData[i].country === null || rowsData[i].country === undefined ? defaultCountry : rowsData[i].country,
                    "position": rowsData[i].position,
                    "isSameInfo": rowsData[i].isSameInfo,
                    "isManager": rowsData[i].isManager
                });
            }

            //add beneficial owners
            for (let i = 0; i < benRowsData.length; i++) {
                entityOffAndBenList.push({
                    "loggedInUserId": getLoggedInUserInfo().id,
                    "applicationId": applicationId,
                    "OfficerOwnerTypeId": OLAEnums.OwnerfficialsTypeEnum.Owner,
                    "firstName": benRowsData[i].firstName,
                    "lastName": benRowsData[i].lastName,
                    "dateOfBirth": benRowsData[i].dateOfBirth,
                    "SSNPPT": benRowsData[i].ssnppt,
                    "address": benRowsData[i].address,
                    "city": benRowsData[i].city,
                    "state": benRowsData[i].state,
                    "postalCode": benRowsData[i].postalCode,
                    "country": benRowsData[i].country === null || benRowsData[i].country === undefined ? defaultCountry : benRowsData[i].country,
                    "position": null,
                    "isSameInfo": null,
                    "isManager": null
                });
            }

            if (rowsData.length === 0) {
                toast.error("Please insert at least one Entity Officer", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }
            if (benRowsData.length === 0) {
                toast.error("Please insert at least one Beneficial Owner", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }

            let data = {
                "EntityOfficerAndOwnerInfoList": entityOffAndBenList
            };

            applicationService.saveEntityOfficerandOwnerInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/upload-entity-documents");
                        }
                        else {
                            toast.success("Owners and officials info has been saved successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
            entityOffAndBenList = [];
            setEntityOfficerAndOwnerInfoList([]);
        }
    });

    return (
        <>
            <div className="tab-content" id="investor">
                <form onSubmit={formik.handleSubmit}>
                    <div className="formContainer">
                        <div className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Entity Officers</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <p className="paragraph1" style={styleItalic}><span className="error-info">*</span>Min: 1 Max: 10</p>
                                            <p className="paragraph1" style={styleItalic}><span className="error-info">*</span>An individual listed as a beneficial owner may also be listed in this section</p>
                                            <p className="paragraph1" style={styleItalic}><span className="error-info">*</span>The following information for each individual with significant responsibility for managing the entity including
                                                all entity officers, such as an executive officer or senior manager (e.g. Chief Executive Officer, Chief Financial Officer, Chief Operating
                                                Officer, Managing Member, General Partner, President, Vice President, Treasurer)</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">

                                        <div className="row">
                                            <div className="col-lg-6">
                                            </div>
                                            <div className="col-lg-6"><button type="button" className="create_Btn" onClick={addTableRows} disabled={ctrlDisable}>Add Entity Officer</button></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <EntityOfficerRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChangeCheckbox={handleChangeCheckbox} handleChange={handleChange} controlDisable={ctrlDisable} />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Certification of Beneficial Owners</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <p className="paragraph1" style={styleItalic}><span className="error-info">*</span>Min: 1 Max: 10</p>
                                            <p className="paragraph1" style={styleItalic}><span className="error-info">*</span>The following information for each individual, if any, who directly or indirectly through any contract,
                                                arrangement, understanding, relationship or otherwise, owns 10 percent or more of the equity interests of the legal entity</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">

                                        <div className="row">
                                            <div className="col-lg-6">
                                            </div>
                                            <div className="col-lg-6"><button type="button" className="create_Btn" onClick={addBenTableRows} disabled={ctrlDisable}>Add Beneficial Owner</button></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <BeneficialOwnerRows benRowsData={benRowsData} deleteBenTableRows={deleteBenTableRows} handleChange={handleBeneficialOwnerChange} controlDisable={ctrlDisable} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(false)}>Save and Continue</button></li>
                        <li><button type='submit' className='btn-primary' disabled={ctrlDisable} onClick={() => setSaveOnly(true)}>Save</button></li>
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>
        </>
    );
};

export default OwnersOfficials;