import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { getLoggedInUserInfo, toTitleCase, hideSSN, checkAuthorization } from '../../common/common';
import { useFormik } from 'formik';
import OLAEnums from '../../helpers/olaenums';
import loader from "super-react-loader";
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import brokerService from "../../services/brokerservice";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";

const PendingCancelQueue = () => {

    const [applications, setApplications] = useState(null);
    const navigate = useNavigate();
    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    //Search form
    const [searchKey, setSearchKey] = useState(null);
    const [searchDropdown, setSearchDropdown] = useState(null);

    const styleLeft = {
        textAlign: 'left'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var broker = getLoggedInUserInfo();
        if (broker !== null) {
            getBrokerApplications(1);
        }
    }, []);

    const styleCursor = {
        cursor: "pointer"
    }

    const getBrokerApplications = (page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let statusArray = [OLAEnums.ApplicationStatusEnum.Closed.toString(), OLAEnums.ApplicationStatusEnum.Canceled.toString()];
        let data = {
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": null,
            "searchKeyword": null,
            "pageNumber": page,
            "pageSize": perPage
        };
        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        let statusArray = [OLAEnums.ApplicationStatusEnum.Closed.toString(), OLAEnums.ApplicationStatusEnum.Canceled.toString()];
        let data = {
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": null,
            "searchKeyword": null,
            "pageNumber": page,
            "pageSize": newPerPage
        };
        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handlePageChange = page => {
        getBrokerApplications(page);
    };

    const { register, handleSubmit, resetField } = useForm({});

    const searchSubmit = (val) => {
        loader.show();
        let userId = getLoggedInUserInfo().id;
        var filterValue = parseInt(val.searchDropdownFilter);
        let statusArray = [OLAEnums.ApplicationStatusEnum.Closed.toString(), OLAEnums.ApplicationStatusEnum.Canceled.toString()];
        let data = {
            "applicationStatus": statusArray.toString(),
            "userId": userId,
            "selectionOption": filterValue,
            "searchKeyword": val.searchKeyword,
            "pageNumber": 1,
            "pageSize": perPage
        };
        setSearchKey(val.searchKeyword);
        setSearchDropdown(val.searchDropdownFilter);
        applicationService.getApplicationQueue(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        navigate('/broker/review-application');
    }
    const handleResetFields = (form) => {
        resetField('searchDropdownFilter');
        resetField('searchKeyword');
        getBrokerApplications(1);
    }

    const columns = [
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName,
            sortable: true,
        },
        {
            name: 'Account Sub Type',
            selector: row => row.accountSubTypeName,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.accountType === OLAEnums.AccountTypeEnum.EntityAccount && row.entityName !== null ? row.entityName : row.firstName !== null ? row.firstName + ' ' + row.lastName : row.userName,
            sortable: true,
        },
        {
            name: 'Social Security Number',
            selector: row => row.socialSecurityNumber && <div title={row.socialSecurityNumber}>{hideSSN(row.socialSecurityNumber, 7)}</div>,
            sortable: true,
        },
        {
            name: 'Citizenship',
            selector: row => row.countryName,
            sortable: true,
        },
        {
            name: 'Application Status',
            selector: row => <div className="acc-status statusprocessing smallFontStatus">{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: '270px'
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Application" onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />

                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Closed & Canceled Queue</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-3">
                            <h2 className="heading">Closed / Canceled Queue</h2>
                        </div>
                        <div className="col-lg-7">
                            <form onSubmit={handleSubmit(searchSubmit)}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <select className="form-select" aria-label="Default select example" name="searchDropdownFilter"  {...register('searchDropdownFilter')}
                                                >
                                                    <option value="">Selection Option</option>
                                                    <option value="1">First Name</option>
                                                    <option value="2">Last Name</option>
                                                    <option value="3">Account Number</option>
                                                    <option value="4">Social Security Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword" {...register('searchKeyword')} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <ul className="search_btns">
                                            <li><span className="searchBtn" onClick={() => handleResetFields()} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary'>Search</button></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {applications && <DataTable theme="default" columns={columns} data={applications}
                        pagination paginationServer paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {applications === null && <DataTable columns={columns} noDataComponent="No record found" />}

                </section>
            </section>
        </>
    );
};

export default PendingCancelQueue;