import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from 'moment';
import loader from "super-react-loader";
import Modal from 'react-bootstrap/Modal';
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';

let appSignatureRequestList = [];
const Disclosures = () => {

    const navigate = useNavigate();
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [accountFeaturesInfo, setAccountFeaturesInfo] = useState(null);
    const [disclosureInfo, setDisclosureInfo] = useState(null);
    const [appSignatureList, setAppSignatureList] = useState(null);
    const [currentUTCDate, setCurrentUTCDate] = useState(null);
    const [primarySign, setPrimarySign] = useState(null);
    const [coAppSign, setCoAppSign] = useState(null);

    const [fullyPaidSecLoadAgreementCheck, setFullyPaidSecLoadAgreementCheck] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    const [showSignatureError, setShowSignatureError] = useState(false);
    const [appGuid, setAppGuid] = useState(false);
    const [isJointAccount, setIsJointAccount] = useState(false);

    const [rbFullyPaidAgreement, setrbFullyPaidAgreement] = useState(false);
    const [rbCashMarginAgreement, setrbCashMarginAgreement] = useState(false);
    const [rbAccountAgreement, setrbAccountAgreement] = useState(false);
    const [areaDisable, setAreaDisable] = useState(false);
    const [isRetirementAccount, setIsRetirementAccount] = useState(false);
    const [isParticipateInLending, setIsParticipateLending] = useState(false);

    const [printedNamePrimary, setPrintedNamePrimary] = useState(null);
    const [printedNameCoApp, setPrintedNameCoApp] = useState(null);

    const [showAcctAgreementConfirmPopup, setShowAcctAgreementConfirmPopup] = useState(false);
    const handleAcctAgreeConfirmaPopupClose = () => setShowAcctAgreementConfirmPopup(false);
    const [saveOnly, setSaveOnly] = useState(false);

    //for disclosures read purpose 
    const [isReadAccountAgreementCash, setIsReadAccountAgreementCash] = useState(false);
    const [isReadAccountAgreementCashMargin, setIsReadAccountAgreementCashMargin] = useState(false);
    const [isReadFullPaidSecLanding, setIsReadFullPaidSecLanding] = useState(false);

    //signatures for entity accounts
    const [authSigners, setAuthSigners] = useState(null);
    const [showAuthSign2, setShowAuthSign2] = useState(false);
    const [showAuthSign3, setShowAuthSign3] = useState(false);
    const [showAuthSign4, setShowAuthSign4] = useState(false);

    //for signature
    const styles = {
        border: '0.0625rem solid #9c9c9c', borderRadius: '0.25rem',
    };
    const stylesError = {
        marginTop: 8, marginBottom: 8, color: 'red', fontSize: 12, fontWeight: 'bold'
    };

    const styleSignLabel = {
        marginTop: 10
    }

    //for Personal and IRA accounts
    const canvas = React.createRef();
    const coAppCanvas = React.createRef();
    const [primarySignatureImage, setPrimarySignatureImage] = useState();
    const [coAppSignatureImage, setCoAppSignatureImage] = useState();

    //for Entity account
    const [isEntityAccount, setIsEntityAccount] = useState(null);
    const canvasAuthSign1 = React.createRef();
    const canvasAuthSign2 = React.createRef();
    const canvasAuthSign3 = React.createRef();
    const canvasAuthSign4 = React.createRef();
    const [authSign1, setAuthSign1] = useState(null);
    const [authSign2, setAuthSign2] = useState(null);
    const [authSign3, setAuthSign3] = useState(null);
    const [authSign4, setAuthSign4] = useState(null);
    const [authSignature1Image, setAuthSignature1Image] = useState('');
    const [authSignature2Image, setAuthSignature2Image] = useState('');
    const [authSignature3Image, setAuthSignature3Image] = useState('');
    const [authSignature4Image, setAuthSignature4Image] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getCurrentUTCDate();
        getAccountFeaturesInfo();
        getDisclosuresAndSignaturesInfo();
    }, []);

    const getAccountFeaturesInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 6
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;

                    if (result?.accountFeaturesInfo?.participateSecuritiesLending) {
                        setIsParticipateLending(true);
                    }
                    if (result?.applicationInfo?.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                        setIsRetirementAccount(true);
                    }
                    if (result.accountFeaturesInfo !== null) {
                        setAccountFeaturesInfo(result.accountFeaturesInfo);
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getCurrentUTCDate = () => {
        let date = new Date();
        let utcMoment = moment.utc(date).format("MM/DD/yyyy");
        setCurrentUTCDate(utcMoment);
    }

    const handlePrevious = () => {
        if (isEntityAccount) {
            navigate('/upload-entity-documents');
        }
        else {
            navigate('/upload-documents');
        }
    }

    const getApplicantBasicInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 2
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicantList != null && result.applicantList.length > 0) {
                        formik.setFieldValue("printedName", result.applicantList[0].firstName + " " + result.applicantList[0].lastName);
                        setPrintedNamePrimary(result.applicantList[0].firstName + " " + result.applicantList[0].lastName);
                        //For Co-Applicant
                        if (result.applicantList[1] !== null) {
                            setIsJointAccount(true);
                            formik.setFieldValue("coAppprintedName", result.applicantList[1].firstName + " " + result.applicantList[1].lastName);
                            setPrintedNameCoApp(result.applicantList[1].firstName + " " + result.applicantList[1].lastName);
                        }
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getDisclosuresAndSignaturesInfo = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 8
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result?.applicationInfo !== null) {
                        setApplicationInfo(result?.applicationInfo);
                        setAppGuid(result?.applicationInfo?.applicationGuid);

                        if (result?.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }

                        if (result?.applicationInfo.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                            setIsEntityAccount(true);
                        }
                        else {
                            getApplicantBasicInfo();
                        }
                    }
                    if (result?.applicationDisclousersInfo !== null) {
                        setDisclosureInfo(result?.applicationDisclousersInfo);

                        result.applicationDisclousersInfo?.wdFullyPaidSecLoadAgreementCheck === null ? setrbFullyPaidAgreement(null) :
                            result.applicationDisclousersInfo?.wdFullyPaidSecLoadAgreementCheck === true ? setrbFullyPaidAgreement(true) : setrbFullyPaidAgreement(false);

                        result.applicationDisclousersInfo?.wdAccountAgreementCashCheck === null ? setrbAccountAgreement(null) :
                            result.applicationDisclousersInfo?.wdAccountAgreementCashCheck === true ? setrbAccountAgreement(true) : setrbAccountAgreement(false);

                        result.applicationDisclousersInfo?.wdAccountAgreementCashMarginCheck === null ? setrbCashMarginAgreement(null) :
                            result.applicationDisclousersInfo?.wdAccountAgreementCashMarginCheck === true ? setrbCashMarginAgreement(true) : setrbCashMarginAgreement(false);
                    }

                    if (result?.applicationSignatureList !== null) {
                        setAppSignatureList(result.applicationSignatureList);
                        if (result?.applicationInfo.accountType !== OLAEnums.AccountTypeEnum.EntityAccount) {
                            setPrimarySignatureImage(result?.applicationSignatureList[0]?.signature);
                            if (result?.applicationSignatureList.length > 1) {
                                setCoAppSignatureImage(result?.applicationSignatureList[1]?.signature);
                            }
                        }
                        else { //if entity account then set signatures here
                            if (result?.entitySignerInfoList !== null) {
                                setAuthSigners(result?.entitySignerInfoList);
                                if (result?.entitySignerInfoList.length > 0) {
                                    if (result?.applicationSignatureList !== null && result?.applicationSignatureList.length > 0) {
                                        setAuthSignature1Image(result?.applicationSignatureList[0].signature);
                                    }
                                }
                                if (result?.entitySignerInfoList.length > 1) {
                                    setShowAuthSign2(true);
                                    if (result?.applicationSignatureList !== null && result?.applicationSignatureList.length > 1) {
                                        setAuthSignature2Image(result?.applicationSignatureList[1].signature);
                                    }
                                }
                                if (result?.entitySignerInfoList.length > 2) {
                                    setShowAuthSign3(true);
                                    if (result?.applicationSignatureList !== null && result?.applicationSignatureList.length > 2) {
                                        setAuthSignature3Image(result?.applicationSignatureList[2].signature);
                                    }
                                }
                                if (result?.entitySignerInfoList.length > 3) {
                                    setShowAuthSign4(true);
                                    if (result?.applicationSignatureList !== null && result?.applicationSignatureList.length > 3) {
                                        setAuthSignature4Image(result?.applicationSignatureList[3].signature);
                                    }
                                }
                            }
                        }
                    }
                    else {
                        setShowSignatureError(true);
                    }
                    loader.hide();
                }
                else {
                    setShowSignatureError(true);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    /* For Personal and IRA accounts */
    const getPrimarySignData = () => {
        canvas.current?.getSketchingTime().then(res => {
            if (res > 0) {
                canvas.current.exportImage("png")
                    .then(data => {
                        setPrimarySign(data);
                        setPrimarySignatureImage(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error('Signature is required', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }
    const getCoAppSignData = () => {
        coAppCanvas.current?.getSketchingTime().then(res => {
            if (res > 0) {
                coAppCanvas.current.exportImage("png")
                    .then(data => {
                        setCoAppSign(data);
                        setCoAppSignatureImage(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error(`Co-applicant's Signature is required`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

    }

    /* For Entity accounts */
    const getAuthSignature1Data = () => {
        canvasAuthSign1.current?.getSketchingTime().then(res => {
            if (res > 0) {
                canvasAuthSign1.current.exportImage("png")
                    .then(data => {
                        setAuthSign1(data);
                        setAuthSignature1Image(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error('Signature is required', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }
    const getAuthSignature2Data = () => {
        canvasAuthSign2.current?.getSketchingTime().then(res => {
            if (res > 0) {
                canvasAuthSign2.current.exportImage("png")
                    .then(data => {
                        setAuthSign2(data);
                        setAuthSignature2Image(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error('Signature is required', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }
    const getAuthSignature3Data = () => {
        canvasAuthSign3.current?.getSketchingTime().then(res => {
            if (res > 0) {
                canvasAuthSign3.current.exportImage("png")
                    .then(data => {
                        setAuthSign3(data);
                        setAuthSignature3Image(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error('Signature is required', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }
    const getAuthSignature4Data = () => {
        canvasAuthSign4.current?.getSketchingTime().then(res => {
            if (res > 0) {
                canvasAuthSign4.current.exportImage("png")
                    .then(data => {
                        setAuthSign4(data);
                        setAuthSignature4Image(data);
                    })
                    .catch(e => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    });
            }
            else {
                toast.error('Signature is required', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })
    }

    const FORM_VALIDATION = useMemo(() => Yup.object().shape({
        wdfullyPaidSecLoadAgreementCheck: accountFeaturesInfo?.participateSecuritiesLending ? Yup.boolean().required("Please select an option for fully paid agreement").nullable() : Yup.boolean().nullable(),
        wdaccountAgreementCashCheck: Yup.boolean().oneOf([true], 'You must agree to the Account Agreement').nullable().required('Please select an option for account agreement'),
        wdaccountAgreementCashMarginCheck: !isRetirementAccount ? Yup.boolean().oneOf([true], 'You must agree to the Account Agreement').nullable().required('Please select an option for margin agreement') : Yup.boolean().nullable(),
        //for Personal and IRA accounts
        // printedName: Yup.string().required("Printed name is required").nullable() : Yup.string().nullable().notRequired(),
        // coAppprintedName: applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && printedNameCoApp === null ? Yup.string().required("Enter Co-Applicant name").nullable() : Yup.string().nullable().notRequired(),
        //For Entity Accounts
    }), [accountFeaturesInfo, isRetirementAccount, fullyPaidSecLoadAgreementCheck])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            loggedInUserId: getLoggedInUserInfo().id,
            applicationId: applicationInfo?.id,
            wdfullyPaidSecLoadAgreementCheck: disclosureInfo?.wdFullyPaidSecLoadAgreementCheck,
            wdaccountAgreementCashCheck: disclosureInfo?.wdAccountAgreementCashCheck == null ? null : disclosureInfo?.wdAccountAgreementCashCheck == true ? 'true' : false,
            wdaccountAgreementCashMarginCheck: disclosureInfo?.wdAccountAgreementCashMarginCheck,
            isSignAgreement: null,
            signature: appSignatureList && appSignatureList[0]?.signature,
            signAgreements: null,
            printedName: appSignatureList && appSignatureList[0]?.printedName,
            //For Co-applicant 
            coAppsignature: appSignatureList && appSignatureList[1]?.signature,
            coAppprintedName: appSignatureList && appSignatureList[1]?.printedName,
            printedNameAuthSign1: null,
            printedNameAuthSign2: null,
            printedNameAuthSign3: null,
            printedNameAuthSign4: null,
        },
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();

            //check if documents are read or not
            if (!isReadAccountAgreementCash && !disclosureInfo?.wdAccountAgreementCashCheck) {
                toast.error("Please click on the Account Agreement disclosure link to open and review its contents", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }
            if (!isReadAccountAgreementCashMargin && !disclosureInfo?.wdAccountAgreementCashMarginCheck && !isRetirementAccount) {
                toast.error("Please click on the Account Agreement Cash & Margin disclosure link to open and review its contents", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }
            if (accountFeaturesInfo?.participateSecuritiesLending && !isReadFullPaidSecLanding && !disclosureInfo?.wdFullyPaidSecLoadAgreementCheck) {
                toast.error("Please click on the Fully Paid Security Lending disclosure link to open and review its contents", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }

            //set primary signature here
            let applicationId = applicationInfo?.applicationId;
            let loggedInUserId = getLoggedInUserInfo().id;
            appSignatureRequestList = [];

            if (!isEntityAccount) { //if not entity account
                if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Individual) {
                    if (values.printedName !== printedNamePrimary) {
                        toast.error("Printed name is not matched with the primary applicant name", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                }

                if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                    if (values.coAppprintedName !== printedNameCoApp) {
                        toast.error("Printed name is not matched with the co-applicant name", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                }
                if (appSignatureList[0]?.signature === null) {
                    toast.error("Please draw your signature and click on the 'Get Signature' button", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                else if (showSignatureError) {
                    toast.error("Please draw your signature and click on the 'Get Signature' button", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }
                else if (!primarySignatureImage && !primarySign) {
                    toast.error("Please draw your signature and click on the 'Capture Signature' button", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                }

                appSignatureRequestList.push({
                    "loggedInUserId": loggedInUserId,
                    "applicationId": applicationId,
                    "applicantTypeId": isRetirementAccount ? OLAEnums.ApplicantTypeEnum.IRAApplicant : OLAEnums.ApplicantTypeEnum.PrimaryApplicant,
                    "signature": primarySign,
                    "signAgreements": null,
                    "isSignAgreement": null,
                    "printedName": values.printedName,
                    "timeStamp": "",
                    "ipAddress": "",
                    "applicationGuid": appGuid
                });
                if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
                    if (appSignatureList[1]?.signature === null) {
                        toast.error("Please draw your co-applicant signature and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    else if (showSignatureError) {
                        toast.error("Please draw your co-applicant signature and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    else if (!coAppSignatureImage && !coAppSign) {
                        toast.error("Please draw your co-applicant signature and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    appSignatureRequestList.push({
                        "loggedInUserId": loggedInUserId,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.CoApplicant, //null in case of entity account
                        "signature": coAppSign,
                        "printedName": values.coAppprintedName,
                        "signAgreements": null,
                        "isSignAgreement": null,
                        "timeStamp": "",
                        "ipAddress": "",
                        "applicationGuid": appGuid
                    });
                }
            }
            else {
                //Here check the list of auth signers and get the signatures one by one, and push all the data one by one in the appSignatureRequestList array
                if (authSigners.length > 0) {
                    if (appSignatureList[0]?.signature === null) {
                        toast.error("Please draw signature for Auth Signer 1 and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    appSignatureRequestList.push({
                        "loggedInUserId": loggedInUserId,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.EntityApplicant,
                        "signature": authSign1,
                        "printedName": authSigners[0].firstName + ' ' + authSigners[0].lastName,
                        "signAgreements": null,
                        "isSignAgreement": null,
                        "timeStamp": "",
                        "ipAddress": "",
                        "applicationGuid": appGuid,
                        "entitySignerId": authSigners[0].id
                    });
                }
                if (authSigners.length > 1) {
                    if (appSignatureList[1]?.signature === null) {
                        toast.error("Please draw signature for Auth Signer 2 and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    appSignatureRequestList.push({
                        "loggedInUserId": loggedInUserId,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.EntityApplicant,
                        "signature": authSign2,
                        "printedName": authSigners[1].firstName + ' ' + authSigners[1].lastName,
                        "signAgreements": null,
                        "isSignAgreement": null,
                        "timeStamp": "",
                        "ipAddress": "",
                        "applicationGuid": appGuid,
                        "entitySignerId": authSigners[1].id
                    });
                }
                if (authSigners.length > 2) {
                    if (appSignatureList[2]?.signature === null) {
                        toast.error("Please draw signature for Auth Signer 3 and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    appSignatureRequestList.push({
                        "loggedInUserId": loggedInUserId,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.EntityApplicant,
                        "signature": authSign3,
                        "printedName": authSigners[2].firstName + ' ' + authSigners[2].lastName,
                        "signAgreements": null,
                        "isSignAgreement": null,
                        "timeStamp": "",
                        "ipAddress": "",
                        "applicationGuid": appGuid,
                        "entitySignerId": authSigners[2].id
                    });
                }
                if (authSigners.length > 3) {
                    if (appSignatureList[3]?.signature === null) {
                        toast.error("Please draw signature for Auth Signer 4 and click on the 'Get Signature' button", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    appSignatureRequestList.push({
                        "loggedInUserId": loggedInUserId,
                        "applicationId": applicationId,
                        "applicantTypeId": OLAEnums.ApplicantTypeEnum.EntityApplicant,
                        "signature": authSign4,
                        "printedName": authSigners[3].firstName + ' ' + authSigners[3].lastName,
                        "signAgreements": null,
                        "isSignAgreement": null,
                        "timeStamp": "",
                        "ipAddress": "",
                        "applicationGuid": appGuid,
                        "entitySignerId": authSigners[3].id
                    });
                }
            }

            if (areaDisable || !rbAccountAgreement) {
                toast.error("You must agree to the Account Agreement", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
                return;
            }

            let data = {
                "loggedInUserId": loggedInUserId,
                "applicationId": applicationId,
                "wdFullyPaidSecLoadAgreementCheck": values.wdfullyPaidSecLoadAgreementCheck,
                "wdAccountAgreementCashCheck": values.wdaccountAgreementCashCheck,
                "wdAccountAgreementCashMarginCheck": values.wdaccountAgreementCashMarginCheck,
                "wdCharacteristicRiskStandardOptCheck": null,
                "wdMarginDisclosureCheck": null,
                "wdMasterSecLendingAgreementCheck": null,
                "wdCustMarginShortAcctAgreementCheck": null,
                "wdTruthInLandingCheck": null,
                "wdOptionAgreementCheck": null,
                "ipAddress": "",
                applicationSignatureRequestList: appSignatureRequestList
            };
            applicationService.saveDisclosureandSignatureInfo(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        getDisclosuresAndSignaturesInfo();
                        loader.hide();
                    }
                    if (!saveOnly) {
                        navigate("/review");
                    }
                    else {
                        toast.success("Disclosure and signature info has been updated successfully", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    //For Personal and IRA accounts
    const clearPrimarySignature = () => {
        canvas.current.clearCanvas();
        setPrimarySignatureImage(null);
    }
    const clearCoAppSignature = () => {
        coAppCanvas.current.clearCanvas();
        setCoAppSignatureImage(null);
    }

    //For Entity accounts
    const clearAuthSignature1 = () => {
        canvasAuthSign1.current.clearCanvas();
        setAuthSignature1Image(null);
    }
    const clearAuthSignature2 = () => {
        canvasAuthSign2.current.clearCanvas();
        setAuthSignature2Image(null);
    }
    const clearAuthSignature3 = () => {
        canvasAuthSign3.current.clearCanvas();
        setAuthSignature3Image(null);
    }
    const clearAuthSignature4 = () => {
        canvasAuthSign4.current.clearCanvas();
        setAuthSignature4Image(null);
    }

    const setAccountAgreementValue = (radioVal) => {
        if (radioVal === "true") {
            setrbAccountAgreement(true);
            setShowAcctAgreementConfirmPopup(false);
            setAreaDisable(false);
        }
        else {
            setrbAccountAgreement(false);
            setShowAcctAgreementConfirmPopup(true);
            setAreaDisable(true);
        }
    }
    const setCashMarginAgreementValue = (radioVal) => {
        radioVal === "true" ? setrbCashMarginAgreement(true) : setrbCashMarginAgreement(false);
    }
    const setFullyPaidAgreementValue = (radioVal) => {
        radioVal === "true" ? setrbFullyPaidAgreement(true) : setrbFullyPaidAgreement(false);
    }
    const handlerReadAccountAgreement = () => {
        setIsReadAccountAgreementCash(true);
        window.open("../../assets/disclosures/account_agreement.pdf", '_blank')
    }
    const handlerReadAccountAgreementCashMargin = () => {
        setIsReadAccountAgreementCashMargin(true);
        window.open("../../assets/disclosures/account_agreement_cash_and_margin_domestic.pdf", '_blank')

    }
    const handlerReadFullPaidSecLanding = () => {
        setIsReadFullPaidSecLanding(true);
        window.open("../../assets/disclosures/fully_paid_securities_loan_agreement.pdf", '_blank')
    }

    useFieldErrorStyle(formik.errors, formik.touched)
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="tab-content" id="disclosures">
                    <div className="formContainer">
                        <div id="disclosures-signatures" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormErrorToottip errorList={formik.errors} touchlist={formik.touched}></FormErrorToottip>
                                        <h2 className="heading">Disclosure &amp; Signatures</h2>
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label className="form-label">Review the documents and enter your signature at the end</label>
                                    </div>

                                    <div className="col-xxl-8 col-7">
                                        <a href='javascript:;' onClick={handlerReadAccountAgreement}>Account Agreement</a> <span className="error-info">*</span>
                                        {formik.touched.wdaccountAgreementCashCheck && formik.errors.wdaccountAgreementCashCheck ? (
                                            <p className="error" style={stylesError}>{formik.errors.wdaccountAgreementCashCheck}</p>
                                        ) : null}
                                    </div>
                                    <div className="col-xxl-4 col-5">
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name={"wdaccountAgreementCashCheck"}
                                                onClick={(e) => setAccountAgreementValue(e.target.value)} disabled={ctrlDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                checked={formik.values.wdaccountAgreementCashCheck == null ? null : !(formik.values.wdaccountAgreementCashCheck == 'true' ? true : false)}
                                                value={false}
                                            />No
                                            <label className="form-check-label" for="radio1"></label>
                                        </div>
                                        <div className="form-check float-end">
                                            <input type="radio" className="form-check-input" name={"wdaccountAgreementCashCheck"}
                                                onClick={(e) => setAccountAgreementValue(e.target.value)} disabled={ctrlDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                checked={formik.values.wdaccountAgreementCashCheck == null ? null : formik.values.wdaccountAgreementCashCheck == 'true' ? true : false}
                                                value={true}
                                            />Yes
                                            <label className="form-check-label" for="radio1">&nbsp;</label>
                                        </div>
                                        <label className="form-check-label">I have read the document</label>
                                    </div>

                                    {!isRetirementAccount && <><div className="col-xxl-8 col-7">
                                        <a href='javascript:;' onClick={handlerReadAccountAgreementCashMargin}>Account Agreement Cash & Margin Domestic</a> <span className="error-info">*</span>
                                        {formik.touched.wdaccountAgreementCashMarginCheck && formik.errors.wdaccountAgreementCashMarginCheck ? (
                                            <p className="error" style={stylesError}>{formik.errors.wdaccountAgreementCashMarginCheck}</p>
                                        ) : null}
                                    </div>
                                        <div className="col-xxl-4 col-5">
                                            <div className="form-check float-end">
                                                <input type="radio" className="form-check-input" name="wdaccountAgreementCashMarginCheck"
                                                    onClick={(e) => setCashMarginAgreementValue(e.target.value)} disabled={ctrlDisable || areaDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                    checked={rbCashMarginAgreement === null ? false : rbCashMarginAgreement === false ? true : false}
                                                    value={false}
                                                />No
                                                <label className="form-check-label" for="radio1"></label>
                                            </div>
                                            <div className="form-check float-end">
                                                <input type="radio" className="form-check-input" name="wdaccountAgreementCashMarginCheck"
                                                    onClick={(e) => setCashMarginAgreementValue(e.target.value)} disabled={ctrlDisable || areaDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                    checked={rbCashMarginAgreement === null ? false : rbCashMarginAgreement === true ? true : false}
                                                    value={true}
                                                />Yes
                                                <label className="form-check-label" for="radio1">&nbsp;</label>
                                            </div>
                                            {areaDisable && <label className="form-check-label" style={{ opacity: '0.4' }}>I have read the document</label>}
                                            {!areaDisable && <label className="form-check-label">I have read the document</label>}
                                        </div></>}

                                    {accountFeaturesInfo?.participateSecuritiesLending && <>
                                        <div className="col-xxl-8 col-7">
                                            <a href='javascript:;' onClick={handlerReadFullPaidSecLanding}>Fully Paid Securities Loan Agreement</a> <span className="error-info">*</span>
                                            {formik.touched.wdfullyPaidSecLoadAgreementCheck && formik.errors.wdfullyPaidSecLoadAgreementCheck ? (
                                                <p className="error" style={stylesError}>{formik.errors.wdfullyPaidSecLoadAgreementCheck}</p>
                                            ) : null}
                                        </div>
                                        <div className="col-xxl-4 col-5">
                                            <div className="form-check float-end">
                                                <input type="radio" className="form-check-input" name="wdfullyPaidSecLoadAgreementCheck"
                                                    onClick={(e) => setFullyPaidAgreementValue(e.target.value)} disabled={ctrlDisable || areaDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                    checked={rbFullyPaidAgreement === null ? false : rbFullyPaidAgreement === false ? true : false}
                                                    value={false}
                                                />No
                                                <label className="form-check-label" for="radio1"></label>
                                            </div>
                                            <div className="form-check float-end">
                                                <input type="radio" className="form-check-input" name="wdfullyPaidSecLoadAgreementCheck"
                                                    onClick={(e) => setFullyPaidAgreementValue(e.target.value)} disabled={ctrlDisable || areaDisable} onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                    checked={rbFullyPaidAgreement === null ? false : rbFullyPaidAgreement === true ? true : false}
                                                    value={true}
                                                />Yes
                                                <label className="form-check-label" for="radio1">&nbsp;</label>
                                            </div>
                                            {areaDisable && <label className="form-check-label" style={{ opacity: '0.4' }}>I have read the document</label>}
                                            {!areaDisable && <label className="form-check-label">I have read the document</label>}
                                        </div>
                                    </>}
                                </div>
                            </div>
                            {/* Signature area for Personal and IRA accounts */}
                            {!isEntityAccount && <div className="row">
                                <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Your Signature</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={canvas}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="printedName" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" readOnly value={formik.values.printedName}
                                                            maxLength={50} />
                                                        {/* {formik.touched.printedName && formik.errors.printedName ? (
                                                            <p className="error" style={stylesError}>{formik.errors.printedName}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="signature" values={formik.values.signature}
                                                            onClick={(e) => clearPrimarySignature()} disabled={ctrlDisable}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' disabled={ctrlDisable} onClick={(e) => getPrimarySignData()}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={primarySignatureImage} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Co-applicant's Signature</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={coAppCanvas}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="coAppprintedName" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" maxLength={50} readOnly value={formik.values.coAppprintedName}
                                                        />
                                                        {/* {formik.touched.coAppprintedName && formik.errors.coAppprintedName ? (
                                                            <p className="error" style={stylesError}>{formik.errors.coAppprintedName}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="coAppsignature" values={formik.values.coAppsignature} disabled={ctrlDisable}
                                                            onClick={(e) => clearCoAppSignature()}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' onClick={(e) => getCoAppSignData()} disabled={ctrlDisable}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={coAppSignatureImage} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}

                            {/* Signature area for entity / corporate accounts */}
                            {isEntityAccount && <div className="row">
                                {authSigners !== null && <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Signature for {authSigners[0].firstName + ' ' + authSigners[0].lastName}</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={canvasAuthSign1}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="printedNameAuthSign1" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" readOnly value={authSigners[0].firstName + ' ' + authSigners[0].lastName}
                                                            maxLength={50} />
                                                        {/* {formik.touched.printedNameAuthSign1 && formik.errors.printedNameAuthSign1 ? (
                                                            <p className="error" style={stylesError}>{formik.errors.printedNameAuthSign1}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="authSignature1" values={formik.values.authSignature1}
                                                            onClick={(e) => clearAuthSignature1()} disabled={ctrlDisable}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' disabled={ctrlDisable} onClick={(e) => getAuthSignature1Data()}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={authSignature1Image} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {showAuthSign2 && <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Signature for {authSigners[1].firstName + ' ' + authSigners[1].lastName}</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={canvasAuthSign2}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="printedNameAuthSign2" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" maxLength={50} readOnly value={authSigners[1].firstName + ' ' + authSigners[1].lastName} />
                                                        {/* {formik.touched.printedNameAuthSign2 && formik.errors.printedNameAuthSign2 ? (
                                                            <p className="error" style={stylesError}>{formik.errors.printedNameAuthSign2}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="authSignature2" values={formik.values.authSignature2} disabled={ctrlDisable}
                                                            onClick={(e) => clearAuthSignature2()}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' onClick={(e) => getAuthSignature2Data()} disabled={ctrlDisable}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={authSignature2Image} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {showAuthSign3 && <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Signature for {authSigners[2].firstName + ' ' + authSigners[2].lastName}</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={canvasAuthSign3}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="printedNameAuthSign3" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" maxLength={50} readOnly value={authSigners[2].firstName + ' ' + authSigners[2].lastName}
                                                        />
                                                        {/* {formik.touched.printedNameAuthSign3 && formik.errors.printedNameAuthSign3 ? (
                                                            <p className="error" style={stylesError}>{formik.errors.printedNameAuthSign3}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="authSignature3" values={formik.values.authSignature3} disabled={ctrlDisable}
                                                            onClick={(e) => clearAuthSignature3()}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' onClick={(e) => getAuthSignature3Data()} disabled={ctrlDisable}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={authSignature3Image} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {showAuthSign4 && <div className="col-lg-6">
                                    <div className="formCard">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h2 className="heading">Enter Signature for {authSigners[3].firstName + ' ' + authSigners[3].lastName}</h2>
                                                <label className="form-label">I acknowledge that I have read and understand these Agreements, terms and conditions</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label">Digital Signature<span className="error-info">*</span></label>
                                                    <div className="input_area">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <ReactSketchCanvas
                                                        style={styles}
                                                        width="600"
                                                        height="400"
                                                        strokeWidth={2}
                                                        strokeColor="blue"
                                                        ref={canvasAuthSign4}
                                                        withTimestamp={true} disabled={ctrlDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Printed Name<span className="error-info">*</span></label>
                                                    <div className="input_area">
                                                        <input type="text" name="printedNameAuthSign4" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled={ctrlDisable}
                                                            className="inputForm" maxLength={50} readOnly value={authSigners[3].firstName + ' ' + authSigners[3].lastName}
                                                        />
                                                        {/* {formik.touched.printedNameAuthSign4 && formik.errors.printedNameAuthSign4 ? (
                                                            <p className="error" style={stylesError}>{formik.errors.printedNameAuthSign4}</p>
                                                        ) : null} */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="input_wrapper">
                                                    <label className="form-label" style={styleSignLabel}>Date</label>
                                                    <div className="input_area">
                                                        <input type="text" className="inputForm" value={currentUTCDate} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <label className="subheading"></label>
                                                    <ul className="btnWrapper">
                                                        <li><button type='button' className='btn-primary' name="authSignature4" values={formik.values.authSignature4} disabled={ctrlDisable}
                                                            onClick={(e) => clearAuthSignature4()}>Clear Signature</button></li>
                                                        <li><button type='button' className='btn-primary' onClick={(e) => getAuthSignature4Data()} disabled={ctrlDisable}>Capture Signature</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ "height": 172 }}>
                                            <div className="col-lg-12">
                                                <div className="input_wrapper">
                                                    <div className="input_area">
                                                        <img className='sign-img' src={authSignature4Image} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' onClick={() => setSaveOnly(false)} className='btn-primary' disabled={ctrlDisable}>Review</button></li>
                        {formik.isValid && <li><button className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </div>
            </form>

            <Modal
                show={showAcctAgreementConfirmPopup}
                onHide={handleAcctAgreeConfirmaPopupClose} backdrop="static" keyboard={false} centered size='md' aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='imgArea'>
                                    <img src='../assets/images/info_img.png' />
                                </div>
                                <div className='msgs_Area'>
                                    <h3>Account Agreement</h3>
                                    <p>You must agree to the Account Agreement</p>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-10'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleAcctAgreeConfirmaPopupClose()}>Close</button></div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Disclosures;