import React, { useEffect } from 'react';
import moment from 'moment';

const GiactResponseComponent = (giactResponse, giactLog) => {

    useEffect(() => {
  
    }, []);

    // style={{ wordBreak: 'break-all' }}

    return (
        <>
            <tr>
                <td className='border' colSpan={2}><i className="accordionHeading txt-left">Response Object:</i></td>
            </tr>
            <tr>
                <td className='border'>ItemReferenceID</td>
                <td className='border'>{giactResponse?.value?.ItemReferenceID}</td>
            </tr>
            <tr>
                <td className='border'>CreatedDate</td>
                {/* <td className='border'>{moment.utc(giactResponse?.value?.CreatedDate).format("DD MMMM, YYYY h:mm:ss a")}</td> */}
                <td className='border'>{giactResponse?.value?.CreatedDate}</td>
            </tr>
            <tr>
                <td className='border'>ErrorMessages</td>
                <td className='border'>{giactResponse?.value?.ErrorMessages}</td>
            </tr>
            <tr>
                <td className='border'>UniqueID</td>
                <td className='border'>{giactResponse?.value?.UniqueID}</td>
            </tr>
            <tr>
                <td className='border'>VerificationResult</td>
                <td className='border'>{giactResponse?.value?.VerificationResult}</td>
            </tr>
            <tr>
                <td className='border'>VerificationResultName</td>
                <td className='border'>{giactResponse?.value1[0]?.verificationName}</td>
            </tr>
            <tr>
                <td className='border'>VerificationResultDescription</td>
                <td className='border'>{giactResponse?.value1[0]?.verificationText}</td>
            </tr>
            <tr>
                <td className='border'>AlertMessages</td>
                <td className='border'>{giactResponse?.value?.AlertMessages}</td>
            </tr>

            {giactResponse?.value?.AccountVerificationResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">AccountVerificationResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.ResponseCode}</td>
                        </tr>
                        <tr>
                            <td className='border'>AccountResponseName</td>
                            <td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseName}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>AccountResponseCodeMapping</td>
                        </tr>
                        <tr>
                            <td className='border w-100 summarytbl' colSpan={2}>
                                <table>
                                    <tr>
                                        <td className='border'>Details</td><td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseCodeInfo?.details}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Description</td><td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseCodeInfo?.description}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Possible Reason /<br />Use Case</td><td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseCodeInfo?.possibleReason}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Best Practice <br />for Client</td><td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseCodeInfo?.bestPracticeClient}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ACH Return <br />Codes Prevented</td><td className='border tblNormal'>{giactResponse?.value1[0]?.accountResponseCodeInfo?.achReturnCodes}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='border'>BankName</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.BankName}</td>
                        </tr>
                        <tr>
                            <td className='border'>AccountAddedDate</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.AccountAddedDate}</td>
                        </tr>
                        <tr>
                            <td className='border'>AccountLastUpdatedDate</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.AccountLastUpdatedDate}</td>
                        </tr>
                        <tr>
                            <td className='border'>AccountClosedDate</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.AccountClosedDate}</td>
                        </tr>
                        <tr>
                            <td className='border'>BankAccountType</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.BankAccountType}</td>
                        </tr>
                        <tr>
                            <td className='border'>FundsConfirmationResult</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountVerificationResult?.FundsConfirmationResult}</td>
                        </tr>
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.AccountAuthenticationResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">AccountAuthenticationResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountAuthenticationResult?.ResponseCode}</td>
                        </tr>
                        <tr>
                            <td className='border'>CustomerResponseName</td>
                            <td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseName}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>CustomerResponseCodeMapping</td>
                        </tr>
                        <tr>
                            <td className='border tblNormal' colSpan={2}>
                                <table className=''>
                                    <tr>
                                        <td className='border'>Details</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.details}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Description</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.description}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Possible Reason /<br />Use Case</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.possibleReason}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Best Practice <br />for Client</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.bestPracticeClient}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ACH Return <br />Codes Prevented</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.achReturnCodes}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='border'>AccountOwnerSigner</td>
                            <td className='border tblNormal'>{giactResponse?.value?.AccountAuthenticationResult?.AccountOwnerSigner}</td>
                        </tr>
                        <tr>
                            <td className='border'>VoidedCheckImage</td>
                            <td className='border tblNormal'><img src={giactResponse?.value?.AccountAuthenticationResult?.VoidedCheckImage} /></td>
                        </tr>
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.PersonIdentificationResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">PersonIdentificationResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border tblNormal'>{giactResponse?.value?.PersonIdentificationResult?.ResponseCode}</td>
                        </tr>
                        <tr>
                            <td className='border'>CustomerResponseName</td>
                            <td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseName}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>CustomerResponseCodeMapping</td>
                        </tr>
                        <tr>
                            <td className='border tblNormal' colSpan={2}>
                                <table className=''>
                                    <tr>
                                        <td className='border'>Details</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.details}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Description</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.description}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Possible Reason /<br />Use Case</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.possibleReason}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Best Practice <br />for Client</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.bestPracticeClient}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ACH Return <br />Codes Prevented</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.achReturnCodes}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='border'>GlobalMatchMessages</td>
                            <td className='border tblNormal'>{giactResponse?.value?.PersonIdentificationResult?.GlobalMatchMessages}</td>
                        </tr>
                        <tr>
                            <td className='border'>KBAResult</td>
                            <td className='border tblNormal'>{giactResponse?.value?.PersonIdentificationResult?.KBAResult}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>MatchedPersonIdentityRecords</td>
                        </tr>
                        {giactResponse?.value?.PersonIdentificationResult?.MatchedPersonIdentityRecords && <tr>
                            <td colSpan={2}>
                                <table className='w-100 summarytbl'>
                                    {giactResponse?.value?.PersonIdentificationResult?.MatchedPersonIdentityRecords?.map((item, i) =>
                                        <>
                                            <tr><td className='border' colSpan={2}>PersonName</td></tr>
                                            <tr><td className='border'>FirstName</td><td className='border tblNormal'>{item?.PersonName.FirstName}</td></tr>
                                            <tr><td className='border'>MiddleName</td><td className='border tblNormal'>{item?.PersonName.MiddleName}</td></tr>
                                            <tr><td className='border'>LastName</td><td className='border tblNormal'>{item?.PersonName.LastName}</td></tr>

                                            {item?.AlternamePersonNames && <tr>
                                                <td className='border' colSpan={2}>AlternamePersonNames</td>
                                            </tr>}
                                            {item?.AlternamePersonNames && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item?.AlternamePersonNames?.map((altPersNames, i) =>
                                                            <></>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}
                                            <tr><td className='border'>DateOfBirth</td><td className='border tblNormal'>{item?.DateOfBirth}</td></tr>
                                            <tr><td className='border'>IDNumber</td><td className='border tblNormal'>{item?.IDNumber}</td></tr>
                                            <tr><td className='border' colSpan={2}>TaxIDRecord</td></tr>
                                            <tr><td className='border'>IssueState</td><td className='border tblNormal'>{item?.TaxIDRecord?.IssueState}</td></tr>
                                            <tr><td className='border'>IssueStartYear</td><td className='border tblNormal'>{item?.TaxIDRecord?.IssueStartYear}</td></tr>
                                            <tr><td className='border'>IssueEndYear</td><td className='border tblNormal'>{item?.TaxIDRecord?.IssueEndYear}</td></tr>
                                            <tr><td className='border' colSpan={2}>AddressRecords</td></tr>
                                            {item?.AddressRecords && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.AddressRecords?.map((addRec, i) =>
                                                            <>
                                                                <tr><td className='border'>AddressLine1</td><td className='border tblNormal'>{addRec?.AddressLine1}</td></tr>
                                                                <tr><td className='border'>AddressLine2</td><td className='border tblNormal'>{addRec?.AddressLine2}</td></tr>
                                                                <tr><td className='border'>City</td><td className='border tblNormal'>{addRec?.City}</td></tr>
                                                                <tr><td className='border'>State</td><td className='border tblNormal'>{addRec?.State}</td></tr>
                                                                <tr><td className='border'>ZipCode</td><td className='border tblNormal'>{addRec?.ZipCode}</td></tr>
                                                                <tr><td className='border'>Status</td><td className='border tblNormal'>{addRec?.Status}</td></tr>
                                                                <tr><td className='border'>DateReported</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.DateReported}</td></tr>
                                                                <tr><td className='border' colSpan={2}>ParsedAddressLine1</td></tr>
                                                                <tr><td className='border'>StreetNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetNumber}</td></tr>
                                                                <tr><td className='border'>PreDirectional</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.PreDirectional}</td></tr>
                                                                <tr><td className='border'>StreetName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetName}</td></tr>
                                                                <tr><td className='border'>StreetSuffix</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetSuffix}</td></tr>
                                                                <tr><td className='border'>PostDirectional</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.PostDirectional}</td></tr>
                                                                <tr><td className='border'>UnitType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.UnitType}</td></tr>
                                                                <tr><td className='border'>UnitIdentifier</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.UnitIdentifier}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}
                                            <tr><td className='border' colSpan={2}>PhoneRecords</td></tr>
                                            {item?.PhoneRecords && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.PhoneRecords?.map((phoneRec, i) =>
                                                            <>
                                                                <tr><td className='border'>Classification</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Classification}</td></tr>
                                                                <tr><td className='border'>NumberType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.NumberType}</td></tr>
                                                                <tr><td className='border'>AreaCode</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.AreaCode}</td></tr>
                                                                <tr><td className='border'>Exchange</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Exchange}</td></tr>
                                                                <tr><td className='border'>Suffix</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Suffix}</td></tr>
                                                                <tr><td className='border'>PhoneNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.PhoneNumber}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}
                                        </>
                                    )}
                                </table>
                            </td>
                        </tr>}


                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.BusinessIdentificationResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">BusinessIdentificationResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.BusinessIdentificationResult?.ResponseCode}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>CustomerResponseCodeMapping</td>
                        </tr>
                        <tr>
                            <td className='border tblNormal' colSpan={2}>
                                <table className=''>
                                    <tr>
                                        <td className='border'>Details</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.details}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Description</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.description}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Possible Reason /<br />Use Case</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.possibleReason}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Best Practice <br />for Client</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.bestPracticeClient}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ACH Return <br />Codes Prevented</td><td className='border tblNormal'>{giactResponse?.value1[0]?.customerResponseCodeInfo?.achReturnCodes}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='border'>GlobalMatchMessages</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.BusinessIdentificationResult?.GlobalMatchMessages}</td>
                        </tr>
                        <tr>
                            <td className='border'>IRSTINValidationStatus</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.BusinessIdentificationResult?.IRSTINValidationStatus}</td>
                        </tr>
                        <tr>
                            <td className='border' colSpan={2}>MatchedBusinessIdentityRecords</td>
                        </tr>
                        {giactResponse?.value?.BusinessIdentificationResult?.MatchedBusinessIdentityRecords && <tr>
                            <td colSpan={2}>
                                <table className='w-100 summarytbl'>
                                    {giactResponse?.value?.BusinessIdentificationResult?.MatchedBusinessIdentityRecords?.map((item, i) =>
                                        <>
                                            {item?.NameRecords && <tr>
                                                <td className='border' colSpan={2}>NameRecords</td>
                                            </tr>}
                                            {item?.NameRecords && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item?.NameRecords?.map((nameRec, i) =>
                                                            <>
                                                                <tr><td className='border'>BusinessName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{nameRec?.BusinessName}</td></tr>
                                                                <tr><td className='border'>BusinessType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{nameRec?.BusinessType}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}
                                            <tr><td className='border'>MatchingBusinessContactFound</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.MatchingBusinessContactFound}</td></tr>
                                            <tr><td className='border'>FEIN</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.FEIN}</td></tr>
                                            <tr><td className='border'>DunsNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.DunsNumber}</td></tr>
                                            <tr><td className='border'>CorporationType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.CorporationType}</td></tr>
                                            <tr><td className='border'>RegistrationType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.RegistrationType}</td></tr>
                                            <tr><td className='border' colSpan={2}>IncorpRecord</td></tr>
                                            <tr><td className='border'>State</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.IncorpRecord?.State}</td></tr>
                                            <tr><td className='border'>Date</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.IncorpRecord?.Date}</td></tr>
                                            <tr><td className='border'>FilingNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.FilingNumber}</td></tr>

                                            <tr><td className='border' colSpan={2}>Industries</td></tr>
                                            {item?.Industries && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.Industries?.map((indust, i) =>
                                                            <>
                                                                <tr><td colSpan={2} className='border tblNormal' style={{ wordBreak: 'break-all' }}>{indust}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}

                                            <tr><td className='border' colSpan={2}>Domains</td></tr>
                                            {item?.Domains && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.Domains?.map((dom, i) =>
                                                            <>
                                                                <tr><td colSpan={2} className='border tblNormal' style={{ wordBreak: 'break-all' }}>{dom}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}

                                            <tr><td className='border' colSpan={2}>AddressRecords</td></tr>
                                            {item?.AddressRecords && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.AddressRecords?.map((addRec, i) =>
                                                            <>
                                                                <tr><td className='border'>AddressLine1</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.AddressLine1}</td></tr>
                                                                <tr><td className='border'>AddressLine2</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.AddressLine2}</td></tr>
                                                                <tr><td className='border'>City</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.City}</td></tr>
                                                                <tr><td className='border'>State</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.State}</td></tr>
                                                                <tr><td className='border'>ZipCode</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ZipCode}</td></tr>
                                                                <tr><td className='border'>Status</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.Status}</td></tr>
                                                                <tr><td className='border'>DateReported</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.DateReported}</td></tr>
                                                                <tr><td className='border' colSpan={2}>ParsedAddressLine1</td></tr>
                                                                <tr><td className='border'>StreetNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetNumber}</td></tr>
                                                                <tr><td className='border'>PreDirectional</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.PreDirectional}</td></tr>
                                                                <tr><td className='border'>StreetName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetName}</td></tr>
                                                                <tr><td className='border'>StreetSuffix</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.StreetSuffix}</td></tr>
                                                                <tr><td className='border'>PostDirectional</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.PostDirectional}</td></tr>
                                                                <tr><td className='border'>UnitType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.UnitType}</td></tr>
                                                                <tr><td className='border'>UnitIdentifier</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{addRec?.ParsedAddressLine1?.UnitIdentifier}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}

                                            <tr><td className='border' colSpan={2}>PhoneRecords</td></tr>
                                            {item?.PhoneRecords && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.PhoneRecords?.map((phoneRec, i) =>
                                                            <>
                                                                <tr><td className='border'>Classification</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Classification}</td></tr>
                                                                <tr><td className='border'>NumberType</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.NumberType}</td></tr>
                                                                <tr><td className='border'>AreaCode</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.AreaCode}</td></tr>
                                                                <tr><td className='border'>Exchange</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Exchange}</td></tr>
                                                                <tr><td className='border'>Suffix</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.Suffix}</td></tr>
                                                                <tr><td className='border'>PhoneNumber</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{phoneRec?.PhoneNumber}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}

                                            <tr><td className='border' colSpan={2}>BusinessContacts</td></tr>
                                            {item?.BusinessContacts && <tr>
                                                <td colSpan={2} className='border'>
                                                    <table className='w-100 summarytbl'>
                                                        {item.BusinessContacts?.map((busContact, i) =>
                                                            <>
                                                                <tr><td className='border'>FirstName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{busContact?.FirstName}</td></tr>
                                                                <tr><td className='border'>MiddleName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{busContact?.MiddleName}</td></tr>
                                                                <tr><td className='border'>LastName</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{busContact?.LastName}</td></tr>
                                                                <tr><td className='border'>Title</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{busContact?.Title}</td></tr>
                                                            </>
                                                        )}
                                                    </table>
                                                </td>
                                            </tr>}

                                            <tr><td className='border'>BankruptcySubjectRecordCount</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.BankruptcySubjectRecordCount}</td></tr>
                                            <tr><td className='border'>BankruptcyCreditorRecordCount</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.BankruptcyCreditorRecordCount}</td></tr>
                                            <tr><td className='border'>MiscellaneousDetails</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.MiscellaneousDetails}</td></tr>
                                            <tr><td className='border'>BusinessReportKey</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item?.BusinessReportKey}</td></tr>
                                        </>
                                    )}
                                </table>
                            </td>
                        </tr>}
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.WorldSanctionScanResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">WorldSanctionScanResult</i></td>
                        </tr>
                        {/* <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.WorldSanctionScanResult?.ResponseCode}</td>
                        </tr> */}
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.ESIResult && <tr>
                <td colSpan={2}>
                    <table>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">ESIResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>Domain</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.Domain}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainCategory</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainCategory}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainCompany</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainCompany}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainCountry</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainCountry}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainCreatedDate</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainCreatedDate}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainExists</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainExists ? "true" : "false"}</td>
                        </tr>
                        <tr>
                            <td className='border'>DomainRisk</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.DomainRisk}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressAdvice</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressAdvice}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressCreatedDate</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressCreatedDate}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressFound</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressFound}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressOwnerCompany</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressOwnerCompany}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressOwnerName</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressOwnerName}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressOwnerPhoto</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressOwnerPhoto && <img style={{ width: "100px", height: "100px" }} src={`data:image/png;base64,` + giactResponse?.value?.ESIResult?.EmailAddressOwnerPhoto} />}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressOwnerTitle</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressOwnerTitle}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressStatus</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressStatus}</td>
                        </tr>
                        <tr>
                            <td className='border'>EmailAddressStatusId</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.EmailAddressStatusId}</td>
                        </tr>
                        <tr>
                            <td className='border'>FirstSeen</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.FirstSeen}</td>
                        </tr>
                        <tr>
                            <td className='border'>Fraud</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.Fraud}</td>
                        </tr>
                        <tr>
                            <td className='border'>IsNotDeliverable</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.IsNotDeliverable ? "true" : "false"}</td>
                        </tr>
                        <tr>
                            <td className='border'>LastSeen</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.LastSeen}</td>
                        </tr>
                        <tr>
                            <td className='border'>SourceIndustry</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.ESIResult?.SourceIndustry}</td>
                        </tr>
                        <tr>
                            <td className='border'>SocialMediaLinks</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>
                                {giactResponse?.value?.ESIResult?.SocialMediaLinks.map((item, i) => <ul className='socialMedia'><li key={i}><a href={item} target='_blank'>{item}</a></li></ul>)}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.IPAddressResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">IPAddressResult</i></td>
                        </tr>
                        <tr>
                            <td className='border'>AnonymousProxy</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.AnonymousProxy ? "true" : "false"}</td>
                        </tr>
                        <tr>
                            <td className='border'>CorporateProxy</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.CorporateProxy ? "true" : "false"}</td>
                        </tr>
                        <tr>
                            <td className='border'>Domain</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Domain}</td>
                        </tr>
                        <tr>
                            <td className='border'>ISP</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.ISP}</td>
                        </tr>
                        <tr>
                            <td className='border'>NetSpeed</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.NetSpeed}</td>
                        </tr>
                        <tr>
                            <td className='border'>Organization</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Organization}</td>
                        </tr>
                        <tr>
                            <td className='border'>UserType</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.UserType}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table className='w-100 summarytbl'>
                                    <tr>
                                        <td className='border' colSpan={2}><i className="accordionHeading txt-left">Location</i></td>
                                    </tr>
                                    <tr>
                                        <td className='border'>AccuracyRadius</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.AccuracyRadius}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>City</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.City}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>CityMatch</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.CityMatch ? "true" : "false"}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Country</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.Country}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>CountryMatch</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.CountryMatch ? "true" : "false"}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Distance</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.Distance}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Latitude</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.Latitude}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>Longitude</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.Longitude}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>StateMatch</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.StateMatch ? "true" : "false"}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>StateRegion</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.StateRegion}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ZipCodeMatch</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.ZipCodeMatch ? "true" : "false"}</td>
                                    </tr>
                                    <tr>
                                        <td className='border'>ZipPostalCode</td>
                                        <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IPAddressResult?.Location?.ZipPostalCode}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.DomainWhoIsResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">DomainWhoIsResult</i></td>
                        </tr>
                        {/* <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.DomainWhoIsResult?.ResponseCode}</td>
                        </tr> */}
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.MobileResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">MobileResult</i></td>
                        </tr>
                        {/* <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.MobileResult?.ResponseCode}</td>
                        </tr> */}
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.AccountInsightsResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">AccountInsightsResult</i></td>
                        </tr>
                        {/* <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.AccountInsightsResult?.ResponseCode}</td>
                        </tr> */}
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.IdentityScoreResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">IdentityScoreResult</i></td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='border'>FraudInsightMessages</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className='border' style={{ wordBreak: 'break-all' }}>
                                <table className='w-100 summarytbl'>
                                    {giactResponse?.value?.IdentityScoreResult?.
                                        FraudInsightMessages?.map((item, i) =>
                                            <>
                                                <tr><td className='border'>Code:</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item.Code}</td></tr>
                                                <tr><td className='border'>Description:</td><td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{item.Description}</td></tr>
                                            </>
                                        )}
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='border'>MaxPossibleScore</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IdentityScoreResult?.MaxPossibleScore}</td>
                        </tr>
                        <tr>
                            <td className='border'>Score</td>
                            <td className='border tblNormal' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.IdentityScoreResult?.Score}</td>
                        </tr>
                    </table>
                </td>
            </tr>}

            {giactResponse?.value?.PhoneVerificationResult && <tr>
                <td colSpan={2}>
                    <table className='w-100 summarytbl'>
                        <tr>
                            <td className='border' colSpan={2}><i className="accordionHeading txt-left">PhoneVerificationResult</i></td>
                        </tr>
                        {/* <tr>
                            <td className='border'>ResponseCode</td>
                            <td className='border' style={{ wordBreak: 'break-all' }}>{giactResponse?.value?.PhoneVerificationResult?.ResponseCode}</td>
                        </tr> */}
                    </table>
                </td>
            </tr>}
        </>
    );
};

export default GiactResponseComponent;