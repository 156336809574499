import http from "../common/common";
import { authHeader } from "../helpers/auth-headers";
import { handleResponse } from "../helpers/handle-response";

//Broker alert types

const getBrokerAlertTypes = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/broker/brokeralerttypes`, requestOptions)
        .then(handleResponse);
}

const createBrokerAlertType = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/brokeralerttypes", data, requestOptions)
        .then(handleResponse);
}

const updateBrokerAlertType = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/updatebrokeralerttype", data, requestOptions)
        .then(handleResponse);
}

const deleteBrokerAlertType = (data) => {
    const requestOptions = { method: "DELETE", headers: authHeader() };
    return http.put("/broker/brokeralerttypes", data, requestOptions)
        .then(handleResponse);
}

const searchBrokerAlertTypes = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/searchbrokeralerttypes", data, requestOptions)
        .then(handleResponse);
}

//Application assignment logs

const getApplicationAssignmentLogs = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/broker/applicationassignmentlog", requestOptions)
        .then(handleResponse);
}

const createApplicationAssignmentLog = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post(`/broker/applicationassignmentlog`, data, requestOptions)
        .then(handleResponse);
}

const bulkApplicationAssignmentLog = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post(`/broker/bulkapplicationassignment`, data, requestOptions)
        .then(handleResponse);
}

//Application status log

const getApplicationStatusLogs = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/broker/applicationstatuslogs", requestOptions)
        .then(handleResponse);
}

const createApplicationStatusLogs = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post(`/broker/applicationstatuslogs`, data, requestOptions)
        .then(handleResponse);
}

//Broker subordinates

const getBrokerSubordinateById = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/broker/brokersubordinate/${id}`, requestOptions)
        .then(handleResponse);
}

const getBrokerSubordinates = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/getbrokersubordinates", data, requestOptions)
        .then(handleResponse);
}

const searchBrokerSubOrdinates = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/searchbrokersubordinates", data, requestOptions)
        .then(handleResponse);
}

const createBrokerSubordinate = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/addbrokersubordinate", data, requestOptions)
        .then(handleResponse);
}

const updateBrokerSubOrdinate = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/updatebrokersubordinate", data, requestOptions)
        .then(handleResponse);
}

const deleteBrokerSubordinate = (data) => {
    const requestOptions = { method: "DELETE", headers: authHeader() };
    return http.delete("/broker/brokersubordinate", data, requestOptions)
        .then(handleResponse);
}

const updateSubordinateRole = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/updatesubordinaterole", data, requestOptions)
        .then(handleResponse);
}

const getBrokerRRAndSupervisorList = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/getBrokerRRSupervisorsList", data, requestOptions)
        .then(handleResponse);
}

const getAllBrokerRRAndSupervisorList = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/getAllBrokerRRSupervisorsList", data, requestOptions)
        .then(handleResponse);
}

//Broker documents

const createBrokerDocument = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/brokerdocument", data, requestOptions)
        .then(handleResponse);
}
const deleteBrokerDocument = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post(`/broker/deletebrokerdocument/`, data, requestOptions)
        .then(handleResponse);
}
const getBrokerDocuments = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/broker/brokerdocuments`, requestOptions)
        .then(handleResponse);
}

//Billing History

const getBrokerBillingHistory = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/broker/brokerbillinghistory`, requestOptions)
        .then(handleResponse);
}

const searchBrokerClients = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/broker/searchbrokerclients", data, requestOptions)
        .then(handleResponse);
}

const brokerService = {
    getBrokerAlertTypes,
    createBrokerAlertType,
    updateBrokerAlertType,
    deleteBrokerAlertType,
    getApplicationAssignmentLogs,
    createApplicationAssignmentLog,
    bulkApplicationAssignmentLog,
    createApplicationStatusLogs,
    getApplicationStatusLogs,
    getBrokerSubordinateById,
    deleteBrokerSubordinate,
    createBrokerSubordinate,
    updateBrokerSubOrdinate,
    getBrokerSubordinates,
    createBrokerDocument,
    deleteBrokerDocument,
    getBrokerDocuments,
    getBrokerBillingHistory,
    updateSubordinateRole,
    searchBrokerAlertTypes,
    searchBrokerSubOrdinates,
    searchBrokerClients,
    getBrokerRRAndSupervisorList,
    getAllBrokerRRAndSupervisorList
};

export default brokerService;