import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import brokerService from '../../services/brokerservice';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { Dropdown } from 'react-bootstrap';
import { checkAuthorization } from '../../common/common';
import { Link } from "react-router-dom";

const BrokerDocuments = () => {

    const [documents, setDocuments] = useState(null);
    const [uploadDocumentShow, setUploadDocumentShow] = useState(false);
    const handleUploadDocumentClose = () => setUploadDocumentShow(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showIsReqUploaded, setShowIsReqUploaded] = useState(false);
    const [showIsReqAccepted, setShowIsReqAccepted] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getBrokerDocuments();
    }, []);

    const styleLeft = {
        textAlign: 'left'
    };

    const docLink = {
        color: 'blue',
        cursor: 'pointer'
    }

    const handleUploadDocumentShow = () => {
        setUploadDocumentShow(true);
    }

    const columns = [
        {
            name: 'Name',
            selector: row => <div className='paddingLeft-15'>{row.name}</div>,
            sortable: true,
        },
        {
            name: 'Document View',
            selector: row => <span style={docLink} onClick={(e) => fileDownloader(row.documentPath)}>{row.name}</span>,
            sortable: true,
        },
        {
            name: 'Is Required Accepted',
            selector: row => row.isRequiredAccepted ? "True" : "False",
            sortable: true,
        },
        {
            name: 'Is Required Upload',
            selector: row => row.isRequiredUpdated ? "True" : "False",
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => moment.utc(row.createdDate).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Delete Document" onClick={(e) => deleteBrokerDocument(row.id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    const getBrokerDocuments = () => {
        loader.show();
        brokerService.getBrokerDocuments()
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setDocuments(result);
                    loader.hide();
                }
                else {
                    setDocuments(null);
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const deleteBrokerDocument = (id) => {
        loader.show();
        let data = { Id: id };
        brokerService.deleteBrokerDocument(data)
            .then((json) => {
                if (json.data.errorCode === 0) {
                    getBrokerDocuments();
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });

    }

    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }

    const ADD_FORM_VALIDATION = Yup.object().shape({
        docname: Yup.string().required("Enter document name").nullable(),
        //docfile: Yup.string().required("Select file to upload").nullable(),
    });

    //Add / upload broker document 
    let formikValues = {
        docname: null,
        docfile: null,
        isRequiredUpdated: null,
        isRequiredAccepted: null
    };

    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            if (uploadedFile === null) {
                toast.error("Select a file to upload", {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            }
            if (uploadedFile.length > 0) {
                uploadBrokerDocument(values.docname);
            }
        }
    });

    const uploadBrokerDocument = (docName) => {
        loader.show();
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        let formData = new FormData();
        formData.append("file", uploadedFile[0]);
        formData.append("name", docName);
        formData.append("isRequiredUpdated", showIsReqUploaded ? true : false);
        formData.append("isRequiredAccepted", showIsReqAccepted ? true : false);
        brokerService.createBrokerDocument(formData, config)
            .then((json) => {
                if (json.data.errorCode === 35 || json.data.errorCode === 37) {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else {
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                handleUploadDocumentClose();
                getBrokerDocuments();
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const handleBrokerDocument = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFile(chosenFiles);
    }

    const handleUploadFile = files => {
        const uploaded = [];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        });
        setUploadedFile(uploaded);
    }

    const toggleRequiredUploaded = (cbVal) => {
        if (cbVal) {
            setShowIsReqUploaded(true);
        }
        else {
            setShowIsReqUploaded(false);
        }
    }

    const toggleRequiredAccepted = (cbVal) => {
        if (cbVal) {
            setShowIsReqAccepted(true);
        }
        else {
            setShowIsReqAccepted(false);
        }
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Broker Documents</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-10">
                            <h2 className="heading">Broker Documents</h2>
                        </div>
                        <div className='col-lg-2'>
                            <ul className="table_btns float-right">
                                <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handleUploadDocumentShow()}>Upload Broker Document</button></li>
                            </ul>
                        </div>
                    </div>

                    {documents && <DataTable theme="default" columns={columns} data={documents} pagination />}
                    {documents === null && <DataTable pagination noDataComponent="No record found" />}
                    <div className="row pt-3"></div>

                </section>

                {/* Add / Upload Document */}
                <Modal
                    show={uploadDocumentShow}
                    onHide={handleUploadDocumentClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Upload Broker Document</h3>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Document Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="docname"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.docname} />
                                                                    {formik.touched.docname && formik.errors.docname ? (
                                                                        <p style={styleLeft} className="error">{formik.errors.docname}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Upload Document<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="file" onBlur={formik.handleBlur} onChange={handleBrokerDocument}
                                                                        className="inputForm" name="docfile" />
                                                                    {/* {formik.touched.docfile && formik.errors.docfile ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.docfile}</p>
                                                                    ) : null} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Required Uploaded?</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleRequiredUploaded(e.target.checked)}
                                                                        type="checkbox" onBlur={formik.isRequiredUpdated} value={formik.values.isRequiredUpdated}
                                                                        checked={showIsReqUploaded ? true : false}
                                                                        id="cbIsUploaded" name="cbIsUploaded" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Is Required Accepted?</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleRequiredAccepted(e.target.checked)}
                                                                        type="checkbox" onBlur={formik.isRequiredAccepted} value={formik.values.isRequiredAccepted}
                                                                        checked={showIsReqAccepted ? true : false}
                                                                        id="cbIsAccepted" name="cbIsAccepted" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleUploadDocumentClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default BrokerDocuments;