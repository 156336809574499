import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { checkAuthorization, toTitleCase } from '../../common/common';
import { useNavigate, useParams } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import loader from "super-react-loader";
import { Link } from "react-router-dom";
import OLAEnums from '../../helpers/olaenums';

const ClientApplications = () => {

    const navigate = useNavigate();
    const [clientApplications, setClientApplications] = useState(null);
    const params = useParams();
    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getClientApplications(1);
    }, []);

    const getClientApplications = (page) => {
        loader.show();
        let data = {
            loggedInUserId: params.id,
            pageNumber: page,
            pageSize: perPage
        };
        applicationService.getApplicationsByClientId(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setClientApplications(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setClientApplications(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide()
            });
    }

    const handlePageChange = page => {
        getClientApplications(page);
    };

    const columns = [
        {
            name: 'Application Id',
            selector: row => row.applicationGuid,
            sortable: true,
        },
        {
            name: 'Account Number',
            selector: row => (
                row.accountNumber === null || row.accountNumber === undefined ? <div className='paddingLeft-15'>-</div> : <div className='paddingLeft-15'>{row.accountNumber}</div>
            ),
            sortable: true,
        },
        {
            name: 'Account Type',
            selector: row => row.accountTypeName,
            sortable: true,
        },
        {
            name: 'Account Sub Type',
            selector: row => row.accountSubTypeName,
            sortable: true,
        },

        {
            name: 'Application Status',
            // selector: row => <div className="acc-status statusapproved smallFontStatus">{toTitleCase(row.applicationStatusName)}</div>,
            selector: row => row.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted && row.applicationStatus !== OLAEnums.ApplicationStatusEnum.Canceled
            ? <div className="acc-status statusapproved smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>
            : <div className="acc-status statusprocessing smallFontStatus" title={toTitleCase(row.applicationStatusName)}>{toTitleCase(row.applicationStatusName)}</div>,
            sortable: true,
            width: '270px'
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="View Application" onClick={(e) => redirectToReviewApplication(row.applicationId)}>View Application</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
        }
    ];

    const redirectToReviewApplication = (id) => {
        localStorage.setItem("currentAppId", id);
        navigate('/broker/review-application');
    }

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/broker/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/broker/brokerclients">Broker Clients</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Client Applications</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="heading">Client Applications</h2>
                        </div>
                        <div className="col-lg-6"></div>
                    </div>

                    {clientApplications && <DataTable theme="default" columns={columns} data={clientApplications}
                        pagination paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange} />}
                    {clientApplications === null && <DataTable columns={columns} noDataComponent="No record found" />}
                    <table className="table table-area">
                    </table>

                </section>

            </section>
        </>
    );
};

export default ClientApplications;