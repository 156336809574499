import React, { useEffect } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import DashboardStatsComponent from '../../components/shared/DashboardStatsComponent';
import SubordinateDashboardComponent from '../../components/shared/SubordinateDashboardComponent';

const SupervisorDashboard = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <DashboardStatsComponent />
                    <SubordinateDashboardComponent />
                </section>
            </section>
        </>
    );
};

export default SupervisorDashboard;