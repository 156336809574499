import React from 'react'
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import accountService from '../../services/accountservice';
import { toast } from 'react-toastify';
import loader from "super-react-loader";

const ForgotPassword = () => {
    let formikValues = {
        email: ""
    };
    let INITIAL_FORM_STATE = formikValues;
    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email("Enter a valid email address")
            .required("Email address is required")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        onSubmit: (values) => {
            loader.show();
            accountService.forgotPassword({
                email: values.email,
            })
                .then((json) => {
                    if (json.data.errorCode !== 0) {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    } else {
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        formik.resetForm(formik.initialValues);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    return (
        <>
            <section className="mode" data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid">
                    <div className="light-dark-mod"></div>
                    <section className="trading_card ">
                        <h1>Forgot Password</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input_wrapper">
                                <label htmlFor='email' className="form-label">Email</label>
                                <div className="input_area">
                                    {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                                    <input name='email' id='email' autoComplete='off' type="email" className="inputForm" onBlur={formik.handleBlur}
                                        onChange={formik.handleChange} value={formik.values.email} placeholder="Enter email address" />
                                    {formik.touched.email && formik.errors.email ? (<p className="error">{formik.errors.email}</p>) : null}
                                </div>
                            </div>
                            <button type="submit" className="btn login_btn">Request Reset Password</button>
                        </form>
                        <Link to="/Login" className="signup_area">Already have an account? <i className="color_orange">Sign in</i></Link>
                    </section>
                </div>
            </section>
        </>
    )
}

export default ForgotPassword;