export function useFieldErrorStyle(errors, tochedList) {
    let allElements = document.querySelectorAll('*');
    allElements.forEach((element) => {
        element.classList.remove('errorFieldborder');
    });

    Object.keys(errors).forEach(ele => {
        if (Array.isArray(tochedList[ele])) {
            if (Array.isArray(errors[ele])) {
                errors[ele].forEach((elem, idx) => {
                    Object.keys(elem || {}).forEach(elex => {
                        // tochedList[ele].forEach((elext, idxt) => {
                        //     Object.keys(elext || {}).forEach(elex1 => {
                        //         if (elext[elex1] && idx == idxt) {

                        //             let [element] = Array.from(document.getElementsByName(`${ele}.${idx}.${elex}`))
                        //             element?.classList.add('errorFieldborder')
                        //         }
                        //     })
                        // })
                        if(tochedList['authSign'][idx] && tochedList['authSign'][idx][elex]){
                            let [element] = Array.from(document.getElementsByName(`${ele}.${idx}.${elex}`))
                                    element?.classList.add('errorFieldborder')
                        }  
                    })
                })
            }
        }
        else {
            let [element] = Array.from(document.getElementsByName(ele))
            element?.classList.add('errorFieldborder')
        }
    })
}