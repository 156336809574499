import http from "../common/common";
import { authHeader } from "../helpers/auth-headers";
import { handleResponse } from "../helpers/handle-response";

const createListofValues = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/lov/savelistofvalue", data, requestOptions)
        .then(handleResponse);
}

const getListofValues = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/lov/listofvalues`, requestOptions)
        .then(handleResponse);
}

const getListbyParentId = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/lov/listofvalues/${id}`, requestOptions)
        .then(handleResponse);
}

const deleteListofValue = (data) => {
    const requestOptions = { method: "DELETE", headers: authHeader() };
    return http.delete('/lov/deletelov', data, requestOptions)
        .then(handleResponse);
}

const lovService = {
    createListofValues,
    getListofValues,
    getListbyParentId,
    deleteListofValue
};

export default lovService;