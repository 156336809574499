import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import * as Yup from "yup";
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';
import { defaultCountry } from '../helpers/constants';

const file_validation = ["image/jpeg", "image/png", "image/jpg", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]
const Documents = () => {

    const navigate = useNavigate();
    const [applicationInfo, setApplicationInfo] = useState(null);
    const [accountFeatures, setAccountFeatures] = useState(null);
    const [appDocuments, setAppDocuments] = useState(null);
    const [countAppDocuments, setCountAppDocuments] = useState([]);
    const [coAppDocuments, setCoAppDocuments] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [deletedDocument, setDeletedDocument] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);
    //const [isEntityAccount, setIsEntityAccount] = useState(false);
    const [showPriGovtId, setShowPriGovtId] = useState(false);
    const [showPriPassport, setShowPriPassport] = useState(false);
    const [showPriDrivingLicense, setShowPriDrivingLicense] = useState(false);
    const [showCoAppGovtId, setShowCoAppGovtId] = useState(false);
    const [showCoAppPassport, setShowCoAppPassport] = useState(false);
    const [showCoAppDrivingLicense, setShowCoAppDrivingLicense] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedCoAppFiles, setUploadedCoAppFiles] = useState([]);
    const [saveOnly, setSaveOnly] = useState(false);

    const styles = {
        color: 'blue', fontWeight: '200', cursor: 'pointer',
    };

    const stylesBold = {
        fontWeight: 'bold', marginTop: 8, marginBottom: 8
    };

    const styleLabelWidth = {
        width: '420px'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicationDocuments();
    }, []);

    const handlePrevious = () => {
        // if (isEntityAccount) {
        //     navigate('/owners-officials');
        // }
        // else {
        navigate('/account-features');
        //}
    }

    const getApplicationDocuments = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 7
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo !== null) {
                        setApplicationInfo(result.applicationInfo);
                        if (result.applicationInfo.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }
                        // if (result.applicationInfo.accountType === OLAEnums.AccountTypeEnum.EntityAccount) {
                        //     setIsEntityAccount(true);
                        // }
                        // else {
                        //     setIsEntityAccount(false);
                        // }
                    }
                    if (result.accountFeaturesInfo !== null) {
                        setAccountFeatures(result.accountFeaturesInfo);
                    }
                    if (result.applicationDocumentsList !== null) {
                        var priAppDocs = result?.applicationDocumentsList.filter(x => x.isPrimaryApplicant === true);
                        setAppDocuments(priAppDocs);
                        var coAppDocs = result?.applicationDocumentsList.filter(x => x.isPrimaryApplicant === false);
                        setCoAppDocuments(coAppDocs);
                        setCountAppDocuments(result.applicationDocumentsList?.length);
                    }

                    if (result.applicantList !== null) {
                        if (result.applicantList[0] !== null) {
                            // if (result.applicantList[0].citizenshipCountryId !== defaultCountry && result.applicationInfo.accountType !== OLAEnums.AccountTypeEnum.Retirement) {
                            //     setShowPrimaryPassSection(true);
                            // }
                            // else if (result.applicationInfo.accountType === OLAEnums.AccountTypeEnum.Retirement) {
                            //     setShowPrimaryPassSection(false);
                            // }
                            if (result.applicantList[0].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                setShowPriGovtId(true);
                                setShowPriPassport(false);
                                setShowPriDrivingLicense(false);
                            }
                            else if (result.applicantList[0].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                setShowPriGovtId(false);
                                setShowPriPassport(true);
                                setShowPriDrivingLicense(false);
                            }
                            else if (result.applicantList[0].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowPriGovtId(false);
                                setShowPriPassport(false);
                                setShowPriDrivingLicense(true);
                            }
                            else {
                                setShowPriGovtId(true);
                                setShowPriPassport(false);
                                setShowPriDrivingLicense(false);
                            }
                        }
                        if (result.applicantList.length > 1) {
                            if (result.applicantList[1] !== null) {
                                // if (result.applicantList[1].citizenshipCountryId !== defaultCountry) {
                                //     setShowCoAppPassSection(true);
                                // }

                                if (result.applicantList[1].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                    setShowCoAppGovtId(true);
                                    setShowCoAppPassport(false);
                                    setShowCoAppDrivingLicense(false);
                                }
                                else if (result.applicantList[1].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                    setShowCoAppGovtId(false);
                                    setShowCoAppPassport(true);
                                    setShowCoAppDrivingLicense(false);
                                }
                                else if (result.applicantList[1].idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                    setShowCoAppGovtId(false);
                                    setShowCoAppPassport(false);
                                    setShowCoAppDrivingLicense(true);
                                }
                                else {
                                    setShowCoAppGovtId(true);
                                    setShowCoAppPassport(false);
                                    setShowCoAppDrivingLicense(false);
                                }
                            }
                        }
                    }
                    // if (result.entityInformationInfo !== null) {
                    //     if (result.entityInformationInfo.citizenshipId !== defaultCountry || result.entityInformationInfo.originCountryId !== defaultCountry) {
                    //         setShowPrimaryPassSection(true);
                    //     }
                    // }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const schema = Yup.object().shape({
        fileGovIssuedIdLicense: showPriGovtId ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePrimaryPassport: showPriPassport ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePrimaryDL: showPriDrivingLicense ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileDividentAuth: accountFeatures?.dividendReinvestment ? Yup.mixed()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            }) : Yup.string().optional(),

        //Co-Applicant documents
        coAppfileGovIssuedIdLicense: applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && showCoAppGovtId ? Yup.mixed()
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && coAppDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            })
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            }) : Yup.string().optional(),
        fileCoAppPassport: showCoAppPassport ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport for co-applicant", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileCoAppDL: showCoAppDrivingLicense ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license for co-applicant", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        coAppfileDividentAuth: accountFeatures?.dividendReinvestment && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint ? Yup.mixed()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            }) : Yup.string().optional(),
    });
    const { register, handleSubmit, formState: { errors, touchedFields, isValid }, setValue } = useForm({
        resolver: yupResolver(schema),
    })
    const handleChangeGovtIssueId = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangePrimaryPassport = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangePrimaryDL = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.DriverLicense);
    }
    const handleChangeCoAppPassport = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleCoAppUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangeCoAppDL = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleCoAppUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.DriverLicense);
    }
    const handleChangeDividentAuth = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Dividentreinvestauthorizationdoc);
    }
    const handleChangeCoAppGovtIssueId = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleCoAppUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangeCoAppDividentAuth = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleCoAppUploadFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Dividentreinvestauthorizationdoc);
    }
    const onSubmit = data => {
        if (uploadedFiles.length === 0 && uploadedCoAppFiles.length === 0) {
            loader.hide();
            if (!saveOnly) {
                navigate("/disclosures-signatures");
            }
        }
        
        if (uploadedFiles.length > 0) {
            uploadPrimaryApplicationDocuments();
        }
        if (uploadedCoAppFiles.length > 0) {
            uploadCoApplicationDocuments();
        }
    }
    const uploadPrimaryApplicationDocuments = () => {
        loader.show();
        let userRole = getLoggedInUserInfo().userRole;
        for (var i = 0; i < uploadedFiles.length; i++) {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let formData = new FormData();
            formData.append("UserId", getLoggedInUserInfo().id);
            formData.append("ApplicationId", getCurrentApplicationId());
            formData.append("file", uploadedFiles[i].file);
            formData.append("UploadedBy", userRole);
            formData.append("DocumentType", uploadedFiles[i].docType);
            formData.append("IsPrimaryApplicant", true);
            formData.append("CreatedBy", getLoggedInUserInfo().id);

            applicationService.uploadApplicationDocuments(formData, config)
                .then((json) => {
                    if (json.data.errorCode === 111 || json.data.errorCode === 112) {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                        return;
                    }
                    else if (uploadedCoAppFiles.length === 0) {
                        loader.hide();
                        if (!saveOnly) {
                            navigate("/disclosures-signatures");
                        }
                        else {
                            toast.success("Documents have been uploaded successfully", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            getApplicationDocuments();
                        }
                    }
                    uploadedFiles.length = 0;
                    uploadedFiles = [];
                })
                .catch((error) => {
                    checkAuthorization(error);
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    }
    const uploadCoApplicationDocuments = () => {
        loader.show();
        let userRole = getLoggedInUserInfo().userRole;
        if (applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint) {
            for (var j = 0; j < uploadedCoAppFiles.length; j++) {
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };
                let formData = new FormData();
                formData.append("UserId", getLoggedInUserInfo().id);
                formData.append("ApplicationId", getCurrentApplicationId());
                formData.append("file", uploadedCoAppFiles[j].file);
                formData.append("UploadedBy", userRole);
                formData.append("DocumentType", uploadedCoAppFiles[j].docType);
                formData.append("IsPrimaryApplicant", false);
                formData.append("CreatedBy", getLoggedInUserInfo().id);

                applicationService.uploadApplicationDocuments(formData, config)
                    .then((json) => {
                        if (json.data.errorCode === 111 || json.data.errorCode === 112) {
                            toast.error(json.data.message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            loader.hide();
                            return;
                        }
                        if (json.data.payload !== null) {
                            loader.hide();
                            if (!saveOnly) {
                                navigate("/disclosures-signatures");
                            }
                            else {
                                toast.success("Documents have been uploaded successfully", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                                getApplicationDocuments();
                            }
                        }
                        uploadedCoAppFiles.length = 0;
                        uploadedCoAppFiles = [];
                    })
                    .catch((error) => {
                        checkAuthorization(error);
                        toast.error(error, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    });
            }
        }
        else {
            loader.hide();
            navigate("/disclosures-signatures");
        }
    }
    function handleUploadFiles(files, name, docType) {
        if (file_validation.includes(files[0].type)) {
            const uploaded = [...uploadedFiles];
            files.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1 && !uploadedCoAppFiles.map(ele => ele.name).includes(files[0].name)) {
                    uploaded.push({"file": file, "docType": docType});
                }
                else {
                    toast.error('Duplicate files are not allowed')
                    setValue(name, null)
                }
            })
            setUploadedFiles(uploaded);
        }
    }
    const handleCoAppUploadFiles = (files, name, docType) => {
        if (file_validation.includes(files[0].type)) {
            const coAppuploaded = [...uploadedCoAppFiles];
            files.some((file) => {
                if (coAppuploaded.findIndex((f) => f.name === file.name) === -1 && !uploadedFiles.map(ele => ele.name).includes(files[0].name)) {
                    coAppuploaded.push({"file": file, "docType": docType});
                }
                else {
                    toast.error('Duplicate files are not allowed')
                    setValue(name, null);
                }
            })
            setUploadedCoAppFiles(coAppuploaded);
        }
    }
    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const deleteApplicationDocument = (data) => {
        setDeletedDocument(data);
        setShowRemoveModal(true);
    }
    const deleteDocumentConfirm = (doc) => {
        let data = {
            Id: doc.id,
            ApplicationId: doc.applicationId,
            Key: doc.key
        }
        applicationService.deleteApplicationDocument(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    toast.success("Document has been deleted successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    handleCloseRemoveModal();
                    getApplicationDocuments();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleCloseRemoveModal = () => setShowRemoveModal(false);
    let erobj;
    for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
            erobj = { ...erobj, [key]: errors[key].message }
        }
    }
    useFieldErrorStyle(errors, touchedFields)
    return (
        <>
            <div className="tab-content" id="document">
                <FormErrorToottip errorList={erobj || {}} touchlist={erobj}></FormErrorToottip>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formContainer">
                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Document Upload</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="paragraph1">Acceptable File Formats: .pdf, .doc, .docx, .png, .jpeg |
                                            Maximum File Size: 5MB</p>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <ul className="browser_btns mt-4">
                                                    <li><h2 className='heading'>Primary Applicant Documents</h2></li>
                                                </ul>
                                                {showPriGovtId && <div className="rows">
                                                    <div className="col-lg-6">

                                                        <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileGovIssuedIdLicense')} onChange={handleChangeGovtIssueId} disabled={ctrlDisable}
                                                                className="inputForm" name="fileGovIssuedIdLicense" />
                                                            <p className="error">{errors?.fileGovIssuedIdLicense && errors.fileGovIssuedIdLicense.message}</p>
                                                        </div>

                                                    </div>
                                                </div>}

                                                {showPriPassport && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePrimaryPassport')} className="inputForm" name="filePrimaryPassport" disabled={ctrlDisable}
                                                                onChange={handleChangePrimaryPassport} />
                                                            <p className="error">{errors?.filePrimaryPassport && errors.filePrimaryPassport.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showPriDrivingLicense && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePrimaryDL')} className="inputForm" name="filePrimaryDL" disabled={ctrlDisable}
                                                                onChange={handleChangePrimaryDL} />
                                                            <p className="error">{errors?.filePrimaryDL && errors.filePrimaryDL.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {accountFeatures && accountFeatures?.dividendReinvestment && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Divident Reinvest Authorization Document</label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDividentAuth')} className="inputForm" name="fileDividentAuth" disabled={ctrlDisable}
                                                                onChange={handleChangeDividentAuth} />
                                                            <p className="error">{errors?.fileDividentAuth && errors.fileDividentAuth.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>
                                            {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className='col-lg-6'>
                                                <ul className="browser_btns mt-4">
                                                    <li><h2 className='heading'>Co-Applicant Documents</h2></li>
                                                </ul>

                                                {showCoAppGovtId && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <div className="input_wrapper">
                                                            <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID<span className="error-info">*</span></label>
                                                            <div className="input_area">
                                                                <input type="file" {...register('coAppfileGovIssuedIdLicense')} onChange={handleChangeCoAppGovtIssueId} disabled={ctrlDisable}
                                                                    className="inputForm" name="coAppfileGovIssuedIdLicense" />
                                                                <p className="error">{errors?.coAppfileGovIssuedIdLicense && errors.coAppfileGovIssuedIdLicense.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showCoAppPassport && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileCoAppPassport')} className="inputForm" name="fileCoAppPassport" disabled={ctrlDisable}
                                                                onChange={handleChangeCoAppPassport} />
                                                            <p className="error">{errors?.fileCoAppPassport && errors.fileCoAppPassport.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showCoAppDrivingLicense && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileCoAppDL')} className="inputForm" name="fileCoAppDL" disabled={ctrlDisable}
                                                                onChange={handleChangeCoAppDL} />
                                                            <p className="error">{errors?.fileCoAppDL && errors.fileCoAppDL.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {accountFeatures && accountFeatures?.dividendReinvestment && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <div className="input_wrapper">
                                                            <label className="form-label" style={styleLabelWidth}>Upload Divident Reinvest Authorization Document</label>
                                                            <div className="input_area">
                                                                <input type="file" {...register('coAppfileDividentAuth')} className="inputForm" name="coAppfileDividentAuth" disabled={ctrlDisable} onChange={handleChangeCoAppDividentAuth} />
                                                                <p className="error">{errors?.coAppfileDividentAuth && errors.coAppfileDividentAuth.message}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="row">
                                                    <div className='col-lg-12'>  <h2 className="heading">Uploaded Documents (Primary Applicant)</h2></div>
                                                </div>
                                                <div className="rows">
                                                    {appDocuments && appDocuments?.length > 0 && <div className="">
                                                        <table className="table table-area">
                                                            <tbody data-gr-ext-installed="" light-mode="light">
                                                                {appDocuments && appDocuments.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td style={{ width: "100%" }}><span style={styles} onClick={(e) => fileDownloader(item.key)}>{item.name}</span> - ({item.documentTypeName})</td>
                                                                        <td style={{ width: "100%" }}>
                                                                            {!ctrlDisable && <span className="ico_table icon-Icon-awesome-window-close" onClick={(e) => deleteApplicationDocument(item)} title='Remove Document'></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                    {appDocuments && appDocuments?.length === 0 && <div className="">
                                                        <p className='paragraph1'>No document found</p>
                                                    </div>}
                                                </div>
                                            </div>
                                            {applicationInfo && applicationInfo?.accountSubType === OLAEnums.PersonalAccountTypeEnum.Joint && <div className='col-lg-6'>
                                                <div className="row">
                                                    <div className='col-lg-12'><h2 className="heading">Uploaded Documents (Co-Applicant)</h2></div>
                                                </div>
                                                <div className="rows">
                                                    {coAppDocuments && coAppDocuments?.length > 0 && <div className="">
                                                        <table className="table table-area">
                                                            <tbody data-gr-ext-installed="" light-mode="light">
                                                                {coAppDocuments && coAppDocuments.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td style={{ width: "100%" }}><span style={styles} onClick={(e) => fileDownloader(item.key)}>{item.name}</span> - ({item.documentTypeName})</td>
                                                                        <td style={{ width: "100%" }}>
                                                                            {!ctrlDisable && <span className="ico_table icon-Icon-awesome-window-close" onClick={(e) => deleteApplicationDocument(item)} title='Remove Document'></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                    {coAppDocuments && coAppDocuments?.length === 0 && <div className="">
                                                        <p className='paragraph1'>No document found</p>
                                                    </div>}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(false)} disabled={ctrlDisable}>Save and Continue</button></li>
                        {isValid && <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>

            <Modal
                show={showRemoveModal}
                onHide={handleCloseRemoveModal}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='imgArea'>
                                    <img src='../assets/images/del_img.png' />
                                </div>
                                <div className='msgs_Area'>
                                    <h3>Delete</h3>
                                    <p>Do you want to delete <span style={stylesBold}>{deletedDocument?.name}</span> document?</p>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-5'><button type='button' className='yes_noBtn' onClick={(e) => deleteDocumentConfirm(deletedDocument)}>Delete</button></div>
                                        <div className='col-lg-5'><button type='button' className='yes_noBtn cancel_btn' onClick={handleCloseRemoveModal}>Cancel</button></div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default Documents;