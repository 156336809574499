import http from "../common/common";
import { authHeader } from "../helpers/auth-headers";
import { handleResponse } from "../helpers/handle-response";

//Dashboard
const getDashboardStats = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http
        .get("/admin/admindashboard", requestOptions)
        .then(handleResponse);
}

const getLatestApplications = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http
        .post("/admin/latestapplications", data, requestOptions)
        .then(handleResponse);
}

const getLatestUsers = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/latestusers", data, requestOptions)
        .then(handleResponse);
}

const searchBrokers = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/searchbrokers", data, requestOptions)
        .then(handleResponse);
}

const searchUsers = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/searchusers", data, requestOptions)
        .then(handleResponse);
}

const searchSubOrdinates = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/searchsubordinates", data, requestOptions)
        .then(handleResponse);
}

const searchSubOrdinatesByAdmin = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/searchsubordinatesbyadmin", data, requestOptions)
        .then(handleResponse);
}

const getBrokerRRSupervisorForAdmin = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/getBrokerRRSupervisorForAdmin", data, requestOptions)
        .then(handleResponse);
}

const addSubordinate = (data) => {// this method will be called from admin side, when admin will create supervisors and rrs
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/addsubordinate", data, requestOptions)
        .then(handleResponse);
}

//Pricing Plan
const getPricingPlans = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http
        .get("/admin/pricingplans", requestOptions)
        .then(handleResponse);
}

const getPricingPlanbyId = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http
        .get(`/admin/pricingplan/${id}`, requestOptions)
        .then(handleResponse);
}

const createPricingPlan = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/pricingplan", data, requestOptions)
        .then(handleResponse);
}

const updatePricingPlan = (data) => {
    const requestOptions = { method: "PUT", headers: authHeader() };
    return http.put("/admin/pricingplan", data, requestOptions)
        .then(handleResponse);
}

const deletePricingPlan = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post('/admin/deletepricingplan', data, requestOptions)
        .then(handleResponse);
}

//Billing History

const getBillingHistories = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http
        .get("/admin/billinghistories", requestOptions)
        .then(handleResponse);
}

const getBillingHistorybyId = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http
        .get(`/admin/billinghistory/${id}`, requestOptions)
        .then(handleResponse);
}

const createBillingHistory = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/billinghistory", data, requestOptions)
        .then(handleResponse);
}

const updateBillingHistory = (data) => {
    const requestOptions = { method: "PUT", headers: authHeader() };
    return http.put("/admin/billinghistory", data, requestOptions)
        .then(handleResponse);
}

const deleteBillingHistory = (data) => {
    const requestOptions = { method: "DELETE", headers: authHeader() };
    return http.delete('/admin/billinghistory', data, requestOptions)
        .then(handleResponse);
}

const getBrokerBillingHistory = (id) => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get(`/admin/brokerbillinghistory/` + id, requestOptions)
        .then(handleResponse);
}

//Alert Types

const getAlertTypes = () => {
    const requestOptions = { method: "GET", headers: authHeader() };
    return http.get("/admin/alerttypes", requestOptions)
        .then(handleResponse);
}

const searchAlertTypes = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/searchalerttypes", data, requestOptions)
        .then(handleResponse);
}

const createAlertType = (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/alerttypes", data, requestOptions)
        .then(handleResponse);
}

const updateAlertType = (data) => {
    const requestOptions = { method: "PUT", headers: authHeader() };
    return http.put("/admin/alerttypes", data, requestOptions)
        .then(handleResponse);
}

const deleteAlertType = (data) => {
    const requestOptions = { method: "DELETE", headers: authHeader() };
    return http.delete('/admin/alerttypes', data, requestOptions)
        .then(handleResponse);
}

const updateBrokerEDocumentSettings= (data) => {
    const requestOptions = { method: "POST", headers: authHeader() };
    return http.post("/admin/updatebrokeredocumentsettings", data, requestOptions)
        .then(handleResponse);
}

const adminService = {
    getDashboardStats,
    getLatestApplications,
    getLatestUsers,
    getPricingPlans,
    getPricingPlanbyId,
    createPricingPlan,
    updatePricingPlan,
    deletePricingPlan,
    getBillingHistories,
    getBillingHistorybyId,
    createBillingHistory,
    updateBillingHistory,
    deleteBillingHistory,
    getBrokerBillingHistory,
    getAlertTypes,
    createAlertType,
    updateAlertType,
    deleteAlertType,
    searchAlertTypes,
    searchBrokers,
    searchUsers,
    searchSubOrdinates,
    addSubordinate,
    searchSubOrdinatesByAdmin,
    updateBrokerEDocumentSettings,
    getBrokerRRSupervisorForAdmin
};

export default adminService;