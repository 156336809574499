import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import authService from '../../services/authservice';
import { toast } from 'react-toastify';
import { applicantRole, brokerRole, rrRole, supervisorRole24, supervisorRole4 } from '../../helpers/constants';
import loader from "super-react-loader";

const Login = () => {
    const navigate = useNavigate();
    let formikValues = {
        username: "",
        password: ""
    };

    const [isShow, setIsShow] = useState(false);

    let INITIAL_FORM_STATE = formikValues;

    const FORM_VALIDATION = Yup.object().shape({
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,

        onSubmit: (values) => {
            loader.show();
            authService.login({
                Username: values.username,
                Password: values.password,
            })
                .then((json) => {
                    let result = json.data.payload;
                    if (result === null) {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    } else {
                        if (result.userRole === applicantRole) {
                            navigate("/dashboard");
                        }
                        else if (result.userRole === brokerRole) {
                            navigate("/broker/dashboard");
                        }
                        else if (result.userRole === rrRole) {
                            navigate("/registerrep/dashboard");
                        }
                        else if (result.userRole === supervisorRole24 || result.userRole === supervisorRole4) {
                            navigate("/supervisor/dashboard");
                        }
                        else {
                            navigate("/admin/dashboard");
                        }
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const showPassword = () => {
        if (isShow) {
            setIsShow(false);
        }
        else {
            setIsShow(true);
        }
    }

    return (
        <>
            <section className="mode" data-gr-ext-installed="" light-mode="light">
                <div className="container-fluid">
                    <div className="light-dark-mod">
                        {/* <span className="switch">
                            <label>Light</label>&nbsp;&nbsp;
                            <input type="checkbox" className="switch" id="switch-id" onclick="toggle_light_mode()" />
                            <label for="switch-id">Dark</label>
                        </span> */}
                    </div>

                    <section className="trading_card ">
                        <h1>Account Login</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="input_wrapper">
                                <label className="form-label">Username</label>
                                <div className="input_area">
                                    {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                                    <input type="text" name="username" id="username" className="inputForm" placeholder="Enter username"
                                        value={formik.values.username} maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                    {formik.touched.username && formik.errors.username ? (
                                        <p className="error">{formik.errors.username}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="input_wrapper">
                                <label className="form-label">Password</label>
                                <div className="input_area">
                                    {!isShow && <i className="ico_fa fa fa-eye" aria-hidden="true" onClick={(e) => showPassword()}></i>}
                                    {isShow && <i className="ico_fa fa fa-eye-slash" aria-hidden="true" onClick={(e) => showPassword()}></i>}
                                    <input name='password' id='password' type={isShow ? "text" : "password"} className="inputForm" placeholder="Enter password"
                                        value={formik.values.password} maxLength="20" onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                    {formik.touched.password && formik.errors.password ? (
                                        <p className="error">{formik.errors.password}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="info_ico">
                                {/* <span className="forget_msg">Forgot your username or password ?</span> */}
                                <Link to="/forgotpassword" className='signup_area'>
                                    <span className="forget_msg">Forgot your username or password?</span>
                                </Link>

                                {/* <i className="ico_fa fa fa-info-circle" aria-hidden="true"></i> */}
                            </div>
                            <button type="submit" className="btn login_btn">Login</button>
                        </form>
                        <Link to="/signup?brokerUserId=8" className="signup_area">No account? <i className="color_orange">Sign Up</i></Link>
                    </section>
                </div>
            </section>
        </>


    )
}
export default Login;