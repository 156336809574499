import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import { useMemo } from 'react';
const FormErrorToottip = ({ errorList, touchlist }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const showPopup = useMemo(() => {
        let flag = false
        if (!!Object.keys(errorList).length && !!Object.keys(touchlist).length) {
            flag = Object.keys(errorList).map(ele => { return touchlist.hasOwnProperty(ele) }).some(ele => !!ele)
        }
        return flag
    }, [errorList, touchlist])
    return (
        <>
            {showPopup && <>
                <Button variant="danger" className='form-errors-list' style={{ backgroundColor: 'red' }} ref={target} onClick={() => setShow(!show)}>
                    {/* <i class="fa fa-times me-2" aria-hidden="true"></i> */}
                    {show ? 'Close' : ' Please complete the indicated sections'}
                </Button>
                <Overlay target={target.current} show={show} placement='left-start'>
                    {(props) => (
                        <Tooltip id="overlay-example" className='cTooltip' {...props}>
                            <ListGroup variant="flush">
                                {Object.keys(errorList).map(ele => touchlist[ele] &&
                                    <>
                                        < ListGroup.Item variant="danger" className='text-start d-flex align-items-center' > {!!ele && !Array.isArray(errorList[ele]) && errorList[ele]}</ListGroup.Item>
                                        {Array.isArray(errorList[ele]) && Array.isArray(touchlist[ele]) &&

                                            errorList[ele].map((elee, idx) => Object.keys(elee || {}).map(elem => touchlist['authSign'][idx] && touchlist['authSign'][idx][elem] &&
                                                < ListGroup.Item variant="danger" className='text-start d-flex align-items-center' > Signer {idx + 1} : {elee[elem]}</ListGroup.Item >
                                            ))}

                                    </>
                                )}

                            </ListGroup>
                        </Tooltip>
                    )}
                </Overlay>
            </>
            }

        </>
    );
}

export default FormErrorToottip;