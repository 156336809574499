import React, { useEffect, useState } from 'react';
import applicationService from '../../services/applicationservice';
import { toast } from 'react-toastify';
import { checkAuthorization, getLoggedInUserInfo } from '../../common/common';
import loader from "super-react-loader";
import { brokerRole } from '../../helpers/constants';

const DashboardStatsComponent = () => {

    const [appStats, setAppStats] = useState(null);
    const [approvedPercent, setApprovedPercent] = useState(null);
    const [rejectedPercent, setRejectedPercent] = useState(null);
    const [notCompletedPercent, setNotCompletedPercent] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        var broker = getLoggedInUserInfo();
        if (broker !== null) {
            getApplicationStats();
        }
    }, []);

    const getApplicationStats = () => {
        loader.show();
        let userId = null;
        if (getLoggedInUserInfo().userRole === brokerRole) {
            userId = getLoggedInUserInfo().id;
        }
        else {
            userId = getLoggedInUserInfo().createdBy;
        }
        let data = {
            "loggedInUserId": userId
        };
        applicationService.getApplicationStats(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setAppStats(result);
                    if (result[0].totalApproved === 0) {
                        setApprovedPercent(0);
                    }
                    else {
                        setApprovedPercent((result[0].totalApproved * 100) / result[0].totalApplications);
                    }
                    if (result[0].totalRejected === 0) {
                        setRejectedPercent(0);
                    }
                    else {
                        setRejectedPercent((result[0].totalRejected * 100) / result[0].totalApplications);
                    }
                    if (result[0].totalNotCompleted === 0) {
                        setNotCompletedPercent(0);
                    }
                    else {
                        setNotCompletedPercent((result[0].totalNotCompleted * 100) / result[0].totalApplications);
                    }
                    loader.hide();
                }
                else{
                    setApprovedPercent(0);
                    setRejectedPercent(0);
                    setNotCompletedPercent(0);
                }
                loader.hide();
            })
            .catch((error) => {
                checkAuthorization(error);
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <h2 className="heading">Clients information</h2>
                </div>
            </div>
            <div className="dashboard-statistic">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card-broker">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2>Applications Approved</h2>
                                    <h3>{appStats && appStats[0].totalApproved}</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="progress-pie-chart" data-percent={approvedPercent}>
                                        <div className="ppc-progress">
                                            <div className="ppc-progress-fill"></div>
                                        </div>
                                        <div className="ppc-percents">
                                            <div className="pcc-percents-wrapper">
                                                <span>{approvedPercent === 0 ? 0 : parseFloat(approvedPercent).toFixed(2)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card-broker">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2>Applications Rejected</h2>
                                    <h3>{appStats && appStats[0].totalRejected}</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="progress-pie-chart" data-percent={rejectedPercent}>
                                        <div className="ppc-progress">
                                            <div className="ppc-progress-fill"></div>
                                        </div>
                                        <div className="ppc-percents">
                                            <div className="pcc-percents-wrapper">
                                                <span>{rejectedPercent === 0 ? 0 : parseFloat(rejectedPercent).toFixed(2)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card-broker">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2>Applications Not Completed</h2>
                                    <h3>{appStats && appStats[0].totalNotCompleted}</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="progress-pie-chart" data-percent={notCompletedPercent}>
                                        <div className="ppc-progress">
                                            <div className="ppc-progress-fill"></div>
                                        </div>
                                        <div className="ppc-percents">
                                            <div className="pcc-percents-wrapper">
                                                <span>{notCompletedPercent === 0 ? 0 : parseFloat(notCompletedPercent).toFixed(2)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
};

export default DashboardStatsComponent;