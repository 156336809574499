import React, { useEffect, useState } from 'react';
import Header from '../../layouts/header/header';
import LeftNav from '../../layouts/leftnav/leftnav';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import * as Yup from "yup";
import { supervisorRole24, supervisorRole4 } from '../../helpers/constants';
import accountService from '../../services/accountservice';
import adminService from '../../services/adminservice';
import brokerService from '../../services/brokerservice';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

const BrokerSupervisors = () => {

    const [supervisorRoles, setSupervisorRoles] = useState(null);
    const [supervisorList, setSupervisorList] = useState(null);
    const [supervisorAddShow, setSupervisorAddShow] = useState(false);
    const [supervisorEditShow, setSupervisorEditShow] = useState(false);
    const [currentSupervisor, setCurrentSupervisor] = useState(null);
    const [showStatus, setShowStatus] = useState(false);
    const [keyword, setKeyword] = useState('');
    //Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const params = useParams();

    const handleSupervisorAddClose = () => {
        setSupervisorAddShow(false);
        addformik.resetForm();
    }
    const handleSupervisorEditClose = () => setSupervisorEditShow(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getSupervisors(1);
    }, []);

    const styleLeft = {
        textAlign: 'left'
    }

    const styleCursor = {
        cursor: "pointer"
    }

    const handleSueprvisorAddShow = () => {
        formik.resetForm();
        getSupervisorRoles();
        setSupervisorAddShow(true);
        setCurrentSupervisor(null);
    }

    const handleSupervisorEditShow = (item) => {
        setCurrentSupervisor(item);
        toggleStatusCheckbox(item.isLocked);
        setSupervisorEditShow(true);
    }

    const getSupervisorRoles = () => {
        loader.show();
        accountService.getRoles()
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    let list = result.filter(x => x.name === supervisorRole24 || x.name === supervisorRole4);
                    setSupervisorRoles(list);
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const columns = [
        {
            name: 'Username',
            selector: row => <div className='paddingLeft-15'>{row.userName}</div>,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: row => <a href={"mailto:" + row.emailAddress}>{row.emailAddress}</a>,
            sortable: true,
        },
        {
            name: 'Role Type',
            selector: row => row.roleName,
            sortable: true,
        },
        {
            name: 'Creation Date',
            selector: row => moment.utc(row.createdDate).format('DD MMM YYYY hh:mm A'),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.isLocked === true ? "Locked" : "UnLocked",
            sortable: true,
        },
        {
            cell: (row) => (
                <>
                    <Dropdown>
                        <Dropdown.Toggle variant="blueColor" id="dropdown-basic">
                            <span className='fa fa-cog'></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item title="Edit Supervisor" onClick={() => handleSupervisorEditShow(row)}>Edit</Dropdown.Item>
                            {/* <Dropdown.Item title="Demote to RR" onClick={() => handleDemoteToRR(row)}>Demote to RR</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            ),
            width: '200px'
        }
    ];

    const handleDemoteToRR = (item) => {
        loader.show();
        let data = {
            userId: item.id,
            roleName: item.roleName
        };
        brokerService.updateSubordinateRole(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    getSupervisors(1);
                    toast.success(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const getSupervisors = (page) => {
        loader.show();
        let data = {
            "brokerUserId": params.id,
            "userRole": "Supervisor",
            "searchKeyword": keyword,
            "pageNumber": page,
            "pageSize": perPage
        };
        //adminService.searchSubOrdinates(data)
        adminService.getBrokerRRSupervisorForAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setSupervisorList(result);
                    setTotalRows(result[0].totalRecords);
                    loader.hide();
                }
                else {
                    setSupervisorList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    //Add supervisor
    let addformikValues = {
        adduserName: null,
        addPassword: null,
        addConfirmPassword: null,
        addfirstName: null,
        addlastName: null,
        addmiddleInitial: null,
        addemailAddress: null,
        addroleName: null,
        addisLocked: null
    };

    let ADD_INITIAL_FORM_STATE = addformikValues;

    const ADD_FORM_VALIDATION = Yup.object().shape({
        adduserName: Yup.string().required("Username is required").nullable(),
        addfirstName: Yup.string().required("First name is required").nullable(),
        addlastName: Yup.string().required("Last name is required").nullable(),
        addemailAddress: Yup.string().email('Invalid email format').required('Email address is required').nullable(),
        addPassword: Yup.string().required("Password is required").min(8, "Password must be at least 8 characters"),
        addConfirmPassword: Yup.string().required("Confirm password is required")
            .oneOf([Yup.ref("addPassword"), null], "Password and confirm password doesn’t match").nullable(),
        addroleName: Yup.string().required("Please select role").nullable()
    });

    const addformik = useFormik({
        enableReinitialize: true,
        initialValues: ADD_INITIAL_FORM_STATE,
        validationSchema: ADD_FORM_VALIDATION,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                loggedInUserId: null,
                userName: values.adduserName,
                password: values.addPassword,
                firstName: values.addfirstName,
                lastName: values.addlastName,
                middleInitial: values.addmiddleInitial,
                emailAddress: values.addemailAddress,
                roleName: values.addroleName,
                brokerUserId: params.id,
                isLocked: showStatus,
            };
            adminService.addSubordinate(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setSupervisorAddShow(false);
                        getSupervisors(1);
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    else {
                        toast.error(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    //Edit supervisor 
    let formikValues = {
        userName: currentSupervisor?.userName,
        firstName: currentSupervisor?.firstName,
        lastName: currentSupervisor?.lastName,
        middleInitial: currentSupervisor?.middleInitial,
        emailAddress: currentSupervisor?.emailAddress,
        isLocked: showStatus
    };

    let INITIAL_FORM_STATE = formikValues;

    INITIAL_FORM_STATE = formikValues;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                loggedInUserId: null,
                UserId: currentSupervisor?.id,
                firstName: values.firstName,
                lastName: values.lastName,
                middleInitial: values.middleInitial,
                emailAddress: values.emailAddress,
                isLocked: values.isLocked
            };
            brokerService.updateBrokerSubOrdinate(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        setSupervisorEditShow(false);
                        getSupervisors(1);
                        toast.success(json.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        loader.hide();
                    }
                    loader.hide();
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handlePageChange = page => {
        getSupervisors(page);
    };

    const toggleStatusCheckbox = (cbVal) => {
        if (cbVal) {
            setShowStatus(true);
        }
        else {
            setShowStatus(false);
        }
    }

    /* Searching criteria starts here */

    let searchFormikValues = {
        searchKeyword: null
    };

    let SEARCH_INITIAL_FORM_STATE = searchFormikValues;

    SEARCH_INITIAL_FORM_STATE = searchFormikValues;
    const searchformik = useFormik({
        enableReinitialize: true,
        initialValues: SEARCH_INITIAL_FORM_STATE,

        onSubmit: (values, { resetForm }) => {
            loader.show();
            let data = {
                "brokerUserId": params.id,
                "userRole": "Supervisor",
                "searchKeyword": values.searchKeyword,
                "pageNumber": 1,
                "pageSize": perPage
            };
            //adminService.searchSubOrdinatesByAdmin(data)
            adminService.getBrokerRRSupervisorForAdmin(data)
                .then((json) => {
                    if (json.data.payload !== null) {
                        var result = json.data.payload;
                        setSupervisorList(result);
                        setTotalRows(result[0].totalRecords);
                        loader.hide();
                    }
                    else {
                        setSupervisorList(null);
                        loader.hide();
                    }
                })
                .catch((error) => {
                    toast.error(error, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                });
        }
    });

    const handleResetFields = () => {
        searchformik.resetForm({ values: { searchKeyword: '' } });
        getSupervisors(1);
    }

    /* Searching criteria ends here */

    const handlePerRowsChange = async (newPerPage, page) => {
        loader.show();
        let data = {
            "brokerUserId": params.id,
            "userRole": "Supervisor",
            "searchKeyword": keyword,
            "pageNumber": page,
            "pageSize": perPage
        };
        //adminService.searchSubOrdinatesByAdmin(data)
        adminService.getBrokerRRSupervisorForAdmin(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    var result = json.data.payload;
                    setSupervisorList(result);
                    setTotalRows(result[0].totalRecords);
                    setPerPage(newPerPage);
                    loader.hide();
                }
                else {
                    setSupervisorList(null);
                    loader.hide();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    };

    return (
        <>
            <section className="light-wrapper" data-gr-ext-installed="" light-mode="light">
                <Header />
                <LeftNav />
                <section className="page-contents" data-gr-ext-installed="" light-mode="light">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/admin/dashboard">Dashboard</Link></li>
                            <li class="breadcrumb-item"><Link to="/admin/brokers">Brokers</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Supervisors</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className="heading">Supervisors</h2>
                        </div>
                        <div className="col-lg-8">
                            <form onSubmit={searchformik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="input_wrapper pad-10">
                                            <div className="input_area">
                                                <input type="text" className="inputForm" placeholder="Search here" name="searchKeyword" 
                                                    onBlur={searchformik.handleBlur} onChange={searchformik.handleChange} value={searchformik.values.searchKeyword}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul className="search_btns float-left">
                                            <li><span className="searchBtn" onClick={() => handleResetFields(searchformik)} style={styleCursor}>Clear</span></li>
                                            <li><span><button type='submit' className='btn-primary' style={styleCursor}>Search</button></span></li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-3'>
                                        <ul className="table_btns float-right">
                                            <li><button type='button' className='btn-primary btn-sm-auto' onClick={() => handleSueprvisorAddShow()}>Add Supervisor</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {supervisorList && <DataTable theme="default" columns={columns} data={supervisorList}
                        pagination paginationServer
                        paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange} />}
                    {supervisorList === null && <DataTable pagination noDataComponent="No record found" />}

                    <div className="row pt-3">
                    </div>

                </section>

                {/* Add Supervisor */}
                <Modal
                    show={supervisorAddShow}
                    onHide={handleSupervisorAddClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Add a New Supervisor</h3>

                                        <form onSubmit={addformik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="adduserName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.adduserName} />
                                                                    {addformik.touched.adduserName && addformik.errors.adduserName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.adduserName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Password<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="password" className="inputForm" name="addPassword"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addPassword} />
                                                                    {addformik.touched.addPassword && addformik.errors.addPassword ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addPassword}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Confirm Password<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="password" className="inputForm" name="addConfirmPassword"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addConfirmPassword} />
                                                                    {addformik.touched.addConfirmPassword && addformik.errors.addConfirmPassword ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addConfirmPassword}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addfirstName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addfirstName} />
                                                                    {addformik.touched.addfirstName && addformik.errors.addfirstName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addfirstName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addlastName"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={formik.values.addlastName} />
                                                                    {addformik.touched.addlastName && addformik.errors.addlastName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addlastName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addmiddleInitial"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addmiddleInitial} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="addemailAddress"
                                                                        maxLength="50" onBlur={addformik.handleBlur} onChange={addformik.handleChange}
                                                                        value={addformik.values.addemailAddress} />
                                                                    {addformik.touched.addemailAddress && addformik.errors.addemailAddress ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addemailAddress}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Select Role<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <select name="addroleName" className="form-select"
                                                                        onBlur={formik.handleBlur} onChange={addformik.handleChange} value={addformik.values.addroleName}>
                                                                        <option value="">Select Role</option>
                                                                        {supervisorRoles && supervisorRoles.map((option) => (
                                                                            <option key={option.id} value={option.name}>{option.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {addformik.touched.addroleName && addformik.errors.addroleName ? (
                                                                        <p className="error" style={styleLeft}>{addformik.errors.addroleName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-2">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Locked</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleStatusCheckbox(e.target.checked)}
                                                                        type="checkbox" onBlur={addformik.handleBlur}
                                                                        value={addformik.values.addisLocked}
                                                                        checked={showStatus ? true : false}
                                                                        id="addcbStatus" name="addcbStatus" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Add</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleSupervisorAddClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Edit Supervisor */}
                <Modal
                    show={supervisorEditShow}
                    onHide={handleSupervisorEditClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='md'
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Body>
                        <div className='modal-dialog modal-dialog-centered'>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='msgs_Area'>
                                        <h3 className=' pb-3'>Update Supervisor</h3>

                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Username</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="userName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange} disabled="true"
                                                                        value={formik.values.userName} />
                                                                    {formik.touched.userName && formik.errors.userName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.userName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">First Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="firstName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.firstName} />
                                                                    {formik.touched.firstName && formik.errors.firstName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.firstName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Last Name<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="lastName"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.lastName} />
                                                                    {formik.touched.lastName && formik.errors.lastName ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.lastName}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Middle Initial</label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="middleInitial"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.middleInitial} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Email Address<span className="error-info">*</span></label>
                                                                <div className="input_area">
                                                                    <input type="text" className="inputForm" name="emailAddress"
                                                                        maxLength="50" onBlur={formik.handleBlur} onChange={formik.handleChange}
                                                                        value={formik.values.emailAddress} />
                                                                    {formik.touched.emailAddress && formik.errors.emailAddress ? (
                                                                        <p className="error" style={styleLeft}>{formik.errors.emailAddress}</p>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-2">
                                                            <div className="input_wrapper">
                                                                <label className="form-label cellText">Locked</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10">
                                                            <div className="input_wrapper">
                                                                <div className="form-check pt-1">
                                                                    <input className="form-check-input"
                                                                        onClick={(e) => toggleStatusCheckbox(e.target.checked)}
                                                                        type="checkbox" onBlur={formik.handleBlur}
                                                                        value={formik.values.isLocked}
                                                                        checked={showStatus ? true : false}
                                                                        id="cbStatus" name="cbStatus" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'><button type='submit' className='yes_noBtn'>Update</button></div>
                                                    <div className='col-lg-6'><button type='button' className='yes_noBtn cancel_btn' onClick={(e) => handleSupervisorEditClose()}>Cancel</button></div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        </>
    );
};

export default BrokerSupervisors;