import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { checkAuthorization, getCurrentApplicationId, getLoggedInUserInfo } from '../common/common';
import applicationService from '../services/applicationservice';
import OLAEnums from '../helpers/olaenums';
import * as Yup from "yup";
import Modal from 'react-bootstrap/Modal';
import loader from "super-react-loader";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldErrorStyle } from '../hooks/FieldErrorStyle';
import FormErrorToottip from './FormErrorTooltip';
import { defaultCountry } from '../helpers/constants';
const file_validation = ["image/jpeg", "image/png", "image/jpg", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"]

const EntityDocuments = () => {
    const navigate = useNavigate();
    const [accountFeatures, setAccountFeatures] = useState(null);
    const [appDocuments, setAppDocuments] = useState(null);
    const [countAppDocuments, setCountAppDocuments] = useState([]);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [deletedDocument, setDeletedDocument] = useState(null);
    const [ctrlDisable, setCtrlDisable] = useState(false);

    const [authSignersList, setAuthSignersList] = useState(null);
    const [showGovtIdforSigner1, setShowGovtIdforSigner1] = useState(false);
    const [showPassportforSigner1, setShowPassportforSigner1] = useState(false);
    const [showDLforSigner1, setShowDLforSigner1] = useState(false);

    const [showGovtIdforSigner2, setShowGovtIdforSigner2] = useState(false);
    const [showPassportforSigner2, setShowPassportforSigner2] = useState(false);
    const [showDLforSigner2, setShowDLforSigner2] = useState(false);

    const [showGovtIdforSigner3, setShowGovtIdforSigner3] = useState(false);
    const [showPassportforSigner3, setShowPassportforSigner3] = useState(false);
    const [showDLforSigner3, setShowDLforSigner3] = useState(false);

    const [showGovtIdforSigner4, setShowGovtIdforSigner4] = useState(false);
    const [showPassportforSigner4, setShowPassportforSigner4] = useState(false);
    const [showDLforSigner4, setShowDLforSigner4] = useState(false);

    //entity document types
    const [showLLCDocs, setShowLLCDocs] = useState(false);
    const [showCorporationDocs, setShowCorporationDocs] = useState(false);
    const [showScorporationDocs, setShowSCorporationDocs] = useState(false);
    const [showTrustDocs, setShowTrustDocs] = useState(false);
    const [showPartnershipDocs, setShowPartnershipDocs] = useState(false);
    const [saveOnly, setSaveOnly] = useState(false);

    const [singleFile, setSingleFile] = useState(null);
    const [singleFileforSigner1, setSingleFileforSigner1] = useState(null);
    const [singleFileforSigner2, setSingleFileforSigner2] = useState(null);
    const [singleFileforSigner3, setSingleFileforSigner3] = useState(null);
    const [singleFileforSigner4, setSingleFileforSigner4] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const styles = {
        color: 'blue', fontWeight: '200', cursor: 'pointer',
    };

    const stylesBold = {
        fontWeight: 'bold', marginTop: 8, marginBottom: 8
    };

    const stylesBoldNormal = {
        fontWeight: 'bold',
    };

    const styleLabelWidth = {
        width: '420px'
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getApplicationDocuments();
    }, []);

    const handlePrevious = () => {
        navigate('/owners-officials');
    }

    const getApplicationDocuments = () => {
        loader.show();
        let data = {
            UserId: getLoggedInUserInfo().id,
            ApplicationId: getCurrentApplicationId(),
            Level: 7
        }
        applicationService.getApplicationBrokerLevel(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    let result = json.data.payload;
                    if (result.applicationInfo !== null) {
                        if (result?.applicationInfo?.applicationStatus !== OLAEnums.ApplicationStatusEnum.NotCompleted) {
                            setCtrlDisable(true);
                        }
                        else {
                            setCtrlDisable(false);
                        }
                    }
                    if (result?.accountFeaturesInfo !== null) {
                        setAccountFeatures(result?.accountFeaturesInfo);
                    }
                    if (result.applicationDocumentsList !== null) {
                        var entityDocs = result?.applicationDocumentsList.filter(x => x.applicationId === getCurrentApplicationId());
                        setAppDocuments(entityDocs);
                    }
                    if (result?.entitySignerInfoList !== null) {
                        setAuthSignersList(result?.entitySignerInfoList);

                        if (result?.entitySignerInfoList.length > 0) {
                            if (result?.entitySignerInfoList[0]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                setShowGovtIdforSigner1(true);
                                setShowPassportforSigner1(false);
                                setShowDLforSigner1(false);
                            }
                            else if (result.entitySignerInfoList[0]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                setShowGovtIdforSigner1(false);
                                setShowPassportforSigner1(true);
                                setShowDLforSigner1(false);
                            }
                            else if (result.entitySignerInfoList[0]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowGovtIdforSigner1(false);
                                setShowPassportforSigner1(false);
                                setShowDLforSigner1(true);
                            }
                            else {
                                setShowGovtIdforSigner1(true);
                                setShowPassportforSigner1(false);
                                setShowDLforSigner1(false);
                            }
                        }
                        if (result?.entitySignerInfoList.length > 1) {
                            if (result?.entitySignerInfoList[1]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                setShowGovtIdforSigner2(true);
                                setShowPassportforSigner2(false);
                                setShowDLforSigner2(false);
                            }
                            else if (result.entitySignerInfoList[1]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                setShowGovtIdforSigner2(false);
                                setShowPassportforSigner2(true);
                                setShowDLforSigner2(false);
                            }
                            else if (result.entitySignerInfoList[1]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowGovtIdforSigner2(false);
                                setShowPassportforSigner2(false);
                                setShowDLforSigner2(true);
                            }
                            else {
                                setShowGovtIdforSigner2(true);
                                setShowPassportforSigner2(false);
                                setShowDLforSigner2(false);
                            }
                        }
                        if (result?.entitySignerInfoList.length > 2) {
                            if (result?.entitySignerInfoList[2]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                setShowGovtIdforSigner3(true);
                                setShowPassportforSigner3(false);
                                setShowDLforSigner3(false);
                            }
                            else if (result.entitySignerInfoList[2]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                setShowGovtIdforSigner3(false);
                                setShowPassportforSigner3(true);
                                setShowDLforSigner3(false);
                            }
                            else if (result.entitySignerInfoList[2]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowGovtIdforSigner3(false);
                                setShowPassportforSigner3(false);
                                setShowDLforSigner3(true);
                            }
                            else {
                                setShowGovtIdforSigner3(true);
                                setShowPassportforSigner3(false);
                                setShowDLforSigner3(false);
                            }
                        }
                        if (result?.entitySignerInfoList.length > 3) {
                            if (result?.entitySignerInfoList[3]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Other)) {
                                setShowGovtIdforSigner4(true);
                                setShowPassportforSigner4(false);
                                setShowDLforSigner4(false);
                            }
                            else if (result.entitySignerInfoList[3]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.Passport)) {
                                setShowGovtIdforSigner4(false);
                                setShowPassportforSigner4(true);
                                setShowDLforSigner4(false);
                            }
                            else if (result.entitySignerInfoList[3]?.idTypeId === parseInt(OLAEnums.DocumentTypeEnum.DriverLicense)) {
                                setShowGovtIdforSigner4(false);
                                setShowPassportforSigner4(false);
                                setShowDLforSigner4(true);
                            }
                            else {
                                setShowGovtIdforSigner4(true);
                                setShowPassportforSigner4(false);
                                setShowDLforSigner4(false);
                            }
                        }
                    }
                    if (result.entityInformationInfo !== null) {
                        setEntityDocType(parseInt(result?.entityInformationInfo?.taxClassification));
                    }
                    loader.hide();
                }
                loader.hide();
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
                loader.hide();
            });
    }

    const setEntityDocType = (entityType) => {
        if (entityType === OLAEnums.EntityAccountTypeEnum.CCorporation || entityType === OLAEnums.EntityAccountTypeEnum.SCorporation) {
            setShowCorporationDocs(true);
            setShowSCorporationDocs(true);
            setShowLLCDocs(false);
            setShowTrustDocs(false);
            setShowPartnershipDocs(false);
        }
        else if (entityType === OLAEnums.EntityAccountTypeEnum.Partnership) {
            setShowCorporationDocs(false);
            setShowSCorporationDocs(false);
            setShowLLCDocs(false);
            setShowTrustDocs(false);
            setShowPartnershipDocs(true);
        }
        else if (entityType === OLAEnums.EntityAccountTypeEnum.LimitedLiabilityCompany) {
            setShowCorporationDocs(false);
            setShowSCorporationDocs(false);
            setShowLLCDocs(true);
            setShowTrustDocs(false);
            setShowPartnershipDocs(false);
        }
        else if (entityType === OLAEnums.EntityAccountTypeEnum.Trust) {
            setShowCorporationDocs(false);
            setShowSCorporationDocs(false);
            setShowLLCDocs(false);
            setShowTrustDocs(true);
            setShowPartnershipDocs(false);
        }
    }

    const schema = Yup.object().shape({
        /*Auth Signer1 */
        fileGovtIssueIdSigner1: showGovtIdforSigner1 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePassportSigner1: showPassportforSigner1 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileDrivingLicenseSigner1: showDLforSigner1 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),

        /*Auth Signer2 */
        fileGovtIssueIdSigner2: showGovtIdforSigner2 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePassportSigner2: showPassportforSigner2 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileDrivingLicenseSigner2: showDLforSigner2 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),

        /*Auth Signer3 */
        fileGovtIssueIdSigner3: showGovtIdforSigner3 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePassportSigner3: showPassportforSigner3 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileDrivingLicenseSigner3: showDLforSigner3 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),

        /*Auth Signer4 */
        fileGovtIssueIdSigner4: showGovtIdforSigner4 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload government issued Id document", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        filePassportSigner4: showPassportforSigner4 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload passport", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),
        fileDrivingLicenseSigner4: showDLforSigner4 ? Yup.mixed().required()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            })
            .test("fileRequired", "Please upload driving license", (value) => {
                if (value.length === 0 && appDocuments.length === 0) {
                    return false;
                }
                else {
                    return true;
                }
            }) : Yup.string().optional(),

        fileDividentAuth: accountFeatures?.dividendReinvestment ? Yup.mixed()
            .test("fileSize", "The file is too large", (value) => {
                if (!value.length) return true
                return value[0].size <= 5242880
            })
            .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
                if (!value.length) return true
                return value.length && file_validation.includes(value[0].type)
            }) : Yup.string().optional(),

        //additional documents validations
        // fileIncorporateArticles: (showCorporationDocs || showScorporationDocs) && Yup.mixed().required()
        // .test("fileSize", "The file is too large", (value) => {
        //     if (!value.length) return true
        //     return value[0].size <= 5242880
        // }).test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
        //     if (!value.length) return true
        //     return value.length && file_validation.includes(value[0].type)
        // }).test("fileRequired", "Please upload articles of incorporation", (value) => {
        //     if (value.length === 0 && appDocuments.length === 0) {
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }),

        // fileLLCArticles: showLLCDocs && Yup.mixed().required().test("fileSize", "The file is too large", (value) => {
        //     if (!value.length) return true
        //     return value[0].size <= 5242880
        // })
        // .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
        //     if (!value.length) return true
        //     return value.length && file_validation.includes(value[0].type)
        // })
        // .test("fileRequired", "Please upload articles of organization and the operating agreement.", (value) => {
        //     if (value.length === 0 && appDocuments.length === 0) {
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }),

        // fileTrustCertificate: showTrustDocs && Yup.mixed().required().test("fileSize", "The file is too large", (value) => {
        //     if (!value.length) return true
        //     return value[0].size <= 5242880
        // })
        // .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
        //     if (!value.length) return true
        //     return value.length && file_validation.includes(value[0].type)
        // })
        // .test("fileRequired", "Please upload certificate of trust", (value) => {
        //     if (value.length === 0 && appDocuments.length === 0) {
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }),

        // filePartnershipAgreement: showPartnershipDocs && Yup.mixed().required().test("fileSize", "The file is too large", (value) => {
        //     if (!value.length) return true
        //     return value[0].size <= 5242880
        // })
        // .test("fileType", "The acceptable file formats: .pdf, .doc, .docx, .png, .jpeg, .jpg", (value) => {
        //     if (!value.length) return true
        //     return value.length && file_validation.includes(value[0].type)
        // })
        // .test("fileRequired", "Please upload partnership agreement", (value) => {
        //     if (value.length === 0 && appDocuments.length === 0) {
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }),

    });

    const { register, handleSubmit, formState: { errors, touchedFields, isValid }, setValue } = useForm({
        resolver: yupResolver(schema),
    })

    /*Handle file upload for auth signer1*/
    const handleChangeGovtIssueIdSigner1 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner1(chosenFiles, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangePassportSigner1 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner1(chosenFiles, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangeDrivingLicenseSigner1 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner1(chosenFiles, OLAEnums.DocumentTypeEnum.DriverLicense);
    }

    /*Handle file upload for auth signer2*/
    const handleChangeGovtIssueIdSigner2 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner2(chosenFiles, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangePassportSigner2 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner2(chosenFiles, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangeDrivingLicenseSigner2 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner2(chosenFiles, OLAEnums.DocumentTypeEnum.DriverLicense);
    }

    /*Handle file upload for auth signer3*/
    const handleChangeGovtIssueIdSigner3 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner3(chosenFiles, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangePassportSigner3 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner3(chosenFiles, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangeDrivingLicenseSigner3 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner3(chosenFiles, OLAEnums.DocumentTypeEnum.DriverLicense);
    }

    /*Handle file upload for auth signer4*/
    const handleChangeGovtIssueIdSigner4 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner4(chosenFiles, OLAEnums.DocumentTypeEnum.Other);
    }
    const handleChangePassportSigner4 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner4(chosenFiles, OLAEnums.DocumentTypeEnum.Passport);
    }
    const handleChangeDrivingLicenseSigner4 = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFileforSigner4(chosenFiles, OLAEnums.DocumentTypeEnum.DriverLicense);
    }

    const handleChangeDividentAuth = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadSingleFile(chosenFiles, OLAEnums.DocumentTypeEnum.Dividentreinvestauthorizationdoc);
    }
    //Additional entity documents starts here
    const handleChangeCorporations = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadEntityFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Corporations);
    }
    const handleChangeLLCs = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadEntityFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.LLCs);
    }
    const handleChangeTrusts = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadEntityFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Trusts);
    }
    const handleChangePartnerships = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadEntityFiles(chosenFiles, e.target.name, OLAEnums.DocumentTypeEnum.Partnership);
    }
    //Additional entity documents ends here
    const onSubmit = data => {
        if (uploadedFiles.length === 0) {
            loader.hide();
            if (!saveOnly) {
                navigate("/disclosures-signatures");
            }
        }
        if (uploadedFiles.length > 0) {
            uploadEntityApplicationDocuments();
        }
    }

    const uploadEntityApplicationDocuments = async () => {
        loader.show();
        const userRole = getLoggedInUserInfo().userRole;
        let fileCounter = 0;

        try {
            const filesToUpload = [...uploadedFiles];

            if(singleFile) {
                filesToUpload.unshift(singleFile);
            }
            if (singleFileforSigner1) {
                filesToUpload.unshift(singleFileforSigner1);
            }
            if (singleFileforSigner2) {
                filesToUpload.unshift(singleFileforSigner2);
            }
            if (singleFileforSigner3) {
                filesToUpload.unshift(singleFileforSigner3);
            }
            if (singleFileforSigner4) {
                filesToUpload.unshift(singleFileforSigner4);
            }

            for (const file of filesToUpload) {
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };
                const formData = new FormData();
                formData.append("UserId", getLoggedInUserInfo().id);
                formData.append("ApplicationId", getCurrentApplicationId());
                formData.append("file", file.file);
                formData.append("UploadedBy", userRole);
                formData.append("DocumentType", file.docType);
                formData.append("IsPrimaryApplicant", true);
                formData.append("CreatedBy", getLoggedInUserInfo().id);
                const json = await applicationService.uploadApplicationDocuments(formData, config);

                if (json.data.errorCode === 111 || json.data.errorCode === 112) {
                    toast.error(json.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    loader.hide();
                    return;
                } else {
                    if (!saveOnly) {
                        navigate("/disclosures-signatures");
                    } else {
                        fileCounter++;
                    }
                }
                if (fileCounter === filesToUpload.length) {
                    toast.success("Documents have been uploaded successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    getApplicationDocuments();
                    loader.hide();

                    // filesToUpload.length = 0;
                    // filesToUpload = [];
                    setSingleFile(null);
                    setSingleFileforSigner1(null);
                    setSingleFileforSigner2(null);
                    setSingleFileforSigner3(null);
                    setSingleFileforSigner4(null);
                    setUploadedFiles(null);
                }
            }
        } catch (error) {
            checkAuthorization(error);
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
            loader.hide();
        }
    };

    const handleUploadSingleFile = (files, docType) => {
        if (file_validation.includes(files[0].type)) {
            setSingleFile({ "file": files[0], "docType": docType });
        }
    }

    const handleUploadSingleFileforSigner1 = (files, docType) => {
        if (file_validation.includes(files[0].type)) {
            setSingleFileforSigner1({ "file": files[0], "docType": docType });
        }
    }
    const handleUploadSingleFileforSigner2 = (files, docType) => {
        if (file_validation.includes(files[0].type)) {
            setSingleFileforSigner2({ "file": files[0], "docType": docType });
        }
    }
    const handleUploadSingleFileforSigner3 = (files, docType) => {
        if (file_validation.includes(files[0].type)) {
            setSingleFileforSigner3({ "file": files[0], "docType": docType });
        }
    }
    const handleUploadSingleFileforSigner4 = (files, docType) => {
        if (file_validation.includes(files[0].type)) {
            setSingleFileforSigner4({ "file": files[0], "docType": docType });
        }
    }
    const handleUploadEntityFiles = (files, name, docType) => {
        const uploaded = [...uploadedFiles];
        for (let i = 0; i < files.length; i++) {
            if (file_validation.includes(files[i].type)) {
                let file = files[i];
                if (uploaded.findIndex((f) => f.name === file.name) === -1 && !uploadedFiles.map(ele => ele.name).includes(file.name)) {
                    uploaded.push({ "file": file, "docType": docType });
                }
                else {
                    toast.error('Duplicate files are not allowed')
                    setValue(name, null)
                }
                setUploadedFiles(uploaded);
            }
        }
    }

    const fileDownloader = (fileName) => {
        applicationService.downloadFileFromS3(fileName)
            .then((json) => {
                if (json.data.payload !== null) {
                    const blob = json.data;
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const deleteApplicationDocument = (data) => {
        setDeletedDocument(data);
        setShowRemoveModal(true);
    }
    const deleteDocumentConfirm = (doc) => {
        let data = {
            Id: doc.id,
            ApplicationId: doc.applicationId,
            Key: doc.key
        }
        applicationService.deleteApplicationDocument(data)
            .then((json) => {
                if (json.data.payload !== null) {
                    toast.success("Document has been deleted successfully", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    handleCloseRemoveModal();
                    getApplicationDocuments();
                }
            })
            .catch((error) => {
                toast.error(error, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }
    const handleCloseRemoveModal = () => setShowRemoveModal(false);
    let erobj;
    for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
            erobj = { ...erobj, [key]: errors[key].message }
        }
    }
    useFieldErrorStyle(errors, touchedFields)
    return (
        <>
            <div className="tab-content" id="document">
                <FormErrorToottip errorList={erobj || {}} touchlist={erobj}></FormErrorToottip>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formContainer">
                        <div id="personal" className="container-fluid">
                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="heading">Document Upload</h2>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="paragraph1">Acceptable File Formats: .pdf, .doc, .docx, .png, .jpeg |
                                            Maximum File Size: 5MB</p>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <ul className="browser_btns mt-4">
                                                    <li><h2 className='heading'>Entity Documents</h2></li>
                                                </ul>

                                                {/*Auth Signer 1*/}
                                                {showGovtIdforSigner1 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID for <span style={stylesBoldNormal}>{authSignersList[0]?.firstName + ' ' + authSignersList[0]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileGovtIssueIdSigner1')} onChange={handleChangeGovtIssueIdSigner1} disabled={ctrlDisable}
                                                                className="inputForm" name="fileGovtIssueIdSigner1" />
                                                            <p className="error">{errors?.fileGovtIssueIdSigner1 && errors.fileGovtIssueIdSigner1.message}</p>
                                                        </div>

                                                    </div>
                                                </div>}

                                                {showPassportforSigner1 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document for <span style={stylesBoldNormal}>{authSignersList[0]?.firstName + ' ' + authSignersList[0]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePassportSigner1')} className="inputForm" name="filePassportSigner1" disabled={ctrlDisable} onChange={handleChangePassportSigner1} />
                                                            <p className="error">{errors?.filePassportSigner1 && errors.filePassportSigner1.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showDLforSigner1 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License for <span style={stylesBoldNormal}>{authSignersList[0]?.firstName + ' ' + authSignersList[0]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDrivingLicenseSigner1')} className="inputForm" name="fileDrivingLicenseSigner1" disabled={ctrlDisable} onChange={handleChangeDrivingLicenseSigner1} />
                                                            <p className="error">{errors?.fileDrivingLicenseSigner1 && errors.fileDrivingLicenseSigner1.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {/*Auth Signer 2*/}
                                                {showGovtIdforSigner2 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID for <span style={stylesBoldNormal}>{authSignersList[1]?.firstName + ' ' + authSignersList[1]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileGovtIssueIdSigner2')} onChange={handleChangeGovtIssueIdSigner2} disabled={ctrlDisable}
                                                                className="inputForm" name="fileGovtIssueIdSigner2" />
                                                            <p className="error">{errors?.fileGovtIssueIdSigner1 && errors.fileGovtIssueIdSigner2.message}</p>
                                                        </div>

                                                    </div>
                                                </div>}

                                                {showPassportforSigner2 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document for <span style={stylesBoldNormal}>{authSignersList[1]?.firstName + ' ' + authSignersList[1]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePassportSigner2')} className="inputForm" name="filePassportSigner2" disabled={ctrlDisable} onChange={handleChangePassportSigner2} />
                                                            <p className="error">{errors?.filePassportSigner2 && errors.filePassportSigner2.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showDLforSigner2 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License for <span style={stylesBoldNormal}>{authSignersList[1]?.firstName + ' ' + authSignersList[1]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDrivingLicenseSigner2')} className="inputForm" name="fileDrivingLicenseSigner2" disabled={ctrlDisable} onChange={handleChangeDrivingLicenseSigner2} />
                                                            <p className="error">{errors?.fileDrivingLicenseSigner2 && errors.fileDrivingLicenseSigner2.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {/*Auth Signer 3*/}
                                                {showGovtIdforSigner3 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID for <span style={stylesBoldNormal}>{authSignersList[2]?.firstName + ' ' + authSignersList[2]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileGovtIssueIdSigner3')} onChange={handleChangeGovtIssueIdSigner3} disabled={ctrlDisable}
                                                                className="inputForm" name="fileGovtIssueIdSigner3" />
                                                            <p className="error">{errors?.fileGovtIssueIdSigner3 && errors.fileGovtIssueIdSigner3.message}</p>
                                                        </div>

                                                    </div>
                                                </div>}

                                                {showPassportforSigner3 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document for <span style={stylesBoldNormal}>{authSignersList[2]?.firstName + ' ' + authSignersList[2]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePassportSigner3')} className="inputForm" name="filePassportSigner3" disabled={ctrlDisable} onChange={handleChangePassportSigner3} />
                                                            <p className="error">{errors?.filePassportSigner3 && errors.filePassportSigner3.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showDLforSigner3 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License for <span style={stylesBoldNormal}>{authSignersList[2]?.firstName + ' ' + authSignersList[2]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDrivingLicenseSigner3')} className="inputForm" name="fileDrivingLicenseSigner3" disabled={ctrlDisable} onChange={handleChangeDrivingLicenseSigner3} />
                                                            <p className="error">{errors?.fileDrivingLicenseSigner3 && errors.fileDrivingLicenseSigner3.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {/*Auth Signer 4*/}
                                                {showGovtIdforSigner4 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Government Issued ID for <span style={stylesBoldNormal}>{authSignersList[3]?.firstName + ' ' + authSignersList[3]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileGovtIssueIdSigner4')} onChange={handleChangeGovtIssueIdSigner4} disabled={ctrlDisable}
                                                                className="inputForm" name="fileGovtIssueIdSigner4" />
                                                            <p className="error">{errors?.fileGovtIssueIdSigner4 && errors.fileGovtIssueIdSigner4.message}</p>
                                                        </div>

                                                    </div>
                                                </div>}

                                                {showPassportforSigner4 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Passport Document for <span style={stylesBoldNormal}>{authSignersList[3]?.firstName + ' ' + authSignersList[3]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('filePassportSigner4')} className="inputForm" name="filePassportSigner4" disabled={ctrlDisable} onChange={handleChangePassportSigner4} />
                                                            <p className="error">{errors?.filePassportSigner4 && errors.filePassportSigner4.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showDLforSigner4 && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Driving License for <span style={stylesBoldNormal}>{authSignersList[3]?.firstName + ' ' + authSignersList[3]?.lastName}</span><span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDrivingLicenseSigner4')} className="inputForm" name="fileDrivingLicenseSigner4" disabled={ctrlDisable} onChange={handleChangeDrivingLicenseSigner4} />
                                                            <p className="error">{errors?.fileDrivingLicenseSigner4 && errors.fileDrivingLicenseSigner4.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {accountFeatures && accountFeatures?.dividendReinvestment && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Upload Divident Reinvest Authorization Document</label>
                                                        <div className="input_area">
                                                            <input type="file" {...register('fileDividentAuth')} className="inputForm" name="fileDividentAuth" disabled={ctrlDisable}
                                                                onChange={handleChangeDividentAuth} />
                                                            <p className="error">{errors?.fileDividentAuth && errors.fileDividentAuth.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* Additional entity documents starts here */}
                                                {(showCorporationDocs || showScorporationDocs) && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Corporations: Articles of Incorporation<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" multiple {...register('fileIncorporateArticles')} className="inputForm" name="fileIncorporateArticles" disabled={ctrlDisable}
                                                                onChange={handleChangeCorporations} />
                                                            <p className="error">{errors?.fileIncorporateArticles && errors.fileIncorporateArticles.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showLLCDocs && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>LLCs: Articles of Organization and the Operating Agreement<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" multiple {...register('fileLLCArticles')} className="inputForm" name="fileLLCArticles" disabled={ctrlDisable}
                                                                onChange={handleChangeLLCs} />
                                                            <p className="error">{errors?.fileLLCArticles && errors.fileLLCArticles.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showTrustDocs && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Trusts: Certificate of Trust<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" multiple {...register('fileTrustCertificate')} className="inputForm" name="fileTrustCertificate" disabled={ctrlDisable}
                                                                onChange={handleChangeTrusts} />
                                                            <p className="error">{errors?.fileTrustCertificate && errors.fileTrustCertificate.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {showPartnershipDocs && <div className="rows">
                                                    <div className="col-lg-6">
                                                        <label className="form-label" style={styleLabelWidth}>Partnership: Partnership Agreement<span className="error-info">*</span></label>
                                                        <div className="input_area">
                                                            <input type="file" multiple {...register('filePartnershipAgreement')} className="inputForm" name="filePartnershipAgreement" disabled={ctrlDisable}
                                                                onChange={handleChangePartnerships} />
                                                            <p className="error">{errors?.filePartnershipAgreement && errors.filePartnershipAgreement.message}</p>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* Additional entity documents ends here */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="formCard">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="row">
                                                    <div className='col-lg-12'><h2 className="heading">Uploaded Entity Documents</h2></div>
                                                </div>
                                                <div className="rows">
                                                    {appDocuments && appDocuments?.length > 0 && <div className="">
                                                        <table className="table table-area">
                                                            <tbody data-gr-ext-installed="" light-mode="light">
                                                                {appDocuments && appDocuments.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td style={{ width: "100%" }}><span style={styles} onClick={(e) => fileDownloader(item.key)}>{item.name}</span> - ({item.documentTypeName})</td>
                                                                        <td style={{ width: "100%" }}>
                                                                            {!ctrlDisable && <span className="ico_table icon-Icon-awesome-window-close" onClick={(e) => deleteApplicationDocument(item)} title='Remove Document'></span>}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                    {appDocuments && appDocuments?.length === 0 && <div className="">
                                                        <p className='paragraph1'>No document found</p>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="btnWrapper">
                        <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(false)} disabled={ctrlDisable}>Save and Continue</button></li>
                        {isValid && <li><button type='submit' className='btn-primary' onClick={() => setSaveOnly(true)} disabled={ctrlDisable}>Save</button></li>}
                        <li><button type='button' className='btn-primary' onClick={handlePrevious}>Previous</button></li>
                    </ul>
                </form>
            </div>

            <Modal
                show={showRemoveModal}
                onHide={handleCloseRemoveModal}
                backdrop="static"
                keyboard={false}
                centered
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body>
                    <div className='modal-dialog modal-dialog-centered'>
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='imgArea'>
                                    <img src='../assets/images/del_img.png' />
                                </div>
                                <div className='msgs_Area'>
                                    <h3>Delete</h3>
                                    <p>Do you want to delete <span style={stylesBold}>{deletedDocument?.name}</span> document?</p>
                                    <div className='row'>
                                        <div className='col-lg-1'></div>
                                        <div className='col-lg-5'><button type='button' className='yes_noBtn' onClick={(e) => deleteDocumentConfirm(deletedDocument)}>Delete</button></div>
                                        <div className='col-lg-5'><button type='button' className='yes_noBtn cancel_btn' onClick={handleCloseRemoveModal}>Cancel</button></div>
                                        <div className='col-lg-1'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default EntityDocuments;