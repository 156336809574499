const ParentLovEnum = {
    Personal: 4503,
    MaritalStatus: 21,
    InvestmentObjective: 2316,
    InvestmentExperience: 5516,
    RiskTolerance: 5521,
    TimeHorizon: 4519,
    TaxRate: 62, //Tax bracket
    AnnualIncome: 39,
    NetWorth: 46,
    LiquidNetWorth: 54,
    LiquidityNeeds: 80,
    BankAccountType: 7532, //ACH bank account type
    CATSAccountType: 7650,
    Prefix: 13,
    IndustrialClassification: 7639,
    EntityAccountActivity: 7577,
    ExpectedWithdrawals: 7573,
    CommercialRetailBusiness: 7684,
    PrimaryOngoingFundingSource: 7688,
    IRABeneficiaryType:7681,
    LegalEntityCustomersExemptions: 7719,
    ApplicationStatus: 181,
    IdType: 4513 //Load Id types govtId, passport, driving license etc
}

const AccountTypeEnum = {
    IndividualAccount: 2,
    JointAccount: 3,
    CorporateAccount: 4,
    EntityAccount: 2417,
    IRA: 2418,
    Personal: 4503,
    Retirement: 4504
}

const PersonalAccountTypeEnum = {
    Individual: 4505,
    Joint: 4506,
    Trust: 4507,
    Estate: 4508,
    SoleProprietorship: 4509,
    UGMA_UTMA: 4510
}

const EntityAccountTypeEnum = {
    CCorporation: 2430,
    LimitedLiabilityCompany: 4512, //LLC
    SCorporation: 2431,
    Partnership: 2432,
    Trust: 2433,
    LLCCCorporation: 2434, 
    LLCSCorporation:2435,
    LLCNonCorporate: 2436,
    LLCPartnership: 2437,
    ForeignFinancialInstitution: 4511
}

const RetirementTypeEnum = {
    TraditionalIRA: 7673,
    RothIRA: 7674,
    SimpleIRAParticipant: 7675,
    BeneficiaryRothIRA: 7676,
    BeneficiaryTraditionalIRA: 7677,
    RolloverIRA: 7678,
    SEPIRA:7679
}

const ApplicantTypeEnum = {
    PrimaryApplicant: 10,
    CoApplicant: 11,
    EntityApplicant: 12,
    IRAApplicant: 2422
}

const EmploymentStatusEnum = {
    Employed: 28,
    SelfEmployed: 29,
    Unemployed: 30,
    Retired: 31,
    Student: 32,
    Other: 33,
    Homemaker: 2351
}

const AccountTypeOptionsEnum = {
    Cash: 189,
    Margin: 190,
    Option: 191
}

const OptionStrategyLevelEnum = {
    Level0: 5525,
    Level1: 2335,
    Level2: 2336,
    Level3: 2337,
    Level4: 2338,
    Level5: 3487,
    Level6: 3488
}

const ApplicationStatusEnum = {
    NotCompleted: 182,
    Completed: 183,
    AccountPending: 184,
    Declined: 185,
    Approved: 187,
    Approved_RR: 215,
    Approved_Sup: 216,
    Declined_RR: 218,
    Declined_Sup: 219,
    Closed: 5541,
    SubmittedPendingApproval:6529,
    Canceled: 6530,
    PendingReview_RR: 8721,
    UnderReview_RR: 8722,
    ActionRequired_RR: 8723,
    PendingReview_Sup: 8724,
    UnderReview_Sup: 8725,
    ActionRequired_Sup: 8726,
    Submitted_ClearingFirm: 8727,
    InfoRequired_ClearingFirm: 8728,
    Approved_ClearingFirm: 8729
}

const AlertTypeEnum = {
    DomesticReminder: 1,
    ForeignReminder: 2,
    CSRNotes: 3,
    FileUpload: 4,
    IdentificationFailedviaGIACT: 5,
    ACHBankAuthenticationFailedviaGIACT: 6,
    EmailVerificationStep1: 7,
    EmailVerificationStep2: 8,
    IdentificationSuccessfulviaGIACT: 9,
    ACHBankAuthenticationSuccessfulviaGIACT: 10,
    ApplicationNotCompleted: 11,
    ApplicationPendingApproval: 12,
    AccountDeclined: 13,
    AccountApproved: 14,
    RRApproved: 15,
    SupervisorApproved: 16,
    RRDeclined: 17,
    SupervisorDeclined: 18,
    AccountClosed: 19,
    AccountCanceled: 20,
    AccountPendingReview_RR: 21,
    AccountUnderReview_RR: 22,
    AccountActionRequired_RR: 23,
    AccountPendingReview_Sup: 24,
    AccountUnderReview_Sup: 25,
    AccountActionRequired_Sup: 26,
    AccountSubmitted_ClearingFirm: 27,
    AccountInfoRequired_ClearingFirm: 28,
    AccountApproved_ClearingFirm: 29
}

const DocumentTypeEnum = {
    DriverLicense: 4514, //Driving license
    Other: 4515, //Other Govt Id
    AffiliatedApproval: 6528,
    NewAccountApplicationAgreement: 7556,
    TradingAuthorizationForm: 7557,
    Dividentreinvestauthorizationdoc: 7558,
    ForeignDueDiligence: 7571,
    Passport: 7572, //Passport,
    LLCs: 8743,
    Corporations: 8744,
    Trusts: 8745,
    Partnership: 8746
}

const OwnerfficialsTypeEnum = {
    Official: 7667,
    Owner: 7668
}

const MicrobiltAPIEnum = {
    AddressNameVerify: 1,
    PhoneNameVerify: 2,
    PhoneAddressVerify: 3,
    SSNNameVerify: 4,
    SSNAddressVerify: 5,
    SSNPhoneVerify: 6,
    SSNVerify: 7,
    DeathMasterVerify: 8,
    AddressStandardVerify: 9,
    IPAddressVerify: 10,
    EmailVerify: 11,
    DLFormatVerify: 12,
    WLSearchVerify: 13,
    IDVerify: 14,
    MLAVerify: 15,
    ABAAccountVerify: 16,
    ACHCheckPrescreenLite: 17,
    ACHAndCheckPrescreen: 18,
    ExtendedACHCheckPrescreen: 19
}

const GiactAPIEnum = {
    BankAccountVerifyPerson: 1,
    BankAccountVerifyBusiness: 2,
    GOfacPerson: 3,
    GOfacBusiness: 4,
    ESI: 5,
    GIdentifyPerson: 6,
    GIdentifyBusiness: 7
}

//Investor Profile Enums starts here
const InvestmentExpEnum = {
    None: 5517,
    Limited: 5518,
    Good: 5519,
    Extensive: 5520
}
const AnnualIncomeEnum = {
    "$0-24,999": 40,
    "$25,000-50,000": 41,
    "$50,001-100,000": 42,
    "$100,001-200,000": 43,
    "$200,001-300,000": 45,
    "$300,001-500,000": 2360,
    "$500,001-1,200,000": 2361,
    "$1,200,001-9,999,999": 2362
}
const TotalNetWorthEnum = {
    "$0-24,999": 47,
    "$25,000-50,000": 8739,
    "$50,001-100,000": 48,
    "$100,001-200,000": 49,
    "$200,001-500,000": 50,
    "$500,001-1,000,000": 51,
    "$1,000,001-5,000,000": 52,
    "$5,000,001-9,999,999": 53
}
const LiquidNetWorthEnum = {
    "$0-24,999": 8740,
    "$25,000-50,000": 55,
    "$50,001-100,000": 56,
    "$100,001-200,000": 57,
    "$200,001-500,000": 58,
    "$500,001-1,000,000": 59,
    "$1,000,001-5,000,000": 60,
    "$5,000,001-9,999,999": 61
}

const BeneficiaryTypeEnum = {
    Primary: 7682,
    Contingent: 7683
}

//Investor Profile Enums ends here

const OLAEnums = {
    ParentLovEnum,
    AccountTypeEnum,
    ApplicantTypeEnum,
    EmploymentStatusEnum,
    OptionStrategyLevelEnum,
    ApplicationStatusEnum,
    AlertTypeEnum,
    DocumentTypeEnum,
    AccountTypeOptionsEnum,
    PersonalAccountTypeEnum,
    EntityAccountTypeEnum,
    RetirementTypeEnum,
    OwnerfficialsTypeEnum,
    MicrobiltAPIEnum,
    GiactAPIEnum,
    InvestmentExpEnum,
    AnnualIncomeEnum,
    TotalNetWorthEnum,
    LiquidNetWorthEnum,
    BeneficiaryTypeEnum
};

export default OLAEnums;